<div class="avatar-wrapper">
    <ng-container *ngFor="let avatar of avatars | slice:0:4; let i=index">
        <ng-container *ngIf="i < 3 else showPlus">
            <div class="avatar" [title]="avatar.fullName" [style.background]="avatar.color">
                <span>{{ avatar.initials }}</span>
            </div>
        </ng-container>
        <ng-template #showPlus>
            <app-tooltip
                [width]="220"
                [triggerTemplate]="plus"
                [forceRight]="true"
                backgroundColor="white"
                innerStyling="background:white; box-shadow: -1px 0px 10px 0px rgba(126,126,126,1); max-height:450px">
                <ng-container *ngFor="let avatar of avatars">
                    <div class="tooltip-contents">
                        <div class="avatar" [style.background]="avatar.color">
                            <span>{{ avatar.initials }}</span>
                        </div>
                        <span>{{ avatar.fullName }}</span>
                    </div>
                </ng-container>
            </app-tooltip>
        </ng-template>
    </ng-container>
</div>

<ng-template #plus>
    <div class="avatar plus">
        <span>+{{ avatars.length - 3 }}</span>
    </div>
</ng-template>
