<ng-template #commands>
    <ul>
        <li
            *ngFor="let command of dropDownCommands"
            (click)="dropDownOutput(command)">
            <span class="command">{{ command.key }}</span> <span class="parameters">{{ command.value }}</span>
        </li>
    </ul>
</ng-template>
<div class="component-main">
    <div class="content">
        <h1>{{ 'execute_shell_command' | myLbTranslate }}</h1>
    </div>
    <form [formGroup]="commandForm">
        <div class="form-group">
            <!--suppress XmlInvalidId -->
            <label for="execute-command">{{ 'execute_command_common' | myLbTranslate }}</label>
            <div class="form-content">
                <app-dropdown
                    dropDownId="execute-command"
                    title="{{ selectedCommand ? selectedCommand : '- Select command -' }}"
                    displayAsInput="true">
                    <div class="drop-container-html">
                        <ng-template [ngTemplateOutlet]="commands"></ng-template>
                    </div>
                </app-dropdown>
                <app-help class="help-button" tag="execute_common-commands"></app-help>
            </div>
        </div>
        <div class="form-group">
            <label for="execute-command-line">{{ 'execute_command_input' | myLbTranslate }}</label>
            <div class="form-content">
                <input
                    #commandLine
                    id="execute-command-line"
                    title="Command To Execute"
                    type="text"
                    size="30"
                    name="commandString"
                    formControlName="commandString"
                />
                <app-help class="help-button" tag="execute_input"></app-help>
            </div>
        </div>
    </form>
    <div *ngIf="returnedCommand" class="code-wrap">
        <ng-container *ngIf="supportRequest; else code">
            <div class="support-request">
                <img src="assets/shared/images/yes.gif" alt="support request"/>
            </div>
        </ng-container>
        <ng-template #code>
            <app-display-code [data]="returnedCommand"></app-display-code>
        </ng-template>
    </div>
</div>
<div class="component-footer">
    <app-loading-button
        buttonId="exec-shell-comm-execute"
        buttonClass="primary"
        buttonText="submit"
        [isDisabled]="commandForm.invalid || commandForm.pristine"
        (click)="sendCommand()">
    </app-loading-button>
</div>
