<div id="restart-page-wrapper" class="external-page">
    <div id="main-content">
        <span class="title">{{'restart_page_title' | myLbTranslate}}</span>
        <span class="sub-title">{{'restart_page_sub_title' | myLbTranslate}}</span>
    </div>
    <div id="restartSpinner">
        <img class="loader" src="assets/shared/icons/loading-spinner-white.svg" alt="loading spinner"/>
    </div>
</div>
<div id="copyright-footer">
    <span>&copy; Loadbalancer.org, {{currentYear}} Inc. {{'external_all_rights_reserved' | myLbTranslate}}</span>
</div>
