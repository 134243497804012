<div class="component-main">
    <div class="content-space-between">
        <h1>{{ 'system_update_software_update' | myLbTranslate }}</h1>
        <!-- if is dark net box (this should always show even if there are no updates) -->
        <button
            id="system-update-manual-update"
            class="primary"
            [disabled]="isButtonDisabled('system-update-manual-update')"
            routerLink="manual-update">
            {{ 'system_update_manual_update' | myLbTranslate }}
        </button>
    </div>
    <ng-container *ngIf="isLoading else content">
        <h3>{{ 'system_update_package_wait' | myLbTranslate }}</h3>
    </ng-container>
    <ng-template #content>
        <ng-container *ngIf="!(updates.majorVersion.length || updates.packages.length) && !error.status">
            <h3>{{ 'system_update_system_up_to_date' | myLbTranslate }}</h3>
        </ng-container>
        <ng-container *ngIf="error.status === 'warning'">
            <h3>{{ 'system_update_failed' | myLbTranslate }}</h3>
        </ng-container>
        <ng-container *ngIf="error.status === 'error'">
            <h3>{{ error.message }}</h3>
        </ng-container>
        <ng-container *ngIf="updates.majorVersion.length">
            <h3>{{ 'system_update_package_to_update_major' | myLbTranslate }} {{ updates.majorVersion.length }}</h3>
            <app-table [rows]="updates.majorVersion" [columnMode]="'flex'" [selectMode]="'none'">
                <app-table-column [flexGrow]="1" prop="versionNumber" name="system_update_version">
                </app-table-column>
                <app-table-column [flexGrow]="1" prop="changelogURL" name="system_update_change_log">
                    <ng-template let-row="row" appLbTableColumnCell>
                        <a href="{{row.changelogURL}}" target="_blank">{{ row.changelogURL }}</a>
                    </ng-template>
                </app-table-column>
                <app-table-column [flexGrow]="0.5" prop="action" [cellAlignment]="'right'">
                    <ng-template let-row="row" appLbTableColumnCell>
                        <button
                            class="primary"
                            [disabled]="isButtonDisabled('system-update-online-update')"
                            (click)="confirmMajorOnlineUpdate(row)">
                            {{ 'update' | myLbTranslate }} {{ 'to' | myLbTranslate }} {{ row.versionNumber }}
                        </button>
                    </ng-template>
                </app-table-column>
            </app-table>
        </ng-container>
        <br>
        <ng-container *ngIf="updates.packages.length">
            <div class="content-space-between">
                <h3>{{ 'system_update_package_to_update' | myLbTranslate }} {{ updates.packages.length }}</h3>
                <button
                    id="system-update-online-update"
                    class="primary"
                    [disabled]="isButtonDisabled('system-update-online-update')"
                    (click)="confirmOnlineUpdate()">
                    {{ 'system_update_online_update' | myLbTranslate }}
                </button>
            </div>
            <app-search-rows
                [rows]="updates.packages"
                [searchKeys]="searchKeys"
                (searchResults)="updateTableRows($event)">
            </app-search-rows>
            <app-table [rows]="updates.packages" [columnMode]="'flex'" [selectMode]="'none'">
                <app-table-column [flexGrow]="1" prop="name" name="name"></app-table-column>
                <app-table-column [flexGrow]="1" prop="currentVersion" name="system_update_current_version">
                </app-table-column>
                <app-table-column [flexGrow]="1" prop="pendingVersion" name="system_update_pending_version">
                </app-table-column>
                <app-table-column [flexGrow]="1" prop="release" name="system_update_release"></app-table-column>
                <app-table-column [flexGrow]="1" prop="architecture" name="arch"></app-table-column>
            </app-table>
        </ng-container>
    </ng-template>
</div>

<app-modal-wrapper
    modalHeight="130"
    modalWidth="400"
    modalTitle="system_update_package_updating"
    modalId="wait"
    [showCloseButton]="false"
    [showCancelButton]="false"
    [isOverlayClickable]="false">
    <app-modal-body>
        <div class="progress-text">
            <p>{{ 'system_update_package_wait' | myLbTranslate }}</p>
            <img class="loader" src="assets/shared/icons/loading-spinner-primary.svg" alt="loading spinner"/>
        </div>
    </app-modal-body>
</app-modal-wrapper>

<app-modal-wrapper
    modalTitle="restart"
    modalId="restartConfirm"
    [showCloseButton]="false">
    <app-modal-body>
        <p>{{ 'system_update_restart_required' | myLbTranslate }}</p>
    </app-modal-body>
    <app-modal-footer>
        <app-loading-button
            buttonId="system-update-restart"
            buttonText="restart"
            buttonClass="primary"
            [callBack]="restart.bind(this)">
        </app-loading-button>
    </app-modal-footer>
</app-modal-wrapper>
