<div class="drag-n-drop-table-wrapper">
    <ng-container *ngIf="tableHeaders?.length">
        <div class="table-header">
            <ng-container *ngFor="let header of tableHeaders">
                <div>{{ header }}</div>
            </ng-container>
            <div class="action-dots"></div>
        </div>
    </ng-container>
    <ng-container *ngIf="tableRows?.length">
        <div class="drag-n-drop-list" appDragNDrop (selectedData)="updateSelected($event)">
            <ng-container *ngFor="let row of tableRows">
                <div class="table-row drag-item" [attr.data-form]="row.id">
                    <ng-container *ngFor="let rawHeader of rawTableHeaders">
                        <div>{{ row[rawHeader] }}</div>
                    </ng-container>
                    <div class="action-dots">
                        <ng-container *ngIf="actionDotsTemplate; else defaultContent">
                            <ng-container *ngTemplateOutlet="actionDotsTemplate; context: {$implicit: row}">
                            </ng-container>
                        </ng-container>
                        <ng-template #defaultContent></ng-template>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>
