<div class="component-main">
    <div class="content-space-between">
        <h1>{{ (editId ? 'net_int_config_edit_vlan' : 'net_int_config_vlan_add') | myLbTranslate }}</h1>
    </div>
    <form [formGroup]="formGroup">
        <div class="form-group">
            <label for="name">{{ 'name' | myLbTranslate }}</label>
            <div class="form-content">
                <input type="text" name="ip" id="name" formControlName="name"/>
                <app-validation-messages [control]="formGroup.controls['name']"></app-validation-messages>
                <app-help class="help-button" tag="add-edit-vlan_name"></app-help>
            </div>
        </div>
        <div class="form-group">
            <!--suppress XmlInvalidId -->
            <label for="interface">{{ 'interface' | myLbTranslate }}</label>
            <div class="form-content">
                <app-dropdown
                    dropDownId="interface"
                    [formGroup]="formGroup"
                    formControlName="parentId"
                    [content]="dropdown">
                </app-dropdown>
                <app-help class="help-button" tag="add-edit-vlan_interface"></app-help>
            </div>
        </div>
        <div class="form-group">
            <label for="vlanId">{{ 'id' | myLbTranslate }}</label>
            <div class="form-content">
                <input
                    type="number"
                    name="ip"
                    id="vlanId"
                    formControlName="vlanId"
                    min="0"
                    max="4095"
                    placeholder="0-4095">
                <app-validation-messages [control]="formGroup.controls['vlanId']"></app-validation-messages>
                <app-help class="help-button" tag="add-edit-vlan_id"></app-help>
            </div>
        </div>
    </form>
</div>
<div class="component-footer">
    <button
        id="vlan-cancel"
        class="outline"
        [routerLink]="editId ? '../../' : '../'">
        {{ 'cancel' | myLbTranslate }}
    </button>
    <app-loading-button
        buttonId="vlan-submit"
        buttonClass="primary"
        buttonText="submit"
        [isDisabled]="formGroup.invalid || formGroup.pristine"
        [callBack]="submitVlan.bind(this)">
    </app-loading-button>
</div>
