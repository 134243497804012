import { Component } from '@angular/core';

@Component({
    selector: 'app-security-dashboard',
    templateUrl: './security-dashboard.component.html',
    styleUrls: ['./security-dashboard.component.scss']
})
export class SecurityDashboardComponent {
    constructor() {
    }
}
