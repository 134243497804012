<ng-container *ngIf="isLoading else table">
    <div class="loading">
        <div>
            <div class="dot-spin"></div>
            <p>Loading table data</p>
        </div>
    </div>
</ng-container>
<ng-template #table>
    <ng-template #pager let-position="position">
        <app-table-pagination
            *ngIf="(rows && amountShown) && (rows.length >= amountShown)"
            select="[pager-bottom]"
            dropdownDirection="{{ position }}"
            [rows]="rows"
            [rowsLength]="rowsLength"
            [amountShown]="amountShown"
            (itemsShown)="handlePaginationClick($event)">
        </app-table-pagination>
    </ng-template>

    <div class="table-wrapper">
        <div *ngIf="tableTitle || (showColumns === true && hiddenColumns.length)" class="content-space-between">
            <h2 *ngIf="tableTitle" class="table-title">{{ tableTitle | myLbTranslate }}</h2>
            <div class="hidden-columns" *ngIf="showColumns === true">
                <button
                    *ngIf="hiddenColumns.length"
                    class="primary"
                    (click)="modal.openModalInParent('hiddenColumn')">
                    {{ 'table_hidden_columns' | myLbTranslate }}
                </button>
            </div>
        </div>

        <ng-container [ngTemplateOutlet]="pager" [ngTemplateOutletContext]="{position: 'down'}"></ng-container>

        <ng-container *ngIf="(amountShown && rows) && (rows.length > amountShown) && showSelectAllMessage">
            <p
                *ngIf="selectedRows && rows &&
                selectedRows.length !== rows.length &&
                selectedRows.length !== amountShown"
                class="selection-message">
                {{ selectedRows.length }}
                {{ 'table_component_all_selected_page' | myLbTranslate}}
                <button class="button-as-link" (click)="handleSelectAll()">
                    {{ 'select_all' | myLbTranslate }} {{ rows && rows.length }} {{ 'rows' | myLbTranslate }}.
                </button>
            </p>
            <p *ngIf="(selectedRows && rows) && (selectedRows.length === amountShown)" class="selection-message">
                {{ 'table_component_all' | myLbTranslate}}
                {{ amountShown }}
                {{ 'table_component_all_selected_page' | myLbTranslate}}
                <button class="button-as-link" (click)="handleSelectAll()">
                    {{ 'select_all' | myLbTranslate }} {{ rows && rows.length }} {{ 'rows' | myLbTranslate }}.
                </button>
            </p>
            <p *ngIf="(selectedRows && rows) && (selectedRows.length === rows.length)" class="selection-message">
                {{ 'table_component_all' | myLbTranslate}}
                {{ rows.length }}
                {{ 'table_component_are_selected' | myLbTranslate}}
                <button class="button-as-link" (click)="handleUnselectingAll()">
                    {{ 'table_component_clear_selection' | myLbTranslate }}
                </button>
            </p>
        </ng-container>

        <div class="table-component" [class.top-border]="tableTopBorder">
            <app-table-header
                [columnHidden]="columnHidden"
                [columnsArray]="columnsArray"
                [columnMode]="columnMode"
                [showColumns]="showColumns"
                [pageRows]="pageRows"
                [fixedHeader]="fixedHeader"
                [selected]="selectMode"
                [selectAll]="selectAll"
                [selectedRows]="selectedRows"
                [selectAllActionDots]="selectAllActionDots"
                [rows]="rows"
                [tableWidth]="tableWidth"
                [unit]="unit"
                (selectedAllVisible)="handleAllVisibleSelected($event)"
                (hiddenColumns)="getHiddenColumns($event)"
                (newColumnsArray)="reorderColumnsArray($event)"
                (newColumnWidths)="getNewColumnWidths($event)">
            </app-table-header>
            <app-table-new-row
                [newRow]="newRow"
                [columnsArray]="columnsArray"
                [columnHidden]="columnHidden"
                [columnMode]="columnMode"
                [tableWidth]="tableWidth"
                [unit]="unit">
            </app-table-new-row>
            <app-table-body
                [columnHidden]="columnHidden"
                [columnsArray]="columnsArray"
                [columnMode]="columnMode"
                [rows]="rows"
                [pageRows]="pageRows"
                [fixedHeader]="fixedHeader"
                [fixedTableHeight]="fixedTableHeight"
                [selectMode]="selectMode"
                [selectAll]="selectAll"
                [tableWidth]="tableWidth"
                [unit]="unit"
                [selectedRows]="selectedRows"
                (allVisibleSelected)="outputSelectedRows($event)">
                <ng-content></ng-content>
            </app-table-body>
            <app-table-footer
                [rowsLength]="rowsLength"
                [fixedHeader]="fixedHeader"
                [unit]="unit"
                [selectedRows]="selectedRows">
            </app-table-footer>
        </div>

        <ng-container [ngTemplateOutlet]="pager" [ngTemplateOutletContext]="{position: 'up'}"></ng-container>

        <div *ngIf="!disableTableOverlay" class="table-mobile-overlay">
            <img src="assets/shared/images/rotation-icon.png"/>
            <div class="table-message">
            <span>{{ 'tables_are_best_viewed' | myLbTranslate }}
                <br>{{ 'table_landscape_on_small_devices' | myLbTranslate }}
            </span>
                <button class="primary"
                        (click)="hideTableOverlay()">{{ 'table_close_overlay' | myLbTranslate }}</button>
            </div>
        </div>
    </div>

    <app-modal-wrapper *ngIf="showColumns === true" modalId="hiddenColumn" modalTitle="table_hidden_columns">
        <app-modal-body>
            <table class="hidden-columns-table">
                <tr *ngFor="let column of hiddenColumns; let i = index;">
                    <td>{{ column }}</td>
                    <td>
                        <button class="primary" (click)="showHiddenColumns(column, i)">Show
                        </button>
                    </td>
                </tr>
            </table>
        </app-modal-body>
    </app-modal-wrapper>
</ng-template>


