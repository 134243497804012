import { WizardTitleEnum } from '../../enums';

export const MonitorTemplate = {
    title: 'L7 Monitor',
    version: 1,
    type: 'monitor',
    modes: ['http'],
    description: 'This sets up a frontend monitor. In order to use this template you need to have a service in HTTP mode.',
    sections: {
        [WizardTitleEnum.MONITORS]: [
            {
                title: 'Monitor',
                label: {
                    value: '',
                    hidden: false
                },
                ports: {
                    value: [80],
                    hidden: false
                },
                mode: {
                    value: 'http',
                    hidden: false
                },
                monitorUri: {
                    value: '',
                    hidden: false
                },
                fipId: {
                    value: null,
                    hidden: false
                },
                aclIds: {
                    value: ['$1'],
                    hidden: false
                }
            },
        ],
        [WizardTitleEnum.ACLS]: [
            {
                title: 'ACL',
                serviceID: {
                    value: '$1',
                    hidden: true
                },
                conditionType: {
                    value: 'default',
                    hidden: true,
                },
                redirectLocation: {
                    value: '$1',
                    hidden: true
                }
            }
        ],
    }
};
