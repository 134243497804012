<div class="grid__item" #gridItem style="width: 420px">
    <div class="grid-content-column-space-between">
        <owl-carousel-o [options]="customOptions">
            <ng-container *ngFor="let slide of slides">
                <ng-template carouselSlide>
                    <div class="slide">
                        <div class="image-wrapper">
                            <img
                                *ngIf="slide.mainImageSrc"
                                ngSrc="{{slide.mainImageSrc}}"
                                height="140"
                                width="310"
                                alt="main image"/>
                        </div>
                        <div class="text-wrapper">
                            <div class="item-header">
                                <h2>{{ slide.title | myLbTranslate }}</h2>
                            </div>
                            <ul class="details" [innerHTML]="slide.details"></ul>
                        </div>
                        <button *ngIf="slide.linkUrl" [routerLink]="slide.linkUrl" target="_blank">
                            {{ slide.linkText | myLbTranslate }}
                        </button>
                        <button *ngIf="slide.callBack" (click)="slide.callBack()">
                            {{ slide.linkText | myLbTranslate }}
                        </button>
                    </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
</div>

<app-calendly-widget
    *ngIf="userObject?.name || userObject.name === undefined"
    [userDetails]="userObject"
    [hidden]="hideCalendly"
    (hideCalendly)="hideCalendlyWidget()">
</app-calendly-widget>
