<div class="component-main">
    <div class="content-space-between">
        <h1 id="component-title">{{title | myLbTranslate | titlecase}}</h1>
    </div>
    <ng-container *ngIf="step === 0">
        <ng-container *ngIf="editId else new">
            <div class="edit-wrapper">
                <div class="form-wrapper">
                    <div class="top-line">
                        <img
                            ngSrc="assets/centralisedManagement/icons/billing_american_express.svg"
                            alt="credit card icon"
                            height="20"
                            width="21">
                        <label>{{cardDetails.issuer}}</label>
                        <span>{{cardDetails.cardNumber}}</span>
                    </div>

                    <form [formGroup]="formGroup">
                        <div class="form-group">
                            <label for="nameOnCard">{{ 'billing_card_name' | myLbTranslate }}*</label>
                            <div class="form-content">
                                <input formControlName="nameOnCard" id="nameOnCard" name="Name on card"
                                       title="nameOnCard"
                                       type="text">
                                <app-validation-messages
                                    [control]="formGroup.get('nameOnCard')"></app-validation-messages>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="expiry">{{ 'billing_expiry' | myLbTranslate }}*</label>
                            <div class="form-content">
                                <input
                                    formControlName="expiry"
                                    id="expiry"
                                    name="expiry"
                                    title="expiry"
                                    type="text"
                                    placeholder="mm/yyyy"
                                    pattern="(0[1-9]|1[1,2])(\/)(19|20)\d{2}">
                                <app-validation-messages [control]="formGroup.get('expiry')"></app-validation-messages>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="address-wrapper">
                    <label>Billing Address</label>
                    <p>{{cardDetails.address}}</p>
                    <a (click)="stepForward()">{{'change' | myLbTranslate}}</a>
                </div>
            </div>
        </ng-container>
        <ng-template #new>
            <form [formGroup]="formGroup">
                <div class="form-group">
                    <label for="cardNumber">{{ 'billing_card_number' | myLbTranslate }}*</label>
                    <div class="form-content">
                        <input formControlName="cardNumber" id="cardNumber" name="Card number" title="cardNumber"
                               type="number">
                        <app-validation-messages [control]="formGroup.get('cardNumber')"></app-validation-messages>
                    </div>
                </div>
                <div class="form-group">
                    <label for="nameOnCard">{{ 'billing_card_name' | myLbTranslate }}*</label>
                    <div class="form-content">
                        <input formControlName="nameOnCard" id="nameOnCard" name="Name on card" title="nameOnCard"
                               type="text">
                        <app-validation-messages [control]="formGroup.get('nameOnCard')"></app-validation-messages>
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-row">
                        <div class="form-group">
                            <label for="expiry">{{ 'billing_expiry' | myLbTranslate }}*</label>
                            <div class="form-content">
                                <input
                                    formControlName="expiry"
                                    id="expiry"
                                    name="expiry"
                                    title="expiry"
                                    type="text"
                                    placeholder="mm/yyyy"
                                    pattern="(0[1-9]|1[1,2])(\/)(19|20)\d{2}">
                                <app-validation-messages [control]="formGroup.get('expiry')"></app-validation-messages>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="cvc">{{ 'billing_card_cvc' | myLbTranslate }}*</label>
                            <div class="form-content">
                                <input formControlName="cvc" id="cvc" name="cvc" title="cvc" type="number">
                                <app-validation-messages [control]="formGroup.get('cvc')"></app-validation-messages>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-content">
                        <input formControlName="default" id="default" class="lb-checkbox" type="checkbox"/>
                        <span>{{'billing_make_default' | myLbTranslate}}</span>
                    </div>
                </div>
            </form>
        </ng-template>
    </ng-container>
    <ng-container *ngIf="step === 1">
        some address stuff
    </ng-container>
</div>

<div class="component-footer">
    <button id="user-edit-cancel" class="outline" routerLink="/cm/organisation/billing/overview">
        {{'cancel' | myLbTranslate}}
    </button>
    <app-loading-button
        buttonId="billing-add-card-method"
        [isDisabled]="formGroup.invalid || formGroup.pristine"
        buttonText="submit"
        buttonClass="primary"
        [callBack]="addPaymentMethod.bind(this)">
    </app-loading-button>
</div>

