<div class="component-main">
    <div class="content-space-between">
        <h1 id="component-title">{{ 'portal_management' | myLbTranslate }}</h1>
    </div>
    <h3>{{ 'portal_management_adoption_status' | myLbTranslate }}</h3>
    <div *ngIf="loaded" class="content">
        <form [formGroup]="formGroup" class="process-container" autocomplete="off">
            <div
                class="step-title"
                [class.shuttle-submitted]="adoptionState === 2"
                [class.adopted]="adoptionState === 3">
                <ng-container *ngIf="adoptionState === 1">
                    <h3>{{ 'portal_management_not_submitted' | myLbTranslate }}</h3>
                </ng-container>
                <ng-container *ngIf="adoptionState === 2">
                    <h3>{{ 'portal_management_connection_submitted' | myLbTranslate }}</h3>
                </ng-container>
                <ng-container *ngIf="adoptionState === 3">
                    <h3>{{ 'portal_management_adoption_submitted' | myLbTranslate }}</h3>
                </ng-container>
            </div>
            <div class="step" [class.active]="activeStep === 1" [class.complete]="activeStep > 1" formGroupName="step1">
                <h3>{{ 'connection_details' | myLbTranslate }}</h3>
                <div class="step-content">
                    <ng-container *ngIf="activeStep === 1">
                        <div class="form-group">
                            <div class="form-content">
                                <input
                                    class="lb-checkbox"
                                    formControlName="enabled"
                                    id="management-enableShuttle"
                                    type="checkbox">
                                <label for="management-enableShuttle">
                                    {{ 'portal_management_shuttle_enabled' | myLbTranslate }}
                                </label>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="management-ip">{{ 'address' | myLbTranslate }}*</label>
                            <div class="form-content">
                                <input formControlName="portalAddress" id="management-ip" name="ip" type="text">
                                <app-validation-messages [control]="formGroup.get('step1.portalAddress')">
                                </app-validation-messages>
                                <app-help class="help-button" tag="portal-management_address"></app-help>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="management-port">{{ 'port' | myLbTranslate }}*</label>
                            <div class="form-content">
                                <input formControlName="portalPort" id="management-port" name="port" type="number">
                                <app-validation-messages [control]="formGroup.get('step1.portalPort')">
                                </app-validation-messages>
                                <app-help class="help-button" tag="portal-management_port"></app-help>
                            </div>
                        </div>
                        <div class="form-group">
                            <!--suppress XmlInvalidId -->
                            <label for="management-static-ip">
                                {{ 'portal_management_ip_address' | myLbTranslate }}
                            </label>
                            <div class="form-content">
                                <app-dropdown
                                    dropDownId="management-static-ip"
                                    formControlName="managementIPId"
                                    [formGroup]="getFormGroup('step1')"
                                    [content]="dropdownContent">
                                </app-dropdown>
                                <app-help class="help-button" tag="portal-management_management-ip"></app-help>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>{{ 'portal_management_subnets' | myLbTranslate }}</label>
                            <div class="form-vertical">
                                <div
                                    *ngFor="let control of getSubnetIdFields.controls; index as i"
                                    class="form-group">
                                    <div class="form-content">
                                        <input
                                            id="subnetId{{[i]}}"
                                            class="lb-checkbox"
                                            type="checkbox"
                                            [formControl]="getFormControl(control)"/>
                                        <label for="checkboxGlobalName{{[i]}}" class="lb-checkbox"></label>
                                        <span>{{ subnets[i].name }}</span>
                                        <app-help
                                            *ngIf="i === 0"
                                            class="help-button"
                                            tag="portal-management_management-subnets">
                                        </app-help>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="activeStep > 1">
                        <div class="set-content">
                            <span>
                                <strong>{{ 'shuttle' | myLbTranslate }}:</strong>
                                {{ 'enabled' | myLbTranslate | lowercase }}
                            </span>
                            <span>
                                <strong>{{ 'hostname' | myLbTranslate }}:</strong>
                                {{ formGroup.get('step1.portalAddress').value }}
                                </span>
                            <span>
                                <strong>{{ 'port' | myLbTranslate }}:</strong>
                                {{ formGroup.get('step1.portalPort').value }}
                                </span>
                            <span>
                                 <button class="button-as-link quinary right-arrow" (click)="setStep(1)">
                                    {{ 'edit' | myLbTranslate }}
                                </button>
                            </span>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="step" [class.active]="activeStep === 2" [class.complete]="activeStep > 2" formGroupName="step2">
                <h3>{{ 'portal_management_adoption_details' | myLbTranslate }}</h3>
                <div class="step-content">
                    <ng-container *ngIf="activeStep === 2">
                        <div class="form-group">
                            <label for="email">{{ 'portal_management_login_email' | myLbTranslate }}*</label>
                            <div class="form-content">
                                <input
                                    formControlName="email"
                                    id="email"
                                    name="email"
                                    type="email"
                                    autocomplete="one-time-email">
                                <app-validation-messages [control]="formGroup.get('step2.email')">
                                </app-validation-messages>
                                <app-help class="help-button" tag="portal-management_username"></app-help>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="password">{{ 'portal_management_login_password' | myLbTranslate }}*</label>
                            <div class="form-content">
                                <app-lb-reveal-password
                                    id="password"
                                    title="{{'password' | myLbTranslate }}"
                                    formControlName="password"
                                    [formGroup]="getFormGroup('step2')">
                                </app-lb-reveal-password>
                                <app-validation-messages [control]="formGroup.get('step2.password')">
                                </app-validation-messages>
                                <app-help class="help-button" tag="portal-management_password"></app-help>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="activeStep > 2">
                        <div class="set-content">
                            <ng-container *ngIf="formGroup.get('step2.email').value else basic">
                                <span>
                                    <strong>{{ 'email' | myLbTranslate }}:</strong>
                                    {{ formGroup.get('step2.email').value }}
                                </span>
                                <span>
                                    <strong>{{ 'password' | myLbTranslate }}:</strong> *******
                                </span>
                            </ng-container>
                            <ng-template #basic>
                                <span>
                                    <strong>{{ 'login' | myLbTranslate }}:</strong>
                                    {{ 'successful' | myLbTranslate }}
                                </span>
                            </ng-template>
                            <span *ngIf="formGroup.get('step1').valid">
                                 <button class="button-as-link quinary right-arrow" (click)="setStep(2)">
                                    {{ 'edit' | myLbTranslate }}
                                </button>
                            </span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="component-footer">
    <button
        *ngIf="activeStep === 1 || activeStep === 2 && adoptionState !== 1"
        id="management-new-back"
        class="outline"
        (click)="setStep(3)">
        {{ 'cancel' | myLbTranslate }}
    </button>
    <app-loading-button
        *ngIf="activeStep === 1"
        buttonId="management-submit-shuttle"
        buttonClass="primary"
        buttonText="submit"
        [isDisabled]="isNextButtonDisabled()"
        [callBack]="submitShuttle.bind(this)">
    </app-loading-button>
    <ng-container *ngIf="activeStep === 2">
        <app-loading-button
            buttonId="management-submit-adopt"
            buttonClass="primary"
            buttonText="adopt"
            [isDisabled]="isNextButtonDisabled()"
            [callBack]="adopt.bind(this)">
        </app-loading-button>
    </ng-container>
    <app-loading-button
        *ngIf="activeStep === 3 && adoptionState === 3"
        buttonId="management-remove"
        buttonClass="primary"
        buttonText="portal_management_remove_adc"
        [callBack]="confirmRemoveAdc.bind(this)">
    </app-loading-button>
</div>
