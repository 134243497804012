<div class="component-main">
    <div class="content">
        <h1>{{ 'user_interface' | myLbTranslate }}</h1>
    </div>
    <form class="form-vertical" [formGroup]="serviceForm">
        <div class="form-group">
            <!--suppress XmlInvalidId -->
            <label for="user-interface-ips">{{ 'ip' | myLbTranslate }}</label>
            <div class="form-content">
                <app-dropdown
                    dropDownId="user-interface-ips"
                    formControlName="ipId"
                    [formGroup]="serviceForm"
                    [content]="dropdownIps">
                </app-dropdown>
                <app-help class="help-button" tag="user-interface_ip"></app-help>
            </div>
        </div>
        <div class="form-group">
            <label for="apiPort">{{ 'user_interface_api_port' | myLbTranslate }}</label>
            <div class="form-content">
                <input
                    title="{{ 'user_interface_api_port' | myLbTranslate }}"
                    type="number"
                    size="30"
                    name="apiPort"
                    class="port"
                    id="apiPort"
                    formControlName="apiPort"/>
                <app-validation-messages [control]="serviceForm.get('apiPort')"></app-validation-messages>
                <app-help class="help-button" tag="user-interface_api-port"></app-help>
            </div>
        </div>
        <div class="form-group">
            <!--suppress XmlInvalidId -->
            <label for="user-interface-certs">{{ 'ssl_certificate' | myLbTranslate }}</label>
            <div class="form-content">
                <app-dropdown
                    dropDownId="user-interface-certs"
                    formControlName="SSLCertificate"
                    [dropdownWidth]="220"
                    [formGroup]="serviceForm"
                    [content]="dropdownCerts">
                </app-dropdown>
                <app-help class="help-button" tag="user-interface_ssl-certificate"></app-help>
            </div>
        </div>
        <app-lb-checkbox
            label="{{ 'user_interface_ssh_enabled' | myLbTranslate }}"
            [hideValidation]="true"
            formControlName="sshEnabled"
            helpTag="user-interface_ssl-enable"
            [formGroup]="serviceForm">
            <div class="form-group">
                <label for="sshPort">{{ 'user_interface_ssh_port' | myLbTranslate }}</label>
                <div class="form-content">
                    <input class="port" type="number" size="30" name="sshPort" id="sshPort" formControlName="sshPort"/>
                    <app-validation-messages [control]="serviceForm.get('sshPort')"></app-validation-messages>
                    <app-help class="help-button" tag="user-interface_ssh-port"></app-help>
                </div>
            </div>
        </app-lb-checkbox>
    </form>
</div>
<div class="component-footer">
    <app-loading-button
        buttonClass="primary"
        buttonText="submit"
        [isDisabled]="serviceForm.invalid || serviceForm.pristine"
        (click)="confirmSubmitUserInterface()"> <!-- opens modal therefore can't use callback -->
    </app-loading-button>
</div>
<app-count-down
    title="reload"
    description="user_interface_reload_model_text"
    interval="1500" (takeOff)="reloadAction()">
</app-count-down>
