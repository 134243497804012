<div class="component-main">
    <div class="content-left">
        <img *ngIf="headerIcon" [ngSrc]="headerIcon" height="32" width="32">
        <h1 id="component-title">{{ header | myLbTranslate }}</h1>
    </div>
    <div class="text-wrapper">
        <h3 *ngIf="titleText" class="empty-title">{{ titleText | myLbTranslate }}</h3>
        <p *ngIf="messageHtml" class="empty-message" [innerHTML]="messageHtml | safeHtml"></p>
        <p *ngIf="messageString" class="empty-message">{{ messageString | myLbTranslate }}</p>
        <ng-container *ngIf="messageTemplate" [ngTemplateOutlet]="messageTemplate"></ng-container>
    </div>

    <table *ngIf="!isError" class="mock-table">
        <thead>
        <tr>
            <th *ngFor="let tableHeader of mockTableHeaders; index as h">
                <ng-container *ngIf="tableHeader === '[placeholder]' else headerContent">
                    <div class="bars-wrapper">
                        <div class="bar" [style.width.%]="randomNumbersList[h + 1]" [style.max-width.px]="150"></div>
                    </div>
                </ng-container>
                <ng-template #headerContent>
                    {{ tableHeader | myLbTranslate }}
                </ng-template>
            </th>
            <th class="action-dots"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let tableRow of tableRows; index as i">
            <td *ngFor="let tableContent of mockTableHeaders; index as j">
                <div class="bars-wrapper">
                    <div *ngIf="j === 0" class="color-bar" [style.background-color]="colorList[i]"></div>
                    <div class="bar" [style.width.%]="randomNumbersList[j + i]"></div>
                </div>
            </td>
            <td class="action-dots">
                <span class="action-dot"></span>
            </td>
        </tr>
        </tbody>
    </table>

    <img class="main-image" [src]="imagePath" height="310" width="auto" alt="empty state for {{titleText}}"/>
</div>
<div *ngIf="buttonOneText || buttonTwoText || buttonThreeText || redirectToButton" class="component-footer">
    <button
        *ngIf="buttonOneText"
        [disabled]="buttonOneDisabled"
        [class]="buttonOneClass"
        (click)="buttonOneCallback()"
        [routerLink]="buttonOneLink">
        {{ buttonOneText | myLbTranslate }}
    </button>
    <button
        *ngIf="buttonTwoText"
        [disabled]="buttonTwoDisabled"
        [class]="buttonTwoClass"
        (click)="buttonTwoCallback()"
        [routerLink]="buttonTwoLink">
        {{ buttonTwoText | myLbTranslate }}
    </button>
    <button
        *ngIf="buttonThreeText"
        [disabled]="buttonThreeDisabled"
        [class]="buttonThreeClass"
        (click)="buttonThreeCallback()"
        [routerLink]="buttonThreeLink">
        {{ buttonThreeText | myLbTranslate }}
    </button>
    <button *ngIf="redirectToButton" class="primary" [routerLink]="redirectToLink">
        {{ 'upgrade_options' | myLbTranslate }}
    </button>
</div>
