<div class="component-main">
    <div class="content-space-between">
        <h1>{{ (userId ? 'rbac_users_edit_user' : 'rbac_users_add_user') | myLbTranslate }}</h1>
    </div>
    <form [formGroup]="formGroup">
        <div class="form-group">
            <label for="firstName">{{ 'name_first' | myLbTranslate }}*</label>
            <div class="form-content">
                <input
                    title="{{'name_first' | myLbTranslate }}"
                    type="text"
                    name="firstName"
                    id="firstName"
                    formControlName="firstName"/>
                <app-validation-messages [control]="formGroup.get('firstName')"></app-validation-messages>
            </div>
        </div>
        <div class="form-group">
            <label for="lastName">{{ 'name_last' | myLbTranslate }}*</label>
            <div class="form-content">
                <input
                    title="{{'name_last' | myLbTranslate }}"
                    type="text"
                    name="lastName"
                    id="lastName"
                    formControlName="lastName"/>
                <app-validation-messages [control]="formGroup.get('lastName')"></app-validation-messages>
            </div>
        </div>
        <div class="form-group">
            <label for="user-username">{{ 'email' | myLbTranslate }}*</label>
            <div class="form-content">
                <input
                    title="{{'email' | myLbTranslate }}"
                    type="email"
                    name="username"
                    id="user-username"
                    formControlName="email"/>
                <app-validation-messages [control]="formGroup.get('email')"></app-validation-messages>
            </div>
        </div>
        <div class="form-group">
            <label for="password">{{ 'password' | myLbTranslate }}</label>
            <div class="form-content">
                <app-lb-reveal-password
                    id="password"
                    title="{{'password' | myLbTranslate }}"
                    formControlName="password"
                    [formGroup]="formGroup">
                </app-lb-reveal-password>
                <app-validation-messages [control]="formGroup.get('password')"></app-validation-messages>
            </div>
        </div>
        <div class="form-group">
            <div class="form-content">
                <input
                    title="{{'disabled' | myLbTranslate }}"
                    id="disabled"
                    class="lb-checkbox"
                    type="checkbox"
                    formControlName="disabled">
                <label for="disabled">{{ 'disabled' | myLbTranslate }}</label>
            </div>
        </div>
    </form>
</div>
<div class="component-footer">
    <button id="user-add-edit-cancel" class="outline" [routerLink]="[ userId ? '../../../' : '../../' ]">
        {{ 'cancel' | myLbTranslate }}
    </button>
    <app-loading-button
        buttonId="user-add-edit-submit"
        buttonClass="primary"
        buttonText="submit"
        [isDisabled]="formGroup.invalid || formGroup.pristine"
        [handleLoadingStateExternally]="true"
        [callBack]="submitUserCheck.bind(this)">
    </app-loading-button>
</div>
