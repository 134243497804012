export * from './body/tableBody.component';
export * from './body/tableColumn.component';
export * from './body/tableRowWrapper.component';
export * from './footer/tableFooter.component';
export * from './header/tableHeader.component';
export * from './newRow/table-new-row.component';
export * from './pager/tablePager.component';
export * from './table.component';
export * from './tableNg.component';
export * from './tableNgColumn.component';
