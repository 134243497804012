<ng-template #successMarketingBlock>
    <img src="assets/shared/logos/logo-white-text.svg" class="logo" alt="Logo"/>
    <img src="assets/shared/images/envelope.svg" alt="email sent image"/>
    <span class="marketing-footer">Smart. Flexible. Unbreakable.</span>
</ng-template>

<app-external-page [marketingBlock]="showSuccess ? successMarketingBlock : null">
    <app-external-page-content-body>
        <form id="user-form" [formGroup]="registerForm" class="no-help">
            <h1 class="welcome-title">{{'registration_title' | myLbTranslate}}</h1>
            <h2 class="welcome-message">{{'registration_sign_up_message' | myLbTranslate}}</h2>
            <ng-container *ngIf="!showSuccess else success">
                <div class="form-group">
                    <label id="email-address-label" for="emailAddress">{{ 'email' | myLbTranslate }}</label>
                    <div class="form-content">
                        <input name="email" formControlName="emailAddress" id="emailAddress" type="email">
                        <app-validation-messages
                            [control]="registerForm.get('emailAddress')"></app-validation-messages>
                    </div>
                </div>
                <app-loading-button
                    buttonId="create-account"
                    buttonClass="primary"
                    buttonText="create_account"
                    [isDisabled]="!registerForm.valid"
                    [callBack]="createAccount.bind(this)"> <!-- this isn't an observable -->
                </app-loading-button>

                <p class="registration-message">{{'registration_policy_message' | myLbTranslate}}
                    <button class="button-as-link" (click)="openTermsModal('service')">
                        {{'external_terms' | myLbTranslate}}
                    </button>
                    {{'and' | myLbTranslate | lowercase}}
                    <button class="button-as-link" style="margin-left: 0" (click)="openTermsModal('privacy')">
                        {{'external_privacy' | myLbTranslate}}
                    </button>
                    .
                </p>
            </ng-container>

            <ng-template #success>
                <span>{{'registration_success_msg' | myLbTranslate}}</span>
            </ng-template>
        </form>
    </app-external-page-content-body>

    <app-external-page-content-footer>
        <div id="already-have-account">
            <span>{{'registration_already_have_account' | myLbTranslate}} </span>
            <button
                id="sign-in"
                class="button-as-link"
                name="sign_in"
                type="submit"
                (click)="signIn()">
                {{'login_in' | myLbTranslate}}
            </button>
        </div>
    </app-external-page-content-footer>
</app-external-page>
