<div class="component-main">
    <div class="content">
        <h1>{{ (editId ? 'gslb_edit_global_name' : 'gslb_add_global_name') | myLbTranslate }}</h1>
    </div>
    <form [formGroup]="globalNameForm" id="sub_domain_form" name="sub_domain_form">
        <div class="form-group">
            <label for="sub_domain_domain">{{ 'domain' | myLbTranslate }}*</label>
            <div class="form-content">
                <input formControlName="domain" id="sub_domain_domain" name="domain" type="text" appInputValidator>
                <app-validation-messages [control]="globalNameForm.get('domain')"></app-validation-messages>
                <app-help class="help-button" tag="global-name_domain"></app-help>
            </div>
        </div>
        <div class="form-group">
            <label for="sub_domain_time_to_live">{{ 'gslb_time_to_live' | myLbTranslate }}*</label>
            <div class="form-content">
                <input
                    formControlName="timeToLive"
                    id="sub_domain_time_to_live"
                    name="time_to_live"
                    type="number"
                    [style.width.px]="inputWidth"
                    appInputValidator/>
                <span> (seconds)</span>
                <app-validation-messages [control]="globalNameForm.get('timeToLive')"></app-validation-messages>
                <app-help class="help-button" tag="global-name_time-to-live"></app-help>
            </div>
        </div>
    </form>
</div>
<div class="component-footer">
    <button class="outline" [routerLink]="editId ? '../..' : '..'" [queryParams]="{sFilter: 'global-names'}">
        {{ 'cancel' | myLbTranslate }}
    </button>
    <app-loading-button
        buttonClass="primary"
        buttonText="save"
        [isDisabled]="globalNameForm.invalid || globalNameForm.pristine"
        [callBack]="submitGlobalName.bind(this)">
    </app-loading-button>
</div>
