<div class="menu {{menuLevelClass}}" [ngClass]="shadowStyle" [class.closed]="closed">
    <div class="menu-header">
        <div *ngIf="minWidthReached || enableMenuCollapse" class="menu-toggle" (click)="toggleMenu()">
            <ng-container *ngIf="closed else open">
                <img ngSrc="./assets/shared/icons/menu-open.svg" alt="Open close menu toggle" height="20" width="20">
            </ng-container>
            <ng-template #open>
                <img ngSrc="./assets/shared/icons/menu-close.svg" alt="Open close menu toggle" height="20" width="20">
            </ng-template>
        </div>
        <div *ngIf="menuTitleImage" class="menu-image-wrapper">
            <img class="menu-title-image" [ngSrc]="menuTitleImage" [alt]="menuTitleImage" height="25" width="25"/>
        </div>
        <div class="menu-titles">
            <h1 class="menu-title">{{ menuTitle | myLbTranslate }}</h1>
            <span *ngIf="menuSubTitle" class="sub-title">{{ menuSubTitle | uppercase }}</span>
        </div>
    </div>
    <div class="menu-items">
        <!-- Primary -->
        <div *ngFor="let group of groups" class="primary">
            <div
                [id]="group.id"
                class="menu-item-wrapper {{group.class}}"
                [class.active]="this.menuService.menuStateLookup[this.menuLevelClass]?.loc === group.loc"
                [ngClass]="toggleChevron(group)"
                appHint
                hintText="{{ group.heading | myLbTranslate }}"
                hintPosition="right"
                [hintShow]="closed"
                (click)="toggleNav(group, group.items)">
                <div *ngIf="group.iconLocation" class="menu-image-wrapper">
                    <img
                        ngSrc="assets/{{group.iconLocation}}.svg"
                        alt="{{group.iconLocation}} menu icon"
                        height="20"
                        width="20">
                </div>
                <span>{{ group.heading | myLbTranslate }}</span>
                <img
                    *ngIf="group.link"
                    class="menu-item-link"
                    ngSrc="./assets/centralisedManagement/icons/link.svg"
                    alt="Link icon"
                    height="19"
                    width="19">
                <img
                    *ngIf="group.subscriptionRequired"
                    class="menu-item-subscription"
                    ngSrc="./assets/centralisedManagement/icons/subscription_required.svg"
                    alt="subscription icon"
                    height="21"
                    width="21">
            </div>
            <ng-container *ngIf="(group.isChild && group.items.length)">
                <div *ngFor="let item of group.items" class="secondary">
                    <ng-container *ngIf="!group.groupHidden">
                        <div
                            [id]="item.id"
                            class="menu-item-wrapper  {{item.class}}"
                            [class.active]="isItemActive(item.loc)"
                            appHint
                            hintText="{{ item.heading }}"
                            hintPosition="right"
                            [hintShow]="closed"
                            (click)="toggleNav(item, item.subItems)">
                            <div class="menu-image-wrapper">
                                <img
                                    *ngIf="item.iconName"
                                    ngSrc="assets/{{item.iconName}}.svg"
                                    alt="{{item.iconName}} menu icon"
                                    height="20"
                                    width="20">
                            </div>
                            <span>{{ item.heading | myLbTranslate }}</span>
                            <img
                                *ngIf="item.subscriptionRequired"
                                class="menu-item-subscription"
                                ngSrc="./assets/centralisedManagement/icons/subscription_required.svg"
                                alt="subscription icon"
                                height="21"
                                width="21">
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="menu-footer">
        <ng-content select="[menu-footer]"></ng-content>
    </div>
</div>
