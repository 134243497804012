<div class="component-main">
    <div class="content-space-between">
        <div class="content-left">
            <h1 id="component-title">{{ 'adc_add' |myLbTranslate }}</h1>
        </div>
        <div class="content-right">
            <app-adc-widget (limitReached)="maxLimit($event)"></app-adc-widget>
        </div>
    </div>
    <div class="divider first"></div>
    <ng-container *ngFor="let adcSupplier of adcSupplierRows; let i = index;">
        <div
            class="content-space-between no-padding"
            [class.coming-soon]="!adcSupplier.active">
            <div class="content-left-vertical">
                <div class="content">
                    <img
                        ngSrc="/assets/centralisedManagement/icons/{{adcSupplier.icon}}-colour.svg"
                        height="20"
                        width="20"
                        alt="{{adcSupplier.title}} Icon">
                    <h2>{{ adcSupplier.title }}</h2>
                </div>
                <p class="description">{{ adcSupplier.description |myLbTranslate }}</p>
                <div *ngIf="adcSupplier.features?.length" class="content features">
                    <ng-container *ngFor="let feature of adcSupplier.features">
                        <img ngSrc="{{feature.iconPath}}.svg" height="20" width="20" alt="feature icon">
                        <span class="feature-name">{{ feature?.name | myLbTranslate }}</span>
                    </ng-container>
                </div>
            </div>
            <div class="content-right">
                <ng-container *ngIf="adcSupplier.active else comingSoon">
                    <button
                        class="primary"
                        [routerLink]="'/cm/adcs/list/add/' + adcSupplier.vendorId"
                        [disabled]="!permissionsLookup['adcs-list-add'] || !maxLimitReached">
                        {{ 'add' |myLbTranslate }}
                    </button>
                </ng-container>
                <ng-template #comingSoon>
                    <span class="coming-soon">{{ 'coming_soon' |myLbTranslate }}</span>
                </ng-template>
            </div>
        </div>
        <div class="divider"></div>
    </ng-container>
</div>

<div class="component-footer">
    <button class="outline" routerLink="/cm/adcs/list">
        {{ 'cancel' |myLbTranslate }}
    </button>
</div>
