<div class="wizard-wrapper">
    <form [formGroup]="templateForm" id="template-form">
        <div class="wizard-left">
            <div class="left-header-block">
                <h1 class="header">{{ 'add_service' | myLbTranslate }}</h1>
                <div class="sub-header">
                    <span>{{ 'template_selector_select_service_type' | myLbTranslate }}:</span>
                </div>
            </div>
            <div class="left-list">
                <ul class="template-list">
                    <ng-container *ngFor="let template of templates">
                        <li
                            [id]="template.htmlId"
                            [class.disabled]="template.disabled"
                            [class.errored]="erroredTemplate === template.title"
                            [class.selected]="selectedTemplate.title === template.title && !importTemplateSelected"
                            (click)="setSelectedTemplate(template)">
                            {{ template.title }}
                        </li>
                    </ng-container>
                    <li
                        *ngIf="!userTemplateLoaded"
                        id="template-import"
                        [class.selected]="importTemplateSelected"
                        (click)="setSelectedTemplate('import')">
                        {{ 'template_selector_import_template' | myLbTranslate }}
                    </li>
                </ul>
            </div>
            <div class="left-footer">
                <i class="fas fa-chevron-left"></i>
                <button
                    type="button"
                    class="button-as-link"
                    (click)="returnToManageServices()">
                    {{ cancelButtonText }}
                </button>
            </div>
        </div>
        <div class="wizard-content">
            <div class="wizard-step">
                <div
                    *ngIf="selectedTemplate && selectedTemplate.modes && !importTemplateSelected && !selectedTemplate.disabled">
                    <h1 class="content">{{ selectedTemplate.title }}</h1>
                    <div *ngIf="description" class="template-description">
                        <p [innerHTML]="description"></p>
                    </div>
                    <div *ngIf="selectedTemplate.modes" class="mode-container">
                        <span>{{ 'mode' | myLbTranslate }}: </span>
                        <span class="{{mode}} mode" *ngFor="let mode of selectedTemplate.modes; let i = index">
                        {{ mode }}
                    </span>
                    </div>
                    <ng-container *ngIf="erroredTemplate;">
                        <div class="error-wrapper">
                            <div
                                *ngFor="let errorMessage of errorMessages" [innerHTML]="errorMessage"
                                class="error-message">
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div *ngIf="importTemplateSelected && !userTemplateLoaded">
                    <h1 class="content">Import a Template</h1>
                    <div class="template-description" [class.import]="selectedTemplate.title === 'import'">
                        {{ 'wizard_user_template' | myLbTranslate }}
                    </div>
                </div>
                <div *ngIf="errorNotification" class="errors-section {{errorNotification.class}}">
                    <div class="left-message">
                        <img
                            ngSrc="../../../../../../../assets/shared/icons/notification-solid-exclamation-circle.svg"
                            alt="Exclamation icon"
                            height="20"
                            width="20"/>
                        <span *ngIf="errorNotification.message; else defaultMessage">
                            {{ errorNotification.message }}
                        </span>
                        <ng-template #defaultMessage>
                            <span class="default-message">Error submitting a service</span>
                        </ng-template>
                        <ng-container *ngIf="errorNotification.callBack">
                            <button class="notification" (click)="errorNotification.callBack.function()">
                                {{ errorNotification.callBack.text | myLbTranslate }}
                            </button>
                        </ng-container>
                    </div>
                    <button class="notification" (click)="closeErrorNotification(errorNotification.id)">
                        {{ (errorNotification.class === 'warning' ? 'notifications_clear_warning' : 'notifications_clear_error') | myLbTranslate }}
                    </button>
                </div>
            </div>
            <div class="wizard-footer">
                <div class="footer-left">
                    <input
                        #inputFile
                        [hidden]="true"
                        type="file"
                        [attr.accept]="'.json'"
                        (change)="onFileChange($event)"/>
                    <button
                        [hidden]="selectedTemplate.title ==='import'"
                        class="primary"
                        type="button"
                        [disabled]="erroredTemplate === selectedTemplate.title"
                        (click)="selectTemplate(selectedTemplate.title)">
                        {{ 'add' | myLbTranslate }} {{ selectedTemplate.title }} {{ 'service' | myLbTranslate }}
                    </button>
                    <button
                        *ngIf="selectedTemplate.isUserTemplate"
                        [hidden]="!userTemplateLoaded"
                        class="outline"
                        type="button"
                        (click)="checkTemplateBeforeRemoval(selectedTemplate)">
                        {{ 'template_selector_remove_template' | myLbTranslate }}
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
