<div id="page-wrapper">
    <app-menu
        menuTitle="support"
        [collapseOnNavigation]="false">
        <div
            id="menu-support-all-ticket"
            loc="./tickets"
            heading="{{'tickets_all' | myLbTranslate}}"
            iconLocation="centralisedManagement/icons/ticket"
            appMenuGroup
            [isOnlyHeader]="true">
        </div>
        <div
            id="menu-support-service-status"
            appMenuGroup
            loc="https://status.loadbalancer.org/"
            heading="{{'service_status' | myLbTranslate}}"
            iconLocation="centralisedManagement/icons/service-status"
            [link]="true"
            [targetNew]="true">
        </div>
        <div
            id="menu-support-telephone"
            class="coming-soon"
            appMenuGroup
            loc="./telephone-support"
            heading="{{'support_telephone' | myLbTranslate}}"
            iconLocation="centralisedManagement/icons/phone"
            [disabled]="true"
            [isOnlyHeader]="true">
        </div>
        <div
            id="menu-support-knowledge-base"
            class="coming-soon"
            appMenuGroup
            loc="./knowledge-base"
            heading="{{'knowledge_base' | myLbTranslate}}"
            iconLocation="centralisedManagement/icons/settings"
            [disabled]="true"
            [isOnlyHeader]="true">
        </div>
    </app-menu>
    <div id="content-wrapper">
        <router-outlet></router-outlet>
    </div>
</div>
