<div class="component-main">
    <div class="content-space-between">
        <h1>{{ 'manage_services' | myLbTranslate }}</h1>
        <div>
            <button
                *ngIf="hangingBackends.length"
                id="manage-services-hanging-backends"
                [disabled]="isButtonDisabled('manage-services-hanging-backends')"
                class="primary"
                (click)="openHangingBackendsModal()">
                {{ 'hanging_backends' | myLbTranslate }} ({{ hangingBackends.length }})
            </button>
            <button
                id="open-new-service-modal"
                class="primary"
                [disabled]="isButtonDisabled('manage-services-new-service')
                || servicesUtilsService.transactionActive || versionMismatch"
                (click)="createNewService()">
                {{ 'add_service' | myLbTranslate }}
            </button>
        </div>
    </div>

    <app-ng-table
        [name]="'frontend-services'"
        [data]="tableRows"
        [allowMultiSelect]="false"
        [showPaginator]="false"
        [stickyHeader]="true"
        [searchTerm]="searchTerm">
        <app-ng-column
            prop="serviceName"
            label="services_name"
            [flexGrow]="1"
            [customCellTemplate]="nameTemplate">
            <ng-template let-data #nameTemplate>
                <span
                    class="routerLink"
                    id="manage-services-service-details"
                    [class.disabled]="isButtonDisabled('manage-services-service-details')"
                    [routerLink]="['service-details', data.id]">
                    {{ data.serviceName }}
                </span>
            </ng-template>
        </app-ng-column>
        <app-ng-column label="" prop="sslEnable" [customCellTemplate]="sslEnableTemplate">
            <ng-template let-data #sslEnableTemplate>
                <img
                    *ngIf="data.sslEnable"
                    ngSrc="./assets/shared/icons/locked.svg"
                    height="16"
                    width="16"
                    alt="SSL enabled lock"/>
            </ng-template>
        </app-ng-column>
        <app-ng-column prop="ip" label="ip" [flexGrow]="1"></app-ng-column>
        <app-ng-column prop="ports" label="ports" [flexGrow]="1"></app-ng-column>
        <app-ng-column prop="mode" label="mode" [flexGrow]="1"></app-ng-column>
        <app-ng-column prop="type" label="type" [flexGrow]="1"></app-ng-column>
        <app-ng-column prop="status" label="status" [flexGrow]="1" [customCellTemplate]="statusTemplate">
            <ng-template let-data #statusTemplate>
                {{ data.status | titlecase }}
            </ng-template>
        </app-ng-column>
        <app-ng-column
            prop="actions"
            label=""
            [customCellTemplate]="actionsTemplate"
            [selectAll]="selectAllActions"
            [multiSelect]="false">
            <ng-template #actionsTemplate let-data>
                <app-action-dots>
                    <ng-container *ngIf="data.status === undefined">
                        <button
                            id="manage-services-restart-haproxy"
                            [disabled]="isButtonDisabled('manage-services-restart-haproxy')"
                            (click)="servicesUtilsService.restartHaProxy()">
                            {{ 'restart_haproxy' | myLbTranslate }}
                        </button>
                    </ng-container>
                    <button
                        id="manage-services-stick-tables"
                        [disabled]="data.status === undefined
                            || isButtonDisabled('manage-services-stick-tables')"
                        (click)="navigateToStickTables(data)">
                        {{ 'manage_services_view_stick_table' | myLbTranslate }}
                    </button>
                    <button
                        *ngIf="data?.type.toLowerCase() !== 'monitor'"
                        id="manage-services-online-service"
                        [disabled]="isButtonDisabled('manage-services-online-service')"
                        [hidden]="servicesUtilsService.showButton(data.status, statusEnum.ONLINE)"
                        (click)="frontendAction('online-Frontend', data)">
                        {{ 'online' | myLbTranslate }}
                    </button>
                    <button
                        *ngIf="data?.type.toLowerCase() !== 'monitor'"
                        id="manage-services-drain-service"
                        [disabled]="isButtonDisabled('manage-services-drain-service')"
                        [hidden]="servicesUtilsService.showButton(data.status, statusEnum.MAINT)"
                        (click)="frontendAction('drain-Frontend', data)">
                        {{ 'drain' | myLbTranslate }}
                    </button>
                    <button
                        *ngIf="data?.type.toLowerCase() !== 'monitor'"
                        id="manage-services-halt-service"
                        [disabled]="isButtonDisabled('manage-services-halt-service')"
                        [hidden]="servicesUtilsService.showButton(data.status, statusEnum.HALT)"
                        (click)="frontendAction('halt-Frontend', data)">
                        {{ 'halt' | myLbTranslate }}
                    </button>
                    <button
                        id="manage-services-edit-service"
                        [disabled]="isButtonDisabled('manage-services-edit-service')"
                        (click)="editService(data)">
                        {{ 'edit' | myLbTranslate }}
                    </button>
                    <button
                        id="manage-services-duplicate-service"
                        [disabled]="isButtonDisabled('manage-services-duplicate-service', data.type)"
                        (click)="editService(data, true)">
                        {{ 'duplicate' | myLbTranslate }}
                    </button>
                    <button
                        id="manage-services-delete-service"
                        [disabled]="isButtonDisabled('manage-services-delete-service')"
                        (click)="frontendAction('delete-Frontend', data)">
                        {{ 'delete' | myLbTranslate }}
                    </button>
                </app-action-dots>
            </ng-template>
        </app-ng-column>
    </app-ng-table>
</div>

<!-- Container used for all modals -->
<ng-container #modalContainer></ng-container>
