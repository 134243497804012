<div class="widget"
     [class.default]="state === WidgetStateEnum.OK"
     [class.warning]="state === WidgetStateEnum.WARNING"
     [class.critical]="state === WidgetStateEnum.CRITICAL">
    <div class="widget-header" id="widget-header">
        <div class="content-space-between">
            <h3><span *ngIf="sslEnabled" class="padlock"></span>{{ title }}</h3>
            <div [hidden]="!loading" class="dot-spin"></div>
        </div>
    </div>
    <div class="widget-content {{title}}" id="widget-content">
        <ng-container
            *ngIf="lbRbacService.isSuperUser || lbRbacService.enabledWidgets.includes(formattedTitle); else inactive">
            <ng-content></ng-content>
        </ng-container>
        <ng-template #inactive>
            <div class="no-permissions">
                <p>You do not have access to view this widget.</p>
            </div>
        </ng-template>
    </div>
</div>
