<div class="component-main">
    <div class="content-space-between">
        <h1 id="component-title">{{ 'recovery' | myLbTranslate}}</h1>
    </div>
    <div class="component-content">
        <h3>{{'accounts_recovery_keep_keys_safe' | myLbTranslate}}</h3>
        <p>{{'accounts_recovery_keep_keys_safe_message' | myLbTranslate}}</p>
        <h5>{{ 'accounts_recovery_download_key' | myLbTranslate }}</h5>
        <app-private-key
            privateKeyId="accounts-user-private-key-download"
            [showIntroMessage]="false"
            [closeModalInternally]="false"
            [keyName]="privateKeyName"
            contentAlignment="left"
            (keyDecrypted)="downloadKeyAndValidate($event, privateKeyName)">
        </app-private-key>
        <h5>{{ 'accounts_recovery_download_key_org' | myLbTranslate }}</h5>
        <app-private-key
            privateKeyId="accounts-org-private-key-download"
            [showIntroMessage]="false"
            [closeModalInternally]="false"
            [getOrgKey]="true"
            [keyName]="orgKeyName"
            contentAlignment="left"
            (keyDecrypted)="downloadKeyAndValidate($event, orgKeyName)">
        </app-private-key>
    </div>
</div>
