<app-dropdown
    id="namespaces-dropdown"
    dropDownId="namespaces-dropdown"
    [title]="nameSpaceSelected?.value"
    image="/assets/centralisedManagement/icons/globe.svg"
    [disabled]="disabled"
    [matches]="!!namespaceSearch.length"
    titleAlign="right"
    [search]="true"
    [dropdownWidth]="150"
    [contentWidth]="250"
    [contentBackground]="'#22003e'"
    (searchOut)="namespacesSearchContent($event)">
    <div class="drop-container-html">
        <ng-container *ngFor="let namespace of namespaces">
            <div
                class="menu-item-wrapper"
                title="{{namespace.value}}"
                [class.hidden]="namespaceSearch.length && !namespaceSearch.includes(namespace.value)"
                (click)="setSelectedNamespace(namespace)">
                <span>{{ namespace.value }}</span>
            </div>
        </ng-container>
    </div>
</app-dropdown>
