<ng-template #contentWrapper>
    <div
        [id]="modalId"
        class="modal-wrapper"
        [style.height.px]="adjustedHeight"
        [style.width.px]="adjustedWidth"
        [style.maxHeight.px]="maxHeight"
        appFocusTrap>
        <div
            *ngIf="modalTitle || showCloseButton"
            id="drag-handle"
            [class.drag-active]="dragActive"
            class="title-wrapper">
            <h1 *ngIf="modalTitle">{{ modalTitle | myLbTranslate }}</h1>
            <img
                *ngIf="showCloseButton"
                [class.disabled]="primaryButtonClicked"
                id="modal-close"
                ngSrc="../../../../../assets/shared/icons/close-cross.svg"
                height="15"
                width="15"
                tabindex="0"
                alt="close modal icon"
                (keydown)="handleKeyDown($event)"
                (click)="close()"/>
        </div>
        <div #modalBodyWrapper class="modal-body-wrapper" [class.no-padding]="removeBodyPadding">
            <ng-content select="app-modal-body"></ng-content>
        </div>
        <div *ngIf="showFooter || hasFooterComponent" class="modal-footer-wrapper {{footerButtonAlignment}}">
            <ng-container *ngIf="hasFooterComponent; else showDefaultFooter">
                <ng-content select="app-modal-footer"></ng-content>
            </ng-container>
            <ng-template #showDefaultFooter>
                <button *ngIf="showCancelButton" id="modal-cancel" class="outline" (click)="onCancel()">
                    {{ 'cancel' | myLbTranslate }}
                </button>
                <button id="modal-submit" class="primary" (click)="onSubmit()">
                    {{ submitButtonTextKey | myLbTranslate }}
                </button>
            </ng-template>
        </div>
    </div>
</ng-template>
