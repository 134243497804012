<div
    class="table-header"
    id="table-header"
    [style.top]="fixedHeader ? '0' : 'auto'"
    [style.z-index]="fixedHeader ? '11' : '1'">
    <div *ngIf="selectAll && rows.length !== 0" class="header-label select-all-header">
        <div class="header-content">
            <span>
                <input
                    id="selectAll"
                    class="lb-checkbox"
                    type="checkbox"
                    [checked]="selectedRows.length !== 0 && selectedRows.length === rows.length"
                    [class.partial]="selectedRows.length >= 1 && selectedRows.length !== rows.length"
                    [disabled]="selected === 'none'"
                    (click)="handleSelectAll()">
            </span>
        </div>
    </div>
    <div
        class="header-label" id="{{ i }}"
        *ngFor="let column of columnsArray; let i = index"
        [ngStyle]="tableService.getStyle(widthData, column, unit)">
        <div *ngIf="column.name" class="header-content {{ column.cellAlignment }}">
            <span>{{ column.name | myLbTranslate }}</span>
            <div *ngIf="column.name === 'Select All' && selected === 'multiRow' && rows.length !== 0">
                <input id="selectAll" class="lb-checkbox" type="checkbox" (click)="handleSelectAll()">
                <label for="selectAll" class="lb-checkbox"></label>
            </div>
            <i
                *ngIf="showColumns && tableService.visibleColumnLength > 1"
                aria-hidden="true" class="fa fa-eye-slash"
                (click)="hideColumn(i)"
                [style.display]="column.columnHidden === true ? 'none' : 'inline-block'">
            </i>
        </div>
        <div
            *ngIf="column.prop === 'actions' && selectAll && selected === 'multiRow' && rows.length !== 0"
            class="header-content {{ column.cellAlignment }}">
            <span>
                <app-action-dots>
                    <ng-container *ngFor="let button of selectAllActionDots">
                        <button
                            (click)="button.buttonAction(selectedRows)"
                            [disabled]="selectedRows && selectedRows.length < 1">
                            {{ button.buttonText | myLbTranslate }}
                        </button>
                    </ng-container>
                </app-action-dots>
            </span>
        </div>
    </div>
</div>
