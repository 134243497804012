<app-modal-wrapper
    [isDraggable]="true"
    [isOverlayClickable]="false"
    [modalHeight]="255"
    [modalWidth]="500"
    modalTitle="password_confirm"
    footerButtonAlignment="content-center"
    modalId="confirm-password"
    (closed)="cancelClicked()">
    <app-modal-body>
        <form [formGroup]="passwordForm">
            <div class="form-group">
                <label>{{ message | myLbTranslate }}</label>
                <div class="form-content">
                    <input type="password" formControlName="password" autocomplete="new-password"/>
                    <app-validation-messages [control]="passwordForm.get('password')"></app-validation-messages>
                </div>
            </div>
        </form>
    </app-modal-body>
    <app-modal-footer>
        <!-- Can't use callback here because the callback function might not be an observable -->
        <app-loading-button
            buttonId="modal-confirm-user-password"
            buttonClass="primary"
            buttonText="submit"
            [isDisabled]="passwordForm.pristine || passwordForm.invalid"
            [callBack]="triggerCallBack.bind(this)">
        </app-loading-button>
    </app-modal-footer>
</app-modal-wrapper>
