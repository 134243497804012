<app-modal-wrapper
    modalWidth="400"
    modalId="noIPGroupsModal"
    modalTitle="attention">
    <app-modal-body>
        <span>{{ 'ip_no_ip_config' | myLbTranslate }}</span>
    </app-modal-body>
    <app-modal-footer>
        <button class="outline" (click)="modalService.close('noIPGroupsModal')">
            {{ 'cancel' | myLbTranslate }}
        </button>
        <button
            class="primary"
            (click)="linkToGroups()">
            {{ 'ip_go_to_groups' | myLbTranslate }}
        </button>
    </app-modal-footer>
</app-modal-wrapper>

<div class="component-main">
    <div class="content-space-between">
        <h1>{{ (editId ? 'static_route_edit' : 'static_route_add') | myLbTranslate }}</h1>
    </div>
    <form [formGroup]="staticRouteForm">
        <div class="form-group">
            <label for="gateway">{{ 'static_route_gateway' | myLbTranslate }}</label>
            <div class="form-content">
                <input type="text" size="25" name="gateway" id="gateway" formControlName="gateway"/>
                <app-validation-messages [control]="staticRouteForm.controls['gateway']"></app-validation-messages>
                <app-help class="help-button" tag="add-edit-static-ip_ip"></app-help>
            </div>
        </div>

        <div class="form-group">
            <!--suppress XmlInvalidId -->
            <label for="static-route-type">{{ 'type' | myLbTranslate }}</label>
            <div class="form-content">
                <app-dropdown
                    dropDownId="static-route-type"
                    [formGroup]="staticRouteForm"
                    formControlName="type"
                    [content]="dropdownTypes">
                </app-dropdown>
                <app-help class="help-button" tag="add-edit-static-ip_group"></app-help>
            </div>
        </div>

        <div class="form-group">
            <!--suppress XmlInvalidId -->
            <label for="static-route-group">{{ 'group' | myLbTranslate }}</label>
            <div class="form-content">
                <app-dropdown
                    dropDownId="static-route-group"
                    [formGroup]="staticRouteForm"
                    formControlName="groupId"
                    [content]="dropdownGroups">
                </app-dropdown>
                <app-help class="help-button" tag="add-edit-static-ip_group"></app-help>
            </div>
        </div>
    </form>
</div>
<div class="component-footer">
    <button id="static-ip-cancel" class="outline" (click)="cancelButton()">
        {{ 'cancel' | myLbTranslate }}
    </button>
    <app-loading-button
        buttonId="static-ip-submit"
        buttonClass="primary"
        [buttonText]="editId ? 'update': 'add'"
        [isDisabled]="staticRouteForm.invalid || staticRouteForm.pristine"
        [callBack]="defaultRouteChangeCheck.bind(this)">
    </app-loading-button>
</div>
