import { Component } from '@angular/core';
import { WidgetModel } from '../../../../models';

@Component({
    selector: 'app-guides-widget',
    templateUrl: '../widgetBaseComponent/listWidgetBase/listWidget.component.html',
    styleUrls: ['../widgetBaseComponent/widget.component.scss']
})
export class GuidesWidgetComponent {
    /**
     * Data has loaded
     */
    public loaded: boolean = true;

    /**
     * Store the formatted data for the view.
     */
    public view: WidgetModel;

    constructor() {
        this.view = {
            iconSrc: 'assets/centralisedManagement/icons/dashboard-guide.svg',
            title: 'dashboard_guides',
            list: [
                { link: '', text: 'Manage Users' },
                { link: '', text: 'My Account' },
                { link: '', text: 'My Subscription' }
            ],
            linkText: 'My settings',
            linkUrl: 'cm/accounts/settings'
        };
    }

}
