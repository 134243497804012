<div class="grid__item">
    <div class="grid-content-column-space-between">
        <div class="grid-content-column">
            <div class="item-header">
                <img
                    *ngIf="view.iconSrc"
                    ngSrc="{{view.iconSrc}}"
                    height="20"
                    width="20"
                    alt="Name space icon">
                <h2>{{view.title | myLbTranslate}}</h2>
            </div>
            <ul *ngIf="view.list" class="list">
                <li *ngFor="let item of view.list">
                    <a href="{{item.link}}" target="_blank">{{item.text}}</a>
                </li>
            </ul>
        </div>
        <div class="item-footer">
            <a routerLink="{{view.linkUrl}}">{{view.linkText}}</a>
            <img ngSrc="../../../../../../../../../assets/shared/icons/chevron-right.svg" height="12" width="12">
        </div>
    </div>
</div>
