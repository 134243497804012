<div [hidden]="addEditCipherListsVisible" [class.component-main]="!componentEmbedded">
    <div class="content-space-between" [class.embedded]="componentEmbedded">
        <div class="content-left">
            <ng-container *ngIf="!componentEmbedded; else embeddedTitle">
                <h1>{{ 'cipher_list_title' | myLbTranslate }}</h1>
            </ng-container>
            <ng-template #embeddedTitle>
                <h2>{{ 'cipher_list_title' | myLbTranslate }}</h2>
            </ng-template>
        </div>
        <div class="content-right">
            <button
                id="ssl-cipher-lists-new-cipher-list"
                class="primary"
                [disabled]="isButtonDisabled('ssl-cipher-lists-new-cipher-list')"
                (click)="addEditCipherList()">
                {{ 'cipher_list_new' | myLbTranslate }}
            </button>
        </div>
    </div>
    <app-search-rows [rows]="tableRows" [searchKeys]="searchKeys" (searchResults)="updateTableRows($event)">
    </app-search-rows>
    <app-table id="cipher-list-table" [rows]="tableRows" [columnMode]="'force'">
        <app-table-column prop="name" name="cipher_list_name"></app-table-column>
        <app-table-column prop="builtin" name="type"></app-table-column>
        <app-table-column prop="usage" name="usage">
            <ng-template let-row="row" appLbTableColumnCell>
                <app-usage [usage]="row.usage" modalTitle="cipher_usage"></app-usage>
            </ng-template>
        </app-table-column>
        <app-table-column prop="actions" name="" [cellAlignment]="'right'">
            <ng-template let-row="row" appLbTableColumnCell>
                <app-action-dots>
                    <ng-container *ngIf="row.builtin === 'User'; else viewDuplicate">
                        <button
                            id="ssl-cipher-lists-edit-cipher-list"
                            [disabled]="isButtonDisabled('ssl-cipher-lists-edit-cipher-list')"
                            (click)="addEditCipherList(row)">
                            {{ 'edit' | myLbTranslate }}
                        </button>
                        <button
                            id="ssl-cipher-lists-delete-cipher-list"
                            [disabled]="isButtonDisabled('ssl-cipher-lists-delete-cipher-list') || row.usage?.length"
                            (click)="confirmDelete(row.id, row.name)">
                            {{ 'delete' | myLbTranslate }}
                        </button>
                    </ng-container>
                    <ng-template #viewDuplicate>
                        <button
                            id="view-list"
                            (click)="viewCipherList(row)">
                            {{ 'view' | myLbTranslate }}
                        </button>
                        <button
                            id="ssl-cipher-lists-duplicate-cipher-list"
                            [disabled]="isButtonDisabled('ssl-cipher-lists-duplicate-cipher-list')"
                            (click)="addEditCipherList(row, true)">
                            {{ 'duplicate' | myLbTranslate }}
                        </button>
                    </ng-template>
                </app-action-dots>
            </ng-template>
        </app-table-column>
    </app-table>
</div>
<div *ngIf="componentEmbedded" class="component-footer embedded">
    <button
        class="outline"
        id="cancel"
        (click)="callBack()">{{ 'cancel' | myLbTranslate }}
    </button>
</div>

<ng-container #addEditCipherListContainer></ng-container>
<app-modal-wrapper modalTitle="cipher_list_view" modalId="viewCipherList">
    <app-modal-body>
        <ul *ngFor="let cipher of this.builtinCiphers">
            <li>{{ cipher }}</li>
        </ul>
    </app-modal-body>
</app-modal-wrapper>

