<p *ngIf="showIntroMessage">{{'account_private_key_message' | myLbTranslate}}</p>
<ng-container *ngIf="!privateKeyDecrypted; else complete">
    <div
        [id]="privateKeyId"
        [class]="'content-' + contentAlignment + ' no-padding filename'"
        (click)="getPrivateKey()">
        <img
            class="download-icon"
            src="/assets/centralisedManagement/icons/download.svg"
            alt="Download Icon"/>
        <span class="button-as-link">{{ keyName }}</span>
    </div>
</ng-container>
<ng-template #complete>
    <div [class]="'content-' + contentAlignment + ' no-padding filename-complete'">
        <img src="/assets/centralisedManagement/icons/downloaded-private-key.svg" alt="download private key icon"/>
        <span>{{ keyName }}</span>&nbsp;<span>{{'accounts_recovery_download_success_message' | myLbTranslate}}</span>
    </div>
</ng-template>

<!-- Container used for all modals -->
<ng-container #modalContainer></ng-container>
