<ng-container *ngIf="isOpen">
    <div class="modals-container">
        <ng-container *ngFor="let template of templates; index as i">
            <div
                *ngIf="i === templates.length - 1"
                class="modal-overlay"
                [class.blurred]="template.options.isOverlayBlurred || templatesContainBlur"
                [style.z-index]="index + i"
                (click)="overlayClick($event, template.options.isOverlayClickable)">
            </div>
            <ng-container *ngIf="template.options.isDraggable else notDraggable">
                <div
                    class="modal-body"
                    [class.drop-shadow]="i < templates.length - 1"
                    [style.z-index]="index + i"
                    appDraggable (dragActiveEmitter)="setDragActive($event)">
                    <ng-template *ngTemplateOutlet="template.template"></ng-template>
                </div>
            </ng-container>
            <ng-template #notDraggable>
                <div class="modal-body" [class.drop-shadow]="i < templates.length - 1" [style.z-index]="index + i">
                    <ng-template *ngTemplateOutlet="template.template"></ng-template>
                </div>
            </ng-template>
        </ng-container>
    </div>
</ng-container>
