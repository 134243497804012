<div *ngIf="selectAll" class="table-item select-all-row">
    <div class="table-item-content">
        <input
            id="selectAll"
            class="lb-checkbox"
            type="checkbox"
            [checked]="selectedRows.includes(row)"
            [disabled]="selectMode === 'none'"
            (click)="selectSingleRow($event)">
    </div>
</div>
<div
    *ngFor="let column of columnsArray; let i = index"
    [className]="column.allSelected === true ? 'table-item selected': 'table-item'"
    [ngStyle]="tableService.getStyle(widthData, column, unit)">
    <div
        class="table-item-content {{ column.cellAlignment }} {{column.prop}}"
        [style.cursor]="column.link ? 'pointer' : 'default'">
        <span *ngIf="column.link" routerLink="{{ column.link }}" [title]="row[column.prop]">
            {{ row[column.prop] }}
        </span>
        <span
            *ngIf="(!column.contentRef && row[column.prop]) && !column.link"
            [innerText]="row[column.prop]"
            [title]="row[column.prop]"></span>
        <ng-container
            *ngIf="column.contentRef"
            [ngTemplateOutlet]="column.contentRef"
            [ngTemplateOutletContext]="setupOutletContext(row, rowIndex)">
        </ng-container>
    </div>
</div>
