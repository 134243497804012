<app-widget
    #widget
    class="widget-item col-span-2"
    title="{{ 'frontends' | myLbTranslate }}"
    [loading]="loading && apiLoading">
    <div *ngIf="scrolled" class="scroll-close-button" (click)="scrolled = !scrolled">
        <img src="assets/shared/icons/close-cross.svg" alt="Close icon"/>
    </div>
    <div class="content-vertical-full-width">
        <div *ngIf="!scrolled">
            <div class="status-head" title="{{ 'online' | myLbTranslate}}">
                <a class="success" (click)="navigateToManageServicesPage('online')">{{online}}</a>
            </div>
            <div class="spacer">&middot;</div>
            <div class="status-head" title="{{ 'maintenance' | myLbTranslate }}">
                <a class="maintenance" (click)="navigateToManageServicesPage('maint')">{{maint}}</a>
            </div>
            <div class="spacer">&middot;</div>
            <div class="status-head" title="{{ 'down' | myLbTranslate }}">
                <a class="critical" (click)="navigateToManageServicesPage('down')">{{down}}</a>
            </div>
            <h2 class="widget-description">{{ 'virtual_service_widget_frontend_overview' | myLbTranslate }}</h2>
        </div>
        <div #list id="service-list" class="expandable-list" [class.extended]="scrolled">
            <table class="widget-table">
                <tr class="table-headers">
                    <th></th>
                    <th></th>
                    <th class="services">{{ 'service' | myLbTranslate }}</th>
                    <th>{{ 'ip' | myLbTranslate }}</th>
                    <th>{{ 'conn' | myLbTranslate }}</th>
                    <th>{{ 'ports' | myLbTranslate }}</th>
                </tr>
                <tr
                    *ngFor="let data of serviceData"
                    class="row-content"
                    (click)="navigateToServiceDetailsPage(data.id)">
                    <td>
                        <app-tooltip width="90" [trigger]="virtualServicesHtml()">
                            {{utils.getStatusDisplayName(data.status) | titlecase}}
                        </app-tooltip>
                        <div>
                            <div [class]="'status-bullet ' + data.status">
                            </div>
                        </div>
                    </td>
                    <td>
                        <div *ngIf="data.SSLEnable">
                            <app-tooltip width="60"
                                         trigger="<span class='padlock'></span>">
                                {{ 'ssl' | myLbTranslate }}
                            </app-tooltip>
                        </div>
                    </td>
                    <td>{{data.label | truncate:[20]}}</td>
                    <td>{{data.fip}}</td>
                    <td>{{data.sessions}}</td>
                    <td>{{data.ports}}</td>
                </tr>
            </table>
        </div>
    </div>
</app-widget>
