<ng-container *ngIf="newRow && newRow.length">
    <div class="table-body">
        <div
                *ngFor="let row of newRow; let rowIndex = index"
                class="table-row new-row"
                [class.selected-row]="true">
            <div
                    *ngFor="let column of columnsArray; let i = index"
                    [className]="column.allSelected === true ? 'table-item selected': 'table-item'"
                    [ngStyle]="tableService.getStyle(widthData, column, unit)">
                <div
                        class="table-item-content {{ column.cellAlignment }} {{column.prop}}"
                        [style.cursor]="column.link ? 'pointer' : 'default'">
                    <span *ngIf="column.link" routerLink="{{ column.link }}">
                        {{ row[column.prop] }}
                    </span>
                    <span *ngIf="(!column.contentRef && row[column.prop]) && !column.link"
                          [innerText]="row[column.prop]"></span>
                    <ng-container
                            *ngIf="column.contentRef"
                            [ngTemplateOutlet]="column.contentRef"
                            [ngTemplateOutletContext]="setupOutletContext(row)">
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-container>
