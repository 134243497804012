<div class="component-main">
    <div class="content-space-between">
        <h1>{{ pageData.name }}</h1>
    </div>

    <app-tab-container>
        <app-tab tabTitle="pipeline" id="pipeline">
            <div class="column-wrapper">
                <div *ngFor="let column of pageData.data let i = index" class="column">
                    <div class="title-wrapper" class="{{column.status}}">
                        <h6>{{column.name | titlecase}}</h6>
                        <ng-container *ngIf="i < pageData.data.length - 1">
                            <img src="../../../../../../../../assets/centralisedManagement/icons/pipeline-direction.svg"
                                 alt="direction arrow"/>
                        </ng-container>
                    </div>
                    <ng-container *ngIf="column?.cards?.length else empty">
                        <div *ngFor="let card of column.cards" class="card {{column.status}}">
                            <p class="title">{{card.title}}</p>
                            <p class="number">#{{card.id}}</p>
                            <p class="product-label">{{card.productLabel}}</p>
                            <p class="details">{{card.details}}</p>
                            <p class="date-time">{{card.date}} {{card.time}}</p>
                            <div class="progress-outer {{card.status}}">
                                <ng-container *ngIf="card.status === 'updating'">
                                    <div class="progress-inner" [style.width]="progress + '%'"></div>
                                </ng-container>
                                <span>{{card.status | titlecase}}</span>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #empty>
                        <div class="card empty"></div>
                    </ng-template>
                </div>
            </div>
        </app-tab>
        <app-tab tabTitle="settings" id="settings">
            <p>settings stuff</p>
        </app-tab>
    </app-tab-container>
</div>
