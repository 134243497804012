<div [hidden]="addEditMode">
    <div class="content-space-between embedded">
        <h2>{{ 'headers' | myLbTranslate }}</h2>
        <button
            class="primary"
            (click)="createAddEditComponent()">{{ 'add' | myLbTranslate }}
        </button>
    </div>
    <app-drag-n-drop-table
        *ngIf="!addEditMode"
        [tableRows]="tableRows"
        type="headers"
        [actionDotsTemplate]="actionsTemplate"
        (orderedRows)="emitHeaderIdOrder($event)">
    </app-drag-n-drop-table>
    <ng-template #actionsTemplate let-row>
        <div>
            <app-action-dots>
                <button (click)="createAddEditComponent(row.id)">{{ 'edit' | myLbTranslate }}</button>
                <button (click)="deleteHeader(row.id)">{{ 'delete' | myLbTranslate }}</button>
            </app-action-dots>
        </div>
    </ng-template>
</div>

<ng-container #addEditContainer></ng-container>
