<form *ngIf="editMode" [formGroup]="formGroup">
    <input
        id="tags-input"
        formControlName="tagInput"
        placeholder="{{'tags_place_holder' | myLbTranslate}}"
        title="ADC Address"
        tabindex="0"
        type="text"
        (focus)="handleFocusBlur(true)"
        (blur)="handleFocusBlur(false)">
    <app-validation-messages [control]="formGroup.get('tagInput')"></app-validation-messages>
    <span *ngIf="tags.length >= maxTags" class="note">{{ 'tags_max_message' |myLbTranslate }}</span>
</form>
<div class="tags">
    <ng-container *ngFor="let tag of tags">
        <div class="tag" [class.disabled]="disableForm">
            <span [class.edit]="editMode">{{ tag }}</span>
            <img
                *ngIf="editMode"
                ngSrc="assets/shared/icons/close.svg"
                height="15"
                width="15"
                alt="close cross"
                (click)="removeTagFromTagsArray(tag)"/>
        </div>
    </ng-container>
</div>
