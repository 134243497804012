<div [class.component-main]="!componentEmbedded">
    <div class="content-space-between" [class.embedded]="componentEmbedded">
        <ng-container *ngIf="!componentEmbedded; else embedded">
            <h1>{{ pageTitle | myLbTranslate }}</h1>
        </ng-container>
        <ng-template #embedded>
            <h2>{{ pageTitle | myLbTranslate }}</h2>
        </ng-template>
    </div>
    <ng-container *ngIf="ciphersRetrieved">
        <div class="content-space-between left-form">
            <div class="form-group">
                <!-- Hidden label for spacing when wrapping -->
                <label class="hidden-label">{{ 'search' | myLbTranslate }}</label>
                <div class="form-content">
                    <app-search-rows
                        [removeMargin]="true"
                        [rows]="availableCiphers"
                        [searchKeys]="['name', 'tlsVersion', 'security']"
                        (searchResults)="updateAvailableCiphers($event)"
                        (searchValues)="setSearchVariable($event)">
                    </app-search-rows>
                </div>
            </div>
            <form [formGroup]="filtersForm">
                <div class="form-group">
                    <!--suppress XmlInvalidId -->
                    <label for="tls-version" class="cipher-label">{{ 'cipher_list_tls_version' | myLbTranslate }}
                        : </label>
                    <div class="form-content">
                        <app-dropdown
                            dropDownId="tls-version"
                            [formGroup]="filtersForm"
                            formControlName="tlsVersion"
                            [displayAsInput]="true"
                            [dropdownWidth]="140"
                            [content]="componentEmbedded ? tempCipherSuiteDropdown : cipherSuiteDropdown">
                        </app-dropdown>
                    </div>
                </div>
            </form>
        </div>
        <form [formGroup]="ciphersForm">
            <div class="right-form">
                <div class="form-group">
                    <label id="list-name-label" class="cipher-label" for="list-name">
                        {{ 'cipher_list_name' | myLbTranslate }}*:
                    </label>
                    <div class="form-content">
                        <input
                            formControlName="name"
                            type="text"
                            name="name"
                            id="list-name"
                            placeholder="{{ 'cipher_list_name' | myLbTranslate }}">
                        <app-validation-messages [control]="ciphersForm.controls['name']"></app-validation-messages>
                    </div>
                </div>
            </div>
            <div class="dropzone-wrapper">
                <div class="drag-n-drop-wrapper">
                    <div class="drag-n-drop-list" appDragNDrop>
                        <ng-container *ngFor="let item of availableCiphers;">
                            <div class="drag-item" [attr.data-form]="item.name">
                                <div id="{{item.name}}"></div>
                                <div class="dot {{item.security}}"></div>
                                {{ item.name }}
                            </div>
                        </ng-container>
                    </div>
                    <p class="info">
                        {{ 'cipher_list_available' | myLbTranslate }}: {{ availableCiphers && availableCiphers.length }}
                    </p>
                </div>
                <div class="drag-n-drop-wrapper">
                    <div
                        class="drag-n-drop-list"
                        appDragNDrop
                        [highlightDropArea]="true"
                        (selectedData)="updateSelected($event)">
                        <ng-container *ngFor="let selected of this.selectedCiphers">
                            <div class="drag-item" [attr.data-form]="selected">
                                <div id="selected{{selected.name}}"></div>
                                <div class="dot {{getSecurityLevel(selected)}}"></div>
                                <ng-container *ngIf="dragActive; else tooltip">
                                    {{ selected }}
                                </ng-container>
                                <ng-template #tooltip>
                                    <app-tooltip
                                        [class]=""
                                        trigger="{{selected}}"
                                        delay="50">
                                        {{ this.getSuiteName(selected) }}
                                    </app-tooltip>
                                </ng-template>
                            </div>
                        </ng-container>
                    </div>
                    <p class="info">
                        {{ 'cipher_list_selected' | myLbTranslate }}: {{ selectedCiphers && selectedCiphers.length }}
                    </p>
                </div>
            </div>
        </form>
    </ng-container>
</div>
<div class="component-footer" [class.embedded]="componentEmbedded">
    <button
        class="outline"
        id="embedded-cancel-add"
        (click)="goBack()">{{ 'cancel' | myLbTranslate }}
    </button>
    <button
        class="primary"
        id="embedded-submit"
        [disabled]="ciphersForm.pristine || ciphersForm.invalid"
        (click)="submitCipherList()">
        {{ (editId ? 'update' : 'add') | myLbTranslate }}
    </button>
</div>
