<form [id]="componentId" class="form-row" [formGroup]="durationForm">
    <input
        formControlName="duration"
        type="number"
        min="1"
        max="99999"
        (select)="selectEventTriggered($event)"
        (keydown)="restrictValueLength($event)">
    <app-dropdown
        dropDownId="{{formControlName}}-unit"
        [formGroup]="durationForm"
        formControlName="unit"
        [dropdownWidth]="115"
        [content]="timeUnits">
    </app-dropdown>
</form>
