import { Component } from '@angular/core';
import { WidgetModel } from '../../../../models';

@Component({
    selector: 'app-portal-whats-new',
    templateUrl: './portal-whats-new.component.html',
    styleUrls: ['./portal-whats-new.component.scss', '../widgetBaseComponent/widget.component.scss']
})
export class PortalWhatsNewComponent {
    /**
     * Store the formatted data for the view.
     */
    public view: WidgetModel = {
        iconSrc: 'assets/centralisedManagement/icons/dashboard-camera.svg',
        title: 'dashboard_whats_new',
        mainImageSrc: '',
        linkUrl: '',
        linkText: 'Watch'
    };

    constructor() {
    }
}
