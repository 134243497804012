<app-modal-wrapper
    modalHeight="400"
    modalWidth="800"
    modalTitle="hanging_backends"
    modalId="hangingBackendsModal">
    <app-modal-body>
        <div class="content-space-between">
            <p>{{ 'hanging_backends_backends_list' | myLbTranslate }}<br>
                {{ 'hanging_backends_backends_info' | myLbTranslate }}</p>
            <div>
                <button
                    *ngIf="selectedRows.length > 1"
                    id="hanging-delete-all-rows"
                    class="button primary medium"
                    (click)="confirmDeleteBackend(selectedRows)">
                    {{ 'delete' | myLbTranslate }} ({{selectedRows.length}}) {{ 'rows' | myLbTranslate }}
                </button>
                <button
                    *ngIf="selectedRows.length !== hangingBackends.length
                        && selectedRows.length <= 1
                        && hangingBackends.length !== 1"
                    id="hanging-select-all-rows"
                    class="button primary medium grey-button"
                    (click)="selectAllRows()">
                    {{ 'select_all' | myLbTranslate }}
                </button>
            </div>
        </div>
        <app-table
            [rows]="hangingBackendRows"
            [columnMode]="'flex'"
            [selectedRows]="selectedRows"
            [selectMode]="'multiRow'"
            (selectedRowsOut)="onSelect($event)">
            <app-table-column [flexGrow]="1" prop="label" name="services_name"></app-table-column>
            <app-table-column [flexGrow]="1" prop="mode" name="services_mode"></app-table-column>
            <app-table-column [flexGrow]="1" prop="realServers" name="services_real_servers">
                <ng-template let-row="row" appLbTableColumnCell>
                    <ng-container *ngIf="row.realServers.length; else zeroMessage">
                        <app-tooltip trigger="{{row.realServers.length}}" [width]="350">
                            <table class="lb-small-table">
                                <thead>
                                <tr>
                                    <th>{{ 'label' | myLbTranslate }}</th>
                                    <th>{{ 'address' | myLbTranslate }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let realServer of realServersByBackend[row.id] | slice:0:2;">
                                    <td>{{realServer.label}}</td>
                                    <td>{{realServer.ip}}/{{realServer.port}}</td>
                                </tr>
                                </tbody>
                            </table>
                            <div class="content-center hanging-backends-button">
                                <button
                                    *ngIf="row.realServers.length && row.realServers.length > 2"
                                    class="primary"
                                    (click)="showUsageModal(row)">
                                    {{ 'more' | myLbTranslate }}
                                </button>
                            </div>
                        </app-tooltip>
                    </ng-container>
                    <ng-template #zeroMessage>
                        <span>{{row.realServers.length}}</span>
                    </ng-template>
                </ng-template>
            </app-table-column>
            <app-table-column [flexGrow]="0.5" prop="actions" [cellAlignment]="'right'">
                <ng-template let-row="row" appLbTableColumnCell>
                    <app-action-dots *ngIf="selectedRows.length < 2">
                        <button *ngIf="frontends.length" (click)="createACL(row.id)">
                            {{ 'hanging_backends_create_acl' | myLbTranslate }}
                        </button>
                        <button
                            id="hanging-backends-delete-backend"
                            [disabled]="isButtonDisabled('hanging-backends-delete-backend')"
                            (click)="confirmDeleteBackend([row])">
                            {{ 'delete' | myLbTranslate }}
                        </button>
                    </app-action-dots>
                </ng-template>
            </app-table-column>
        </app-table>
    </app-modal-body>
</app-modal-wrapper>

<app-modal-wrapper
    modalHeight="300"
    modalWidth="500"
    modalTitle="hanging_backend_real_servers_usage"
    modalId="BackendRealServersUsageModal">
    <app-modal-body>
        <p>{{ 'hanging_backends_usage_for_backend' | myLbTranslate }}: {{modalLabel}}</p>
        <app-table [rows]="realServersInfo" [columnMode]="'flex'" [selectMode]="'singleRow'">
            <app-table-column [flexGrow]="1" name="hanging_backends_usage_real_server_label">
                <ng-template let-row="row" appLbTableColumnCell>
                    <span class="real-server-label">
                        {{ row.label }}
                    </span>
                </ng-template>
            </app-table-column>
            <app-table-column [flexGrow]="1" name="hanging_backends_usage_real_server_address">
                <ng-template let-row="row" appLbTableColumnCell>
                    <span class="real-server-address">
                        {{ row.ip }}
                    </span>
                </ng-template>
            </app-table-column>
        </app-table>
    </app-modal-body>
</app-modal-wrapper>

<app-modal-wrapper modalTitle="hanging_backends_deleting" modalId="progressModal" modalWidth="400">
    <app-modal-body>
        <div class="dot-wrapper">
            <div class="dot-spin"></div>
        </div>
        <div class="progress-box" [innerHTML]="progressMessage"></div>
    </app-modal-body>
</app-modal-wrapper>
