<div id="page-wrapper">
    <div id="content-wrapper">
        <div class="component-main">
            <div class="content-space-between">
                <h1>{{ 'notifications' | myLbTranslate }}</h1>
                <button (click)="clearNotifications()" class="primary">{{ 'notifications_clear' | myLbTranslate }}
                </button>
            </div>

            <app-dropdown
                dropDownId="notifications-type"
                displayAsInput="true"
                [content]="dropdownContent"
                [dropdownWidth]="400"
                [selectedKey]="9"
                (keyOut)="updateTable($event)">
            </app-dropdown>

            <app-table
                [rows]="tableRows"
                [columnMode]="'flex'"
                [selectMode]="'multiRow'"
                [selectAll]="true"
                [selectAllActionDots]="actionDotsArray"
                [selectedRows]="selectedRowObject">
                <app-table-column [flexGrow]="7" prop="message" name="notification">
                    <ng-template let-row="row" appLbTableColumnCell>
                        <img
                            class="level{{row.level}}"
                            src="../../../../../assets/shared/icons/notification-solid-checked-circle.svg"
                            alt="Exclamation icon"/>
                        <span [innerHTML]="row.message" (click)="navigate(row.url)"></span>
                    </ng-template>
                </app-table-column>
                <app-table-column [flexGrow]="3" prop="date" name="date"></app-table-column>
                <app-table-column [flexGrow]="1.5" prop="actions" name="" [cellAlignment]="'right'">
                    <ng-template let-row="row" appLbTableColumnCell>
                        <div *ngIf="!row.editMode && !row.addMode">
                            <app-action-dots>
                                <button
                                    id="notifications-clear"
                                    (click)="clearSelectedNotification(row.id)">
                                    {{ 'notification_clear_selected' | myLbTranslate }}
                                </button>
                            </app-action-dots>
                        </div>
                    </ng-template>
                </app-table-column>
            </app-table>
        </div>
    </div>
</div>
