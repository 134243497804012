<form [formGroup]="organisationForm">
    <!--    <div class="form-group">-->
    <!--        <app-image-upload id="upload-file" [resetFileInput]="inputReset" (fileSelected)="onFileChange($event)">-->
    <!--        </app-image-upload>-->
    <!--        <app-validation-messages [control]="organisationForm.get('image')"></app-validation-messages>-->
    <!--    </div>-->
    <div class="form-group">
        <label for="name">{{ 'organization_title' | myLbTranslate  | titlecase }} {{ 'name' | myLbTranslate }}*</label>
        <div class="form-content">
            <input id="name" formControlName="name" name="name" type="text" minlength="3">
            <app-help class="help-button" tag="onboard_add"></app-help>
            <app-validation-messages [control]="organisationForm.get('name')"></app-validation-messages>
        </div>
    </div>

    <app-phone-number
        [hideCountryCodes]=false
        [formGroup]="organisationForm"
        [applyExternalStyling]="true"
        formControlName="phoneNumber">
    </app-phone-number>
</form>
