<div id="header-wrapper">
    <div class="header">
        <div class="header-block logo-component">
            <app-logo></app-logo>
        </div>
        <div class="header-block search">
            <app-algolia-search></app-algolia-search>
        </div>
        <div class="header-block icons">
            <app-notifications-bell-icon></app-notifications-bell-icon>
            <div class="header-item header-account">
                <app-lb-logged-in-user titleAlignment="left"></app-lb-logged-in-user>
            </div>
        </div>
        <div class="header-block burger-menu" (click)="setRightMenuState(true)">
            <img ngSrc="assets/shared/icons/burger-menu.svg" alt="Burger menu icon" height="22" width="26"/>
        </div>
    </div>
</div>

<div id="main-wrapper" [class.notification]="licenceExpired">
    <app-notification-header-wrapper></app-notification-header-wrapper>
    <div #mainContent id="main-content">
        <app-new-menu
            menuTitle="enterprise"
            [menuSubTitle]="licenceModelName"
            menuLevelClass="level-1"
            shadowStyle="dark-dark"
            [closed]="true"
            (toggleEvent)="closedMenu = $event;">
            <ng-container *ngFor="let item of lbMenu">
                <div
                    *ngIf="lbRbacService.isMenuItemVisible(item.rbac) && item.children"
                    id="menu-{{item.id}}"
                    loc="{{item.location}}"
                    heading="{{item.heading}}"
                    [children]="item.children"
                    iconLocation="{{item.iconLocation}}"
                    [class]="item.class ? item.class : ''"
                    [disabled]="item.disabled"
                    appMenuGroup>
                </div>
            </ng-container>
            <div menu-footer>
                <ng-container *ngTemplateOutlet="menuFooterContent"></ng-container>
            </div>
        </app-new-menu>
        <ng-container *ngIf="menuService.menuStateLookup['level-1']?.children.length">
            <app-new-menu
                menuTitleImage="assets/{{menuService.menuStateLookup['level-1'].iconLocation}}.svg"
                menuTitle="{{menuService.menuStateLookup['level-1'].heading}}"
                [enableMenuCollapse]="false"
                [collapseOnNavigation]="false"
                menuLevelClass="level-2"
                shadowStyle="dark-light">
                <ng-container *ngFor="let item of menuService.menuStateLookup['level-1']?.children">
                    <div
                        *ngIf="item.auth"
                        id="menu-{{item.id}}"
                        loc="{{item.location}}"
                        heading="{{item.heading}}"
                        [children]="item.children"
                        [isChild]="item.isChild"
                        [class]="item.class ? item.class : ''"
                        [disabled]="item.disabled"
                        appMenuGroup>
                        <ng-container *ngIf="item.children?.length">
                            <ng-container *ngFor="let child of item.children">
                                <div
                                    *ngIf="child.auth"
                                    id="{{child.id}}"
                                    loc="{{child.location}}"
                                    [class]="child.class ? child.class : ''"
                                    [disabled]="child.disabled"
                                    appMenuItem>
                                    {{ child.heading }}
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>
            </app-new-menu>
        </ng-container>
        <div> <!-- Required as unable to apply 100% to the router-outlet -->
            <div id="content-wrapper">
                <ng-container *ngIf="menuService.menuStateLookup['level-1']">
                    <router-outlet></router-outlet>
                </ng-container>
            </div>
            <ng-container *ngIf="tooltipToggle">
                <div class="client-details-container">
                    <ng-container [ngTemplateOutlet]="clientDetails"></ng-container>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #clientDetails>
    <app-license-pill></app-license-pill>
    <app-lb-client-details></app-lb-client-details>
</ng-template>

<ng-template #menuFooterContent>
    <div class="menu-footer-content">
        <div class="menu-footer-text" [class.hide]="!menuOpen">
            <ng-container [ngTemplateOutlet]="clientDetails"></ng-container>
        </div>
        <div class="menu-footer-collapsed" [class.show]="!menuOpen">
            <div id="client-details-trigger" #clientDetailsTrigger (click)="setTooltipState()">
                <img ngSrc='./assets/shared/icons/help-i.svg' alt="help icon" height="20" width="20">
            </div>
        </div>
    </div>
</ng-template>

<app-burger-menu-content [open]="burgerMenuOpen" (menuEmitter)="setRightMenuState($event)">
    <ng-container *ngFor="let item of lbMenu">
        <div
            *ngIf="lbRbacService.isMenuItemVisible(item.rbac) && item.children"
            id="burger-menu-{{item.id}}"
            class="menu-item-wrapper"
            [class.active]="isItemActive(item.location)"
            loc="{{item.location}}"
            heading="{{item.heading}}"
            [children]="item.children"
            iconLocation="{{item.iconLocation}}"
            appMenuGroup
            (click)="navigate(item, 'level-1')">
            <div class="menu-image-wrapper">
                <img
                    ngSrc="/assets/{{ item.iconLocation }}.svg"
                    alt="{{ item.heading | myLbTranslate}} icon"
                    height="20"
                    width="20">
            </div>
            <span>{{ item.heading | myLbTranslate }}</span>
        </div>
        <ng-container *ngIf="menuService.menuStateLookup['level-1']?.children.length && isBurgerParentActive(item)">
            <ng-container *ngFor="let child of menuService.menuStateLookup['level-1']?.children">
                <div
                    id="burger-menu-{{child.id}}"
                    class="menu-item-wrapper secondary"
                    [class.active]="isItemActive(child.location)"
                    [ngClass]="toggleChevron(child)"
                    loc="{{child.location}}"
                    heading="{{child.heading}}"
                    [children]="child.children"
                    iconLocation="{{child.iconLocation}}"
                    appMenuGroup
                    (click)="navigate(child, 'level-2')">
                    <span>{{ child.heading | myLbTranslate }}</span>
                </div>
                <ng-container
                    *ngIf="child.children && !child.groupIsHidden">
                    <ng-container *ngFor="let subItem of child.children">
                        <div
                            id="burger-menu-{{subItem.id}}"
                            class="menu-item-wrapper secondary sub-item"
                            [class.active]="isItemActive(subItem.location)"
                            loc="{{subItem.location}}"
                            heading="{{subItem.heading}}"
                            [children]="subItem.children"
                            iconLocation="{{subItem.iconLocation}}"
                            appMenuGroup
                            (click)="navigate(subItem, 'level-2')">
                            <span>{{ subItem.heading | myLbTranslate }}</span>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
    <div id="burger-menu-logout" class="menu-item-wrapper" (click)="openLogoutModal()">
        <div class="menu-image-wrapper">
            <img
                ngSrc="/assets/centralisedManagement/icons/logout.svg"
                alt="{{'logs' | myLbTranslate}} icon"
                height="19"
                width="19">
        </div>
        <span>{{ 'logout' | myLbTranslate | titlecase }}</span>
    </div>
    <div class="burger-menu-client-details">
        <ng-container [ngTemplateOutlet]="clientDetails"></ng-container>
    </div>
</app-burger-menu-content>

<!-- Drawer holder -->
<app-drawer></app-drawer>

<app-relogin></app-relogin>
<app-modal-wrapper
    [isOverlayBlurred]="true"
    [isOverlayClickable]="false"
    modalTitle="logout_confirmation"
    modalId="logoutConfirm"
    style="user-select: none;"
    footerButtonAlignment="content-center">
    <app-modal-body>
        <p>{{ 'confirm_logout' | myLbTranslate }}</p>
    </app-modal-body>
    <app-modal-footer>
        <button class="primary" (click)="logout()">{{ 'logout' | myLbTranslate }}</button>
    </app-modal-footer>
</app-modal-wrapper>
