<div #qrHolder class="qr-code-holder"></div>
<div class="code">
    <p>{{ 'accounts_two_factor_auth_app_code_message' | myLbTranslate }}</p>
    <div class="token-holder" [class.shimmer]="shimmer">
        {{ authCode }}
        <button
            appCopyClipboard
            class="copy-icon"
            [updateButtonText]=false
            [textToCopy]="authCode"
            (copied)="authCodeCopied()">
        </button>
    </div>
</div>
