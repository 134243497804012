<div class="component-main">
    <div class="content">
        <h1>{{'support_technical_support_download' | myLbTranslate }}</h1>
    </div>
    <div class="download-text-wrapper">
        <p>{{ 'support_technical_info' | myLbTranslate }} {{ 'support_technical_download_info' | myLbTranslate }}</p>
        <p>{{'support_technical_recommended' | myLbTranslate}}</p>
    </div>
</div>
<div class="component-footer">
    <app-loading-button
        buttonId="download"
        buttonClass="primary"
        buttonText="download"
        (click)="requestRecommendedFileDownload()">
    </app-loading-button>
    <ng-container #progressContainer></ng-container>
</div>
