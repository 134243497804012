<div class="component-main">
    <div class="content-space-between">
        <h1 id="component-title">{{ 'organization_title' | myLbTranslate  | titlecase }} {{ 'settings' | myLbTranslate | titlecase }}</h1>
    </div>
    <ng-container *ngIf="!permissionsLookup['organization-settings-update'] else showForm">
        <p>
            <strong>{{ 'organization_title' | myLbTranslate  | titlecase }} {{ 'name' | myLbTranslate }}:</strong>
            {{ organisation?.name }}
        </p>
        <p>
            <strong>{{ 'contact_number' | myLbTranslate }}:</strong>
            {{ organisation?.contactNumber }}
        </p>
        <span class="note small">{{ 'organization_change_denied' | myLbTranslate }}</span>
    </ng-container>
    <ng-template #showForm>
        <form [formGroup]="organisationForm">
            <div class="form-group">
                <label for="organization-name">
                    {{ 'organization_title' | myLbTranslate  | titlecase }} {{ 'name' | myLbTranslate }}*
                </label>
                <div class="form-content">
                    <input
                        formControlName="name"
                        id="organization-name"
                        title="{{ 'name' | myLbTranslate }}"
                        type="text">
                    <app-validation-messages [control]="organisationForm.get('name')"></app-validation-messages>
                </div>
            </div>
            <app-phone-number
                [hideCountryCodes]=hideCountryCodes()
                [formGroup]="organisationForm"
                numberFormName="contactNumber">
            </app-phone-number>
            <div class="form-group">
                <label for="organization-id">
                    {{ 'organization_title' | myLbTranslate  | titlecase }} ID
                </label>
                <div class="form-content">
                    <input id="organization-id" type="text" disabled value="{{ currentOrgID }}">
                </div>
            </div>
        </form>
    </ng-template>
</div>
<div class="component-footer">
    <app-loading-button
        buttonId="organization-settings-update"
        buttonClass="primary"
        buttonText="update_settings"
        [callBack]="updateOrganisation.bind(this)"
        [isDisabled]="!permissionsLookup['organization-settings-update'] || (organisationForm.pristine || organisationForm.invalid)">
    </app-loading-button>
</div>
