<ng-container *ngIf="!hasTier else page">
    <app-alternative-state-page
        headerIcon="assets/centralisedManagement/icons/subscription_required.svg"
        header="accounts_two_factor_auth_upgrade_title"
        messageString="accounts_two_factor_auth_upgrade_message"
        imagePath="assets/centralisedManagement/empty-state/samlNoTier.svg"
        buttonOneText="contact_sales"
        [redirectToButton]="true">
    </app-alternative-state-page>
</ng-container>
<ng-template #page>
    <div class="component-main">
        <div class="content-space-between">
            <h1 id="component-title">{{ 'accounts_two_factor_auth' | myLbTranslate }}</h1>
        </div>
        <h3>{{ 'accounts_two_factor_auth_message' | myLbTranslate }}</h3>
        <form [formGroup]="twoFactorAuthForm" [class.text-width]="formStep === 1">
            <div *ngIf="formStep === 1" class="step1" formGroupName="step1">
                <div class="auth">
                    <div *ngIf="twoFAType !== 'APP'" class="content-space-between">
                        <img src="assets/centralisedManagement/icons/mobile.svg" alt="Mobile icon"/>
                        <div class="text-wrapper">
                            <h5 class="auth-type">SMS</h5>
                            <ng-container *ngIf="twoFAEnabled else smsMessage">
                                <span>{{ 'accounts_two_factor_auth_sms_using' | myLbTranslate }} {{ getHashedNumber() }}</span>
                            </ng-container>
                            <ng-template #smsMessage>
                                <span>{{ 'accounts_two_factor_auth_sms_message' | myLbTranslate }}</span>
                            </ng-template>
                        </div>
                        <div class="button-wrapper">
                            <ng-container *ngIf="twoFAEnabled else setupSms">
                                <button class="primary" (click)="disableNumberConfirmation()">
                                    {{ 'turn_off' | myLbTranslate }}
                                </button>
                            </ng-container>
                            <ng-template #setupSms>
                                <button
                                    id="two-factor-auth-setup-sms"
                                    class="primary"
                                    (click)="nextStep('SMS')">
                                    {{ 'setup' | myLbTranslate }}
                                </button>
                            </ng-template>
                        </div>
                    </div>
                    <div *ngIf="twoFAType !== 'SMS'" class="content-space-between">
                        <img src="assets/centralisedManagement/icons/auth-app.svg" alt="2FA app icon"/>
                        <div class="text-wrapper">
                            <h5 class="auth-type">{{ 'accounts_two_factor_auth_app_title' | myLbTranslate }}</h5>
                            <span>{{ 'accounts_two_factor_auth_app_message' | myLbTranslate }}</span>
                        </div>
                        <div class="button-wrapper">
                            <ng-container *ngIf="twoFAEnabled else setupApp">
                                <button class="primary" (click)="disableAppConfirmation()">
                                    {{ 'turn_off' | myLbTranslate }}
                                </button>
                            </ng-container>
                            <ng-template #setupApp>
                                <button
                                    id="two-factor-auth-setup-app"
                                    class="primary"
                                    (click)="nextStep('APP')">
                                    {{ 'setup' | myLbTranslate }}
                                </button>
                            </ng-template>
                        </div>
                    </div>
                </div>
                <app-tooltip
                    *ngIf="twoFAEnabled"
                    content="{{'accounts_two_factor_auth_disable_tooltip' | myLbTranslate}}"
                    trigger="<img src='./assets/shared/icons/help-i.svg' class='block' alt='help icon'>">
                </app-tooltip>
            </div>
            <div *ngIf="formStep === 2" formGroupName="step2">
                <ng-container *ngIf="twoFAType === 'SMS'">
                    <p class="setup-message">{{ 'accounts_two_factor_auth_use_sms' | myLbTranslate }}</p>
                    <app-phone-number [hideCountryCodes]=false [formGroup]="getFormGroup('step2')"></app-phone-number>
                </ng-container>
                <ng-container *ngIf="twoFAType === 'APP'">
                    <p>{{ 'accounts_two_factor_auth_use_app' | myLbTranslate }}</p>
                    <app-two-factor-auth-app [userId]="userObject?.id"></app-two-factor-auth-app>
                </ng-container>
            </div>
            <div *ngIf="formStep === 3" formGroupName="step3">
                <ng-container *ngIf="twoFAType === 'SMS'">
                    <p class="setup-message">{{ 'accounts_two_factor_auth_use_sms_code' | myLbTranslate }} {{ getHashedNumber() }}</p>
                </ng-container>
                <ng-container *ngIf="twoFAType === 'APP'">
                    <p class="setup-message">{{ 'accounts_two_factor_auth_use_app_code' | myLbTranslate }} {{ getHashedNumber() }}</p>
                </ng-container>
                <app-two-factor-auth
                    [authType]="twoFAType"
                    [callBack]="setupTwoFactorAuthSms.bind(this)"
                    (authenticated)="setAuthCode($event)">
                </app-two-factor-auth>
            </div>
        </form>
    </div>
    <div class="component-footer">
        <div>
            <ng-container *ngIf="formStep !== 1">
                <button id="two-factor-auth-cancel" class="outline" (click)="cancelSetup()">
                    {{ 'cancel' | myLbTranslate }}
                </button>
            </ng-container>
            <ng-container *ngIf="formStep === 2">
                <button
                    id="two-factor-auth-next-step"
                    class="primary"
                    [disabled]="!permissionsLookup['two-factor-auth-next-step'] || twoFactorAuthForm.get('step2').invalid"
                    (click)="twoFAType === 'SMS' ? setupTwoFactorAuthSms() : nextStep()">
                    {{ 'next' | myLbTranslate }}
                </button>
            </ng-container>
        </div>
    </div>
</ng-template>
