<div class="component-main">
    <div class="content">
        <h1>{{ 'date_time_sys_title' | myLbTranslate }}</h1>
    </div>
    <div class="current-time-date">
        <img ngSrc="assets/loadbalancer/icons/time-date.svg" alt="Calendar icon" width="21" height="18"/>
        <span>{{ pageDateTime.dateTime }} {{ pageDateTime.timeZone }}</span>
    </div>
    <form [formGroup]="timeZoneForm">
        <div class="content radio-button-wrapper">
            <input
                type="radio"
                id="ntp"
                tabindex="0"
                [checked]="ntpEnabled"
                (click)="toggleNtp(true)">
            <label for="ntp">{{ 'date_time_use_ntp_servers' | myLbTranslate }}</label>
            <input
                type="radio"
                id="manual"
                tabindex="0"
                [checked]="!ntpEnabled"
                (click)="toggleNtp(false)">
            <label for="manual">{{ 'date_time_set_manually' | myLbTranslate }}</label>
        </div>
        <div class="form-group">
            <label>{{ 'date_time_sys_timezone' | myLbTranslate }}</label>
            <div class="form-content">
                <app-loadbalancer-timezone
                    [disabled]="timeZoneForm.disabled"
                    [timeZoneString]="dateTime.timeZone"
                    (timeZoneOut)="timeZoneFormValue = $event">
                </app-loadbalancer-timezone>
            </div>
        </div>
    </form>
    <ng-container *ngIf="ntpEnabled; else showDateTime">
        <form [formGroup]="ntpForm" id="ntpForm" [disableFocus]="true">
            <input
                type="hidden"
                name="ntpEnabled"
                formControlName="ntpEnabled"
                [value]="ntpEnabled">
            <div class="form-group content-top">
                <label>{{ 'date_time_ntp_servers' | myLbTranslate }}</label>
                <ng-container *ngFor="let control of ntpFields.controls; index as i">
                    <div class="form-group">
                        <div class="form-content">
                            <input
                                title="{{'date_time_ntp_server_1' | myLbTranslate}}"
                                type="text"
                                name="ntp"
                                [formControl]="getFormControl(control)"/>
                            <app-validation-messages
                                class="group-validation" [control]="ntpForm.get('ntp')">
                            </app-validation-messages>
                            <app-validation-messages [control]="control"></app-validation-messages>
                        </div>
                    </div>
                </ng-container>
            </div>
        </form>
    </ng-container>
    <ng-template #showDateTime>
        <form [formGroup]="dateTimeForm" id="dateTimeForm" [disableFocus]="true">
            <div class="form-row">
                <div class="form-group">
                    <label>{{ 'date' | myLbTranslate }}</label>
                    <div class="form-content">
                        <input id="dateTime-date" type="date" formControlName="date"/>
                        <app-validation-messages [control]="dateTimeForm.controls['date']"></app-validation-messages>
                    </div>
                </div>
                <div class="form-group">
                    <label>{{ 'time' | myLbTranslate }}</label>
                    <div class="form-content">
                        <input id="dateTime-time" title="Time" type="time" name="time" formControlName="time" required>
                        <app-validation-messages [control]="dateTimeForm.controls['time']"></app-validation-messages>
                    </div>
                </div>
            </div>
        </form>
    </ng-template>
</div>
<div class="component-footer">
    <app-loading-button
        buttonId="update-config"
        buttonClass="primary"
        buttonText="submit"
        [isDisabled]="(dateTimeForm.invalid || dateTimeForm.pristine) && (ntpForm.invalid || ntpForm.pristine) && (timeZoneForm.invalid || timeZoneForm.pristine)"
        [callBack]="submitHelper.bind(this)">
    </app-loading-button>
</div>

