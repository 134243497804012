<ng-container *ngIf="showModal">
    <app-modal-wrapper
        modalWidth="500"
        modalTitle="missing_ip_addresses"
        modalId="missingIps"
        [showCloseButton]="modalHasError || !dropdown.groups?.length"
        [isOverlayClickable]="false">
        <app-modal-body>
            <p>{{ 'missing_ip_add_to_complete' | myLbTranslate }}</p>
            <form [formGroup]="missingIpForm">
                <table class="mock-table">
                    <thead>
                    <tr>
                        <th>IP</th>
                        <th>Group</th>
                        <th class="fip-status">FIP Status</th>
                        <th class="right">
                            <input
                                id="fips-select-all"
                                class="lb-checkbox"
                                type="checkbox"
                                formControlName="selectAll"
                                [class.partial]="selectRowCount()"
                                (click)="selectRows()"
                            />
                        </th>
                    </tr>
                    </thead>
                    <tbody formGroupName="rows">
                    <ng-container *ngFor="let row of getRows(); index as i">
                        <tr>
                            <td>{{ row?.value?.ip }}</td>
                            <td [formGroupName]="i.toString()">
                                <div class="form-group">
                                    <div class="form-content">
                                        <app-dropdown
                                            dropDownId="multiplefips-groups-{{i}}"
                                            [dropdownWidth]="150"
                                            [dropdownContentWidth]="250"
                                            [formGroup]="missingIpForm?.get('rows').controls[i]"
                                            formControlName="groupId"
                                            [content]="dropdown.groups">
                                        </app-dropdown>
                                    </div>
                                </div>
                            </td>
                            <td class="fip-status">
                                <span class="status-bullet {{ fipState(row.value.enabled) }}"></span>
                                <span [class.disabled]="!row.value.enabled">
                                    {{ fipState(row.value.enabled) | titlecase }}
                                </span>
                            </td>
                            <td class="right">
                                <input
                                    id="fip-enabled-{{[i]}}"
                                    class="lb-checkbox"
                                    type="checkbox"
                                    [formControl]="row.controls.enabled"/>
                                <label for="fip-enabled-{{[i]}}" class="lb-checkbox"></label>
                            </td>
                        </tr>
                    </ng-container>
                    </tbody>
                </table>
            </form>
        </app-modal-body>
        <app-modal-footer>
            <app-loading-button
                buttonId="missing-ip-submit"
                buttonClass="primary"
                buttonText="missing_ip_submit_new_ips"
                [isDisabled]="missingIpForm.get('rows').invalid"
                [callBack]="submitNewIps.bind(this)">
            </app-loading-button>
        </app-modal-footer>
    </app-modal-wrapper>
</ng-container>
