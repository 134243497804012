<div id="page-wrapper">
    <app-menu
        menuTitle="security"
        menuLevelClass="level-2"
        shadowStyle="dark-light"
        [enableMenuCollapse]="false"
        [collapseOnNavigation]="false">
        <div
            id="menu-auditing"
            loc="./auditing"
            heading="{{'auditing' | myLbTranslate}}"
            iconLocation="centralisedManagement/icons/auditing"
            appMenuGroup
            [subscriptionRequired]="isPathAuthorised('/api/v1/organisations/:orgid/audit')"
            [isOnlyHeader]="true">
        </div>
        <div
            id="menu-saml"
            loc="./saml"
            heading="{{'saml_title' | myLbTranslate}}"
            iconLocation="centralisedManagement/icons/saml"
            appMenuGroup
            [subscriptionRequired]="isPathAuthorised('/api/v1/organisations/:orgid/saml')"
            [isOnlyHeader]="true">
        </div>
    </app-menu>
    <div id="content-wrapper">
        <router-outlet></router-outlet>
    </div>
</div>
