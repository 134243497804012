<div class="component-main equal-padding">
    <div class="content">
        <h1>{{ 'support_technical_contact_us' | myLbTranslate }}</h1>
    </div>

    <h4>Raise a Support Ticket</h4>
    <p>For technical support or assistance, <a href="https://www.loadbalancer.org/support/" target="_blank">please click
        here to raise a ticket</a> or email <a href="mailto:support@loadbalancer.org">support@loadbalancer.org.</a></p>
    <p>To help us resolve the issue as quickly as possible, make sure to include the following information in your
        initial ticket/email:</p>

    <h4>Subject line indicating the problem:</h4>
    <ul>
        <li>A high-level overview of the issue e.g. “Issue with SSL termination”, or “Print server load balancing issue”
            or “Enterprise version update issue”.
        </li>
    </ul>

    <h4>The body of the email:</h4>
    <ul>
        <li><strong>A brief description of the problem experienced.</strong> Please be specific about the issue and
            include any other information you think is relevant.
        </li>
        <li><strong>The severity of the situation.</strong> Is it an inconvenience or affecting multiple users/customers
            of your business?
        </li>
        <li><strong>Identify the affected virtual service (VIP) name (if applicable).</strong> Knowing which service is
            experiencing issues will help us focus on the right areas.
        </li>
        <li><strong>An indication of when the issue occurred</strong> Ensure you include the date, time and timezone so
            we can investigate the logs as efficiently as possible.
        </li>
        <li><strong>Any background information e.g. history/scope.</strong> Is this a new configuration or a service
            that previously worked that has developed an issue?
        </li>
        <li><strong>Attach the Technical Support Download from the affected appliance.</strong> To generate the
            download, follow these steps:
        </li>
        <ul>
            <li>Navigate to the 'Support → Technical Support Download' page.</li>
            <li>Click the "Download" button.</li>
            <li>The archive file starts downloading to your local machine.</li>
            <li>Note, this process can take up to 15 minutes in rare circumstances. During this time, please do not
                refresh the page.
            </li>
            <li>If the Technical Support Download is too large to attach to your email, let us know and we can provide
                details of our upload server.
            </li>
        </ul>
    </ul>

    <br>

    <p>While you’re waiting for a response, you might find the following resources helpful:</p>

    <h4>Admin manual</h4>
    <p>Download easy-to-understand <a href="https://www.loadbalancer.org/support/manuals/" target="_blank">admin
        manuals</a> for our latest release or older versions.</p>

    <h4>Step-by-step deployment guides</h4>
    <p>Download step-by-step <a href="https://www.loadbalancer.org/support/deployment-guides/" target="_blank">deployment
        guides</a> for a wide range of applications.</p>
</div>
