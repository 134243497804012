<div id="page-wrapper">
    <app-menu
        menuTitle="resources"
        [collapseOnNavigation]="false">
        <ng-container
            *ngFor="let child of portalMenuService.cmMenu['resources'].children; index as i">
            <div
                [id]="child.id"
                [loc]="child['location']"
                heading="{{ child['heading'] | myLbTranslate}}"
                iconLocation="{{ child['iconLocation'] }}"
                [comingSoon]="child['comingSoon']"
                [disabled]="child['comingSoon']"
                [hidden]="!portalMenuService?.cmMenu['resources'].children[i].auth"
                [isOnlyHeader]="true"
                appMenuGroup>
            </div>
        </ng-container>
    </app-menu>
    <div id="content-wrapper">
        <router-outlet></router-outlet>
    </div>
</div>

