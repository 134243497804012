<div class="component-main">
    <div class="content-space-between">
        <div class="content-left">
            <h1>{{ 'services_restart' | myLbTranslate }}</h1>
        </div>
        <div class="content-right buttons-wrapper">
            <app-loading-button
                buttonId="restart-services-apply-services"
                buttonText="services_apply_all"
                buttonClass="primary"
                [isDisabled]="apiActive || isButtonDisabled('restart-services-apply-services')"
                [callBack]="applyAllChanges.bind(this)">
            </app-loading-button>
            <app-split-action-button
                buttonId="restart-services-restart-loadbalancer"
                buttonText="system_control_restart_lb"
                buttonClass="primary"
                [callBack]="confirmRestartShutdown.bind(this,'restart')">
                <button
                    id="system-control-shutdown-loadbalancer"
                    [disabled]="isButtonDisabled('system-control-shutdown-loadbalancer')"
                    (click)="confirmRestartShutdown('shutdown')">
                    {{ 'system_control_shutdown_lb' | myLbTranslate }}
                </button>
            </app-split-action-button>
        </div>
    </div>

    <app-table
        [name]="'sys-services-table'"
        [data]="tableRows"
        [searchTerm]="searchTerm"
        (searchValues)="updateSearchTerm($event)">
        <app-column [flexGrow]="1" prop="nameFull" label="name"></app-column>
        <app-column [flexGrow]="1" prop="state" label="state" [customCellTemplate]="serviceState">
            <ng-template let-row #serviceState class="lb-table-warning">
                <span class="status-bullet {{row.stateClass}}"></span>
                <span [class]="row.state === 'Inactive' ? 'disabled' : row.state">
                    {{ row.state | titlecase }}
                </span>
            </ng-template>
        </app-column>
        <app-column
            [flexGrow]="0.5"
            prop="action"
            label=""
            [cellAlignment]="'right'"
            [customCellTemplate]="servicesActions">
            <ng-template let-row #servicesActions>
                <app-action-dots [hidden]="row.state === 'Inactive'">
                    <button
                        id="restart-services-{{row.name}}-restart"
                        [disabled]="apiActive || isButtonDisabled('restart-services-' + row.name + '-restart')"
                        (click)="sendServiceRequest(row.name, 'restart')">
                        {{ 'restart' | myLbTranslate }}
                    </button>
                    <button
                        *ngIf="row.reload"
                        id="restart-services-{{row.name}}-reload"
                        [disabled]="apiActive || isButtonDisabled('restart-services-' + row.name + '-reload')"
                        (click)="sendServiceRequest(row.name, 'reload')">
                        {{ 'reload' | myLbTranslate }}
                    </button>
                </app-action-dots>
            </ng-template>
        </app-column>
    </app-table>
</div>
