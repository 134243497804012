<div class="component-main">
    <div class="content-space-between">
        <h1>{{ 'manage_acls' | myLbTranslate }}</h1>
        <div>
            <button
                *ngIf="hangingAcls.length"
                id="manage-services-hanging-backends"
                [disabled]="isButtonDisabled('manage-services-hanging-backends')"
                class="primary"
                (click)="openHangingAclsModal()">
                {{ 'hanging_acls' | myLbTranslate }} ({{ hangingAcls.length }})
            </button>
            <button id="manage-acls-add-acl" class="primary" [disabled]="!frontends.length" (click)="addAcl()">
                {{ 'manage_acls_add' | myLbTranslate }}
            </button>
        </div>
    </div>
    <app-search-rows [rows]="tableRows" [searchKeys]="searchKeys" (searchResults)="updateTableRows($event)">
    </app-search-rows>
    <app-table [rows]="tableRows" [columnMode]="'force'">
        <app-table-column [flexGrow]="1" prop="frontend" name="frontend">
            <ng-template let-row="row" appLbTableColumnCell>
                <span
                    class="routerLink"
                    id="manage-services-service-details"
                    [class.disabled]="isButtonDisabled('manage-acls-acls-details')"
                    [routerLink]="['acls-details', row.frontendId]">
                    {{ row.frontendLabel }}
                </span>
            </ng-template>
        </app-table-column>
        <app-table-column [flexGrow]="0.5" prop="aclsCount" name="count"></app-table-column>
        <app-table-column [flexGrow]="3" prop="conditions" name="condition"></app-table-column>
    </app-table>
</div>

<!-- Container used for all modals -->
<ng-container #modalContainer></ng-container>
