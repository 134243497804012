<div class="component-main">
    <div class="content-space-between">
        <h1>{{ pageTitle | myLbTranslate }}</h1>
    </div>
    <form [formGroup]="formGroup" id="pool-form" name="pool_form">
        <div class="form-group">
            <label for="peer-address">{{ 'ha_conf_peer_ip_address' | myLbTranslate }}</label>
            <div class="form-content">
                <input
                    id="peer-address"
                    name="peer_address"
                    type="text"
                    class="form_input"
                    formControlName="ip">
                <app-validation-messages [control]="formGroup.controls['ip']"></app-validation-messages>
                <app-help class="help-button" tag="ha-conf_ip"></app-help>
            </div>
        </div>
        <div class="form-group">
            <label for="peer-port">{{ 'ha_conf_peer_port' | myLbTranslate }}</label>
            <div class="form-content">
                <input
                    id="peer-port"
                    name="peer_port"
                    type="number"
                    class="form-input"
                    formControlName="port">
                <app-validation-messages [control]="formGroup.controls['port']"></app-validation-messages>
                <app-help class="help-button" tag="ha-conf_port"></app-help>
            </div>
        </div>
        <div class="form-group">
            <label for="peer-email">{{ 'ha_conf_administrator_email' | myLbTranslate }}</label>
            <div class="form-content">
                <input
                    id="peer-email"
                    name="peer_email"
                    type="email"
                    class="form-input"
                    formControlName="email">
                <app-help class="help-button" tag="ha-conf_username"></app-help>
                <app-validation-messages [control]="formGroup.controls['email']"></app-validation-messages>
            </div>
        </div>
        <div class="form-group">
            <label for="peer-password">{{ 'ha_conf_administrator_password' | myLbTranslate }}</label>
            <div class="form-content">
                <app-lb-reveal-password
                    id="peer-password"
                    class="form-input"
                    title="{{'password' | myLbTranslate }}"
                    formControlName="password"
                    [formGroup]="formGroup">
                </app-lb-reveal-password>
                <app-help class="help-button" tag="ha-conf_password"></app-help>
                <app-validation-messages [control]="formGroup.controls['password']"></app-validation-messages>
            </div>
        </div>
    </form>
</div>
<div class="component-footer">
    <button
        id="modal-cancel"
        class="outline"
        [routerLink]="[nodeId ? '../..' : '..']">
        {{ 'cancel' | myLbTranslate }}
    </button>
    <app-loading-button
        buttonId="modal-confirm"
        buttonClass="primary"
        [buttonText]="nodeId ? 'restore' : 'confirm'"
        [isDisabled]="formGroup.invalid || formGroup.pristine"
        [callBack]="confirmConfiguration.bind(this, !!nodeId)">
    </app-loading-button>
</div>
