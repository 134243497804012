<form class="content-space-between table-pagination" [formGroup]="tablePaginationForm">
    <div class="pager">
        <span>{{ 'table_show_records' | myLbTranslate }}</span>
        <app-dropdown
            dropDownId="table-pagination"
            [dropdownWidth]="83"
            [content]="tableService.dropdownValues"
            [formGroup]="tablePaginationForm"
            formControlName="amount">
        </app-dropdown>
    </div>
    <div class="pager center" *ngIf="rows.length !== 0">
        <button
            [disabled]="tableService.currentPage === 1"
            class="primary small arrow-left"
            (click)="handleClickOnPaginationButtons('prev')">
        </button>
        <div class="ellipsis" disabled="true">
            <span [hidden]="!tableService.showLeftEllipsis">...</span>
        </div>
        <div
            *ngFor="let pageNumber of tableService.pageNumbers;"
            class="page-number"
            [class.active]="tableService.currentPage === pageNumber"
            [class.disabled]="numberOfPages === 1">
            <button
                [disabled]="pageNumber === '...'"
                class="small"
                (click)="handleClickOnPaginationButtons('num', pageNumber)">
                {{ pageNumber }}
            </button>
        </div>
        <div class="ellipsis" disabled="true">
            <span [hidden]="!tableService.showLeftEllipsis">...</span>
        </div>
        <button
            [disabled]="tableService.currentPage === numberOfPages"
            class="primary small arrow-right"
            (click)="handleClickOnPaginationButtons('next')">
        </button>
    </div>
    <div class="pager">
        <span>{{ 'table_go_to_page' | myLbTranslate }}</span>
        <input
            type="number"
            size="25"
            [min]="1"
            [max]="tableService.pageNumbers.length"
            formControlName="goToPage"/>
        <button
            [disabled]="!tablePaginationForm.valid || numberOfPages === 1"
            class="primary small arrow-right"
            (click)="handleClickOnPaginationButtons('num', tablePaginationForm.get('goToPage').value)">
        </button>
    </div>
</form>
