<div class="component-main equal-padding">
    <div class="content-space-between">
        <h1 id="component-title">{{ 'subscriptions' | myLbTranslate | titlecase }}</h1>
    </div>
    <form [formGroup]="formGroup">
        <div class="content toggle-wrapper">
            <span>{{ 'subscriptions_annual_plan' | myLbTranslate }} <span class="discount">(20% off)</span></span>
            <input type="checkbox" id="switch" class="toggle-switch" formControlName="toggle">
            <label for="switch"></label>
            <span>{{ 'subscriptions_monthly_plan' | myLbTranslate }}</span>
        </div>
    </form>

    <div class="grid">
        <div class="grid__item width-3/12" [class.selected]="selectedPlan === 'free'">
            <div class="grid-content-column-space-between">
                <div class="grid-content-column">
                    <span class="title">{{ 'subscriptions_free' | myLbTranslate }}</span>
                    <span
                        class="sub-title primary-text">{{ 'subscriptions_for_starter_projects' | myLbTranslate }}</span>
                    <div class="divider-line"></div>
                    <div class="price-wrapper">
                        <span class="price">$0</span>
                    </div>
                    <div class="price-wrapper annual">
                    </div>
                    <button
                        class="primary"
                        [disabled]="selectedPlan === 'free'"
                        (click)="contactSales(intercomMessage.free)">
                        {{ (selectedPlan === 'free' ? 'subscriptions_current_plan' : 'contact_sales') | myLbTranslate | titlecase }}
                    </button>
                    <div class="divider-line"></div>
                    <span class="sub-title">ADC Connections</span>
                    <span class="title light primary-text">6</span>
                    <div class="divider-line"></div>
                    <span class="sub-title">ADC Backups</span>
                    <span class="title light primary-text">2 per ADC</span>
                    <span class="history">With 1 Year history</span>
                    <div class="divider-line"></div>
                    <span class="sub-title">Support Level</span>
                    <span class="title light primary-text">Account & Billing</span>
                    <span class="history">&nbsp;</span>
                    <div class="divider-line"></div>
                    <span class="list-title">Portal</span>
                    <ul>
                        <li>Dashboard</li>
                        <li>ADC Backups</li>
                    </ul>
                    <span class="list-title">ADC Access</span>
                    <ul>
                        <li>Secure Direct Access</li>
                    </ul>
                    <span class="list-title">Security</span>
                    <ul>
                        <li>ADC Security Insights</li>
                    </ul>
                    <span class="list-title">Coming Soon</span>
                    <ul>
                        <li>License management</li>
                        <li>API Access</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="grid__item width-3/12" [class.selected]="selectedPlan === 'professional'">
            <div class="grid-content-column-space-between">
                <div class="grid-content-column">
                    <span class="title">Portal pro</span>
                    <span class="sub-title primary-text">For professionals</span>
                    <div class="divider-line"></div>
                    <div class="price-wrapper">
                        <span class="price">{{ toggle ? '$130' : '$99' }}</span>
                        <span class="term">/mo</span>
                    </div>
                    <div class="price-wrapper annual">
                        <ng-container *ngIf="!toggle">
                            <span class="annual-price">Billed at <strong>$1188</strong></span>
                            <span class="term">/yr</span>
                        </ng-container>
                    </div>
                    <button
                        class="primary"
                        [disabled]="selectedPlan === 'pro'"
                        (click)="contactSales(intercomMessage.pro)">
                        {{ (selectedPlan === 'pro' ? 'subscriptions_current_plan' : 'contact_sales') | myLbTranslate | titlecase }}
                    </button>

                    <div class="divider-line"></div>
                    <span class="sub-title">ADC Connections</span>
                    <span class="title light primary-text">12</span>
                    <div class="divider-line"></div>

                    <span class="sub-title">ADC Backups</span>
                    <span class="title light primary-text">25 per ADC</span>
                    <span class="history">With 1 Year history</span>
                    <div class="divider-line"></div>
                    <span class="sub-title">Support Level</span>
                    <span class="title light primary-text">9am - 5pm</span>
                    <span class="history">Portal Platform (In your timezone)</span>
                    <div class="divider-line"></div>
                    <span class="list-title">Portal</span>
                    <ul>
                        <li>Dashboard</li>
                        <li>ADC Backups</li>
                        <li>Online Updates</li>
                        <li>Asset Scheduling</li>
                    </ul>
                    <span class="list-title">ADC Access</span>
                    <ul>
                        <li>Secure Direct Access</li>
                        <li>Secure Warp Remote Access</li>
                    </ul>
                    <span class="list-title">Security</span>
                    <ul>
                        <li>ADC Security Insights</li>
                        <li>ADC Certificate Insights</li>
                    </ul>
                    <span class="list-title">Coming Soon</span>
                    <ul>
                        <li>License management</li>
                        <li>API Access</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="grid__item width-3/12" [class.selected]="selectedPlan === 'business'">
            <div class="grid-content-column-space-between">
                <div class="grid-content-column">
                    <span class="title">Portal business</span>
                    <span class="sub-title primary-text">For multiple teams</span>
                    <div class="divider-line"></div>
                    <div class="price-wrapper">
                        <span class="price">{{ toggle ? '$630' : '$499' }}</span>
                        <span class="term">/mo</span>
                    </div>
                    <div class="price-wrapper annual">
                        <ng-container *ngIf="!toggle">
                            <span class="annual-price">Billed at <strong>$5988</strong></span>
                            <span class="term">/yr</span>
                        </ng-container>
                    </div>
                    <button
                        class="primary"
                        [disabled]="selectedPlan === 'business'"
                        (click)="contactSales(intercomMessage.business)">
                        {{ (selectedPlan === 'business' ? 'subscriptions_current_plan' : 'contact_sales') | myLbTranslate | titlecase }}
                    </button>

                    <div class="divider-line"></div>
                    <span class="sub-title">ADC Connections</span>
                    <span class="title light primary-text">30</span>
                    <div class="divider-line"></div>
                    <span class="sub-title">ADC Backups</span>
                    <span class="title light primary-text">Unlimited</span>
                    <span class="history">With 1 Year history</span>
                    <div class="divider-line"></div>
                    <span class="sub-title">Support Level</span>
                    <span class="title light primary-text">24/7</span>
                    <span class="history">Portal Platform (Any timezone)</span>
                    <div class="divider-line"></div>

                    <span class="list-title">Portal</span>
                    <ul>
                        <li>Dashboard</li>
                        <li>ADC Backups</li>
                        <li>Online Updates</li>
                        <li>Asset Scheduling</li>
                    </ul>
                    <span class="list-title">ADC Access</span>
                    <ul>
                        <li>Secure Direct Access</li>
                        <li>Secure Warp Remote Access</li>
                    </ul>
                    <span class="list-title">Security</span>
                    <ul>
                        <li>ADC Security Insights</li>
                        <li>ADC Certificate Insights</li>
                        <li>Single Sign-On<br>(G Suite + Azure AD)</li>
                        <li>Two-Factor Authentication (SMS + APP)</li>
                        <li>Audit Log</li>
                    </ul>
                    <span class="list-title">Coming Soon</span>
                    <ul>
                        <li>License management</li>
                        <li>API Access</li>
                        <li>Asset Scheduling - Pipelines</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="grid__item width-3/12" [class.selected]="selectedPlan === 'tailored'">
            <div class="grid-content-column-space-between">
                <div class="grid-content-column">
                    <span class="title">Portal tailored</span>
                    <span class="sub-title primary-text">For total customization</span>
                    <div class="divider-line"></div>
                    <div class="price-wrapper">
                        <span class="price">Let's talk</span>
                    </div>
                    <div class="price-wrapper annual">
                    </div>
                    <button
                        class="primary"
                        [disabled]="selectedPlan === 'tailored'"
                        (click)="contactSales(intercomMessage.tailored)">
                        {{ (selectedPlan === 'tailored' ? 'subscriptions_current_plan' : 'contact_sales') | myLbTranslate | titlecase }}
                    </button>

                    <div class="divider-line"></div>
                    <span class="sub-title">ADC Connections</span>
                    <span class="title light primary-text">Unlimited</span>
                    <div class="divider-line"></div>
                    <span class="sub-title">ADC Backups</span>
                    <span class="title light primary-text">Unlimited</span>
                    <span class="history">With 1 Year history</span>
                    <div class="divider-line"></div>
                    <span class="sub-title">Support Level</span>
                    <span class="title light primary-text">24/7</span>
                    <span class="history">Portal Platform (Any timezone)</span>
                    <div class="divider-line"></div>

                    <span class="list-title">Portal</span>
                    <ul>
                        <li>Dashboard</li>
                        <li>ADC Backups</li>
                        <li>Online Updates</li>
                        <li>Asset Scheduling</li>
                        <li>Dedicated Success Manager</li>
                        <li>Professional Services</li>
                    </ul>
                    <span class="list-title">ADC Access</span>
                    <ul>
                        <li>Secure Direct Access</li>
                        <li>Secure Warp Remote Access</li>
                    </ul>
                    <span class="list-title">Security</span>
                    <ul>
                        <li>ADC Security Insights</li>
                        <li>ADC Certificate Insights</li>
                        <li>Single Sign-On<br>(G Suite + Azure AD + SAML)</li>
                        <li>Two-Factor Authentication (SMS + APP)</li>
                        <li>Audit Log</li>
                    </ul>
                    <span class="list-title">Coming Soon</span>
                    <ul>
                        <li>License management</li>
                        <li>API Access</li>
                        <li>Asset Scheduling - Pipelines</li>
                        <li>White Label</li>
                    </ul>
                </div>
                <div class="grid-content-column-bottom">

                </div>
            </div>
        </div>
    </div>

    <div class="grid footer">
        <div class="grid__item width-12/12 footer-gradient-background">
            <div class="grid-content-middle-space-between">
                <div class="grid-content-middle">
                    <img src="/assets/shared/icons/add-adc.svg" class="adc-icon" alt="adc icon"/>
                    <div class="grid-content-column-middle">
                        <span class="footer-title">Need more ADC connections?</span>
                        <span class="footer-message">
                            Add an additional 10 ADC connections to your Portal for only <strong>{{ toggle ? '$149/month' : '$119/month' }}</strong>.
                        </span>
                    </div>
                </div>
                <button class="outline" (click)="contactSales(intercomMessage.general)">
                    {{ 'contact_sales' | myLbTranslate | titlecase }}
                </button>
            </div>
        </div>
        <div class="grid__item width-12/12">
            <div class="grid-content-middle-space-between">
                <div class="grid-content-middle">
                    <img src="/assets/shared/icons/user-headset.svg" class="support-icon" alt="Support icon"/>
                    <div class="grid-content-column-middle">
                        <span class="footer-title">Enterprise-ready! Contact our team for a custom quote.</span>
                        <span class="footer-message">Our Tailored subscription is designed to work for large enterprises. Book a free demo with us
                        today.</span>
                    </div>
                </div>
                <button class="primary" (click)="contactSales(intercomMessage.tailored)">
                    {{ 'subscriptions_book_a_demo' | myLbTranslate | titlecase }}
                </button>
            </div>
        </div>
    </div>
</div>
