//  _    ___   ___  ___  ___
// | |  | _ ) / _ \| _ \/ __|
// | |__| _ \| (_) |   / (_ |
// |____|___(_)___/|_|_\\___|
//           Loadbalancer.org
// ---

import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { Directive } from '@angular/core';

@Directive({
    selector: '[appHostnameValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: GlobalNamesDomainValidationDirective, multi: true }]
})

export class GlobalNamesDomainValidationDirective implements Validator {
    public validate(control: AbstractControl): {[key: string]: any} | null {
        return globalNamesDomainValidation(control);
    }
}

export function globalNamesDomainValidation(control: any) {
    if (!control || !control.value || control.value === '') {
        return null;
    }

    const regex = new RegExp(/^[A-Za-z-0-9.]+$/);
    const characters = regex.test(control.value);
    const consecutivePeriods = control.value.match(/\.{2,}/g);

    if (!characters) {
        // If the domain doesnt contain the correct characters return error.
        return { 'globalNameDomainValidation': 'Invalid characters should only contain: a-z A-z 0-9 - .' };
    } else if (consecutivePeriods !== null) {
        // If the domain contains consecutive periods return error.
        return { 'globalNameDomainValidation': 'The domain should not contain consecutive periods.' };
    } else if (control.value.length >= 63) {
        // If the domain length is equal to or longer than 63 return error.
        return { 'globalNameDomainValidation': 'The domain should not be longer than 63 characters.' };
    }
}
