<div class="component-main">
    <div class="content-space-between">
        <div class="content-left">
            <h1 id="component-title">{{ 'adc_edit_details' | myLbTranslate }}</h1>
        </div>
    </div>
    <form [formGroup]="formGroup">
        <div class="form-group">
            <div class="form-content">
                <input id="edit-adc-lock" formControlName="locked" class="lb-checkbox" type="checkbox">
                <span>{{ 'adc_edit_lock' | myLbTranslate }}</span>
            </div>
            <p class="adc-edit-lock-message">{{ 'adc_edit_lock_message' |myLbTranslate }}</p>
        </div>
        <div class="form-group">
            <label>{{ 'adc_name' | myLbTranslate }}</label>
            <div class="form-content">
                <input
                    id="adc-edit-label"
                    formControlName="label"
                    title="ADC label"
                    type="text">
                <app-validation-messages [control]="formGroup.get('label')"></app-validation-messages>
            </div>
        </div>
        <div class="form-group">
            <!--suppress XmlInvalidId -->
            <label for="shuttle">{{ 'shuttle' | myLbTranslate }}*</label>
            <div class="form-content">
                <app-dropdown
                    dropDownId="shuttle"
                    [displayAsInput]="true"
                    [content]="shuttlesDropdown">
                </app-dropdown>
            </div>
        </div>
        <div class="form-group">
            <label>{{ 'address' | myLbTranslate }}</label>
            <div class="form-content">
                <input
                    id="adc-edit-address"
                    formControlName="ipAddress"
                    title="ADC Address"
                    type="text">
                <app-validation-messages [control]="formGroup.get('ipAddress')"></app-validation-messages>
            </div>
        </div>
        <div class="form-group">
            <label>{{ 'port' | myLbTranslate }}</label>
            <div class="form-content">
                <input
                    id="adc-edit-port"
                    formControlName="port"
                    title="ADC Address"
                    type="number">
                <app-validation-messages [control]="formGroup.get('port')"></app-validation-messages>
            </div>
        </div>
        <div class="form-group">
            <!--suppress XmlInvalidId -->
            <label for="adc-edit-namespace">{{ 'namespace' | myLbTranslate }}</label>
            <div class="form-content">
                <app-dropdown
                    dropDownId="adc-edit-namespace"
                    [formGroup]="formGroup"
                    formControlName="namespaceID"
                    [search]="true"
                    [content]="namespaceDropdown">
                </app-dropdown>
                <app-validation-messages [control]="formGroup.get('namespaceID')"></app-validation-messages>
            </div>
        </div>
        <div class="form-group">
            <label>{{ 'notes' | myLbTranslate }}</label>
            <div class="form-content">
                <textarea id="adc-edit-notes" formControlName="notes"></textarea>
                <app-validation-messages [control]="formGroup.get('notes')"></app-validation-messages>
            </div>
        </div>
        <div class="form-group">
            <label>{{ 'tags' | myLbTranslate }}</label>
            <div class="form-content">
                <app-adc-tags
                    [tags]="formGroup.get('tags').value"
                    [disableForm]="formGroup.get('tags').disabled"
                    (selectedTags)="setTagsFormValue($event)">
                </app-adc-tags>
            </div>
        </div>
    </form>
</div>
<div class="component-footer">
    <button id="adc-edit-cancel" class="outline" routerLink="/cm/adcs/list">
        {{ 'cancel' | myLbTranslate }}
    </button>
    <app-loading-button
        buttonId="adc-edit-new-submit"
        [isDisabled]="formGroup.invalid || formGroup.pristine"
        buttonText="submit"
        buttonClass="primary"
        [callBack]="updateAdc.bind(this)">
    </app-loading-button>
</div>
