<div
    *ngIf="!isNotificationsPage"
    id="notifications-wrapper"
    [class.empty]="hideWrapper"
    [class.drawer-open]="drawerState">
    <ng-container *ngFor="let group of grouping">
        <div class="group-wrapper">
            <ng-container *ngFor="let notification of getFirstPropertyFromObject(group); let i = index">
                <div
                    [id]="notification.id"
                    class="group-item"
                    [class.no-scale]="getGroupLength(group) === 1"
                    [class.active]="activeNotifications.includes(notification) || !notification.canAnimate"
                    [@inOut]="{value: activeNotifications.includes(notification) ? 'in' : 'out', params: {animation: animation}}"
                    [ngStyle]="{'margin-top.px': getGroupMarginTop(group), 'z-index': i}"
                    (@inOut.done)="endOfAnimation($event, notification)">
                    <app-notifications-banner [notification]="notification"></app-notifications-banner>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>
