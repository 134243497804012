<div class="component-main" *ngIf="dataAvailable">
    <div class="content">
        <h1>{{ 'gslb' | myLbTranslate }}</h1>
    </div>
    <ng-container *ngIf="poolsOfflineTotal">
        <div class="widgetContainer" id="gslb-widget-container">
            <app-widget
                title="{{ 'alerts' | myLbTranslate }}"
                id="gslb-widget">
                <div class="content-half-width-center">
                    <div class="content-vertical-middle">
                        <h1 class="critical">{{ poolsOfflineTotal }}</h1>
                        <h2>{{ 'offline' | myLbTranslate }}</h2>
                    </div>
                </div>
            </app-widget>
        </div>
    </ng-container>

    <app-tab-container id="gslb" [activeTabId]="activeTab">
        <app-tab tabTitle="gslb_global_names" id="global-names">
            <div class="content-space-between">
                <div class="content-left">
                    <app-search-rows
                        [removeMargin]="true"
                        [rows]="globalNames"
                        [searchKeys]="['domain', 'timeToLive']"
                        (searchResults)="updateTableRows($event, 'globalNames')">
                    </app-search-rows>
                </div>
                <div class="content-right">
                    <button
                        id="gslb-add-globalname"
                        [disabled]="isButtonDisabled('gslb-add-globalname')"
                        class='primary'
                        (click)="navigateToAddEdit('/cluster-configuration/gslb/global-names')">
                        {{ 'gslb_add_global_names' | myLbTranslate }}
                    </button>
                </div>
            </div>
            <app-table
                [rows]="globalNames"
                [columnMode]="'flex'"
                [selectMode]="'singleRow'">
                <app-table-column [flexGrow]="1" prop="domain" name="gslb_global_name">
                </app-table-column>
                <app-table-column [flexGrow]="1" prop="timeToLive" name="ttl"></app-table-column>
                <app-table-column [flexGrow]="0.75" prop="usage" name="usage">
                    <ng-template let-row="row" appLbTableColumnCell>
                        <app-usage [usage]="row.usage" modalTitle="global_names_usage"></app-usage>
                    </ng-template>
                </app-table-column>
                <app-table-column [flexGrow]="0.75" prop="actions" name="" [cellAlignment]="'right'">
                    <ng-template let-row="row" appLbTableColumnCell>
                        <app-action-dots>
                            <button
                                id="gslb-edit-globalname"
                                [disabled]="isButtonDisabled('gslb-edit-globalname')"
                                (click)="navigateToAddEdit('/cluster-configuration/gslb/global-names', row)">
                                {{ 'edit' | myLbTranslate }}
                            </button>
                            <button
                                id="gslb-delete-globalname"
                                [disabled]="isButtonDisabled('gslb-delete-globalname') || row.usage?.length"
                                (click)="confirmDelete('globalName', row)">
                                {{ 'delete' | myLbTranslate }}
                            </button>
                        </app-action-dots>
                    </ng-template>
                </app-table-column>
            </app-table>
        </app-tab>

        <app-tab tabTitle="members" id="members">
            <div class="content-space-between">
                <app-search-rows
                    [removeMargin]="true"
                    [rows]="members"
                    [searchKeys]="['name', 'ip', 'weight']"
                    (searchResults)="updateTableRows($event, 'members')">
                </app-search-rows>
                <button
                    id="gslb-add-member"
                    [disabled]="isButtonDisabled('gslb-add-members')"
                    class='primary'
                    (click)="navigateToAddEdit('/cluster-configuration/gslb/members')">
                    {{ 'gslb_add_member' | myLbTranslate }}
                </button>
            </div>
            <app-table
                [rows]="members"
                [columnMode]="'flex'"
                [selectMode]="'singleRow'">
                <app-table-column [flexGrow]="1" prop="name" name="name"></app-table-column>
                <app-table-column [flexGrow]="1" prop="ip" name="ip"></app-table-column>
                <app-table-column [flexGrow]="1" prop="weight" name="weight"></app-table-column>
                <app-table-column [flexGrow]="0.75" prop="usage" name="usage">
                    <ng-template let-row="row" appLbTableColumnCell>
                        <app-usage [usage]="row.usage" modalTitle="global_names_usage"></app-usage>
                    </ng-template>
                </app-table-column>
                <app-table-column [flexGrow]="0.5" prop="actions" name="" [cellAlignment]="'right'">
                    <ng-template let-row="row" appLbTableColumnCell>
                        <app-action-dots>
                            <button
                                id="gslb-edit-member"
                                [disabled]="isButtonDisabled('gslb-edit-member')"
                                (click)="navigateToAddEdit('/cluster-configuration/gslb/members', row)">
                                {{ 'edit' | myLbTranslate }}
                            </button>
                            <button
                                id="gslb-delete-member"
                                [disabled]="isButtonDisabled('gslb-delete-member') || row.usage?.length"
                                (click)="confirmDelete('member', row)">
                                {{ 'delete' | myLbTranslate }}
                            </button>
                        </app-action-dots>
                    </ng-template>
                </app-table-column>
            </app-table>
        </app-tab>

        <app-tab tabTitle="pools" id="pools">
            <div class="content-space-between">
                <app-search-rows
                    [removeMargin]="true"
                    [rows]="poolsTableRows"
                    [searchKeys]="['name', 'monitor', 'lbMethod']"
                    (searchResults)="updateTableRows($event, 'pools')">
                </app-search-rows>
                <button
                    id="gslb-add-pool"
                    class='primary'
                    [disabled]="isButtonDisabled('gslb-add-pools')"
                    (click)="navigateToAddEdit('/cluster-configuration/gslb/pools')">
                    {{ 'gslb_add_pool' | myLbTranslate }}
                </button>
            </div>
            <app-table
                [rows]="poolsTableRows"
                [columnMode]="'flex'"
                [selectMode]="'singleRow'">
                <app-table-column [flexGrow]="1" prop="name" name="name"></app-table-column>
                <app-table-column [flexGrow]="1" prop="monitor" name="monitor"></app-table-column>
                <app-table-column [flexGrow]="1" prop="lbMethod" name="gslb_lb_method"></app-table-column>
                <app-table-column [flexGrow]="1" prop="status" name="status">
                    <ng-template let-row="row" appLbTableColumnCell class="lb-table-warning">
                        <span
                            class="status-bullet {{row.status === 'offline' || row.status === 'pending' ? 'inactive': 'active' }}"></span>
                        <span [class]="row.status === 'offline' || row.status ===  'pending' ? 'disabled' : 'active'">
                            {{ row.status | titlecase }}
                        </span>
                    </ng-template>
                </app-table-column>
                <app-table-column [flexGrow]="0.5" prop="actions" name="" [cellAlignment]="'right'">
                    <ng-template let-row="row" appLbTableColumnCell>
                        <app-action-dots>
                            <button
                                id="gslb-edit-pool"
                                [disabled]="isButtonDisabled('gslb-edit-pool')"
                                (click)="navigateToAddEdit('/cluster-configuration/gslb/pools', row)">
                                {{ 'edit' | myLbTranslate }}
                            </button>
                            <button
                                id="gslb-delete-pool"
                                [disabled]="isButtonDisabled('gslb-delete-pool')"
                                (click)="confirmDelete('pool', row)">
                                {{ 'delete' | myLbTranslate }}
                            </button>
                        </app-action-dots>
                    </ng-template>
                </app-table-column>
            </app-table>
        </app-tab>

        <app-tab tabTitle="topologies" id="topologies">
            <div class="content-space-between">
                <app-search-rows
                    [removeMargin]="true"
                    [hidden]="members && members.length === 0"
                    [rows]="topologiesTableRows"
                    [searchKeys]="['subnet', 'member']"
                    (searchResults)="updateTableRows($event, 'topologies')">
                </app-search-rows>
                <button
                    id="gslb-add-topology"
                    class="primary"
                    [hidden]="members && members.length === 0"
                    [disabled]="isButtonDisabled('gslb-add-topology')"
                    (click)="navigateToAddEdit('/cluster-configuration/gslb/topologies')">
                    {{ 'gslb_add_topology' | myLbTranslate }}
                </button>
            </div>
            <ng-container *ngIf="members && members.length; else noMembers">
                <app-table
                    [rows]="topologiesTableRows"
                    [columnMode]="'flex'"
                    [selectMode]="'singleRow'">
                    <app-table-column [flexGrow]="1" prop="subnet" name="subnet"></app-table-column>
                    <app-table-column [flexGrow]="1" prop="member" name="member"></app-table-column>
                    <app-table-column [flexGrow]="0.5" prop="actions" name="" [cellAlignment]="'right'">
                        <ng-template let-row="row" appLbTableColumnCell>
                            <app-action-dots>
                                <button
                                    id="gslb-edit-topology"
                                    [disabled]="isButtonDisabled('gslb-edit-topology')"
                                    (click)="navigateToAddEdit('/cluster-configuration/gslb/topologies', row)">
                                    {{ 'edit' | myLbTranslate }}
                                </button>
                                <button
                                    id="gslb-delete-topology"
                                    [disabled]="isButtonDisabled('gslb-delete-topology')"
                                    (click)="confirmDelete('topology', row)">
                                    {{ 'delete' | myLbTranslate }}
                                </button>
                            </app-action-dots>
                        </ng-template>
                    </app-table-column>
                </app-table>
            </ng-container>
            <ng-template #noMembers>
                <h1>{{ 'gslb_no_members' | myLbTranslate }}</h1>
                <span>{{ 'gslb_no_members_text' | myLbTranslate }}</span>
            </ng-template>
        </app-tab>
    </app-tab-container>
</div>
