<ng-container *ngIf="isLoading else pageContent">
    <div class="loader-wrapper">
        <img
            class="loader"
            ngSrc="assets/shared/icons/loading-spinner-primary.svg"
            height="40"
            width="40"
            alt="loading spinner"/>
    </div>
</ng-container>
<ng-template #pageContent>
    <ng-container *ngIf="!this.storedAdcs.length else table">
        <ng-template #shuttleMessageTemplate>
            <p>
                {{ 'adc_empty_shuttle_message' | myLbTranslate }} {{ storedShuttles.length }}
                {{ 'adc_empty_shuttle_message_2' | myLbTranslate }}
                <a class="default" routerLink="/cm/adcs/list/select-vendor">
                    {{ 'adc_empty_shuttle_message_3' | myLbTranslate }}
                </a>
                {{ 'adc_empty_shuttle_message_4' | myLbTranslate }}
            </p>
        </ng-template>
        <app-alternative-state-page
            *ngIf="storedShuttles.length && !storedAdcs.length"
            header="adcs"
            titleText="adc_empty_shuttle_title"
            [messageTemplate]="shuttleMessageTemplate"
            [mockTableHeaders]="['label', 'ip_address', 'port', 'status']"
            imagePath="assets/centralisedManagement/empty-state/hasShuttles.svg"
            buttonOneText="adc_add"
            buttonOneLink="/cm/adcs/list/select-vendor"
            [buttonOneDisabled]="!permissionsLookup['adcs-list-add']">
        </app-alternative-state-page>
        <ng-template #adcMessageTemplate>
            <p>
                {{ 'adc_empty_no_shuttle_message' | myLbTranslate }}
                <button class="button-as-link" routerLink="/cm/adcs/shuttle">
                    {{ 'adc_shuttle_management' | myLbTranslate }}.
                </button>
            </p>
        </ng-template>
        <app-alternative-state-page
            *ngIf="!storedShuttles.length"
            header="adcs"
            titleText="adc_empty_no_shuttle_title"
            [messageTemplate]="adcMessageTemplate"
            [mockTableHeaders]="['adc_shuttle_name', 'adc_running_status', 'adc_shuttle_associated', 'namespace']"
            imagePath="assets/centralisedManagement/empty-state/noShuttles.svg"
            buttonOneText="adc_shuttle_management"
            buttonOneLink="/cm/adcs/shuttle"
            [buttonOneDisabled]="!permissionsLookup['adcs-list-add']">
        </app-alternative-state-page>
    </ng-container>
    <ng-template #table>
        <div class="component-main">
            <div class="content-space-between">
                <div class="content-left">
                    <img ngSrc="assets/centralisedManagement/icons/adc.svg" alt="adc icon" height="25" width="25">
                    <h1 id="component-title">ADCs</h1>
                </div>
                <div class="content-right">
                    <button
                        id="adcs-list-add"
                        [disabled]="!permissionsLookup['adcs-list-add'] || !maxLimitReached"
                        class="primary"
                        routerLink="/cm/adcs/list/select-vendor">
                        {{ 'adc_add' |myLbTranslate }}
                    </button>
                </div>
            </div>

            <app-adc-widget [sitInlineWithSearch]="true" (limitReached)="maxLimit($event)"></app-adc-widget>

            <app-table
                name="adcs-list"
                [data]="tableRows"
                [allowMultiSelect]="false"
                [showPaginator]="false"
                [stickyHeader]="true"
                [searchTerm]="searchTerm"
                (searchValues)="setSearchValues($event)"
                (selectedRowsOut)="openDrawer($event[0])">
                <app-column label="label" prop="label" [flexGrow]="1.5" [customCellTemplate]="adcNameTemplate">
                    <ng-template let-row #adcNameTemplate>
                        <div class="adc_label">
                            <img
                                *ngIf="isInCluster(row) && row?.haStatus === 'passive'"
                                class="paired-icon"
                                src="assets/centralisedManagement/icons/pair.svg"
                                alt="paired icon"
                                height="10"
                                width="25"/>
                            <img
                                class="company-logo"
                                [class.in-cluster]="isInCluster(row) && row.haStatus === 'passive'"
                                src="assets/centralisedManagement/icons/{{row.vendorIcon}}-colour.svg"
                                height="25"
                                width="25"
                                alt="{{row.vendorName}} Logo"
                                title="{{row.vendorName}}"/>
                            <span
                                class="adc-title routerLink"
                                [class.in-cluster]="isInCluster(row) && row?.haStatus === 'passive'"
                                title="{{row.label}}">
                                {{ row.label }}
                            </span>
                        </div>
                    </ng-template>
                </app-column>
                <app-column
                    label="adc_running_status"
                    prop="status"
                    [flexGrow]="0.75"
                    [customCellTemplate]="runningStatusTemplate">
                    <ng-template let-row #runningStatusTemplate>
                        <img
                            [src]="getRunningStatusSrc(row)"
                            [class]="getAdcStatusClassName(row)"
                            alt="Running Status"
                            [title]="row.status"/>
                    </ng-template>
                </app-column>
                <app-column
                    label="adc_ha_status"
                    prop="haStatus"
                    [flexGrow]="0.5"
                    [customCellTemplate]="haStatusTemplate">
                    <ng-template let-row #haStatusTemplate>
                        <img
                            [class]="row.haStatusText"
                            ngSrc="assets/centralisedManagement/icons/ha-status.svg"
                            alt="HA Status"
                            title="{{ row.haStatusText === 'not-applicable' ? 'n/a' : ( row.haStatusText | titlecase )}}"
                            height="8"
                            width="8"/>
                    </ng-template>
                </app-column>
                <app-column
                    label="address"
                    prop="ipAddress"
                    [flexGrow]="1.5"
                    [customCellTemplate]="ipAddressTemplate">
                    <ng-template let-row #ipAddressTemplate>
                        <span [title]="row.ipAddress">{{ row.ipAddress | truncate:[25, '...'] }}</span>
                    </ng-template>
                </app-column>
                <app-column [flexGrow]="0.5" label="port" prop="port"></app-column>
                <app-column [flexGrow]="0.75" label="version" prop="version" [customCellTemplate]="versionTemplate">
                    <ng-template let-row #versionTemplate>
                        <div class="version-wrapper">
                            <span>{{ row.version }}</span>
                            <ng-container *ngIf="showUpdateIndicator(row.updateStatus)">
                                <app-tooltip
                                    trigger='<img class="block" src="./assets/centralisedManagement/icons/download.svg" alt="Update available">'
                                    class="update-available">
                                    {{ 'adc_new_version' | myLbTranslate }} {{ row.updateVersion }} {{ 'available' |myLbTranslate }}
                                </app-tooltip>
                            </ng-container>
                        </div>
                    </ng-template>
                </app-column>
                <app-column
                    [flexGrow]="0.5"
                    label="namespace"
                    prop="namespace"
                    [columnHidden]="selectedNamespaceKey !== 0">
                </app-column>
                <!--                <app-column-->
                <!--                    [columnHidden]="!matchedKeys.includes('tags')"-->
                <!--                    [flexGrow]="2"-->
                <!--                    prop="tags"-->
                <!--                    label="tags"-->
                <!--                    [customCellTemplate]="tagsTemplate">-->
                <!--                    <ng-template let-row #tagsTemplate>-->
                <!--                        <app-adc-tags [editMode]="false" [tags]="getMatchTags(row)"></app-adc-tags>-->
                <!--                    </ng-template>-->
                <!--                </app-column>-->
                <app-column
                    [flexGrow]="1"
                    label=""
                    prop="statusRunning"
                    [disableSort]=true
                    [customCellTemplate]="adcStatusTemplate">
                    <ng-template let-row #adcStatusTemplate>
                        <div class="adc-status-button-wrapper">
                            <ng-container *ngIf="row.status === 'updating'">
                                <img
                                    class="icon-updating"
                                    ngSrc="./assets/centralisedManagement/icons/updating.svg"
                                    alt="updating"
                                    height="16"
                                    width="16">
                            </ng-container>
                            <ng-container *ngIf="row.status === 'backup'">
                                <img
                                    ngSrc="./assets/centralisedManagement/icons/backing-up.svg"
                                    alt="backing up"
                                    height="16"
                                    width="21">
                            </ng-container>
                            <ng-container *ngIf="row.locked">
                                <img
                                    ngSrc="../../../../../../assets/shared/icons/locked.svg"
                                    alt="locked"
                                    height="20"
                                    width="19">
                            </ng-container>
                            <ng-container *ngIf="row.vulnerable">
                                <img
                                    ngSrc="./assets/centralisedManagement/icons/security.svg"
                                    class="vulnerable {{row.vulnerableSeverity.toLowerCase()}}"
                                    title="{{'security' | myLbTranslate }} {{'security_insights' | myLbTranslate}}"
                                    alt="security"
                                    [routerLink]="'/cm/security/insights/details/' + row.id"
                                    height="21"
                                    width="20">
                            </ng-container>
                        </div>
                    </ng-template>
                </app-column>
                <app-column
                    prop="actions"
                    label=""
                    cellAlignment="right"
                    [flexGrow]="1"
                    [customCellTemplate]="adcDetailsTemplate">
                    <ng-template let-row #adcDetailsTemplate>
                        <ng-container
                            *ngIf="adcIsReady(row.adoptionStatus) || row.vendor === 0 else other"
                            [ngTemplateOutlet]="actionDots"
                            [ngTemplateOutletContext]="{row, isDrawer: false, showCaret: true}">
                        </ng-container>
                        <ng-template #other>
                            <div class="status-wrapper">
                                <ng-container *ngIf="row.shuttle?.legacy else notLegacy">
                                    <img
                                        ngSrc="assets/shared/icons/internal_link.svg"
                                        class="routerLink"
                                        height="16"
                                        width="16"
                                        alt="External link icon"
                                        (click)="notifyUserOfPendingShuttle()"/>
                                    <div class="status-message routerLink" (click)="notifyUserOfPendingShuttle()">
                                        {{ 'adc_pending_adoption' | myLbTranslate }}
                                    </div>
                                </ng-container>
                                <ng-template #notLegacy>
                                    <img
                                        *ngIf="getButtonText(row) === 'timed_out'"
                                        ngSrc="assets/centralisedManagement/icons/exclamation-square.svg"
                                        height="14"
                                        width="14"
                                        alt="exclamation icon"/>
                                    <img
                                        *ngIf="getButtonText(row) === 'adopting'"
                                        class="loader"
                                        ngSrc="assets/shared/icons/loading-spinner-primary.svg"
                                        alt="loading spinner"
                                        height="20"
                                        width="20"/>
                                    <span class="status-message">{{ getButtonText(row) | myLbTranslate }}</span>
                                </ng-template>
                                <img
                                    id="remove-adc"
                                    class="actions-toggle"
                                    ngSrc="./assets/shared/icons/close-cross.svg"
                                    height="12"
                                    width="12"
                                    alt="{{ 'adc_remove' | myLbTranslate }}"
                                    (click)="confirmRemoveADC(row)"/>
                            </div>
                        </ng-template>
                    </ng-template>
                </app-column>
            </app-table>
        </div>
    </ng-template>
</ng-template>
<!-- Drawer content -->
<ng-template #drawerContent>
    <div *ngIf="templateContent" id="drawer-template">
        <div class="content-space-between">
            <div class="button-wrapper">
                <ng-container
                    *ngIf="adcIsReady(templateContent.adoptionStatus) || templateContent.vendor === 0 else adopt">
                    <app-loading-button
                        *ngIf="templateContent.vendor !== 0"
                        buttonId="adcs-list-warp"
                        buttonText="adc_warp_to_adc"
                        buttonClass="primary"
                        buttonIconLocation="assets/centralisedManagement/icons/warp.svg"
                        [isDisabled]="!isWarpButtonEnabled(templateContent) || !permissionsLookup['adcs-list-warp']"
                        [callBack]="navigateBasedOnRowStatus.bind(this, templateContent, isWarpButtonEnabled(templateContent))">
                    </app-loading-button>
                    <button
                        id="adcs-list-direct"
                        *ngIf="templateContent.vendor === 0"
                        class="primary"
                        [disabled]="!permissionsLookup['adcs-list-direct']"
                        (click)="navigateToDirectAdc(templateContent)">
                        {{ 'adc_direct_access_short' | myLbTranslate }}
                    </button>
                </ng-container>
                <ng-template #adopt>
                    <ng-container *ngIf="templateContent.shuttle?.legacy else notLegacy">
                        <img
                            ngSrc="assets/shared/icons/internal_link.svg"
                            class="routerLink"
                            height="16"
                            width="16"
                            alt="internal link"
                            (click)="notifyUserOfPendingShuttle()"/>
                        <div class="status-message routerLink" (click)="notifyUserOfPendingShuttle()">
                            {{ 'adc_pending_adoption' | myLbTranslate }}
                        </div>
                    </ng-container>
                    <ng-template #notLegacy>
                        <img
                            *ngIf="getButtonText(templateContent) === 'timed_out'"
                            ngSrc="assets/centralisedManagement/icons/exclamation-square.svg"
                            height="14"
                            width="14"
                            alt="exclamation mark"/>
                        <img
                            *ngIf="getButtonText(templateContent) === 'adopting'"
                            class="loader"
                            ngSrc="assets/shared/icons/loading-spinner-primary.svg"
                            alt="loading spinner"
                            height="20"
                            width="20"/>
                        <span class="status-message">{{ getButtonText(templateContent) | myLbTranslate }}</span>
                    </ng-template>
                </ng-template>
            </div>
            <div class="adc-modal-content">
                <div class="product-holder">
                    <app-cm-product-box size="small" [complete]="getAdcStatusClassName(templateContent) === 'online'">
                        <div
                            class="product-box-content"
                            [class.complete]="getAdcStatusClassName(templateContent) === 'online'">
                            <img
                                src="/assets/centralisedManagement/icons/{{templateContent.vendorIcon}}-colour.svg"
                                height="24"
                                width="24"
                                alt="{{templateContent.vendorName}} Icon">
                        </div>
                    </app-cm-product-box>
                </div>
            </div>
        </div>
        <app-tab-container tabsAlignment="center">
            <app-tab tabTitle="overview" id="overview">
                <table class="flex-table">
                    <tr>
                        <th>{{ 'adc_locked' | myLbTranslate }}</th>
                        <td class="right">
                            <img
                                class="drawer-image"
                                ngSrc="assets/shared/icons/{{getLockedStateIcon(templateContent.locked)}}.svg"
                                height="20"
                                width="20"
                                alt="Locked"/>
                        </td>
                    </tr>
                    <tr>
                        <th>{{ 'vendor' | myLbTranslate }}</th>
                        <td class="right">
                            <img
                                class="drawer-image"
                                src="assets/centralisedManagement/icons/{{templateContent.vendorIcon}}-colour.svg"
                                height="20"
                                width="20"
                                alt="{{templateContent.vendorName}} Logo"/>
                        </td>
                    </tr>
                    <tr>
                        <th>{{ 'product' | myLbTranslate }}</th>
                        <td class="right">{{ templateContent.productName || '-' }}</td>
                    </tr>
                    <tr>
                        <th>{{ 'adc_running_status' | myLbTranslate }}</th>
                        <td class="right">
                            <img
                                [src]="getRunningStatusSrc(templateContent)"
                                [class]="getAdcStatusClassName(templateContent)"
                                [title]="templateContent.status"
                                alt="Running Status"/>
                        </td>
                    </tr>
                    <tr>
                        <th>{{ 'adc_ha_status' | myLbTranslate }}</th>
                        <td class="right">
                            <img
                                [class]="getHaStatusClassName(templateContent)"
                                src="assets/centralisedManagement/icons/ha-status.svg" alt="HA Status"
                                title="{{ (templateContent.haStatus ? getHaStatusClassName(templateContent) : 'Unavailable') | titlecase }}"
                                height="8"
                                width="20"/>
                        </td>
                    </tr>
                    <tr>
                        <th>{{ 'address' | myLbTranslate }}</th>
                        <td class="right" [title]="templateContent.ipAddress">{{ templateContent.ipAddress }}</td>
                    </tr>
                    <tr>
                        <th>{{ 'port' | myLbTranslate }}</th>
                        <td class="right">{{ templateContent.port }}</td>
                    </tr>
                    <tr>
                        <th>{{ 'version' | myLbTranslate }}</th>
                        <td class="right">{{ templateContent.version }}</td>
                    </tr>
                    <tr>
                        <th>{{ 'serial' | myLbTranslate }}</th>
                        <td [title]="templateContent.serial" class="right">{{ templateContent.serial }}</td>
                    </tr>
                </table>
                <span class="notes"><strong>{{ 'notes' | myLbTranslate }}</strong></span>
                <ng-container *ngIf="templateContent.notes else noNotes">
                    <p>{{ templateContent.notes }}</p>
                </ng-container>
                <ng-template #noNotes>
                    <p class="no-data">{{ 'adc_data_no_notes' | myLbTranslate }}</p>
                </ng-template>
                <span class="tags"><strong>{{ 'tags' | myLbTranslate }}</strong></span>
                <ng-container *ngIf="templateContent.tags else noTags">
                    <app-adc-tags [editMode]="false" [tags]="templateContent.tags"></app-adc-tags>
                </ng-container>
                <ng-template #noTags>
                    <p class="no-data">{{ 'adc_data_no_tags' | myLbTranslate }}</p>
                </ng-template>
            </app-tab>
            <app-tab tabTitle="shuttle" id="shuttle">
                <ng-container *ngIf="templateContent.shuttle else emptyShuttle">
                    <table class="flex-table">
                        <tr>
                            <th>{{ 'adc_locked' | myLbTranslate }}</th>
                            <td class="right">
                                <img
                                    class="drawer-image"
                                    ngSrc="assets/shared/icons/{{getLockedStateIcon(templateContent.shuttle.locked)}}.svg"
                                    height="20"
                                    width="20"
                                    alt="Locked"/>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <a
                                    class="default"
                                    routerLink="/cm/adcs/shuttle"
                                    [queryParams]="{sFilter: templateContent.shuttle.id}">
                                    {{ templateContent.shuttle.label }}
                                </a>
                            </th>
                            <td class="right">
                                <img
                                    class="drawer-image"
                                    src="assets/centralisedManagement/icons/{{templateContent.shuttle.vendorIcon}}-colour.svg"
                                    height="20"
                                    width="20"
                                    alt="{{templateContent.shuttle.vendorName}} Logo"/>
                            </td>
                        </tr>
                        <tr>
                            <th>{{ 'adc_running_status' | myLbTranslate }}</th>
                            <td class="right">
                                <img
                                    [src]="adcUtilsService.getShuttleRunningStatusSrc(templateContent.shuttle)"
                                    [class]="adcUtilsService.getShuttleStatusClassName(templateContent.shuttle)"
                                    alt="Running Status"/>
                            </td>
                        </tr>
                        <tr>
                            <th>{{ 'address' | myLbTranslate }}</th>
                            <td class="right">{{ templateContent.shuttle.ipAddress }}</td>
                        </tr>
                        <tr>
                            <th>{{ 'port' | myLbTranslate }}</th>
                            <td class="right">{{ templateContent.shuttle.port }}</td>
                        </tr>
                        <tr>
                            <th>{{ 'namespace' | myLbTranslate }}</th>
                            <td class="right">{{ templateContent.shuttle.namespace }}</td>
                        </tr>
                        <tr>
                            <th>{{ 'version' | myLbTranslate }}</th>
                            <td class="right">{{ templateContent.shuttle.version }}</td>
                        </tr>
                    </table>
                    <span class="notes"><strong>{{ 'notes' | myLbTranslate }}</strong></span>
                    <ng-container *ngIf="templateContent.shuttle.notes else noNotes">
                        <p>{{ templateContent.shuttle.notes }}</p>
                    </ng-container>
                    <ng-template #noNotes>
                        <p class="no-data">{{ 'adc_data_no_notes' | myLbTranslate }}</p>
                    </ng-template>
                    <span class="tags"><strong>{{ 'tags' | myLbTranslate }}</strong></span>
                    <ng-container *ngIf="templateContent.shuttle.tags else noTags">
                        <app-adc-tags [editMode]="false" [tags]="templateContent.shuttle.tags"></app-adc-tags>
                    </ng-container>
                    <ng-template #noTags>
                        <p class="no-data">{{ 'adc_data_no_tags' | myLbTranslate }}</p>
                    </ng-template>
                </ng-container>
                <ng-template #emptyShuttle>
                    <div class="empty-tab">
                        <img
                            ngSrc="assets/centralisedManagement/icons/astronaut.svg"
                            height="138"
                            width="159"
                            alt="astronaut">
                        <span>All good, nothing to review here.</span>
                    </div>
                </ng-template>
            </app-tab>
            <app-tab
                tabTitle="security_insights"
                id="insights"
                iconLocation="centralisedManagement/icons/security.svg"
                iconAlignment="right"
                [iconStyle]="getSecurityStyle(templateContent.vulnerableSeverity)">
                <ng-container *ngIf="templateContent.vulnerable else emptyInsights">
                    <table class="flex-table">
                        <tr>
                            <th>
                                <div class="content-left no-padding">
                                    <img
                                        class="drawer-security-image vulnerable {{templateContent.vulnerableSeverity}}"
                                        ngSrc="assets/centralisedManagement/icons/security.svg"
                                        alt="Locked"
                                        height="21"
                                        width="20"/>
                                    <div>
                                        {{ 'security_insights' | myLbTranslate }} -
                                        <a
                                            class="default"
                                            [routerLink]="'/cm/security/insights/details/' + templateContent.id">
                                            {{ 'view' | myLbTranslate }}
                                        </a>
                                    </div>
                                </div>
                            </th>
                            <td class="right">
                                <div
                                    class="operational-risk-{{templateContent.vulnerableSeverity}}"
                                    title="{{templateContent.vulnerableSeverity}}">
                                    {{ templateContent.vulnerableSeverity | titlecase }}
                                </div>
                            </td>
                        </tr>
                    </table>
                    <table class="flex-table security-table">
                        <tr>
                            <th>{{ 'security_vulnerability_id' | myLbTranslate }}</th>
                            <th class="right">{{ 'security_severity_score' | myLbTranslate }}</th>
                        </tr>
                        <ng-container *ngFor="let security of storedSecurityDetails[templateContent.id]">
                            <tr>
                                <th>{{ security.CVEID }}</th>
                                <td class="right">
                                    <div class="security-risk-{{getSeverityScoreClass(security.baseScore)}}">
                                        {{ security.baseScore }}
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </table>
                </ng-container>
                <ng-template #emptyInsights>
                    <div class="empty-tab">
                        <img
                            ngSrc="assets/centralisedManagement/icons/astronaut.svg"
                            height="138"
                            width="159"
                            alt="astronaut">
                        <span>All good, nothing to review here.</span>
                    </div>
                </ng-template>
            </app-tab>
        </app-tab-container>
    </div>
</ng-template>

<ng-template
    #actionDots
    let-row="row"
    let-isDrawer="isDrawer"
    let-displayAsButton="displayAsButton"
    let-showCaret="showCaret">
    <app-action-dots [displayAsButton]=displayAsButton [showCaret]="showCaret">
        <ng-container *ngIf="row.vendor !== 0">
            <button
                *ngIf="isDrawer"
                id="adcs-list-direct-access"
                [disabled]="permissionsLookup['adcs-list-direct']"
                (click)="navigateToDirectAdc(row)">
                {{ 'adc_direct_access' | myLbTranslate }}
            </button>
            <button
                id="adcs-list-system-update"
                [disabled]="disabledUpdateButtons(row)"
                (click)="systemUpdateAdc(row)">
                {{ 'adc_update_now' | myLbTranslate }}
            </button>
            <button
                id="adcs-list-backup"
                [disabled]="disabledBackupButtons(row)"
                (click)="backupAdc(row)">
                {{ 'adc_backup_now' | myLbTranslate }}
                <ng-container *ngIf="backupLimitReached(row)">
                    <img
                        class="menu-item-subscription"
                        ngSrc="./assets/centralisedManagement/icons/subscription_required.svg"
                        alt="subscription icon"
                        title="Backup limit reached"
                        height="21"
                        width="21">
                </ng-container>
            </button>
            <button
                id="adcs-list-view-backups"
                [disabled]="disabledBackupButtons(row, true)"
                [routerLink]="'/cm/storage/backups/' + row.id">
                {{ 'adc_backups_view_list' | myLbTranslate }}
            </button>
            <button
                id="adcs-list-add-schedule"
                [disabled]="disabledScheduleButtons(row)"
                [routerLink]="'/cm/scheduler/schedules/new'"
                [queryParams]="{id: row.id, type: 'adc'}">
                {{ 'adc_add_schedule' | myLbTranslate }}
                <img
                    *ngIf="tier === 'free'"
                    class="menu-item-subscription"
                    ngSrc="./assets/centralisedManagement/icons/subscription_required.svg"
                    alt="subscription icon"
                    title="Subscription required"
                    height="21"
                    width="21">
            </button>
            <button
                id="adcs-list-view-schedule"
                [disabled]="disabledScheduleViewButtons(row)"
                [routerLink]="'/cm/scheduler/schedules'"
                [queryParams]="{sFilter: row.label}">
                {{ 'adc_view_schedules' | myLbTranslate }}
                <img
                    *ngIf="tier === 'free'"
                    class="menu-item-subscription"
                    ngSrc="./assets/centralisedManagement/icons/subscription_required.svg"
                    alt="subscription icon"
                    title="Subscription required"
                    height="21"
                    width="21">
            </button>
            <button
                id="adcs-list-view-insights"
                [hidden]="!row.vulnerable"
                [disabled]="!permissionsLookup['adcs-list-view-insights']"
                [routerLink]="'/cm/security/insights/details/' + row.id">
                {{ 'adc_view_security_risks' | myLbTranslate }}
            </button>
        </ng-container>
        <button
            id="adcs-list-edit-adc"
            [disabled]="!permissionsLookup['adcs-list-edit-adc']"
            [routerLink]="'/cm/adcs/list/edit/' + row.id + '/' + row.namespaceId">
            {{ 'adc_edit' | myLbTranslate }}
        </button>
        <button
            id="adcs-list-delete-adc"
            [disabled]="!permissionsLookup['adcs-list-delete-adc'] || row.locked"
            (click)="confirmRemoveADC(row)">
            {{ 'adc_remove' | myLbTranslate }}
        </button>
    </app-action-dots>
</ng-template>
