<div class="component-main">
    <div class="content-space-between">
        <h1>{{ (editId ? 'namespace_edit' : 'namespace_add') | myLbTranslate }}</h1>
    </div>
    <form [formGroup]="namespaceForm">
        <div class="form-group">
            <label for="name">{{ 'name' | myLbTranslate }}*</label>
            <div class="form-content">
                <input formControlName="name" id="name" name="label" title="name" type="text">
                <app-validation-messages [control]="namespaceForm.controls['name']"></app-validation-messages>
                <app-help class="help-button" tag="namespace-list_namespace"></app-help>
            </div>
        </div>
    </form>
</div>
<div class="component-footer">
    <button
        id="static-ip-cancel"
        class="outline"
        routerLink="/cm/organisation/namespaces">
        {{ 'cancel' | myLbTranslate }}
    </button>
    <app-loading-button
        buttonId="static-ip-submit"
        buttonClass="primary"
        [buttonText]="editId ? 'update': 'add'"
        [isDisabled]="namespaceForm.invalid || namespaceForm.pristine"
        [callBack]="submitNamespace.bind(this)">
    </app-loading-button>
</div>
