<div class="component-main">
    <div class="content-space-between">
        <h1>{{'host_dns' | myLbTranslate }}</h1>
    </div>
    <form [formGroup]="hostnameDnsForm">
        <!-- Hostname -->
        <div class="form-group">
            <label for="hostname">{{'hostname' | myLbTranslate }}</label>
            <div class="form-content">
                <!-- Hostname length may be between 1 and 63 characters
                (Ref: http://tools.ietf.org/html/rfc1034#section-3.1 )-->
                <input
                    title="Hostname"
                    type="text"
                    size="30"
                    id="hostname"
                    name="hostname"
                    formControlName="hostname"
                    appInputValidator/>
                <app-help tag="hostname-dns_hostname"></app-help>
                <app-validation-messages [control]="hostnameDnsForm.controls['hostname']"></app-validation-messages>
            </div>
        </div>
        <div class="form-group">
            <label for="domain">{{'domain' | myLbTranslate }}</label>
            <div class="form-content">
                <!-- Tricky to set limits on the domain name.
                RFC1034 says that the total length must be a maximum of 255 octets.
                Since the minimum length of the hostname is 1, and there must be a  period between elements,
                the maximum length of the domain part is 253 octets.
                The minimum length is set to zero, so that the field may be omitted.
                (Ref: http://tools.ietf.org/html/rfc1034#section-3.1 ) -->
                <input
                    title="Domain"
                    type="text"
                    size="30"
                    id="domain"
                    name="domain"
                    formControlName="domain"
                    appInputValidator/>
                <app-validation-messages [control]="hostnameDnsForm.controls['domain']"></app-validation-messages>
                <app-help tag="hostname-dns_domain"></app-help>
            </div>
        </div>
        <br>
        <h1>{{'host_dns_domain_name_servers' | myLbTranslate }}</h1>
        <div class="form-group">
            <label for="dns0">{{'primary' | myLbTranslate }}</label>
            <div class="form-content">
                <input title="DNS server 1" type="text" size="30" id="dns0" name="dns0" formControlName="dns0"/>
                <app-validation-messages [control]="hostnameDnsForm.controls['dns0']"></app-validation-messages>
                <app-help tag="hostname-dns_domain-name-servers"></app-help>
            </div>
        </div>
        <div class="form-group">
            <!-- DNS - Secondary -->
            <label for="dns1">{{'secondary' | myLbTranslate }}</label>
            <div class="form-content">
                <input title="DNS server 2" type="text" size="30" id="dns1" name="dns1" formControlName="dns1"/>
                <app-validation-messages [control]="hostnameDnsForm.controls['dns1']"></app-validation-messages>
            </div>
        </div>
        <div class="form-group">
            <!-- DNS - Tertiary -->
            <label for="dns2">{{'tertiary' | myLbTranslate }}</label>
            <div class="form-content">
                <input title="DNS server 3" type="text" size="30" id="dns2" name="dns2" formControlName="dns2"/>
                <app-validation-messages [control]="hostnameDnsForm.controls['dns2']"></app-validation-messages>
            </div>
        </div>
        <app-loading-button
            id="hostname-dns-update"
            buttonText="submit"
            buttonClass="primary"
            [isDisabled]="hostnameDnsForm.invalid || hostnameDnsForm.pristine"
            [callBack]="submitHostnameDns.bind(this)">
        </app-loading-button>
    </form>
</div>
