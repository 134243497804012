<div class="component-main">
    <div class="content-space-between">
        <div class="content-left">
            <img
                ngSrc="/assets/centralisedManagement/icons/{{selectedSupplier?.icon}}-colour.svg"
                height="24"
                width="24"
                alt="{{selectedSupplier?.title}} Icon">
            <h1 id="component-title">{{ selectedSupplier?.title }}</h1>
        </div>
    </div>
    <div class="content page-wrapper">
        <div class="content-half-width">
            <form [formGroup]="formGroup" class="process-container">
                <ng-container *ngIf="selectedSupplier?.name !== 'genericAppliance'">
                    <div
                        class="step"
                        [class.active]="activeStep === 1"
                        [class.complete]="activeStep > 1"
                        formGroupName="step1">
                        <h3>{{ 'adc_add_shuttle_details' | myLbTranslate }}</h3>
                        <div class="step-content">
                            <ng-container *ngIf="activeStep === 1">
                                <div class="form-group">
                                    <!--suppress XmlInvalidId -->
                                    <label for="shuttle">{{ 'shuttle' | myLbTranslate }}*</label>
                                    <div class="form-content">
                                        <app-dropdown
                                            dropDownId="shuttle"
                                            [formGroup]="getFormGroup('step1')"
                                            formControlName="shuttleId"
                                            [content]="dropdown.shuttles"
                                            [search]="true">
                                        </app-dropdown>
                                        <app-help class="help-button" tag="adcs-add_shuttle"></app-help>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="activeStep > 1">
                                <div class="set-content">
                                <span>
                                    <strong>{{ 'label' | myLbTranslate }}:</strong>
                                    {{ getShuttleDetails() }}
                                </span>
                                    <span>
                                    <strong>{{ 'network' | myLbTranslate }}:</strong>
                                        {{ getShuttleDetails('network') }}
                                </span>
                                    <span>
                                    <button class="button-as-link quinary right-arrow" (click)="setStep(1)">
                                        {{ 'edit' | myLbTranslate }}
                                    </button>
                                </span>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div
                        class="step"
                        [class.active]="activeStep === 2"
                        [class.complete]="activeStep > 2"
                        formGroupName="step2">
                        <h3>{{ 'adc_add_api_details' | myLbTranslate }}</h3>
                        <div class="step-content">
                            <ng-container *ngIf="activeStep === 2">
                                <div class="form-group">
                                    <label for="username">
                                        {{ 'username' | myLbTranslate }}/{{ 'email' | myLbTranslate }}*
                                    </label>
                                    <div class="form-content">
                                        <input
                                            formControlName="username"
                                            id="username"
                                            name="username"
                                            type="text"
                                            autocomplete="one-time-code">
                                        <app-validation-messages [control]="formGroup.get('step2.username')">
                                        </app-validation-messages>
                                        <app-help tag="adcs-add_username"></app-help>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="password">{{ 'password' | myLbTranslate }}*</label>
                                    <div class="form-content">
                                        <app-lb-reveal-password
                                            id="password"
                                            title="{{'password' | myLbTranslate }}"
                                            formControlName="password"
                                            [formGroup]="getFormGroup('step2')">
                                        </app-lb-reveal-password>
                                        <app-validation-messages [control]="formGroup.get('step2.password')">
                                        </app-validation-messages>
                                        <app-help tag="adcs-add_password"></app-help>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="apiIp">{{ 'address' | myLbTranslate }}*</label>
                                    <div class="form-content">
                                        <input formControlName="ipAddress" id="apiIp" name="ip" type="text">
                                        <app-validation-messages [control]="formGroup.get('step2.ipAddress')">
                                        </app-validation-messages>
                                        <app-help class="help-button" tag="adcs-add_address"></app-help>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="apiPort">{{ 'port' | myLbTranslate }}*</label>
                                    <div class="form-content">
                                        <input formControlName="port" id="apiPort" name="port" type="number">
                                        <app-validation-messages [control]="formGroup.get('step2.port')">
                                        </app-validation-messages>
                                        <app-help class="help-button" tag="adcs-add_port"></app-help>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="activeStep > 2">
                                <div class="set-content">
                                <span>
                                    <strong>{{ 'username' | myLbTranslate }}:</strong>
                                    {{ formGroup.get('step2.username').value }}
                                </span>
                                    <span>
                                    <strong>{{ 'password' | myLbTranslate }}:</strong> *******
                                </span>
                                    <span>
                                    <strong>{{ 'address' | myLbTranslate }}:</strong>
                                        {{ formGroup.get('step2.ipAddress').value }}
                                </span>
                                    <span>
                                    <strong>{{ 'port' | myLbTranslate }}:</strong>
                                        {{ formGroup.get('step2.port').value }}
                                </span>
                                    <span>
                                    <button class="button-as-link quinary right-arrow" (click)="setStep(2)">
                                        {{ 'edit' | myLbTranslate }}
                                    </button>
                                </span>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
                <div
                    class="step"
                    [class.active]="activeStep === 3"
                    [class.complete]="activeStep > 3"
                    formGroupName="step3">
                    <h3>{{ 'adc_add_address' | myLbTranslate }}</h3>
                    <div class="step-content">
                        <ng-container *ngIf="activeStep === 3">
                            <div class="form-group">
                                <label for="label">{{ 'label' | myLbTranslate }}*</label>
                                <div class="form-content">
                                    <input formControlName="label" id="label" name="label" type="text">
                                    <app-validation-messages [control]="formGroup.get('step3.label')">
                                    </app-validation-messages>
                                    <app-help class="help-button" tag="adcs-add_label"></app-help>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="ip">{{ 'address' | myLbTranslate }}*</label>
                                <div class="form-content">
                                    <input formControlName="ipAddress" id="ip" name="ip" type="text">
                                    <app-validation-messages [control]="formGroup.get('step3.ipAddress')">
                                    </app-validation-messages>
                                    <app-help class="help-button" tag="adcs-add_address"></app-help>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="port">{{ 'port' | myLbTranslate }}*</label>
                                <div class="form-content">
                                    <input formControlName="port" id="port" name="port" type="number">
                                    <app-validation-messages [control]="formGroup.get('step3.port')">
                                    </app-validation-messages>
                                    <app-help class="help-button" tag="adcs-add_port"></app-help>
                                </div>
                            </div>
                            <ng-container *ngIf="showNamespace()">
                                <div class="form-group">
                                    <!--suppress XmlInvalidId -->
                                    <label for="adc-add-namespace">{{ 'namespace' | myLbTranslate }}</label>
                                    <div class="form-content">
                                        <app-dropdown
                                            dropDownId="adc-add-namespace"
                                            [formGroup]="getFormGroup('step3')"
                                            formControlName="namespaceID"
                                            [content]="dropdown.namespaces"
                                            [search]="false"
                                            (keyOut)="setNamespaceId($event)">
                                        </app-dropdown>
                                        <app-help class="help-button" tag="adcs-add_namespace"></app-help>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="activeStep > 3">
                            <div class="set-content">
                                <span>
                                    <strong>{{ 'label' | myLbTranslate }}:</strong>
                                    {{ formGroup.get('step3.label').value }}
                                </span>
                                <span>
                                    <strong>{{ 'address' | myLbTranslate }}:</strong>
                                    {{ formGroup.get('step3.ipAddress').value }}
                                </span>
                                <span>
                                    <strong>{{ 'port' | myLbTranslate }}:</strong>
                                    {{ formGroup.get('step3.port').value }}
                                </span>
                                <ng-container *ngIf="formGroup.get('step3.namespaceID').value !== 0">
                                <span>
                                    <strong>{{ 'namespace' | myLbTranslate }}:</strong>
                                    {{ getNamespaceLabel() }}
                                </span>
                                </ng-container>
                                <span>
                                    <button class="button-as-link quinary right-arrow" (click)="setStep(3)">
                                        {{ 'edit' | myLbTranslate }}
                                    </button>
                                </span>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div
                    class="step"
                    [class.active]="activeStep === 4"
                    [class.complete]="activeStep > 4"
                    formGroupName="step4">
                    <h3>{{ 'adc_add_optional_info' | myLbTranslate }}</h3>
                    <div class="step-content">
                        <ng-container *ngIf="activeStep === 4">
                            <div class="form-group">
                                <label>{{ 'notes' | myLbTranslate }}</label>
                                <div class="form-content">
                                    <textarea tabindex="0" id="edit-adc-notes" formControlName="notes"></textarea>
                                    <app-validation-messages [control]="formGroup.get('step4.notes')">
                                    </app-validation-messages>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>{{ 'tags' | myLbTranslate }}</label>
                                <div class="form-content">
                                    <app-adc-tags
                                        [tags]="formGroup.get('step4.tags').value"
                                        (selectedTags)="setTagsFormValue($event)">
                                    </app-adc-tags>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="activeStep > 4">
                            <div class="set-content">
                                <span>
                                    <strong>{{ 'notes' | myLbTranslate }}:</strong>
                                    {{ formGroup.get('step4.notes').value }}
                                </span>
                                <span class="set-tags">
                                    <strong>{{ 'tags' | myLbTranslate }}:</strong>
                                    <app-adc-tags [editMode]="false" [tags]="formGroup.get('step4.tags').value">
                                    </app-adc-tags>
                                </span>
                                <span>
                                    <button class="button-as-link quinary right-arrow" (click)="setStep(4)">
                                        {{ 'edit' | myLbTranslate }}
                                    </button>
                                </span>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="step" [class.active]="activeStep === 5" [class.complete]="activeStep > 5">
                    <h3>{{ 'adc_add_ready_to_connect' | myLbTranslate }}</h3>
                    <div class="step-content">
                        <p>{{ 'adc_add_ready_to_connect_message' | myLbTranslate }}</p>
                    </div>
                </div>
            </form>
        </div>
        <div class="product-box content-half-width">
            <div class="message">
                <ng-container *ngIf="activeStep === 5">
                    <img src="/assets/shared/icons/check.svg" height="48" width="48" alt="checkbox"/>
                    <h1>Ready to connect</h1>
                </ng-container>
            </div>
            <app-cm-product-box [complete]="activeStep === 5">
                <div class="product-box-content" [class.complete]="activeStep === 5">
                    <img
                        ngSrc="/assets/centralisedManagement/icons/{{selectedSupplier?.icon}}-colour.svg"
                        height="24"
                        width="24"
                        alt="{{selectedSupplier?.title}} Icon">
                    <span class="product-box-text {{selectedSupplier?.name}}">{{ selectedSupplier?.title }}</span>
                </div>
            </app-cm-product-box>
        </div>
    </div>
</div>

<div class="component-footer">
    <button class="outline" tabindex="0" routerLink="/cm/adcs/list/select-vendor">
        {{ 'cancel' |myLbTranslate }}
    </button>
    <button id="adc-new-back" tabindex="0" class="primary" [disabled]="isBackButtonDisabled()" (click)="backStep()">
        {{ 'back' | myLbTranslate }}
    </button>
    <button
        *ngIf="activeStep < 5"
        id="adc-new-next"
        class="primary"
        [disabled]="isNextButtonDisabled()"
        (click)="nextStep()">
        {{ 'next' | myLbTranslate }}
    </button>
    <app-loading-button
        *ngIf="activeStep === 5"
        buttonId="adc-new-submit"
        buttonClass="primary"
        buttonText="submit"
        [isDisabled]="isNextButtonDisabled()"
        [callBack]="submitAdc.bind(this)">
    </app-loading-button>
</div>
