<ng-container [formGroup]="formGroup">
    <div class="form-group">
        <div class="form-content" [style]="'width:' + editorStyles.width">
            <quill-editor
                [modules]="quillConfiguration"
                [styles]="editorStyles"
                [formControlName]="editorControlName">
            </quill-editor>
        </div>
    </div>
</ng-container>
