<app-widget
    #widget
    class="widget-item col-span-1"
    title="{{ 'alerts' | myLbTranslate }}"
    [loading]="loading"
    [state]="state">
    <div class="content-vertical-middle">
        <h1 [class.default]="notifications.length < 1"
            [class.warning]="notifications.length >= 1 && notifications.length < 5"
            [class.critical]="notifications.length >= 5">{{notifications.length}}</h1>
        <h2 class="notification_type" *ngIf="notifications.length > 1">{{ 'critical' | myLbTranslate }}</h2>
        <ng-container *ngIf="notifications.length > 0">
            <small>{{ 'last_alert' | myLbTranslate }} {{ timeElapsed }}</small>
        </ng-container>
    </div>
</app-widget>
