import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { version } from './version';
import { Observable } from 'rxjs';

// NOTE: This file is used to log errors internally. The submitEvent() function is cleared out in production builds.

@Injectable()
export class Warden {
    constructor(
        private http: HttpClient
    ) {
    }

    public submitEvent(title: string, user: string, url: string, message: string, stack: string): Observable<any> {
        return new Observable((observer) => {
            this.http.post('https://warden.loadbalancer.org/api/v1/events', {
                bucket: 'lb_client' + (version ? '-' + version : ''),
                type: 0,
                data: { title, user, url, message, stack }
            }).subscribe({
                next: (data: any): void => {
                    observer.next(data);
                    observer.complete();
                },
                error: (error: any): void => {
                    observer.error(error);
                    observer.complete();
                }
            });
        });
    }
}

