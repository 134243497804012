<div class="product-setup-wrapper">
    <div
        class="menu-header"
        [class.center]="centerTitle"
        [class.reduce-header-height]="inModal">
        <div class="menu-titles">
            <h2 class="menu-title">{{ addExisting ? 'Connect to Loadbalancer Enterprise' : platform.title }}</h2>
        </div>
    </div>
    <div class="download-process">
        <div class="progress-bar-wrapper">
            <div class="progress-line">
            </div>
            <div class="progress-items">
                <div
                    *ngIf="!addExisting"
                    [class.active]="isStepOne()"
                    [class.complete]="isStepTwo() || isStepThree()"
                    class="progress-item left">
                    <div class="progress-dot"></div>
                    <span class="progress-text">{{ 'download' | myLbTranslate }}</span>
                </div>
                <div
                    [class.active]="isStepTwo()"
                    [class.complete]="isStepThree()"
                    class="progress-item {{addExisting ? 'left' : 'center'}}">
                    <div class="progress-dot"></div>
                    <span class="progress-text">{{ 'setup' | myLbTranslate }}</span>
                </div>
                <div [class.active]="isStepThree()" class="progress-item right">
                    <div class="progress-dot"></div>
                    <span class="progress-text">{{ 'adoption' | myLbTranslate }}</span>
                </div>
            </div>
        </div>
        <div *ngIf="isStepOne()" class="download-step">
            <h3 class="step-title">
                <span class="step">{{ 'step' | myLbTranslate }}</span> 1 -
                {{ 'download' | myLbTranslate }} {{ platform.title }} {{ 'platform' | myLbTranslate }}
            </h3>
            <p>Firstly, download the {{ platform.title }} appliance in order to connect to the Loadbalancer Portal.</p>
            <div *ngIf="!inModal" class="product-box-wrapper">
                <!--                <app-cm-product-box-->
                <!--                    [complete]="progressState === adoptionEnum.CONNECTED">-->
                <!--                    <div class="product-box-content">-->
                <!--                        <span class="product-box-text">{{ platform.title }}</span>-->
                <!--                    </div>-->
                <!--                </app-cm-product-box>-->
            </div>
            <div class="download-options-wrapper">
                <ng-container *ngFor="let service of platform.services; let k = index">
                    <div
                        *ngIf="k > 0"
                        class="download-option"
                        [class.full-width]="inModal"
                        [class.disabled]="progressState === adoptionEnum.DOWNLOADING"
                        (click)="triggerSimpleDownload(service)">
                        <ng-container
                            *ngIf="simpleDownloadProgressState.includes(service.name); else downloadIcon">
                            <input class="lb-checkbox" type="checkbox" checked/>
                        </ng-container>
                        <ng-template #downloadIcon>
                            <img
                                class="download-icon"
                                ngSrc="/assets/centralisedManagement/icons/download-basic.svg"
                                alt="Download Icon"
                                height="14"
                                width="15"/>
                        </ng-template>
                        <span>{{ service.name }}</span>
                    </div>
                </ng-container>
            </div>
        </div>
        <div *ngIf="isStepTwo()" class="download-step">
            <h3 class="step-title">
                <span class="step">{{ 'step' | myLbTranslate }}</span> {{ addExisting ? '1' : '2' }}
                - {{ 'adc_setup_shuttle' | myLbTranslate }}
            </h3>
            <p>{{ 'adc_setup_appliance_message' | myLbTranslate }}</p>
            <img ngSrc="assets/centralisedManagement/icons/add-adc-computer-login.svg"
                 class="large-computer-image"
                 alt="image of login screen"
                 height="248"
                 width="332"/>
        </div>
        <div *ngIf="isStepThree()" class="download-step">
            <h3 class="step-title">
                <span class="step">{{ 'step' | myLbTranslate }}</span> {{ addExisting ? '2' : '3' }}
                - {{ 'adc_setup_connected_title' | myLbTranslate }}
            </h3>
            <p>{{ 'adc_setup_connected_message' | myLbTranslate }}</p>
            <img ngSrc="assets/centralisedManagement/icons/add-adc-computer-adopt.svg"
                 class="large-computer-image"
                 alt="image of login screen"
                 height="248"
                 width="365"/>
        </div>
    </div>
    <div class="component-footer">
        <ng-container *ngIf="isStepOne()">
            <div *ngIf="progressState === adoptionEnum.WAITING" class="waiting">
                <button
                    class="primary"
                    (click)="triggerDownload(platform.services[0])">
                    {{ 'download' | myLbTranslate }} {{ platform.title }} {{ 'platform' | myLbTranslate }}
                </button>
            </div>
            <div [hidden]="progressState !== adoptionEnum.DOWNLOADING">
                <ng-container #progressContainer></ng-container>
            </div>
            <div *ngIf="progressState === adoptionEnum.DOWNLOADED" class="step-complete">
                <button class="primary" (click)="nextStep(adoptionEnum.SETUP)">
                    {{ 'next' | myLbTranslate }}
                </button>
                <img ngSrc="/assets/centralisedManagement/icons/downloaded-private-key.svg"
                     alt="green success checkbox"
                     height="33"
                     width="32"/>
                <span>{{ platform.title }} {{ 'adc_download_success' | myLbTranslate }}</span>
            </div>
        </ng-container>
        <ng-container *ngIf="isStepTwo()">
            <div *ngIf="progressState === adoptionEnum.SETUP" class="setup">
                <button class="primary" routerLink="/cm/adcs/shuttle">
                    {{ 'adc_setup_skip_to_overview' | myLbTranslate }}
                </button>
                <div class="loading-wrapper">
                    <img
                        ngSrc="/assets/shared/icons/loading-spinner-primary.svg"
                        class="loader"
                        alt="loading spinner"
                        height="20"
                        width="20"/>
                </div>
                <span>{{ 'adc_waiting_for_shuttle' |myLbTranslate }}</span>
            </div>
            <div *ngIf="progressState === adoptionEnum.SETUP_COMPLETE" class="step-complete">
                <button class="primary" (click)="nextStep(adoptionEnum.CONNECTED)">
                    {{ 'next' | myLbTranslate }}
                </button>
                <img ngSrc="/assets/centralisedManagement/icons/downloaded-private-key.svg"
                     alt="green success checkbox" height="33" width="32"/>
                <span>{{ 'adc_setup_successful_message' | myLbTranslate }}</span>
            </div>
        </ng-container>
        <ng-container *ngIf="isStepThree()">
            <button class="primary" routerLink="/cm/adcs/shuttle" [queryParams]="{ sFilter: adcAddressForAdoption}">
                {{ 'adc_go_to' | myLbTranslate }}
            </button>
        </ng-container>
        <div class="help-prompt">
            <p>{{ 'adc_setup_help_prompt_message' | myLbTranslate }}</p>
            <img
                ngSrc="assets/centralisedManagement/icons/add-adc-help-squiggle.svg"
                alt="Arrow to help chat"
                height="64"
                width="43"/>
        </div>
    </div>
</div>
