<div id="page-wrapper">                 <!-- This is correct but because this component -->
    <div class="component-main">        <!-- doesn't have its own menu/ parent component-->
        <div class="content-space-between">
            <div>
                <h1 id="component-title">{{ 'welcome' | myLbTranslate }} {{ currentUserFirstName }}!</h1>
                <div class="sub-header">
                    <img
                        ngSrc="/assets/centralisedManagement/icons/portal.svg"
                        alt="Dynamic image without description"
                        height="14"
                        width="14">
                    <h6>{{ organisationDetails.name }}</h6>
                </div>
            </div>
        </div>

        <div class="grid-wrapper">
            <div class="grid">
                <app-namespaces-widget class="width-4/12"></app-namespaces-widget>
                <app-adcs-widget class="width-4/12"></app-adcs-widget>
                <app-schedules-widget class="width-4/12"></app-schedules-widget>
                <app-security-widget class="width-4/12"></app-security-widget>
                <app-storage-widget class="width-4/12"></app-storage-widget>
                <!--            <app-quick-link-widget class="width-3/12"></app-quick-link-widget>-->
                <!--            <app-portal-whats-new class="width-3/12"></app-portal-whats-new>-->
                <!--            <app-guides-widget class="width-3/12"></app-guides-widget>-->
                <!--            <app-features-widget class="width-3/12"></app-features-widget>-->
                <app-user-widget class="width-4/12"></app-user-widget>
            </div>
            <app-advert-widget></app-advert-widget>
        </div>

    </div>
</div>
