<div #actions class="actions-container">
    <ng-container *ngIf="!isLoading; else spinner">
        <div
            class="actions-toggle {{actionDotsClass}}"
            [class.display-as-button]="displayAsButton"
            (click)="toggleActionDots($event)">
            <img
                class="{{actionDotsClass}}"
                [class.usingExternalImage]="usingExternalImage()"
                src="{{triggerButtonImagePath}}"
                alt="Action dots"/>
        </div>
    </ng-container>
    <ng-template #spinner>
        <div class="actions-loader-wrapper">
            <div class="dot-spin"></div>
        </div>
    </ng-template>
    <ng-template #contentWrapper>
        <app-action-dots-wrapper>
            <ng-content></ng-content>
        </app-action-dots-wrapper>
    </ng-template>
</div>
