<app-modal-wrapper
    [isOverlayBlurred]="true"
    [isOverlayClickable]="false"
    modalId="relogin"
    modalTitle="relogin"
    [showCancelButton]="false"
    [showCloseButton]="false"
    [showFooter]="!otpEnabled && !ssoProvider"
    style="user-select: none;"
    footerButtonAlignment="content-center">
    <app-modal-body>
        <div [hidden]="!showLogin">
            <ng-container *ngIf="otpEnabled">
                <p>{{ 'accounts_two_factor_auth_use_sms_code' | myLbTranslate }} {{ twoFactorAuthPhoneNumber }}</p>
                <app-two-factor-auth [callBack]="submitReLogin.bind(this)" (authenticated)="logUserInWithToken($event)">
                </app-two-factor-auth>
            </ng-container>
            <ng-container *ngIf="ssoProvider">
                <p>{{ 'relogin-sso-click' | myLbTranslate }}</p>
                <div class="content-center no-padding">
                    <button class="sso-button" [disabled]="hasSSOToken" (click)="linkToSSOProvider()">
                        <img
                            ngSrc="assets/centralisedManagement/icons/sso-{{ssoProvider}}.svg"
                            height="20"
                            width="20"
                            alt="{{ssoProvider}} single sign on SSO icon">
                        <span class="sso-name">{{ getSsoProviderName() }}</span>
                    </button>
                </div>
            </ng-container>
            <ng-container *ngIf="!otpEnabled && !ssoProvider">
                <form [formGroup]="reLoginForm">
                    <div class="form-group">
                        <label for="password">{{ 'password' | myLbTranslate }}*</label>
                        <div class="form-content">
                            <input
                                title="{{ 'password' | myLbTranslate }}"
                                type="password"
                                id="password"
                                name="password"
                                formControlName="password"/>
                            <app-validation-messages [control]="reLoginForm.controls.password">
                            </app-validation-messages>
                        </div>
                    </div>
                </form>
            </ng-container>
        </div>
        <div [hidden]="!showConnecting">
            <div class="content-vertical">
                <div class="actions-loader-wrapper">
                    <div class="dot-spin"></div>
                </div>
                <br>
                {{ 'relogin_requesting_auth_token' | myLbTranslate }}
            </div>
        </div>
    </app-modal-body>
    <app-modal-footer>
        <div [hidden]="!showLogin || otpEnabled">
            <button class="outline" (click)="logout()">{{ 'logout' | myLbTranslate }}</button>
            <button *ngIf="!ssoProvider" class="primary" (click)="submitReLogin()" [disabled]="!reLoginForm.valid">
                {{ 'login' | myLbTranslate }}
            </button>
        </div>
    </app-modal-footer>
</app-modal-wrapper>

<app-modal-wrapper
    [isOverlayBlurred]="true"
    [isOverlayClickable]="false"
    modalId="certExpiredLogout"
    modalTitle="relogin_cert_expired_title"
    modalHeight="220"
    modalWidth="320"
    [showCloseButton]="false">
    <app-modal-body>
        <span>{{ 'relogin_cert_expired_text' | myLbTranslate }}</span>
    </app-modal-body>
    <app-modal-footer>
        <button class="primary" (click)="logout()">
            {{ 'logout' | myLbTranslate }}
        </button>
    </app-modal-footer>
</app-modal-wrapper>
