<div class="component-main">
    <div class="content">
        <h1>{{ (editId ? 'ip_edit_group' : 'ip_add_group') | myLbTranslate }}</h1>
    </div>
    <form [formGroup]="groupForm">
        <div class="form-group">
            <label for="name">{{ 'name' | myLbTranslate }}*</label>
            <div class="form-content">
                <input
                    title="{{'name' | myLbTranslate }}"
                    type="text"
                    size="30"
                    name="name"
                    id="name"
                    formControlName="name"/>
                <app-validation-messages [control]="groupForm.get('name')"></app-validation-messages>
                <app-help tag="fip-group_name"></app-help>
            </div>
        </div>
        <div class="form-group">
            <!--suppress XmlInvalidId -->
            <label for="ip-groups-interface">{{ 'interface' | myLbTranslate }}*</label>
            <div class="form-content">
                <app-dropdown
                    dropDownId="ip-groups-interface"
                    formControlName="interfaces"
                    [formGroup]="groupForm"
                    [content]="dropdown.interfaces">
                </app-dropdown>
                <app-help tag="fip-group_interface"></app-help>
            </div>
        </div>
        <div class="form-group">
            <div class="form-content">
                <input id="enableIpv4" formControlName="enableIpv4" class="lb-checkbox" type="checkbox">
                <label for="enableIpv4">{{ 'ip_enable_ipv4' | myLbTranslate }}</label>
            </div>
        </div>
        <div class="form-group" *ngIf="groupForm.get('enableIpv4').value === true">
            <!--suppress XmlInvalidId -->
            <label for="ipv4-mask">{{ 'ip_ipv4_mask' | myLbTranslate }}*</label>
            <div class="form-content">
                <app-dropdown
                    dropDownId="ipv4-mask"
                    formControlName="ipv4Mask"
                    [formGroup]="groupForm"
                    [content]="dropdown.ipv4Mask">
                </app-dropdown>
                <app-help tag="fip-group_ipv4-mask"></app-help>
            </div>
        </div>
        <div class="form-group" *ngIf="groupForm.get('enableIpv4').value === true">
            <label for="ipv4Network">{{ 'ip_ipv4_network' | myLbTranslate }}*</label>
            <div class="form-content">
                <input
                    title="{{'ip_ipv4_network' | myLbTranslate }}"
                    type="text"
                    size="30"
                    name="ipv4Network"
                    id="ipv4Network"
                    formControlName="ipv4Network"/>
                <app-validation-messages [control]="groupForm.get('ipv4Network')"></app-validation-messages>
                <app-help tag="fip-group_ipv4-network"></app-help>
            </div>
        </div>
        <div class="form-group">
            <div class="form-content">
                <input id="enableIpv6" formControlName="enableIpv6" class="lb-checkbox" type="checkbox">
                <label for="enableIpv6">{{ 'ip_enable_ipv6' | myLbTranslate }}</label>
            </div>
        </div>
        <div class="form-group" *ngIf="groupForm.get('enableIpv6').value === true">
            <!--suppress XmlInvalidId -->
            <label for="ipv6-mask">{{ 'ip_ipv6_mask' | myLbTranslate }}*</label>
            <div class="form-content">
                <app-dropdown
                    dropDownId="ipv6-mask"
                    formControlName="ipv6Mask"
                    [formGroup]="groupForm"
                    [content]="dropdown.ipv6Mask">
                </app-dropdown>
                <app-help tag="fip-group_ipv6-mask"></app-help>
            </div>
        </div>
        <div class="form-group" *ngIf="groupForm.get('enableIpv6').value === true">
            <label for="ipv6Network">{{ 'ip_ipv6_network' | myLbTranslate }}*
            </label>
            <div class="form-content">
                <input
                    title="{{'ip_ipv6_network' | myLbTranslate }}"
                    type="text"
                    size="30"
                    name="ipv6Network"
                    id="ipv6Network"
                    formControlName="ipv6Network"/>
                <app-validation-messages [control]="groupForm.get('ipv6Network')"></app-validation-messages>
                <app-help tag="fip-group_ipv6-network"></app-help>
            </div>
        </div>
    </form>
</div>
<div class="component-footer">
    <button
        id="new-ip-groups-cancel"
        class="outline"
        (click)="navigateToURL('/network/ip-groups')">
        {{ 'cancel' | myLbTranslate }}
    </button>
    <app-loading-button
        buttonId="new-ip-groups-add"
        buttonClass="primary"
        [buttonText]="editId ? 'update': 'add'"
        [isDisabled]="groupForm.invalid || groupForm.pristine"
        [callBack]="submitGroupCheck.bind(this)">
    </app-loading-button>
</div>
