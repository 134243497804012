<div class="component-main">
    <div class="content">
        <h1>{{ 'view_config_' + id | myUnderScore | myLbTranslate }}</h1>
    </div>
    <ng-container *ngIf="isLoading else displayData">
        <p>{{ 'loading' | myLbTranslate }}</p>
    </ng-container>
    <ng-template #displayData>
        <app-display-code [data]="data" errorMessage="view_config_{{id}}_message">
            <p>{{ 'loading' | myLbTranslate }}</p>
        </app-display-code>
    </ng-template>
</div>
