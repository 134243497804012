<div class="component-main">
    <div class="content-space-between">
        <h1>{{ titleAction | myLbTranslate }} {{ 'health_check' | myLbTranslate }}</h1>
    </div>
    <ng-container *ngIf="!viewMode; else viewScript">
        <form [formGroup]="healthCheckForm">
            <div class="form-group">
                <label>{{ 'name' | myLbTranslate }}*</label>
                <div class="form-content">
                    <input
                        type="text"
                        size="30"
                        name="name"
                        formControlName="name"
                        value=""/>
                    <app-validation-messages [control]="healthCheckForm.get('name')"></app-validation-messages>
                    <app-help class="help-button" tag="healthchecks_name"></app-help>
                </div>
            </div>
            <div class="form-group">
                <label>{{ 'description' | myLbTranslate }}</label>
                <div class="form-content">
                    <input type="text" size="30" name="description" formControlName="description"/>
                    <app-help class="help-button" tag="healthchecks_description"></app-help>
                </div>
            </div>
            <label>{{ 'services' | myLbTranslate }}*</label>
            <div class="form-group" *ngFor="let control of services.controls; index as i">
                <div class="form-content">
                    <input
                        id="service-{{control.get('name').value}}"
                        [formControl]="getFormControl(control)"
                        class="lb-checkbox"
                        name="services"
                        type="checkbox">
                    <label for="service-{{control.get('name').value}}" class="lb-checkbox"></label>
                    <span>{{ control.get('viewName').value }}</span>
                    <app-validation-messages [control]="control.get('name').value"></app-validation-messages>
                    <app-help *ngIf="i === 0" class="help-button" tag="healthchecks_services"></app-help>
                </div>
            </div>
            <div class="form-group content-top">
                <label>{{ 'health_check_script_content' | myLbTranslate }}*</label>
                <div class="form-content">
                    <textarea formControlName="script" rows="10" cols="53" class="code">
                    </textarea>
                    <app-validation-messages [control]="healthCheckForm.get('script')"></app-validation-messages>
                    <app-help class="help-button" tag="healthchecks_script"></app-help>
                </div>
            </div>
        </form>
    </ng-container>
    <ng-template #viewScript>
        <ng-container *ngIf="selectedHealthCheck">
            <h3>{{ selectedHealthCheck.name }}</h3>
            <app-display-code
                [hideCopyConfigButton]="true"
                [data]="selectedHealthCheck.script">
            </app-display-code>
        </ng-container>
    </ng-template>
</div>
<div class="component-footer">
    <button id="health-checks-new-cancel" class="outline" [routerLink]="[redirectUrl]">
        {{ 'cancel' | myLbTranslate }}
    </button>
    <app-loading-button
        *ngIf="!viewMode"
        buttonId="health-checks-new-submit"
        [isDisabled]="healthCheckForm.invalid || healthCheckForm.pristine"
        [buttonText]="editMode && !duplicateMode ? 'update' : 'submit'"
        buttonClass="primary"
        [callBack]="submitHealthCheck.bind(this)">
    </app-loading-button>
</div>
