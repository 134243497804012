import { WizardTitleEnum } from '../../enums';

export const QuickService = {
    title: 'Layer 7 Quick Start',
    version: 1,
    type: '7',
    modes: ['tcp', 'http'],
    wizardDefaultMode: 'partner',
    wizardModes: ['Partner'],
    wizardHideModes: true,
    description: {
        [WizardTitleEnum.TEMPLATE]: 'Quick setup: This template only asks for one label the FIP and the real server IP address.'
    },
    builds: {
        [WizardTitleEnum.FRONTENDS]: [
            {
                title: 'Label & FIP',
                label: {
                    value: 'service',
                    hidden: false
                },
                fipId: {
                    value: null,
                    hidden: false
                },
            }
        ],
        [WizardTitleEnum.BACKENDS]: [
            // Must include at least one property in order for the backend to be created.
            {
                mode: {
                    value: 'http',
                    hidden: true
                },
            }
        ],
        [WizardTitleEnum.REALSERVERS]: [
            {
                title: 'Real Server IP',
                backendID: {
                    value: '$1',
                    hidden: true
                },
                ip: {
                    value: '',
                    hidden: false
                }
            }
        ],
        [WizardTitleEnum.ACLS]: [
            {
                serviceID: {
                    value: '$1',
                    hidden: true
                },
                conditionType: {
                    value: 'default',
                    hidden: true,
                },
                redirectLocation: {
                    value: '$1',
                    hidden: true
                }
            }
        ],
    },
    sections: {
        [WizardTitleEnum.FRONTENDS]: [
            {
                // General
                label: {
                    value: 'frontend_[placeHolder]',
                    hidden: false
                },
                ports: {
                    value: [80],
                    hidden: false
                },
                mode: {
                    value: 'http',
                    hidden: false
                },
                fipId: {
                    value: '[placeHolder]',
                    hidden: false
                }
            }
        ],
        [WizardTitleEnum.BACKENDS]: [
            {
                // General Options
                label: {
                    value: 'backend_[placeHolder]',
                    hidden: false
                },
                mode: {
                    value: 'http',
                    hidden: false
                }
            }
        ],
        [WizardTitleEnum.REALSERVERS]: [
            {
                backendID: {
                    value: '$1',
                    hidden: false
                },
                label: {
                    value: 'realserver_[placeHolder]',
                    hidden: false
                },
                ip: {
                    value: '[placeHolder]',
                    hidden: false
                },
                port: {
                    value: 80,
                    hidden: false
                },
            }
        ],
        [WizardTitleEnum.ACLS]: [
            {
                serviceID: {
                    value: '$1',
                    hidden: true
                },
                conditionType: {
                    value: 'default',
                    hidden: true,
                },
                redirectLocation: {
                    value: '$1',
                    hidden: true
                },
                redirectType: {
                    value: 'backend',
                    hidden: true
                }
            }
        ],
    }
};
