<div class="component-main">
    <div class="content-space-between">
        <h1>{{ 'health_checks' | myLbTranslate }}</h1>
        <div class="button-container">
            <button
                id="health-checks-new-healthcheck"
                class="primary"
                [disabled]="isButtonDisabled('health_check_new')"
                [routerLink]="['add']">
                {{ 'health_check_add' | myLbTranslate | titlecase }}
            </button>
        </div>
    </div>

    <app-table [rows]="tableRows" [columnMode]="'flex'" [selectMode]="'singleRow'">
        <app-table-column [flexGrow]="1" prop="name" name="name"></app-table-column>
        <app-table-column [flexGrow]="1.5" prop="description" name="description"></app-table-column>
        <app-table-column [flexGrow]="1" prop="services" name="services"></app-table-column>
        <app-table-column [flexGrow]="0.75" prop="builtin" name="health_check_created_by">
            <ng-template let-row="row" appLbTableColumnCell>
                <ng-container *ngIf="row.builtin else custom;">
                    <span>{{ 'default' | myLbTranslate }}</span>
                </ng-container>
                <ng-template #custom>
                    <span>{{ 'user' | myLbTranslate }}</span>
                </ng-template>
            </ng-template>
        </app-table-column>
        <app-table-column [flexGrow]="0.75" prop="usage" name="usage">
            <ng-template let-row="row" appLbTableColumnCell>
                <app-usage [usage]="row.usage" modalTitle="health_checks_usage"></app-usage>
            </ng-template>
        </app-table-column>
        <app-table-column [flexGrow]="0.5" cellAlignment="right">
            <ng-template let-row="row" appLbTableColumnCell>
                <app-action-dots *ngIf="!row.builtin">
                    <button
                        [disabled]="isButtonDisabled('healthchecks-edit-healthcheck')"
                        [routerLink]="['edit/' + row.id]">
                        {{ 'edit' | myLbTranslate }}
                    </button>
                    <button
                        [disabled]="isButtonDisabled('healthchecks-delete-healthcheck') || row.usage?.length"
                        (click)="confirmDelete(row.id)">
                        {{ 'delete' | myLbTranslate }}
                    </button>
                </app-action-dots>
                <app-action-dots *ngIf="row.builtin" class="builtin">
                    <button [routerLink]="['view/' + row.id]">
                        {{ 'view' | myLbTranslate }}
                    </button>
                    <button
                        [disabled]="isButtonDisabled('healthchecks-duplicate-healthcheck')"
                        [routerLink]="['duplicate/' + row.id]">
                        {{ 'duplicate' | myLbTranslate }}
                    </button>
                </app-action-dots>
            </ng-template>
        </app-table-column>
    </app-table>
</div>
