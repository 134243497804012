export enum ModulesEnum {
    CENTRALISED_MANAGEMENT,
    CENTRALISED_MANAGEMENT_ON_PREMISE,
    LOADBALANCER
}

export const environment = {
    production: true,
    name: 'Loadbalancer.org Ltd.',
    theme: 'default',
    helpDrawActive: true,
    externalResourceUrl: 'https://cf.loadbalancer.org/portal/',
    module: ModulesEnum.LOADBALANCER,
};
