<div id="page-wrapper">
    <app-menu
        menuTitle="adcs"
        [collapseOnNavigation]=" false">
        <ng-container
            *ngFor="let child of portalMenuService.cmMenu['adcs'].children; index as i">
            <div
                [id]="child.id"
                [loc]="child['location']"
                heading="{{ child['heading'] | myLbTranslate}}"
                iconLocation="{{ child['iconLocation'] }}"
                appMenuGroup
                [hidden]="!portalMenuService?.cmMenu['adcs'].children[i].auth"
                [isOnlyHeader]="true">
            </div>
        </ng-container>
    </app-menu>
    <div id="content-wrapper">
        <router-outlet></router-outlet>
    </div>
</div>
