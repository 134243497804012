<ng-container *ngIf="data">
    <div class="node {{data.class}}">
        <div class="details-wrapper">
            <ng-container *ngIf="data.type === 'parent' else subnet">
                <div class="content-vertical">
                    <div class="content-vertical top">
                        <div class="content-space-between-top">
                            <div class="title">{{ 'gateway' | myLbTranslate }}</div>
                            <ng-container *ngIf="data.details?.callBacks?.length">
                                <app-action-dots actionDotsClass="primary">
                                    <ng-container *ngFor="let callBack of data.details?.callBacks; index as i">
                                        <button
                                            class="inverted-action-dot"
                                            [disabled]="callBack.disabled"
                                            (click)="callBack.callBack()">
                                            {{ callBack.label | myLbTranslate }}
                                        </button>
                                    </ng-container>
                                </app-action-dots>
                            </ng-container>
                        </div>
                        <div class="content-left">
                            <img
                                *ngIf="data.details?.imgPath"
                                ngSrc="{{data.details.imgPath}}"
                                height="15"
                                width="15"
                                alt="node chart image">
                            <span class="label">{{ data.details?.label }}</span>
                        </div>
                    </div>
                    <div class="content-space-between bottom details">
                        <div class="section">
                            <span class="details-label">{{ 'ip_address' | myLbTranslate }}</span>
                            <span class="details-value">{{ data.details?.gateway }}</span>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #subnet>
                <div class="content-vertical">
                    <div class="content-vertical top">
                        <div class="content-space-between-top">
                            <div class="title">{{ data.details?.subnet }}</div>
                            <ng-container *ngIf="data.details?.callBacks?.length">
                                <app-action-dots>
                                    <ng-container *ngFor="let callBack of data.details?.callBacks; index as i">
                                        <button
                                            class="adcs-shuttle-update"
                                            [disabled]="callBack.disabled"
                                            (click)="callBack.callBack()">
                                            {{ callBack.label | myLbTranslate }}
                                        </button>
                                    </ng-container>
                                </app-action-dots>
                            </ng-container>
                        </div>
                    </div>
                    <div class="content-space-between bottom details">
                        <div class="content">
                            <span class="details-value">Subnet ID: {{ data.details?.id }}</span>
                            <span class="details-value">/{{ data.details?.label }}</span>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</ng-container>

