<div class="component-main">
    <div class="content">
        <h1 id="component-title">
            {{ (storedSubnetId ? 'adc_shuttle_edit_subnet_edit' : 'adc_shuttle_edit_subnet_add') | myLbTranslate | titlecase }}
        </h1>
    </div>
    <form [formGroup]="formGroup">
        <div class="form-group">
            <label for="subnetLabel">{{ 'label' | myLbTranslate }}*</label>
            <div class="form-content">
                <input
                    formControlName="subnetLabel"
                    id="subnetLabel"
                    name="subnetLabel"
                    title="subnetLabel"
                    type="text">
                <app-validation-messages [control]="formGroup.get('subnetLabel')"></app-validation-messages>
            </div>
        </div>
        <div class="form-group">
            <label for="subnet">{{ 'subnet' | myLbTranslate }}*</label>
            <div class="form-content">
                <input
                    formControlName="subnet"
                    id="subnet"
                    name="subnet"
                    title="subnet"
                    type="text">
                <app-validation-messages [control]="formGroup.get('subnet')"></app-validation-messages>
            </div>
        </div>
        <div class="content radio-button-wrapper">
            <input
                type="radio"
                id="manual"
                [checked]="!newGateway"
                (click)="toggleGatewayType(false)">
            <label for="manual">{{ 'adc_shuttle_edit_subnet_existing_gateway' | myLbTranslate }}</label>
            <input
                type="radio"
                id="ntp"
                [checked]="newGateway"
                (click)="toggleGatewayType(true)">
            <label for="ntp">{{ 'adc_shuttle_edit_subnet_new_gateway' | myLbTranslate }}</label>
        </div>
        <ng-container *ngIf="newGateway else selectGateway">
            <div class="form-group">
                <label for="gateway">{{ 'gateway' | myLbTranslate }}*</label>
                <div class="form-content">
                    <input
                        formControlName="gateway"
                        id="gateway"
                        name="gateway"
                        title="gateway"
                        type="text">
                    <app-validation-messages [control]="formGroup.get('gateway')"></app-validation-messages>
                </div>
            </div>
        </ng-container>
        <ng-template #selectGateway>
            <div class="form-group">
                <!--suppress XmlInvalidId -->
                <label for="shuttle-gateways">{{ 'gateway' | myLbTranslate }}*</label>
                <div class="form-content">
                    <app-dropdown
                        [formGroup]="formGroup"
                        formControlName="gateway"
                        dropDownId="shuttle-gateways"
                        [content]="dropdown.gateways">
                    </app-dropdown>
                </div>
            </div>
        </ng-template>
    </form>
</div>
<div class="component-footer">
    <div>
        <button id="user-invite-cancel" class="outline" (click)="cancel()">
            {{ 'cancel' | myLbTranslate }}
        </button>
        <app-loading-button
            buttonId="submit_subnet"
            buttonClass="primary"
            buttonText="submit"
            [callBack]="submit.bind(this)"
            [isDisabled]="formGroup.pristine || formGroup.invalid">
        </app-loading-button>
    </div>
</div>
