<form [formGroup]="userForm">
    <div class="form-group">
        <label for="email">{{ 'email' | myLbTranslate }}*</label>
        <div class="form-vertical">
            <div class="form-content" *ngFor="let control of formEmails.controls; index as i">
                <input
                        id="email"
                        [formControl]="formEmails.controls[i]"
                        name="email"
                        type="text">
                <app-validation-messages [control]="formEmails.controls[i]"></app-validation-messages>
            </div>
        </div>
        <button class="add-user-button" [disabled]="userForm.invalid" (click)="addEmail()">
            <img src="assets/centralisedManagement/icons/add-user.svg" alt="Add user"/>
            Add more
        </button>
    </div>
    <div class="button-wrapper">
        <app-loading-button
                buttonText="organization_send_continue"
                buttonClass="primary"
                [isDisabled]="userForm.invalid"
                [callBack]="submitUsers.bind(this)">
        </app-loading-button>
    </div>
</form>
