<div [class.component-main]="!componentEmbedded">
    <div class="content" [class.embedded]="componentEmbedded">
        <ng-container *ngIf="!componentEmbedded; else embeddedTitle">
            <h1>{{ pageTitle | myLbTranslate }}</h1>
        </ng-container>
        <ng-template #embeddedTitle>
            <h2>{{ pageTitle | myLbTranslate }}</h2>
        </ng-template>
    </div>
    <form [formGroup]="uploadForm">
        <div class="form-group">
            <label for="upload-label">{{ 'label' | myLbTranslate }}</label>
            <div class="form-content">
                <input
                    title="{{'label' | myLbTranslate }}"
                    id="upload-label"
                    type="text"
                    size="30"
                    formControlName="label"
                    autocomplete="new-password"/>
                <app-validation-messages [control]="uploadForm.controls['label']"></app-validation-messages>
            </div>
        </div>
        <div class="form-group">
            <div class="form-content upload-file-wrapper">
                <app-upload
                    id="upload-file"
                    acceptedTypes=".pem, .pfx"
                    [resetFileInput]="inputReset"
                    (fileSelected)="onFileChange($event)">
                </app-upload>
                <app-validation-messages [control]="uploadForm.controls['file']"></app-validation-messages>
            </div>
        </div>
        <div class="form-group" [hidden]="hidePfxPasswordFieldGroup">
            <label for="upload-label">{{ 'password' | myLbTranslate }}</label>
            <div class="form-content">
                <app-lb-reveal-password
                    id="password"
                    title="{{'password' | myLbTranslate }}"
                    formControlName="password"
                    [formGroup]="uploadForm">
                </app-lb-reveal-password>
            </div>
        </div>
    </form>
</div>
<div class="component-footer" [class.embedded]="componentEmbedded">
    <button
        id="certs-cancel"
        class="outline"
        (click)="returnToParent()">
        {{ 'cancel' | myLbTranslate }}
    </button>
    <app-loading-button
        buttonClass="primary"
        [buttonText]="id ? 'apply' : 'submit'"
        [isDisabled]="uploadForm.pristine || uploadForm.invalid"
        [callBack]="submitCertUpload.bind(this)">
    </app-loading-button>
</div>
