import { WizardTitleEnum } from '../../enums';

export const CloudianLayer7Template = {
    title: 'Cloudian Layer 7',
    version: 1,
    type: '7',
    modes: ['tcp', 'http'],
    wizardDefaultMode: 'partner',
    wizardModes: ['Default', 'Advanced', 'Partner'],
    description: {
        [WizardTitleEnum.FRONTENDS]: `This template builds {{${WizardTitleEnum.FRONTENDS}}} frontends.`,
        [WizardTitleEnum.BACKENDS]: `This template contains {{${WizardTitleEnum.BACKENDS}}} backends. The Authentication
            section is used only for the "API_" config.`,
        [WizardTitleEnum.REALSERVERS]: `This template creates {{${WizardTitleEnum.REALSERVERS}}} real server configurations.
            Each section has duplicates enabled creating a total of {{totalReals}} real servers.`,
        [WizardTitleEnum.ACLS]: '',
        [WizardTitleEnum.MONITORS]: '',
        [WizardTitleEnum.TEMPLATE]: `This template builds {{${WizardTitleEnum.FRONTENDS}}} frontends,
            {{${WizardTitleEnum.BACKENDS}}} backends and comprises {{${WizardTitleEnum.REALSERVERS}}} real server sections,
            creating a total of {{totalReals}} real servers. <br><br> Fields shown in the wizard are configured to populate
            placeholders across the difference services, some fields maybe preconfigured and not shown here.`,
    },
    builds: {
        [WizardTitleEnum.FRONTENDS]: [
            {
                label: {
                    value: '',
                    hidden: false
                },
                fipId: {
                    value: null,
                    hidden: false
                },
            }
        ],
        [WizardTitleEnum.BACKENDS]: [
            {
                authentication: {
                    value: true,
                    hidden: false
                },
                healthCheckUsername: {
                    value: '',
                    hidden: false
                },
                healthCheckPassword: {
                    value: '',
                    hidden: false
                },
            }
        ],
        [WizardTitleEnum.REALSERVERS]: [
            {
                backendID: {
                    value: '$1',
                    hidden: true
                },
                ip: {
                    value: '',
                    hidden: false
                },
                enabledCount: {
                    value: true,
                    hidden: false
                },
                count: {
                    value: 3,
                    hidden: false
                },
            }
        ],
        [WizardTitleEnum.ACLS]: [
            {
                serviceId: {
                    value: '$1',
                    hidden: true
                },
                conditionType: {
                    value: 'default',
                    hidden: true,
                },
                redirectLocation: {
                    value: '$1',
                    hidden: true
                }
            }
        ],
    },
    sections: {
        [WizardTitleEnum.FRONTENDS]: [
            {
                title: 'CMC_Frontend',
                // General
                label: {
                    value: 'CMC_[placeHolder]',
                    hidden: false
                },
                ports: {
                    value: [8888, 8443],
                    hidden: false
                },
                mode: {
                    value: 'tcp',
                    hidden: false
                },
                fipId: {
                    value: '[placeHolder]',
                    hidden: false
                }
            },
            {
                title: 'API_Frontend',
                // General
                label: {
                    value: 'API_[placeHolder]',
                    hidden: false,
                },
                ports: {
                    value: [19443],
                    hidden: false,
                },
                mode: {
                    value: 'http',
                    hidden: false
                },
                fipId: {
                    value: '[placeHolder]',
                    hidden: false
                }
            },
            {
                title: 'S3-HTTP_Frontend',
                // General
                label: {
                    value: 'S3-HTTP_[placeHolder]',
                    hidden: false,
                },
                ports: {
                    value: [80],
                    hidden: false,
                },
                mode: {
                    value: 'http',
                    hidden: false
                },
                fipId: {
                    value: '[placeHolder]',
                    hidden: false
                }
            },
            {
                title: 'S3-HTTPS_Frontend',
                // General
                label: {
                    value: 'S3-HTTPS_[placeHolder]',
                    hidden: false,
                },
                ports: {
                    value: [443],
                    hidden: false,
                },
                mode: {
                    value: 'http',
                    hidden: false
                },
                fipId: {
                    value: '[placeHolder]',
                    hidden: false
                }
            },
            {
                title: 'HTTPS_IAM_Frontend',
                // General
                label: {
                    value: 'HTTPS_IAM_[placeHolder]',
                    hidden: false,
                },
                ports: {
                    value: [16443],
                    hidden: false,
                },
                mode: {
                    value: 'http',
                    hidden: false
                },
                fipId: {
                    value: '[placeHolder]',
                    hidden: false
                }
            },
            {
                title: 'HTTP_IAM_Frontend',
                // General
                label: {
                    value: 'HTTP_IAM_[placeHolder]',
                    hidden: false,
                },
                ports: {
                    value: [1680],
                    hidden: false,
                },
                mode: {
                    value: 'http',
                    hidden: false
                },
                fipId: {
                    value: '[placeHolder]',
                    hidden: false
                }
            },
        ],
        [WizardTitleEnum.BACKENDS]: [
            {
                title: 'CMC_Backend',
                // General Options
                label: {
                    value: 'CMC_[placeHolder]',
                    hidden: false
                },
                mode: {
                    value: 'tcp',
                    hidden: false
                },
                //  Persistence options
                persistenceTimeout: {
                    value: '30s',
                    hidden: false
                },
                // Health check options
                healthCheck: {
                    value: 'negotiateHTTPSOPTIONS',
                    hidden: false
                },
                healthCheckPort: {
                    value: 8443,
                    hidden: false
                },
                healthCheckRequestToSend: {
                    value: '/Cloudian/login.htm',
                    hidden: false
                },
            },
            {
                title: 'API_Backend',
                // General Options
                label: {
                    value: 'API_[placeHolder]',
                    hidden: false
                },
                mode: {
                    value: 'http',
                    hidden: false
                },
                // Health check options
                healthCheck: {
                    value: 'negotiateHTTPHEAD',
                    hidden: false
                },
                healthCheckPort: {
                    value: 8443,
                    hidden: false
                },
                healthCheckRequestToSend: {
                    value: '/.healthCheck',
                    hidden: false
                },
                authentication: {
                    value: '[placeHolder]',
                    hidden: false
                },
                healthCheckUsername: {
                    value: '[placeHolder]',
                    hidden: false
                },
                healthCheckPassword: {
                    value: '[placeHolder]',
                    hidden: false
                },
            },
            {
                title: 'S3-HTTP_Backend',
                // General Options
                label: {
                    value: 'S3-HTTP_[placeHolder]',
                    hidden: false
                },
                mode: {
                    value: 'http',
                    hidden: false
                },
                //  Persistence options
                persistenceTimeout: {
                    value: '30s',
                    hidden: false
                },
                // Health check options
                healthCheck: {
                    value: 'negotiateHTTPHEAD',
                    hidden: false
                },
                healthCheckPort: {
                    value: 8443,
                    hidden: false
                },
                healthCheckRequestToSend: {
                    value: '/.healthCheck',
                    hidden: false
                },
            },
            {
                title: 'S3-HTTPS_Backend',
                // General Options
                label: {
                    value: 'S3-HTTPS_[placeHolder]',
                    hidden: false
                },
                mode: {
                    value: 'http',
                    hidden: false
                },
                //  Persistence options
                persistenceTimeout: {
                    value: '30s',
                    hidden: false
                },
                // Health check options
                healthCheck: {
                    value: 'negotiateHTTPSHEAD',
                    hidden: false
                },
                healthCheckPort: {
                    value: 8443,
                    hidden: false
                },
                healthCheckRequestToSend: {
                    value: '/.healthCheck',
                    hidden: false
                },
            },
            {
                title: 'HTTPS_IAM_Backend',
                // General Options
                label: {
                    value: 'HTTPS_IAM_[placeHolder]',
                    hidden: false
                },
                mode: {
                    value: 'http',
                    hidden: false
                },
                //  Persistence options
                persistenceTimeout: {
                    value: '30s',
                    hidden: false
                },
                // Health check options
                healthCheck: {
                    value: 'connectToPort',
                    hidden: false
                },
            },
            {
                title: 'HTTP_IAM_Backend',
                // General Options
                label: {
                    value: 'HTTP_IAM_[placeHolder]',
                    hidden: false
                },
                mode: {
                    value: 'http',
                    hidden: false
                },
                //  Persistence options
                persistenceTimeout: {
                    value: '30s',
                    hidden: false
                },
                // Health check options
                healthCheck: {
                    value: 'connectToPort',
                    hidden: false
                },
            },
        ],
        [WizardTitleEnum.REALSERVERS]: [
            {
                title: 'CMC_Real Server',
                backendID: {
                    value: '',
                    hidden: false
                },
                label: {
                    value: 'CMC_[placeHolder]',
                    hidden: false
                },
                ip: {
                    value: '[placeHolder]',
                    hidden: false
                },
                port: {
                    value: 86,
                    hidden: false
                },
                enabledCount: {
                    value: '[placeHolder]',
                    hidden: false
                },
                count: {
                    value: '[placeHolder]',
                    hidden: false
                }
            },
            {
                title: 'API_Real Server',
                backendID: {
                    value: '',
                    hidden: false
                },
                label: {
                    value: 'API_[placeHolder]',
                    hidden: false
                },
                ip: {
                    value: '[placeHolder]',
                    hidden: false
                },
                port: {
                    value: 81,
                    hidden: false
                },
                enabledCount: {
                    value: '[placeHolder]',
                    hidden: false
                },
                count: {
                    value: '[placeHolder]',
                    hidden: false
                }
            },
            {
                title: 'S3-HTTP_Real Server',
                backendID: {
                    value: '',
                    hidden: false
                },
                label: {
                    value: 'S3-HTTP_[placeHolder]',
                    hidden: false
                },
                ip: {
                    value: '[placeHolder]',
                    hidden: false
                },
                port: {
                    value: 82,
                    hidden: false
                },
                enabledCount: {
                    value: '[placeHolder]',
                    hidden: false
                },
                count: {
                    value: '[placeHolder]',
                    hidden: false
                }
            },
            {
                title: 'S3-HTTPS_Real Server',
                backendID: {
                    value: '',
                    hidden: false
                },
                label: {
                    value: 'S3-HTTPS_[placeHolder]',
                    hidden: false
                },
                ip: {
                    value: '[placeHolder]',
                    hidden: false
                },
                port: {
                    value: 83,
                    hidden: false
                },
                enabledCount: {
                    value: '[placeHolder]',
                    hidden: false
                },
                count: {
                    value: '[placeHolder]',
                    hidden: false
                }
            },
            {
                title: 'HTTPS_IAM_Real Server',
                backendID: {
                    value: '',
                    hidden: false
                },
                label: {
                    value: 'HTTPS_IAM_[placeHolder]',
                    hidden: false
                },
                ip: {
                    value: '[placeHolder]',
                    hidden: false
                },
                port: {
                    value: 84,
                    hidden: false
                },
                enabledCount: {
                    value: '[placeHolder]',
                    hidden: false
                },
                count: {
                    value: '[placeHolder]',
                    hidden: false
                }
            },
            {
                title: 'HTTP_IAM_Real Server',
                backendID: {
                    value: '',
                    hidden: false
                },
                label: {
                    value: 'HTTP_IAM_[placeHolder]',
                    hidden: false
                },
                ip: {
                    value: '[placeHolder]',
                    hidden: false
                },
                port: {
                    value: 85,
                    hidden: false
                },
                enabledCount: {
                    value: '[placeHolder]',
                    hidden: false
                },
                count: {
                    value: '[placeHolder]',
                    hidden: false
                }
            },
        ],
        [WizardTitleEnum.ACLS]: [
            {
                conditionType: {
                    value: 'default',
                    hidden: true,
                },
                redirectLocation: {
                    value: '$1',
                    hidden: true
                },
                redirectType: {
                    value: 'backend',
                    hidden: true
                }
            },
            {
                conditionType: {
                    value: 'default',
                    hidden: true,
                },
                redirectLocation: {
                    value: '$2',
                    hidden: true
                },
                redirectType: {
                    value: 'backend',
                    hidden: true
                }
            },
            {
                conditionType: {
                    value: 'default',
                    hidden: true,
                },
                redirectLocation: {
                    value: '$3',
                    hidden: true
                },
                redirectType: {
                    value: 'backend',
                    hidden: true
                }
            },
            {
                conditionType: {
                    value: 'default',
                    hidden: true,
                },
                redirectLocation: {
                    value: '$4',
                    hidden: true
                },
                redirectType: {
                    value: 'backend',
                    hidden: true
                }
            },
            {
                conditionType: {
                    value: 'default',
                    hidden: true,
                },
                redirectLocation: {
                    value: '$5',
                    hidden: true
                },
                redirectType: {
                    value: 'backend',
                    hidden: true
                }
            },
            {
                conditionType: {
                    value: 'default',
                    hidden: true,
                },
                redirectLocation: {
                    value: '$6',
                    hidden: true
                },
                redirectType: {
                    value: 'backend',
                    hidden: true
                }
            },
        ],
    }
};
