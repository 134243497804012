<app-rbac-table
    [data]="data"
    [buttonPermissions]="buttonReference"
    (addEditUser)="addEditUser($event)"
    (addEditRole)="addEditRole($event)"
    (addEditGroup)="addEditGroup($event)"
    (deleteUser)="deleteUser($event)"
    (deleteRole)="deleteRole($event)"
    (deleteGroup)="deleteGroup($event)">
</app-rbac-table>
