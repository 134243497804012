<div class="component-main">
    <div class="content-space-between">
        <h1 id="component-title">{{ 'users_edit_user' | myLbTranslate | titlecase }}</h1>
    </div>

    <!-- TODO - content of this page to be confirmed   -->
    <!--    <app-add-addEditUsers></app-add-addEditUsers>-->
    <form [formGroup]="newUserForm">
        <div class="form-group">
            <label for="email">{{ 'email' | myLbTranslate }}*</label>
            <div class="form-content">
                <input formControlName="email" id="email" name="email" title="email" type="text">
                <app-validation-messages [control]="newUserForm.get('email')"></app-validation-messages>
                <app-help class="help-button" tag=""></app-help>
            </div>
        </div>
        <div class="form-group">
            <label for="confirmEmail">{{ 'confirmEmail' | myLbTranslate }}*</label>
            <div class="form-content">
                <input
                    formControlName="confirmEmail"
                    id="confirmEmail"
                    name="confirmEmail"
                    title="confirmEmail"
                    type="text">
                <app-validation-messages [control]="newUserForm.get('confirmEmail')"></app-validation-messages>
                <app-help class="help-button" tag=""></app-help>
            </div>
        </div>
        <div class="form-group">
            <label for="permission">{{ 'user_permissions' | myLbTranslate }}*</label>
            <div class="form-content">
                <app-dropdown
                    id="permission"
                    [formGroup]="newUserForm"
                    formControlName="permission"
                    [content]="dropdown.permission">
                </app-dropdown>
                <app-help class="help-button" tag=""></app-help>
            </div>
        </div>
        <div>
            <button id="user-edit-cancel" class="outline" routerLink="/cm/organisation/users">
                {{ 'cancel' | myLbTranslate }}
            </button>
            <app-loading-button
                buttonId="user-edit-send-invite"
                buttonClass="primary"
                buttonText="user_send_invite"
                [callBack]="updatedUser.bind(this)"
                [isDisabled]="newUserForm.invalid">
            </app-loading-button>
        </div>
    </form>
</div>
