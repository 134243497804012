<div class="component-main">
    <div class="content-space-between">
        <h1>{{ 'net_int_config_break_bond' | myLbTranslate }}</h1>
    </div>

    <form *ngIf="isLoaded" [formGroup]="formGroup">
        <div
            [hidden]="!hasGroups"
            class="step"
            [class.active]="activeStep === 1"
            formGroupName="groups">
            <p>{{ 'net_int_config_break_bond_groups_message' | myLbTranslate }}</p>
            <div class="form-group" *ngFor="let control of getGroupsFields.controls; index as i">
                <label for="bonds-groups{{i}}">{{ control.value.label }}</label>
                <div class="form-content" [formGroupName]="i.toString()">
                    <app-dropdown
                        dropDownId="bonds-groups{{i}}"
                        [formGroup]="getFormGroup(control)"
                        formControlName="interfaceId"
                        [content]="dropdownContent"
                        (keyOut)="updateView('groups')">
                    </app-dropdown>
                </div>
            </div>
        </div>
        <div
            [hidden]="!hasVLANs"
            class="step"
            [class.active]="activeStep === 2"
            formGroupName="vlans">
            <p>{{ 'net_int_config_break_bond_vlans_message' | myLbTranslate }}</p>
            <div class="form-group" *ngFor="let control of getVlansFields.controls; index as j">
                <label for="bonds-vlans{{j}}">{{ control.value.label }}</label>
                <div class="form-content" [formGroupName]="j.toString()">
                    <app-dropdown
                        dropDownId="bonds-vlans{{j}}"
                        [formGroup]="getFormGroup(control)"
                        formControlName="parentId"
                        [content]="dropdownContent"
                        (keyOut)="updateView('vlans')">
                    </app-dropdown>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="component-footer">
    <button
        id="break-bond-cancel"
        class="outline"
        [routerLink]="'../../'">
        {{ 'cancel' | myLbTranslate }}
    </button>
    <ng-container *ngIf="hasGroups && hasVLANs">
        <button id="break-bond-back" class="primary" [disabled]="activeStep === 1" (click)="backStep()">
            {{ 'back' | myLbTranslate }}
        </button>
    </ng-container>
    <ng-container
        *ngIf="(hasGroups && hasVLANs) && activeStep < 2 else submitBlock">
        <button
            [disabled]="isNextButtonDisabled()"
            id="break-bond-next"
            class="primary"
            (click)="nextStep()">
            {{ 'next' | myLbTranslate }}
        </button>
    </ng-container>
    <ng-template #submitBlock>
        <app-loading-button
            buttonId="break-bond-submit"
            buttonClass="primary"
            buttonText="submit"
            [isDisabled]="formGroup.invalid || formGroup.pristine"
            [callBack]="submit.bind(this)">
        </app-loading-button>
    </ng-template>

</div>
