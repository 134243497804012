<ng-container *ngIf="!hasTier">
    <app-alternative-state-page
        headerIcon="assets/centralisedManagement/icons/subscription_required.svg"
        header="security_insights_upgrade_title"
        messageString="security_insights_upgrade_message"
        imagePath="assets/centralisedManagement/empty-state/samlNoTier.svg"
        [redirectToButton]="true">
    </app-alternative-state-page>
</ng-container>
<ng-container *ngIf="isLoading else pageContent">
    <div class="loader-wrapper">
        <img
            class="loader"
            ngSrc="assets/shared/icons/loading-spinner-primary.svg"
            height="40"
            width="40"
            alt="loading spinner"/>
    </div>
</ng-container>
<ng-template #pageContent>
    <ng-container *ngIf="!tableRows?.length else table">
        <app-alternative-state-page
            header="certificates"
            titleText="certificate_counter_none"
            messageHtml="{{ 'certificate_counter_none_message' | myLbTranslate }}"
            [mockTableHeaders]="['certificate_name', 'certificate_common_name', 'certificate_issuer', 'billing_expiry']"
            imagePath="assets/centralisedManagement/empty-state/certificates.svg">
        </app-alternative-state-page>
    </ng-container>
    <ng-template #table>
        <div class="component-main">
            <div class="content-space-between">
                <div class="content-left">
                    <h1 id="component-title">{{ 'certificates' | myLbTranslate }}</h1>
                </div>
            </div>
            <app-ng-table
                name="security-certificates"
                [data]="tableRows"
                [showPaginator]="false">
                <app-ng-column [flexGrow]="1" prop="label" label="adc_name" [customCellTemplate]="adcName">
                    <ng-template let-row #adcName>
                        <img
                            class="company-logo"
                            src="assets/centralisedManagement/icons/{{adcUtilsService.getVendorIconName(row.vendor)}}-colour.svg"
                            alt="{{adcUtilsService.getVendorTitle(row.vendor)}} Logo"
                            title="{{adcUtilsService.getVendorTitle(row.vendor)}}"
                        />
                        <span
                            class="routerLink"
                            title="{{row.label}}"
                            [routerLink]="'/cm/security/certificates/details/' + row.id">
                        {{ row.label }}
                    </span>
                    </ng-template>
                </app-ng-column>
            </app-ng-table>
        </div>
    </ng-template>
</ng-template>
