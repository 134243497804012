<div class="blocks-wrapper">
    <div class="hide-form">
        <form [formGroup]="twoFactorAuthForm">
            <input
                #twoFactorAuth
                id="two-factor-auth-code"
                type="text"
                inputmode="numeric"
                formControlName="authCode"
                pattern="[0-9]*"
                maxlength="6"
                autocomplete="one-time-code"/>
        </form>
        <app-validation-messages [control]="twoFactorAuthForm.get('authCode')"></app-validation-messages>
    </div>
    <div
        *ngFor="let number of [0,1,2,3,4,5]"
        class="block"
        [class.focus]="authInputValue.length === number"
        [class.disabled]="twoFactorAuthForm.disabled || fakeDisabled"
        (keydown)="moveFocusToInput()" (click)="moveFocusToInput()">
        <span [class.focus]="authInputValue.length === number && inputIsActive()">
            {{ getAuthPart(number) }}
        </span>
    </div>
    <div *ngIf="fakeDisabled" class="hover-loading">
        <img
            class="loader"
            ngSrc="assets/shared/icons/loading-spinner-disabled.svg"
            alt="loading spinner"
            height="20"
            width="20"/>
    </div>
</div>
<ng-container *ngIf="authType === 'SMS'">
    <ng-container *ngIf="timeRemaining > 0 else sendCode">
        <div class="content-space-between no-padding">
        <span>
            {{ 'accounts_two_factor_auth_resend' | myLbTranslate }}
            {{ 'in' | myLbTranslate }} {{ timeRemaining }}
            {{ 'seconds' | myLbTranslate }}</span>
            <img
                class="loader"
                ngSrc="assets/shared/icons/loading-spinner-primary.svg"
                alt="loading spinner"
                height="20"
                width="20"/>
        </div>
    </ng-container>
    <ng-template #sendCode>
        <button class="button-as-link" (click)="resendCode()">{{ 'accounts_two_factor_auth_resend' | myLbTranslate }}
        </button>
    </ng-template>
</ng-container>

