<div class="component-main">
    <div class="content-space-between">
        <h1>{{ 'ip_groups' | myLbTranslate }}</h1>
        <button
            id="ip-groups-add-group"
            class="primary"
            [disabled]="isButtonDisabled('ip-groups-add-group')"
            [routerLink]="'add'">
            {{ 'ip_add_group' | myLbTranslate }}
        </button>
    </div>
    <app-search-rows [rows]="tableRows" [searchKeys]="searchKeys" (searchResults)="updateTableRows($event)">
    </app-search-rows>
    <app-table [rows]="tableRows" [columnMode]="'flex'">
        <app-table-column name="name" prop="name" [flexGrow]="0.75" columnWidth='auto'></app-table-column>
        <app-table-column name="ip_ipv4_network_upper" prop="ipv4Network" [flexGrow]="1" columnWidth='auto'>
            <ng-template let-row="row" appLbTableColumnCell>
                <div *ngIf="row.ipv4Network">
                    <app-tooltip
                        [width]="432"
                        trigger="{{row.ipv4Network}}/{{ row.ipv4Mask }}">
                        Range: {{ row.ipv4Range }}
                    </app-tooltip>
                </div>
                <span *ngIf="!row.ipv4Network">
                    {{ 'none_set' | myLbTranslate }}
                </span>
            </ng-template>
        </app-table-column>
        <app-table-column name="ip_ipv6_network_upper" prop="ipv6Network" [flexGrow]="1.5" columnWidth='auto'>
            <ng-template let-row="row" appLbTableColumnCell>
                <div *ngIf="row.ipv6Network">
                    <app-tooltip
                        [width]="432"
                        [forceRight]="true"
                        trigger="{{row.ipv6Network}}/{{ row.ipv6Mask }}">
                        Range: {{ row.ipv6Range }}
                    </app-tooltip>
                </div>
                <span *ngIf="!row.ipv6Network">
                    {{ 'none_set' | myLbTranslate }}
                </span>
            </ng-template>
        </app-table-column>
        <app-table-column [flexGrow]="1" columnWidth='auto' prop="assignedInterfaces"
                          name="interface"></app-table-column>
        <app-table-column [flexGrow]="0.75" prop="usage" name="usage">
            <ng-template let-row="row" appLbTableColumnCell>
                <app-usage [usage]="row.usage" modalTitle="ip_groups_usage"></app-usage>
            </ng-template>
        </app-table-column>
        <app-table-column [flexGrow]="0.4" columnWidth='auto' prop="actions" [cellAlignment]="'right'">
            <ng-template let-row="row" appLbTableColumnCell>
                <app-action-dots>
                    <button
                        id="ip-groups-edit-group"
                        [disabled]="isButtonDisabled('ip-groups-edit-group')"
                        (click)="openEditGroup(row)">
                        {{ 'edit' | myLbTranslate }}
                    </button>
                    <button
                        id="ip-groups-delete-group"
                        [disabled]="isButtonDisabled('ip-groups-delete-group') || row.usage?.length"
                        (click)="confirmDeleteGroup(row)">
                        {{ 'delete' | myLbTranslate }}
                    </button>
                </app-action-dots>
            </ng-template>
        </app-table-column>
    </app-table>
</div>
