<div class="component-main">
    <div class="content">
        <h1>Prometheus</h1>
    </div>
    <form [formGroup]="prometheusForm">
        <div formGroupName="haProxyForm">
            <app-lb-checkbox
                [formGroup]="getFormGroup('haProxyForm')"
                formControlName="enabled"
                label="{{ 'prometheus_enable_exporter_ha_proxy' | myLbTranslate }}">
                <div class="form-group">
                    <label for="haProxyPort">{{ 'prometheus_exporter_port' | myLbTranslate }}</label>
                    <div class="form-content">
                        <input
                            id="haProxyPort"
                            type="number"
                            name="port"
                            appInputValidator
                            formControlName="port"/>
                        <app-validation-messages [control]="prometheusForm.get('haProxyForm.port')">
                        </app-validation-messages>
                        <app-help class="help-button" tag="prometheus_exporter-port"></app-help>
                    </div>
                </div>
                <div class="form-group">
                    <!--suppress XmlInvalidId -->
                    <label for="collectors-haProxy-ip">{{ 'ip' | myLbTranslate }}</label>
                    <div class="form-content">
                        <app-dropdown
                            dropDownId="collectors-haProxy-ip"
                            formControlName="ipID"
                            [formGroup]="getFormGroup('haProxyForm')"
                            [content]="dropdown.ips">
                        </app-dropdown>
                        <app-help class="help-button" tag="prometheus_exporter-ip"></app-help>
                    </div>
                </div>
            </app-lb-checkbox>
        </div>
        <div formGroupName="nodeForm">
            <app-lb-checkbox
                [formGroup]="getFormGroup('nodeForm')"
                formControlName="enabled"
                label="{{ 'prometheus_enable_exporter_node' | myLbTranslate }}">
                <div class="form-group">
                    <label for="nodePort">{{ 'prometheus_exporter_port' | myLbTranslate }}</label>
                    <div class="form-content">
                        <input
                            id="nodePort"
                            type="number"
                            name="port"
                            appInputValidator
                            formControlName="port"/>
                        <app-validation-messages [control]="prometheusForm.get('nodeForm.port')">
                        </app-validation-messages>
                        <app-help class="help-button" tag="prometheus_node-exporter-port"></app-help>
                    </div>
                </div>
                <div class="form-group">
                    <!--suppress XmlInvalidId -->
                    <label for="collectors-node-ip">{{ 'ip' | myLbTranslate }}</label>
                    <div class="form-content">
                        <app-dropdown
                            dropDownId="collectors-node-ip"
                            formControlName="ipID"
                            [formGroup]="getFormGroup('nodeForm')"
                            [content]="dropdown.ips">
                        </app-dropdown>
                        <app-help class="help-button" tag="prometheus_node-exporter-ip"></app-help>
                    </div>
                </div>
            </app-lb-checkbox>
        </div>
    </form>
</div>
<div class="component-footer">
    <app-loading-button
        id="prometheus-set-exporters"
        buttonText="submit"
        buttonClass="primary"
        [isDisabled]="submitButtonDisabled"
        [callBack]="prometheusSubmit.bind(this)">
    </app-loading-button>
</div>
