<ng-container *ngIf="_rows && _rows.length; else noData">
    <div
        class="table-body"
        [class.fixed-header]="fixedHeader"
        [style.height]="fixedTableHeight ? fixedTableHeight + unit : 'auto'"
        [style.overflow]="fixedHeader ? 'scroll' : 'inherit'">
        <div
            *ngFor="let row of pageRows; let rowIndex = index"
            class="table-row"
            [class.selected-row]="selectedRows.includes(row)"
            (click)="selectMode !== 'none' ? handleSelectedRows($event, row) : ''">
            <app-body-row-wrapper
                [columnsArray]="columnsArray"
                [columnHidden]="columnHidden"
                [columnMode]="columnMode"
                [fixedHeader]="fixedHeader"
                [selectMode]="selectMode"
                [selectedRows]="selectedRows"
                [selectAll]="selectAll"
                [row]="row"
                [unit]="unit"
                [rowIndex]="rowIndex"
                [tableWidth]="tableWidth"
                (outputSelectedRows)="handleSelectedRows($event, row)">
            </app-body-row-wrapper>
        </div>
    </div>
</ng-container>
<ng-template #noData>
    <div [className]="'table-row'">
        <div class="table-no-data">
            No data to display
        </div>
    </div>
</ng-template>
