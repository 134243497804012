<div class="content-space-between embedded">
    <h2>{{ 'headers' | myLbTranslate }}</h2>
</div>
<form [formGroup]="headerForm">
    <div class="form-group">
        <!--suppress XmlInvalidId -->
        <label for="l7-headers-type">{{ 'type' | myLbTranslate }}</label>
        <div class="form-content">
            <app-dropdown
                dropDownId="l7-headers-type"
                class="header-dropdown"
                formControlName="type"
                [formGroup]="headerForm"
                [content]="dropdown.requestTypes">
            </app-dropdown>
        </div>
    </div>
    <div class="form-group">
        <!--suppress XmlInvalidId -->
        <label for="l7-headers-option">{{ 'option' | myLbTranslate }}</label>
        <div class="form-content">
            <app-dropdown
                dropDownId="l7-headers-option"
                class="header-dropdown"
                formControlName="option"
                [formGroup]="headerForm"
                [content]="dropdown.options">
            </app-dropdown>
        </div>
    </div>
    <!-- Name -->
    <div class="form-group" *ngIf="!headerForm.get('name').disabled">
        <label for="l7-headers-name">{{ 'name' | myLbTranslate }}</label>
        <div class="form-content">
            <input
                type="text"
                id="l7-headers-name"
                name="name"
                formControlName="name"
                [value]="">
            <app-validation-messages [control]="headerForm.controls['name']"></app-validation-messages>
        </div>
    </div>
    <!-- Value -->
    <div class="form-group" *ngIf="!headerForm.get('value').disabled">
        <div
            [className]="headerForm.controls['option']['value'] === 'replace'
                                    ? 'label-wrapper replace-label-wrapper' : 'label-wrapper'">
            <label for="l7-headers-value">{{ 'value' | myLbTranslate }}</label>
        </div>
        <p *ngIf="headerForm.controls['option']['value'] === 'replace'" class="replace-extra-text">
            {{ 'replace' | myLbTranslate }}
        </p>
        <div class="form-content">
            <textarea
                type="text"
                rows="1"
                name="value"
                id="l7-headers-value"
                formControlName="value">
            </textarea>
            <app-validation-messages [control]="headerForm.controls['value']"></app-validation-messages>
        </div>
    </div>
    <!-- Replace -->
    <div class="form-group" *ngIf="!headerForm.get('replace').disabled">
        <div class="label-wrapper replace-label-wrapper">
            <label for="l7-headers-value-replace"></label>
        </div>
        <p class="replace-extra-text">
            {{ 'with' | myLbTranslate }}
        </p>
        <div class="form-content">
            <textarea
                id="l7-headers-value-replace"
                type="text"
                rows="1"
                name="replace"
                formControlName="replace">
            </textarea>
            <app-validation-messages [control]="headerForm.controls['replace']"></app-validation-messages>
        </div>
    </div>
</form>

<div class="component-footer embedded">
    <button
        class="outline"
        id="button-cancel"
        (click)="cancelAddEditHeader()">
        {{ 'cancel' | myLbTranslate }}
    </button>
    <button
        [disabled]="!headerForm.valid || headerForm.pristine"
        class="primary"
        (click)="submitHeaderToCallBack()">
        {{ (header ? 'update' : 'add') | myLbTranslate }}
    </button>
</div>
