<ng-container *ngIf="!hasTier else page">
    <app-alternative-state-page
        headerIcon="assets/centralisedManagement/icons/subscription_required.svg"
        header="saml_upgrade_title"
        messageString="saml_upgrade_message"
        imagePath="assets/centralisedManagement/empty-state/samlNoTier.svg"
        [redirectToButton]="true">
    </app-alternative-state-page>
</ng-container>
<ng-template #page>
    <div class="component-main">
        <div class="content-space-between">
            <h1 id="component-title">{{ 'saml_title' | myLbTranslate }}</h1>
        </div>
        <div *ngIf="activeStep === 0" class="step">
            <div class="auth">
                <div class="content-space-between">
                    <img
                        ngSrc="assets/centralisedManagement/icons/sso-saml.svg"
                        alt="SAML single sign on SSO icon"
                        height="20"
                        width="21">
                    <span class="sso-name">SAML</span>
                    <button
                        id="saml-delete-config"
                        class="primary"
                        [disabled]="!permissionsLookup['saml-delete-config']"
                        (click)="confirmDeleteSamlConfig()">
                        {{ 'disconnect' | myLbTranslate }}
                    </button>
                </div>
                <!--         TODO waiting for back end at a later stage      -->
                <!--                <div class="content-space-between">-->
                <!--                    <span>Require SAML login for all users </span>-->
                <!--                </div>-->
            </div>
        </div>
        <div *ngIf="activeStep === 1" class="step">
            <h2>{{ 'saml_title_what_is' | myLbTranslate }}</h2>
            <p>{{ 'saml_title_message' | myLbTranslate }}</p>
            <p>{{ 'saml_title_message_help' | myLbTranslate }}
                <a class="default" (click)="getHelp()">{{ 'saml_title_message_help_link' | myLbTranslate }}</a>.
            </p>

            <div class="copy-content">
                <div class="form-group">
                    <label>{{ 'saml_title_entityId' | myLbTranslate }}</label>
                    <div class="form-content">
                        <p>{{ entityId }}</p>
                        <button appCopyClipboard class="copy-icon" [updateButtonText]=false [textToCopy]="entityId">
                        </button>
                    </div>
                </div>
                <div class="form-group">
                    <label>{{ 'saml_title_issuer' | myLbTranslate }}</label>
                    <div class="form-content">
                        <p>{{ issuerId }}</p>
                        <button appCopyClipboard class="copy-icon" [updateButtonText]=false [textToCopy]="issuerId">
                        </button>
                    </div>
                </div>
                <div class="form-group">
                    <label>{{ 'saml_title_sso_url' | myLbTranslate }}</label>
                    <div class="form-content">
                        <p>{{ ssoUrl }}</p>
                        <button appCopyClipboard class="copy-icon" [updateButtonText]=false [textToCopy]="ssoUrl">
                        </button>
                    </div>
                </div>
                <div class="form-group">
                    <label>{{ 'saml_title_name_id' | myLbTranslate }}</label>
                    <div class="form-content">
                        <p>{{ nameId }}</p>
                    </div>
                </div>
            </div>
            <div class="copy-content">
                <p>{{ 'saml_title_xml_message' | myLbTranslate }}</p>
                <button class="download-button" (click)="downloadXmlFile()">
                    <img ngSrc="assets/shared/icons/download.svg" alt="download icon" height="17" width="15">
                    {{ 'saml_download_xml' | myLbTranslate }}
                </button>
            </div>
        </div>
        <div *ngIf="activeStep === 2" class="step">
            <h2>{{ 'saml_setup_need_help' | myLbTranslate }}</h2>
            <p>{{ 'saml_setup_message' | myLbTranslate }}
                <a class="default" (click)="getHelp()">{{ 'saml_title_message_help_link' | myLbTranslate }}</a>.
            </p>
            <form [formGroup]="formGroup">
                <div class="form-group">
                    <label for="metaData">
                        {{ 'saml_setup_form_label' | myLbTranslate }}*
                    </label>
                    <div class="form-content">
                        <input
                            type="text"
                            name="metaData"
                            id="metaData"
                            formControlName="metaDataLink">
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div *ngIf="activeStep > 0" class="component-footer">
        <ng-container *ngIf="activeStep === 1">
            <button class="primary" (click)="next()">{{ 'next' | myLbTranslate }}</button>
        </ng-container>
        <ng-container *ngIf="activeStep === 2">
            <button class="outline" (click)="back()">{{ 'cancel' | myLbTranslate }}</button>
            <button
                id="saml-submit-config"
                class="primary"
                [disabled]="!permissionsLookup['saml-submit-config'] || formGroup.invalid"
                (click)="submit()">{{ 'submit' | myLbTranslate }}
            </button>
        </ng-container>
    </div>
</ng-template>
