//  _    ___   ___  ___  ___
// | |  | _ ) / _ \| _ \/ __|
// | |__| _ \| (_) |   / (_ |
// |____|___(_)___/|_|_\\___|
//           Loadbalancer.org
// ---

import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { Directive } from '@angular/core';

@Directive({
    selector: '[appIsIntegerValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: IsIntegerValidationDirective, multi: true }]
})

export class IsIntegerValidationDirective implements Validator {
    public validate(control: AbstractControl): {[key: string]: any} | null {
        return isIntegerValidation(control);
    }
}

export function isIntegerValidation(control: any) {
    const regex = new RegExp('^[0-9]+$');
    const result = regex.test(control.value);

    return result ? null : { 'isIntegerValidator': 'Value not a valid number' };
}
