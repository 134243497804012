<div id="shutdown-page-wrapper" class="external-page">
    <div id="main-content">
        <ng-container *ngIf="showShutdownCompleted else shutdownInprogress">
            <span class="title">{{ 'shutdown_completed_page_title' | myLbTranslate }}</span>
            <span class="sub-title">{{ 'shutdown_completed_sub_title' | myLbTranslate }}</span>
        </ng-container>
        <ng-template #shutdownInprogress>
            <span class="title">{{ 'shutdown_page_title' | myLbTranslate }}</span>
            <span class="sub-title">{{ 'shutdown_page_sub_title' | myLbTranslate }}</span>
            <div id="shutdownSpinner">
                <img class="loader" src="assets/shared/icons/loading-spinner-white.svg" alt="shutdown spinner"/>
            </div>
        </ng-template>
    </div>
</div>
<div id="copyright-footer">
    <span>&copy; Loadbalancer.org, {{ currentYear }} Inc. {{ 'external_all_rights_reserved' | myLbTranslate }}</span>
</div>
