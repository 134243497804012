<div *ngIf="!hideBanner" class="content-space-between">
    <div class="content-left">
        <img src="assets/centralisedManagement/icons/subscription.svg" alt="Subscription icon"/>
        <p>
            <strong>Welcome to Early Access</strong> - Check out the <a
            routerLink="/cm/organisation/subscriptions">full list of
            features</a>.
        </p>
    </div>
    <div class="content-right">
        <p>Early access ends on 31st July 2023</p>
        <button
            class="close-button"
            (click)="closeBanner()">
            <img
                src="assets/shared/icons/close-cross.svg"
                alt="close notification icon"/>
        </button>
    </div>
</div>
