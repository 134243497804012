<app-widget
    #widget
    [class]="colSpan ? 'widget-item col-span-' + colSpan : ''"
    title="{{ 'alerts' | myLbTranslate }}"
    [loading]="loading"
    [state]="disabled ? 'default' : state">
    <ng-container *ngIf="!disabled; else disabledMessage">
        <div class="content-half-width-center">
            <div class="content-vertical-middle">
                <ng-container *ngIf="!this.loading; else nodata">
                    <h1 [class]="getWidgetStatusClass(state)">
                        <ng-container *ngIf="!socketData.status; else count">
                            {{'n_a' | myLbTranslate}}
                        </ng-container>
                        <ng-template #count>
                            {{getTotalAlerts()}}
                        </ng-template>
                    </h1>
                    <h2 *ngIf="getTotalAlerts()" [class]="getWidgetStatusClass(state)">
                       {{getSubHeaderMessage()}}
                    </h2>
                </ng-container>
                <ng-template #nodata>
                    <h1>0</h1>
                </ng-template>
            </div>
        </div>
        <div class="content-half-width">
            <div class="content-vertical-middle">
                <ng-container class="node-list" *ngIf="getTotalAlerts() !== 0; else message">
                    <div class="node-list">
                        <table>
                            <tr *ngFor="let node of widgetData"
                                [hidden]="node.ripsByState.down.length === 0
                                    && totalWarningsByBackend(node) === 0
                                    && node.ripsByState.pending.length === 0">
                                <th>
                                    <span (click)="callBackFunction(node.name, '', true)"
                                          class="service-name" [class.critical]="node.status.toLowerCase() === 'down'">
                                        {{node.name}}
                                    </span>
                                </th>
                                <td>
                                    <ng-container *ngIf="node.status.toLowerCase() === 'down'; else alertCountContent">
                                    <span (click)="callBackFunction(node.name, 'down', true)"
                                          class="critical">
                                        {{node.status | uppercase}}
                                    </span>
                                    </ng-container>
                                    <ng-template #alertCountContent>
                                        <div class="content-space-evenly">
                                            <span (click)="callBackFunction(node.name, 'down')"
                                                  class="alerts critical">{{node.ripsByState.down.length}}</span>
                                            <span (click)="callBackFunction(node.name, 'warning')"
                                                  class="alerts warning">{{totalWarningsByBackend(node)}}</span>
                                            <span (click)="callBackFunction(node.name, 'pending')"
                                                  class="alerts info">{{node.ripsByState.pending.length}}</span>
                                        </div>
                                    </ng-template>
                                </td>
                            </tr>
                        </table>
                    </div>
                </ng-container>
                <ng-template #message>
                    <p class="default" [hidden]="clickCounter > 4" (click)="clickIncrementer()">
                        {{ statusMessage | myLbTranslate }}
                    </p>
                    <div [hidden]="clickCounter < 5" class="marquee">
                        <p class="success" (click)="clickIncrementer()">{{awesomeText}}</p>
                    </div>
                </ng-template>
            </div>
        </div>
    </ng-container>
    <ng-template #disabledMessage>
        <h2 class="disabled-message">Widget disabled</h2>
    </ng-template>
</app-widget>
