<form class="search-rows-wrapper" [class.no-margin]="removeMargin">
    <input
        id="search-rows-input"
        type="search"
        placeholder="{{ placeholder | myLbTranslate }}"
        required
        (keyup)="setUpSearch($event)"
        [value]="hideSearchTerm ? null : searchTerm || null"/>
    <button class="clear-search" type="reset" (click)="clearSearch()">
        <img src="assets/shared/icons/close-cross.svg" alt="Clear search"/>
    </button>
</form>
