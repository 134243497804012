<div class="component-main" [hidden]="addEditSMTP">
    <div class="content-space-between">
        <h1>{{ 'view_config_snmp_conf' | myLbTranslate }}</h1>
        <ng-container *ngIf="(snmpSettings?.enabledV2 || snmpSettings?.enabledV3) && !addEditSMTP">
            <button class="primary" (click)="editSettings('edit')">{{ 'snmp_conf_edit_settings' | myLbTranslate }}
            </button>
        </ng-container>
    </div>
    <ng-container *ngIf="!snmpOff">
        <div class="snmp-settings">
            <h3>{{ 'snmp_conf_protocol_in_use' | myLbTranslate }} {{ protocolsInUse }}</h3>
            <div id="snmp-settings-wrapper">
                <p><strong>{{ 'snmp_conf_snmp_community' | myLbTranslate }}:</strong>
                    {{ (snmpSettings.community || 'N/A') | titlecase }}
                </p>
                <p><strong>{{ 'snmp_conf_snmp_location' | myLbTranslate }}:</strong>
                    {{ snmpSettings.location | titlecase }}
                <p>
                <p><strong>{{ 'snmp_conf_snmp_contact' | myLbTranslate }}:</strong>
                    {{ snmpSettings.contact }}</p>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="userTableRows?.length">
        <div class="content-space-between">
            <h1>{{ 'users' | myLbTranslate }}</h1>
            <div class="add-user">
                <button class="primary" (click)="addUser('user/add')">Add user</button>
            </div>
        </div>
    </ng-container>
    <div class="users content-space-between">
        <ng-container *ngIf="userTableRows?.length">
            <app-ng-table
                name="snmps-users-list"
                [data]="userTableRows"
                [allowMultiSelect]="false"
                [showPaginator]="false"
                [stickyHeader]="true">
                <app-ng-column [flexGrow]="1" prop="user" label="user"></app-ng-column>
                <app-ng-column [flexGrow]="1" prop="authentication" label="authentication"></app-ng-column>
                <app-ng-column [flexGrow]="1" prop="privacy" label="external_privacy"></app-ng-column>
                <app-ng-column [flexGrow]="1" prop="permission" label="permission"></app-ng-column>
                <app-ng-column
                    [flexGrow]="1"
                    prop="actions"
                    label=""
                    cellAlignment="right" [customCellTemplate]="actions">
                    <ng-template let-row #actions>
                        <app-action-dots>
                            <button id="snmp-edit-user" (click)="editUser(row.id)">
                                {{ 'edit' | myLbTranslate }}
                            </button>
                            <button id="snmp-delete-user" (click)="confirmDeleteUser(row)">
                                {{ 'delete' | myLbTranslate }}
                            </button>
                        </app-action-dots>
                    </ng-template>
                </app-ng-column>
            </app-ng-table>
        </ng-container>
    </div>
</div>
