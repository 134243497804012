<section [class.tabVertical]="vertical" [class.contained]="contained" class="tab-set">
    <ul class="tabs {{tabsAlignment}}">
        <li
            *ngFor="let tab of tabs"
            [class.active]="tab.active"
            [class.disabled]="tab.disabled"
            class="tab"
            id="tab-{{tab.htmlId}}"
            (click)="tabClicked(tab)">
            <div class="title-content {{tab.iconAlignment}}">
                <span>{{ tab.tabTitle | myLbTranslate }}</span>
                <img
                    *ngIf="tab.iconLocation"
                    src="./assets/{{tab.iconLocation}}"
                    [style]="tab.iconStyle"
                    alt="tab icon"/>
            </div>
        </li>
    </ul>
</section>
<div
    class="tab-content-wrapper"
    [class.no-border]="disableBorder"
    [style.width.px]="width"
    [style.width.%]="widthPercent"
    [style.min-height.px]="minHeight">
    <ng-content></ng-content>
</div>
