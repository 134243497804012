<div class="component-main">
    <div class="content">
        <h1>{{ 'physical_adv_conf' | myLbTranslate }}</h1>
    </div>
    <section [hidden]="hostnameForm.disabled">
        <h2>{{ 'hostname' | myLbTranslate }} & {{ 'domain' | myLbTranslate | lowercase }}</h2>
        <form [formGroup]="hostnameForm" [loaded]="isLoaded">
            <div class="form-group">
                <label for="hostname">{{ 'hostname' | myLbTranslate }}</label>
                <div class="form-content">
                    <input
                        title="{{ 'hostname' | myLbTranslate }}"
                        type="text"
                        size="30"
                        id="hostname"
                        name="hostname"
                        formControlName="hostname"
                        appInputValidator/>
                    <app-help tag="physical-adv-conf_host-name"></app-help>
                    <app-validation-messages [control]="hostnameForm.controls['hostname']"></app-validation-messages>
                </div>
            </div>
            <div class="form-group">
                <label for="domain">{{ 'domain' | myLbTranslate }}</label>
                <div class="form-content">
                    <input
                        title="{{ 'domain' | myLbTranslate }}"
                        type="text"
                        size="30"
                        id="domain"
                        name="domain"
                        formControlName="domain"
                        appInputValidator/>
                    <app-validation-messages [control]="hostnameForm.controls['domain']"></app-validation-messages>
                    <app-help tag="physical-adv-conf_domain-name"></app-help>
                </div>
            </div>
        </form>
    </section>
    <section [hidden]="httpProxyForm.disabled">
        <h2>{{ 'physical_adv_conf_internet_access' | myLbTranslate }}
            ({{ 'physical_adv_conf_http_proxy' | myLbTranslate }})</h2>
        <form [formGroup]="httpProxyForm">
            <app-lb-checkbox
                [formGroup]="httpProxyForm"
                formControlName="enable"
                checkboxId="proxy-enabled"
                label="{{ 'enabled' | myLbTranslate }}"
                [showHiddenContent]="true"
                helpTag="physical-adv-conf_proxy-enabled">
                <div class="form-group">
                    <label for="proxyAddress">{{ 'address' | myLbTranslate }}</label>
                    <div class="form-content">
                        <input
                            title="{{ 'physical_adv_conf_proxy_address' | myLbTranslate }}"
                            type="text"
                            name="address"
                            id="proxyAddress"
                            formControlName="address"
                            appInputValidator/>
                        <app-validation-messages [control]="httpProxyForm.get('address')"></app-validation-messages>
                        <app-help class="help-button" tag="physical-adv-conf_proxy-address"></app-help>
                    </div>
                </div>
                <div class="form-group">
                    <label for="proxyPort">{{ 'port' | myLbTranslate }}</label>
                    <div class="form-content">
                        <input
                            title="{{ 'physical_adv_conf_proxy_port' | myLbTranslate }}"
                            type="number"
                            name="port"
                            id="proxyPort"
                            formControlName="port"
                            appInputValidator/>
                        <app-validation-messages [control]="httpProxyForm.get('port')"></app-validation-messages>
                        <app-help class="help-button" tag="physical-adv-conf_proxy-port"></app-help>
                    </div>
                </div>
                <div class="form-group">
                    <label for="proxyUsername">{{ 'username' | myLbTranslate }}</label>
                    <div class="form-content">
                        <input
                            title="{{ 'physical_adv_conf_proxy_username' | myLbTranslate }}"
                            type="text"
                            name="proxyUsername"
                            id="proxyUsername"
                            formControlName="username"/>
                        <app-validation-messages [control]="httpProxyForm.get('username')"></app-validation-messages>
                        <app-help class="help-button" tag="physical-adv-conf_proxy-username"></app-help>
                    </div>
                </div>
                <div class="form-group">
                    <label for="proxyPassword">{{ 'password' | myLbTranslate }}</label>
                    <div class="form-content">
                        <app-lb-reveal-password
                            id="proxyPassword"
                            title="{{'password' | myLbTranslate }}"
                            formControlName="password"
                            [formGroup]="httpProxyForm">
                        </app-lb-reveal-password>
                        <app-validation-messages [control]="httpProxyForm.get('password')"></app-validation-messages>
                        <app-help class="help-button" tag="physical-adv-conf_proxy-password"></app-help>
                    </div>
                </div>
            </app-lb-checkbox>
        </form>
    </section>
    <section [hidden]="consoleForm.disabled">
        <h2>{{ 'physical_adv_conf_console' | myLbTranslate }}</h2>
        <form [formGroup]="consoleForm" disableFocus="true">
            <div class="form-group">
                <div class="form-content">
                    <input
                        class="lb-checkbox"
                        type="checkbox"
                        name="console"
                        id="consoleEnabled"
                        formControlName="active"/>
                    <label class="lb-checkbox" for="consoleEnabled">{{ 'enabled' | myLbTranslate }}</label>
                    <app-validation-messages [control]="consoleForm.get('active')"></app-validation-messages>
                    <app-help class="help-button" tag="physical-adv-conf_console-enabled"></app-help>
                </div>
            </div>
            <div class="form-group">
                <label for="consolePassword">{{ 'password' | myLbTranslate }}</label>
                <div class="form-content">
                    <app-lb-reveal-password
                        id="consolePassword"
                        title="{{'password' | myLbTranslate }}"
                        formControlName="password"
                        [formGroup]="consoleForm">
                    </app-lb-reveal-password>
                    <app-validation-messages [control]="consoleForm.get('password')"></app-validation-messages>
                    <app-help class="help-button" tag="physical-adv-conf_console-password"></app-help>
                </div>
            </div>
        </form>
    </section>
    <section [hidden]="firewallForm.disabled">
        <h2>{{ 'firewall' | myLbTranslate }}</h2>
        <form [formGroup]="firewallForm" disableFocus="true">
            <div class="form-group">
                <label for="size">{{ 'physical_adv_conf_conn_trac_table_size' | myLbTranslate }}</label>
                <div class="form-content">
                    <input
                        id="size"
                        title="{{ 'physical_adv_conf_firewall_connections' | myLbTranslate }}"
                        type="number"
                        name="size"
                        formControlName="size"
                        [min]="10000"
                        [max]="1000000"
                        appInputValidator
                        [maximumLength]="7"/>
                    <app-validation-messages [control]="firewallForm.get('size')"></app-validation-messages>
                    <app-help class="help-button" tag="physical-adv-conf_fwconnections"></app-help>
                </div>
            </div>
        </form>
    </section>
    <section [hidden]="keyMapForm.disabled">
        <h2>{{ 'keyboard' | myLbTranslate }}</h2>
        <form [formGroup]="keyMapForm" disableFocus="true">
            <div class="form-group">
                <!--suppress XmlInvalidId -->
                <label for="phys-adv-conf-keymap">{{ 'keymap' | myLbTranslate }}</label>
                <div class="form-content">
                    <app-dropdown
                        dropDownId="phys-adv-conf-keymap"
                        formControlName="keymap"
                        [formGroup]="keyMapForm"
                        [content]="dropdown.keyMap"
                        [dropdownWidth]="260"
                        [search]="true">
                    </app-dropdown>
                    <app-help class="help-button" tag="physical-adv-conf_keymap"></app-help>
                </div>
            </div>
        </form>
    </section>
    <section [hidden]="smtpForm.disabled">
        <h2>{{ 'physical_adv_conf_smtp_relay' | myLbTranslate }}</h2>
        <form [formGroup]="smtpForm" disableFocus="true">
            <app-lb-checkbox
                [formGroup]="smtpForm"
                formControlName="enable"
                checkboxId="smtp-enabled"
                label="{{ 'enable' | myLbTranslate }}"
                [showHiddenContent]="true"
                helpTag="physical-adv-conf_smtp-enabled">
                <div class="form-group">
                    <label for="address">{{ 'physical_adv_conf_smtp_server_address' | myLbTranslate }}</label>
                    <div class="form-content">
                        <input
                            title="{{ 'physical_adv_conf_smtp_address' | myLbTranslate }}"
                            type="text"
                            name="address"
                            id="address"
                            formControlName="address"/>
                        <app-validation-messages [control]="smtpForm.get('address')"></app-validation-messages>
                        <app-help class="help-button" tag="physical-adv-conf_smtp-address"></app-help>
                    </div>
                </div>
                <div class="form-group">
                    <label for="port">{{ 'physical_adv_conf_smtp_port' | myLbTranslate }}</label>
                    <div class="form-content">
                        <input
                            id="port"
                            title="{{ 'physical_adv_conf_smtp_port' | myLbTranslate }}"
                            type="number"
                            name="port"
                            formControlName="port"
                            appInputValidator/>
                        <app-validation-messages [control]="smtpForm.get('port')"></app-validation-messages>
                        <app-help class="help-button" tag="physical-adv-conf_smtp-port"></app-help>
                    </div>
                </div>
                <div class="form-group">
                    <label for="username">{{ 'physical_adv_conf_smtp_username' | myLbTranslate }}</label>
                    <div class="form-content">
                        <input
                            title="{{ 'physical_adv_conf_smtp_username' | myLbTranslate }}"
                            type="text"
                            name="username"
                            id="username"
                            formControlName="username"/>
                        <app-validation-messages [control]="smtpForm.get('username')"></app-validation-messages>
                        <app-help class="help-button" tag="physical-adv-conf_smtp-username"></app-help>
                    </div>
                </div>
                <div class="form-group">
                    <label for="password">{{ 'physical_adv_conf_smtp_password' | myLbTranslate }}</label>
                    <div class="form-content">
                        <app-lb-reveal-password
                            id="password"
                            title="{{ 'physical_adv_conf_smtp_password' | myLbTranslate }}"
                            formControlName="password"
                            [formGroup]="smtpForm">
                        </app-lb-reveal-password>
                        <app-validation-messages [control]="smtpForm.get('password')"></app-validation-messages>
                        <app-help class="help-button" tag="physical-adv-conf_smtp-password"></app-help>
                    </div>
                </div>
            </app-lb-checkbox>
        </form>
    </section>
</div>
<div class="component-footer">
    <app-loading-button
        buttonId="update-config"
        buttonClass="primary"
        buttonText="submit"
        [isDisabled]="isButtonDisabled()"
        [callBack]="submitForms.bind(this)">
    </app-loading-button>
</div>
