<app-widget
    #widget
    class="widget-item col-span-1"
    title="{{ 'system_updates' | myLbTranslate }}"
    [loading]="loading">
    <div class="content-vertical-middle" *ngIf="updates">
        <h1 [class.default]="count < 1"
            [class.success]="count >= 1 && count < 5"
            [class.critical]="count >= 5">{{loading ? 0 : count}}</h1>
        <h2 *ngIf="count > 0">{{ 'available' | myLbTranslate }}</h2>
        <button (click)="internalLink('../maintenance/software-update')" *ngIf="count > 0" class="primary">
            {{ 'update' | myLbTranslate }}
        </button>
    </div>
</app-widget>
