<app-search-rows
    [removeMargin]="true"
    [rows]="searchData"
    [searchKeys]="searchKeys"
    (searchResults)="search($event)">
</app-search-rows>

<div id="d3-container">
    <button class="reset" (click)="fitZoom()">
        <img ngSrc="assets/shared/icons/refresh.svg" height="15" width="15"/>
    </button>
</div>
