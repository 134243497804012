<div class="component-wrapper" [class.full-width]="fullWidth">
    <section #panel1 class="panel-1 {{classList}}" [class.open]="open">
        <ng-container *ngFor="let title of titles">
            <ng-container *ngTemplateOutlet="title.template"></ng-container>
        </ng-container>
        <div *ngFor="let item of items" (click)="itemClicked(item)" [class.disabled]="!item.enabled">
            <ng-container *ngTemplateOutlet="item.template"></ng-container>
        </div>
    </section>
    <section #panel2 class="panel-2 {{classList}}" [class.open]="open">
        <div class="header">
            <button class="button-as-link concertina-back-button" (click)="toggleConcertina()">
                <img src="assets/shared/icons/chevron-left.svg" alt="back to previous step"/>
            </button>
        </div>
        <div
            *ngFor="let content of contents"
            [class.active]="content.index === activeIndex"
            class="panel-2-content-wrapper" [hidden]="content.index !== activeIndex">
            <ng-container *ngTemplateOutlet="content.template"></ng-container>
        </div>
    </section>
</div>
