import { ThemeModel } from '../../../../app/core/models';

export const darkTheme: ThemeModel = {
    name: 'darkMode',
    deactivate: false,
    properties: {
        '--header-background': '#1E2229',
        '--header-text': 'var(--color-text-inverted)',
        '--page-background': 'var(--color-dark)',
        '--spinner-color': 'var(--color-primary)',

        /** primary **/
        '--color-primary': '#dc2323',
        '--color-primary-lighten': '#dc595F',
        '--color-primary-extra-light': '#fce9e9',
        '--color-primary-super-light': '#EC9F9F',

        /** secondary **/
        '--color-secondary': '#494949',

        /** tertiary **/
        '--color-tertiary': 'var(--color-white)',

        /** success **/
        '--color-success': '#21bf73',
        '--color-success-shade': '#105f39',
        '--color-success-tint': '#63d29d',

        /** warning **/
        '--color-warning': '#ffd166',
        '--color-warning-shade': '#e0ae00',
        '--color-warning-tint': '#fde0a5',

        /** danger **/
        '--color-danger': '#dc2323',
        '--color-danger-shade': '#ac0505',
        '--color-danger-tint': '#d26262',

        /** Info **/
        '--color-info': '#25557c',
        '--color-info-shade': '#013e57',
        '--color-info-tint': '#2f9bcc',

        /** dark **/
        '--color-dark': '#1E2229',

        /** medium **/
        '--color-medium': '#2D333B',

        /** light **/
        '--color-light': '#444C56',

        /** Text **/
        '--color-text': 'var(--color-text-inverted)',

        /** Text Inactive **/
        '--color-text-inactive': '#878787',

        /** Text Inverted **/
        '--color-text-inverted': '#adbac7',

        /** Element Inactive **/
        '--color-element-inactive': '#cacaca',

        /** Container Background **/
        '--color-container-background': 'var(--color-medium)',

        /** Container Inverted Background **/
        '--color-container-inverted-background': '#161616',

        /** Disabled Element **/
        '--color-disabled-element': '#f1f1f1',

        /** Notification Banner **/
        '--notification-banner-background': 'var(--color-light)',
        '--notification-banner-text': 'var(--color-text)',

        /** Menu Items **/
        '--menu-background-color': '#1E2229',
        '--menu-item-text-color': 'var(--color-text-inverted)',
        '--menu-item-active-text-color': 'var(--color-dark)',
        '--menu-item-active-background-color': 'var(--color-medium)',
        '--menu-item-active-border-color': 'var(--color-primary)',

        /** Inputs **/
        '--input-text-color': 'var(--color-dark)',
        '--input-background-color': 'var(--color-container-background)',

        /** Other Colors **/
        '--color-white': '#ffffff',
        '--color-off-white': '#f8f8f8',
        '--color-light-gray': '#dadedf',
        '--color-gray-80': '#cccccc',
        '--color-mid-gray': '#808080',
        '--color-dark-gray': '#40403f',
        '--color-green': '#3c763d',

        /** Shadow Color **/
        '--box-shadow-color': '0,0,0',

        /** Graph line colours **/
        '--red-border-color': 'var(--color-primary)',
        '--red-point-background-color': 'var(--color-primary)',
        '--red-point-border-color': 'var(--color-white)',
        '--red-point-hover-background-color': 'var(--color-white)',
        '--red-point-hover-border-color': 'var(--color-primary)',
        '--grey-border-color': 'var(--color-text-inverted)',
        '--grey-point-background-color': 'var(--color-text-inverted)',
        '--grey-point-border-color': 'var(--color-white)',
        '--grey-point-hover-background-color': 'var(--color-white)',
        '--grey-point-hover-border-color': 'var(--color-text-inverted)'
    },
    styleSheetLocation: '/images/loadbalancer/themes/darkMode/css/styles.css',
    lightLogo: '/images/loadbalancer/themes/darkMode/images/logo_light.png',
    darkLogo: '/images/loadbalancer/themes/darkMode/images/logo_dark.png',
    imagePath: '/images/loadbalancer/themes/darkMode/images/'
};
