<div class="menu {{menuLevelClass}}" [ngClass]="shadowStyle"
     [class.closed]="menuLevelClass === 'level-1' ? menuService.menuClosed : false">
    <div class="menu-header">
        <div *ngIf="minWidthReached || enableMenuCollapse" class="menu-toggle" (click)="toggleMenu()">
            <ng-container *ngIf="menuService.menuClosed else open">
                <img ngSrc="./assets/shared/icons/menu-open.svg" alt="Open close menu toggle" height="20" width="20">
            </ng-container>
            <ng-template #open>
                <img ngSrc="./assets/shared/icons/menu-close.svg" alt="Open close menu toggle" height="20" width="20">
            </ng-template>
        </div>
        <div *ngIf="menuTitleImage" class="menu-image-wrapper">
            <img class="menu-title-image" [src]="menuTitleImage" [alt]="menuTitleImage"/>
        </div>
        <div class="menu-titles">
            <h1 class="menu-title">{{ menuTitle | myLbTranslate }}</h1>
            <span *ngIf="menuSubTitle" class="sub-title">{{ menuSubTitle | uppercase }}</span>
        </div>
        <div *ngIf="menuTitleImageLocation" class="background-image-wrapper">
            <img [src]="menuTitleImageLocation" alt="Menu header background image"/>
        </div>
    </div>
    <div class="menu-items">
        <!-- Primary -->
        <div *ngFor="let group of groups" class="primary">
            <ng-container *ngIf="!group.hidden">
                <div
                    [id]="group.id"
                    class="menu-item-wrapper {{group.class}}"
                    [class.coming-soon]="group.comingSoon"
                    [class.active]="isItemActive(group.loc)"
                    [ngClass]="toggleChevron(group)"
                    appHint
                    hintText="{{group.heading}}"
                    hintPosition="right"
                    [hintShow]="menuService.menuClosed"
                    (click)="toggleNav(group, group.items)">
                    <div *ngIf="group.iconLocation" class="menu-image-wrapper">
                        <img src="assets/{{group.iconLocation}}.svg" alt="{{group.iconLocation}} menu icon">
                    </div>
                    <span>{{ group.heading }}</span>
                    <img
                        *ngIf="group.link"
                        class="menu-item-link"
                        ngSrc="./assets/centralisedManagement/icons/link.svg"
                        alt="Link icon"
                        height="19"
                        width="19">
                    <img
                        *ngIf="group.subscriptionRequired"
                        class="menu-item-subscription"
                        ngSrc="./assets/centralisedManagement/icons/subscription_required.svg"
                        alt="subscription icon"
                        height="21"
                        width="21">
                </div>
            </ng-container>
            <!-- Secondary -->
            <ng-container *ngIf="(!group.isOnlyHeader && !group.groupHidden)">
                <div *ngFor="let item of group.items" class="secondary">
                    <div
                        *ngIf="!item.hidden"
                        [id]="item.id"
                        class="menu-item-wrapper"
                        [class.active]="isItemActive(item.loc)"
                        [ngClass]="toggleChevron(item)"
                        appHint
                        hintText="{{item.heading}}"
                        hintPosition="right"
                        [hintShow]="menuService.menuClosed"
                        (click)="toggleNav(item, item.subItems)">
                        <div class="menu-image-wrapper">
                            <img
                                *ngIf="item.iconName"
                                src="assets/{{item.iconName}}.svg"
                                alt="{{item.iconName}} menu icon">
                        </div>
                        <span>{{ item.heading }}</span>
                        <img
                            *ngIf="item.subscriptionRequired"
                            class="menu-item-subscription"
                            ngSrc="./assets/centralisedManagement/icons/subscription_required.svg"
                            alt="subscription icon"
                            height="21"
                            width="21">
                    </div>
                    <!-- Tertiary -->
                    <ng-container *ngIf="item.subHeading && item.subGroupHidden">
                        <div *ngFor="let subItem of item.subItems" class="tertiary">
                            <div
                                [id]="subItem.id"
                                class="menu-item-wrapper"
                                [class.active]="isItemActive(subItem.loc)"
                                appHint
                                hintText="{{subItem.heading}}"
                                hintPosition="right"
                                [hintShow]="menuService.menuClosed"
                                (click)="toggleNav(subItem)">
                                <div class="menu-image-wrapper">
                                    <img
                                        *ngIf="subItem.iconName"
                                        src="assets/{{subItem.iconName}}.svg"
                                        alt="{{subItem.iconName}} menu icon">
                                </div>
                                <span>{{ subItem.heading }}</span>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="menu-footer">
        <ng-content select="[menu-footer]"></ng-content>
    </div>
</div>
