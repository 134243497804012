<div class="form-group" *ngFor="let control of subjectAlts.controls; index as i">
    <div class="form-content subjectAlt">
        <input
            #subjects
            id="subjectAlt-{{i}}"
            [formControl]="getFormControl(control)"
            title="{{ 'certificate_input_subject_alt' | myLbTranslate }}"
            type="text">
        <ng-container *ngIf="subjectAlts.length > 1">
            <button type="button" class="button-as-link" (click)="removeSubjectAlt(i)">
                {{ 'delete' | myLbTranslate }}
            </button>
        </ng-container>
        <ng-container *ngIf="i === subjectAlts.controls.length -1">
            <button
                #addButton
                [disabled]="subjectAltIsInvalid(i)"
                class="button-as-link"
                (click)="addSubjectAlt()">
                {{ 'add' | myLbTranslate }}
            </button>
        </ng-container>
        <app-validation-messages [control]="subjectAlts.controls[i]"></app-validation-messages>
        <app-help *ngIf="i === 0" class="help-button" [tag]="helpTag"></app-help>
    </div>
</div>
