<div [class.forceDown]="sitInlineWithSearch" [hidden]="!view.adcCount || !view.tierMax">
    <p>{{ view.adcCount }}
        <ng-container *ngIf="view.tier !== 'tailored'">
            {{ 'of' | myLbTranslate }} {{ view.tierMax }}
        </ng-container>
        {{ 'adc_widget_connections' | myLbTranslate }}
    </p>
    <a *ngIf="showUpgradeMessage" class="default right-arrow" routerLink="/cm/organisation/subscriptions">
        {{ 'widget_upgrade_options' | myLbTranslate }}
    </a>
</div>
