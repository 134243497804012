<div class="component-main">
    <div class="content">
        <h1>{{'licence_key' | myLbTranslate }}</h1>
    </div>
    <div class="license-wrapper">
        <p><strong>{{'licence_company_name' | myLbTranslate}}:</strong> {{licenceData.companyName | titlecase}}</p>
        <p><strong>{{'licence_type' | myLbTranslate }}:</strong> {{licenceData.licenceType | titlecase}}
        <p>
        <p><strong>{{'licence_serial_number' | myLbTranslate}}:</strong> {{licenceData.serial}}</p>
        <p><strong>{{'licence_model' | myLbTranslate}}:</strong> {{ licenceModelName | uppercase}}</p>
        <p><strong>{{'platform' | myLbTranslate}}:</strong> {{licenceData.platform | titlecase}}</p>
        <p><strong>{{'licence_support_ends_on' | myLbTranslate}}:</strong> {{licenceData.supportExpireDate}}</p>
        <p><strong>{{'gslb' | myLbTranslate}}:</strong> {{ gslbEnabled }}</p>
    </div>
    <form [formGroup]="licenceForm">
        <div class="form-group">
            <label>{{'licence_update_key' | myLbTranslate}}</label>
            <div class="form-content">
                <input id="licencekeyarea" title="licencekeyarea" formControlName="licenceKey">
            </div>
        </div>
    </form>
    <div class="questions-wrapper">
        <p class="question">{{'licence_question' | myLbTranslate}}</p>
        <p class="question-description">{{'licence_question_description' | myLbTranslate}}</p>
        <button class="primary" (click)="contactSales()">
            {{ 'contact_sales' | myLbTranslate }}
        </button>
    </div>
</div>
<div class="component-footer">
    <app-loading-button
        buttonClass="primary"
        buttonText="submit"
        [isDisabled]="this.licenceForm.value.licenceKey === null || this.licenceForm.value.licenceKey === ''"
        [callBack]="updateLicence.bind(this)">
    </app-loading-button>
</div>
