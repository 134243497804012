<ng-container *ngIf="!hasTier else page">
    <app-alternative-state-page
        headerIcon="assets/centralisedManagement/icons/subscription_required.svg"
        header="accounts_sso_upgrade_title"
        messageString="accounts_sso_upgrade_message"
        imagePath="assets/centralisedManagement/empty-state/samlNoTier.svg"
        [redirectToButton]="true">
    </app-alternative-state-page>
</ng-container>
<ng-template #page>
    <div class="component-main">
        <div class="content-space-between">
            <h1 id="component-title">{{ 'accounts_single-sign-on' | myLbTranslate }} {{ 'settings' | myLbTranslate }}</h1>
        </div>
        <h3>{{ 'accounts_sso_message' | myLbTranslate }}</h3>
        <ng-container *ngIf="!ssoProvider else selectedSso">
            <div class="auth">
                <div class="content-space-between">
                    <img
                        ngSrc="assets/centralisedManagement/icons/sso-google.svg"
                        alt="Google single sign on SSO icon"
                        height="20"
                        width="21">
                    <span class="sso-name">Google</span>
                    <button class="primary" (click)="linkToSSOProvider('google')">{{ 'connect' | myLbTranslate }}
                    </button>
                </div>
                <div class="content-space-between">
                    <img
                        ngSrc="assets/centralisedManagement/icons/sso-azureadv2.svg"
                        alt="Azure single sign on SSO icon"
                        height="20"
                        width="21">
                    <span class="sso-name">Azure AD</span>
                    <button class="primary" (click)="linkToSSOProvider('azureadv2')">{{ 'connect' | myLbTranslate }}
                    </button>
                </div>
            </div>
        </ng-container>
        <ng-template #selectedSso>
            <div class="form-group">
                <div class="content-space-between">
                    <img
                        src="assets/centralisedManagement/icons/sso-{{ssoProvider}}.svg"
                        alt="{{ssoProvider}} single sign on SSO icon">
                    <span class="sso-name">{{ ssoProvider | titlecase }}</span>
                    <span class="userLogin" title="userLogin">{{ userLogin }}</span>
                    <button id="accounts-sso-remove-link" class="primary" (click)="removeLinkToSSOProvider()">
                        {{ 'disconnect' | myLbTranslate }}
                    </button>
                </div>
                <app-help tag="accounts-sso_disconnect"></app-help>
            </div>
        </ng-template>
    </div>
</ng-template>
