<div [hidden]="loading" class="component-main no-padding">
    <div class="ticket">
        <div class="ticket-left">
            <div class="ticket-header">
                <h1 class="component-title">Ticket #{{ ticketDetails.id }}</h1>
            </div>
            <table class="ticket-items">
                <tr *ngIf="ticketDetails.created">
                    <th>{{ 'date' | myLbTranslate }}</th>
                    <td>{{ ticketDetails.created | titlecase }}</td>
                </tr>
                <tr *ngIf="ticketDetails.priority">
                    <th>{{ 'priority' | myLbTranslate | titlecase }}</th>
                    <td>{{ ticketDetails.priority | titlecase }}</td>
                </tr>
                <tr *ngIf="ticketDetails.type">
                    <th>{{ 'ticket_type' | myLbTranslate }}</th>
                    <td>{{ ticketDetails.type | titlecase }}</td>
                </tr>
                <tr *ngIf="ticketDetails.product">
                    <th>{{ 'product' | myLbTranslate }}</th>
                    <td>{{ ticketDetails.product | titlecase }}</td>
                </tr>
                <tr *ngIf="ticketDetails.adcName">
                    <th>{{ 'adc_name' | myLbTranslate }}</th>
                    <td>{{ ticketDetails.adcName | titlecase }}</td>
                </tr>
                <tr>
                    <th>{{ 'status' | myLbTranslate }}</th>
                    <td>{{ ticketDetails.status | titlecase }}</td>
                </tr>
            </table>
            <button *ngIf="ticketDetails.status !== 'closed'"
                    id="ticket-conversation-close-ticket"
                    class="outline"
                    (click)="closeTicket()">
                {{ 'support_close_ticket' | myLbTranslate }}
            </button>
        </div>
        <div class="ticket-right">
            <div class="ticket-header">
                <h1 class="component-title">{{ ticketDetails.subject }}</h1>
            </div>
            <div #commentWrapper class="comments-wrapper">
                <ng-container *ngFor="let comment of comments">
                    <div class="response">
                        <div class="{{comment.isLbReply ? 'reply' : 'author' }}">
                            <div class="content-top user">
                                <div class="mock-user"></div>
                            </div>
                            <div class="content-top-vertical">
                                <div class="content-space-between">
                                    <h4>{{ comment.author }}</h4>
                                    <span>{{ comment.timeStamp }}</span>
                                </div>
                                <div class="comment" [innerHTML]="comment.html"></div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div *ngIf="pagination?.nextParameter" class="content-center-middle">
                <button class="primary" (click)="backPage()">{{ 'back' | myLbTranslate }}</button>
                <button class="primary" [disabled]="!pagination.showMoreRows" (click)="nextPage()">
                    {{ 'next' | myLbTranslate }}
                </button>
            </div>
            <div class="ticket-footer" *ngIf="ticketDetails.status !== 'closed'">
                <form [formGroup]="upDateMessageForm" appAutoFocus="true">
                    <app-editor
                        editorControlName="message"
                        [formGroup]="upDateMessageForm">
                    </app-editor>
                </form>
                <button id="ticket-create-cancel" class="outline" routerLink="/cm/support/tickets">
                    {{ 'cancel' | myLbTranslate }}
                </button>
                <app-loading-button
                    buttonId="ticket-conversation-submit"
                    buttonClass="primary"
                    buttonText="submit"
                    [callBack]="updateTicket.bind(this)"
                    [isDisabled]="upDateMessageForm.pristine || upDateMessageForm.invalid">
                </app-loading-button>
            </div>
            <div class="component-footer ticket-footer" *ngIf="ticketDetails.status === 'closed'">
                <button class="outline" routerLink="/cm/support/tickets">
                    {{ 'cancel' | myLbTranslate }}
                </button>
            </div>
        </div>
    </div>
</div>

