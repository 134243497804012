<div *ngIf="isLoading">
    <h2>{{ 'loading' | myLbTranslate }}</h2>
</div>
<router-outlet appScreenCapture (activate)="onActivate()"></router-outlet>
<app-confirm-box></app-confirm-box>
<app-parent-modal></app-parent-modal>
<app-content-overlay></app-content-overlay>
<!-- Notifications -->
<app-notification-toast-wrapper></app-notification-toast-wrapper>
<div class="small-screen-overlay">
    <div class="text-wrapper">
        <app-logo></app-logo>
        <p>{{ environmentName | titlecase }} {{ 'app_overlay_message' | myLbTranslate }}</p>
    </div>
</div>
