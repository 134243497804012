<div class="component-main no-bottom-margin">
    <div class="content-space-between">
        <div class="content-left">
            <h1 id="component-title">{{ 'adc_shuttle_edit' | myLbTranslate }}</h1>
            <ng-container *ngIf="formGroup.get('locked').value">
                <img ngSrc="/assets/shared/icons/locked.svg" alt="locked" height="20" width="19">
            </ng-container>
        </div>
    </div>
    <div class="chart-wrapper">
        <app-chart [data]="chartData"></app-chart>
        <app-dropdown
            dropDownId="shuttle-gateways"
            [displayAsInput]="true"
            [contentWidth]="180"
            [content]="dropdown.gateways"
            [selectedKey]="selectedKey"
            (keyOut)="setChartData($event)">
        </app-dropdown>
    </div>
    <app-tab-container tabsAlignment="left" (eventEmitter)="setActiveTabId($event)">
        <app-tab tabTitle="overview" id="overview">
            <form [formGroup]="formGroup">
                <div class="form-group">
                    <label for="adc-edit-label">{{ 'adc_shuttle_name' | myLbTranslate }}</label>
                    <div class="form-content">
                        <input
                            id="adc-edit-label"
                            formControlName="label"
                            title="ADC label"
                            type="text">
                        <app-validation-messages [control]="formGroup.get('label')"></app-validation-messages>
                    </div>
                </div>
                <div class="form-group">
                    <!--suppress XmlInvalidId -->
                    <label for="adc-edit-namespace">{{ 'namespace' | myLbTranslate }}</label>
                    <div class="form-content">
                        <app-dropdown
                            dropDownId="adc-edit-namespace"
                            [formGroup]="formGroup"
                            formControlName="namespaceId"
                            [content]="namespaceDropdown">
                        </app-dropdown>
                        <app-validation-messages [control]="formGroup.get('namespaceId')"></app-validation-messages>
                    </div>
                </div>
                <div class="form-group">
                    <label for="adc-edit-notes">{{ 'notes' | myLbTranslate }}</label>
                    <div class="form-content">
                        <textarea id="adc-edit-notes" formControlName="notes"></textarea>
                        <app-validation-messages [control]="formGroup.get('notes')"></app-validation-messages>
                    </div>
                </div>
                <div class="form-group">
                    <label for="adc-edit-tags">{{ 'tags' | myLbTranslate }}</label>
                    <div class="form-content">
                        <app-adc-tags
                            id="adc-edit-tags"
                            [tags]="formGroup.get('tags').value"
                            [disableForm]="formGroup.get('tags').disabled"
                            (selectedTags)="setTagsFormValue($event)">
                        </app-adc-tags>
                    </div>
                </div>
                <div class="form-group">
                    <label>{{ 'version' | myLbTranslate }}</label>
                    <div class="form-content">
                        <span>{{ shuttleDetails?.version }}</span>
                    </div>
                </div>
                <div class="form-group">
                    <label>{{ 'ip' | myLbTranslate }}</label>
                    <div class="form-content">
                        <span>{{ shuttleDetails?.ip }}</span>
                    </div>
                </div>
            </form>
        </app-tab>
        <app-tab tabTitle="subnets" id="subnets">
            <div class="inline-with-table-search">
                <button class="primary" [disabled]="formLocked || disableSubnetCrud" (click)="navigateToAddPage()">
                    {{ 'adc_shuttle_edit_subnet_add' | myLbTranslate }}
                </button>
            </div>
            <app-ng-table
                name="edit-shuttle-subnets-list"
                [data]="tableRows"
                [allowMultiSelect]="false"
                [showPaginator]="false"
                [searchTerm]="searchTerm">
                <app-ng-column [flexGrow]="1" prop="subnetLabel" label="label"></app-ng-column>
                <app-ng-column [flexGrow]="1" prop="subnet" label="cidr"></app-ng-column>
                <app-ng-column [flexGrow]="1" prop="gateway" label="gateway"></app-ng-column>
                <app-ng-column prop="actions" label="" [flexGrow]="1" [customCellTemplate]="adcDetailsTemplate">
                    <ng-template let-row #adcDetailsTemplate>
                        <app-action-dots>
                            <button
                                class="edit-shuttle-subnet"
                                [disabled]="formLocked || disableSubnetCrud"
                                (click)="navigateToEditPage(row?.details?.id)">
                                {{ 'adc_shuttle_edit_subnet_edit' | myLbTranslate }}
                            </button>
                            <button
                                class="adcs-shuttle-subnet_remove"
                                [disabled]="formLocked || disableSubnetCrud|| disableDeleteSubnet"
                                (click)="confirmDeleteSubnet(row)">
                                {{ 'adc_shuttle_edit_subnet_delete' | myLbTranslate }}
                            </button>
                        </app-action-dots>
                    </ng-template>
                </app-ng-column>
            </app-ng-table>
        </app-tab>
        <app-tab tabTitle="security" id="security">
            <form [formGroup]="formGroup">
                <div class="form-group">
                    <div class="form-content">
                        <input id="edit-adc-lock" formControlName="locked" class="lb-checkbox" type="checkbox">
                        <span>{{ 'adc_shuttle_edit_lock_message' | myLbTranslate }}</span>
                    </div>
                </div>
            </form>
        </app-tab>
    </app-tab-container>
</div>

<div class="component-footer">
    <button id="adc-edit-cancel" class="outline" routerLink="/cm/adcs/shuttle">
        {{ 'cancel' | myLbTranslate }}
    </button>
    <app-loading-button
        buttonId="adc-edit-new-submit"
        [isDisabled]="formGroup.invalid || formGroup.pristine"
        buttonText="submit"
        buttonClass="primary"
        [callBack]="updateShuttle.bind(this)">
    </app-loading-button>
</div>
