<ng-template #measurements>
    <h6 *ngIf="showPercentage" class="progress-percentage">{{ percentDone }}%</h6>
    <h6 *ngIf="showTransferRate" class="progress-percentage">{{ transferRate }}KB/s</h6>
    <h6 *ngIf="showTimeRemaining" class="progress-percentage">{{ timeRemaining }}</h6>
</ng-template>
<div class="progress-wrapper">
    <ng-container *ngIf="spinner">
        <p>{{ progressPrefixText | myLbTranslate }} <span class="file-url">{{ fileName }}</span></p>
        <ng-template [ngTemplateOutlet]="measurements"></ng-template>
        <img
            class="loader"
            ngSrc="assets/shared/icons/loading-spinner-primary.svg"
            alt="loading spinner"
            height="20"
            width="20"/>
    </ng-container>
    <ng-container *ngIf="bar">
        <div class="progress-bar">
            <div class="progress-line" [style.width.%]="percentDone"></div>
        </div>
        <ng-template [ngTemplateOutlet]="measurements"></ng-template>
    </ng-container>
    <img
        id="user-cancel"
        ngSrc="./assets/shared/icons/close-cross.svg"
        height="12"
        width="12"
        alt="{{ 'cancel' | myLbTranslate }}"
        (click)="userCancel()"/>
</div>
