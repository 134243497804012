<app-external-page>
    <app-external-page-content-body>
        <form id="user-form" [formGroup]="createAccountForm">
            <div *ngIf="activeStep === 1" formGroupName="step1">
                <h1 class="welcome-title">{{ 'activate_marketing_title' | myLbTranslate }}</h1>
                <div class="form-group">
                    <label id="activation-passcode-label">
                        {{ 'verify' | myLbTranslate }} {{ 'email' | myLbTranslate | lowercase }}*
                    </label>
                    <div class="form-content">
                        <input formControlName="email" id="verifyEmail" type="email"/>
                        <app-validation-messages
                            [control]="createAccountForm.get('step1.email')"></app-validation-messages>
                        <app-help class="help-button" tag="activate-account-marketing_email"></app-help>
                    </div>
                </div>
                <div class="form-group">
                    <label id="password-label" for="password">
                        {{ 'password' | myLbTranslate }}*
                    </label>
                    <div class="form-content">
                        <input
                            name="password"
                            formControlName="password"
                            id="password"
                            type="password"
                            autocomplete="new-password"/>
                        <app-validation-messages [control]="createAccountForm.get('step1.password')">
                        </app-validation-messages>
                    </div>
                </div>
                <div class="form-group">
                    <label id="confirm-password-label" for="confirm-password">
                        {{ 'password_confirm' | myLbTranslate }}*
                    </label>
                    <div class="form-content">
                        <input
                            name="password"
                            formControlName="confirmPassword"
                            id="confirm-password"
                            type="password"
                            autocomplete="new-password">
                        <app-validation-messages [control]="createAccountForm.get('step1.confirmPassword')">
                        </app-validation-messages>
                    </div>
                </div>
            </div>
            <div *ngIf="activeStep === 2" formGroupName="step2">
                <h1 class="welcome-title">{{ 'accounts_recovery_download_key' | myLbTranslate }}</h1>
                <app-private-key
                    [keyName]="privateKeyName"
                    [userPassword]="createAccountForm.get('step1.password').value"
                    (keyDecrypted)="downloadKeyAndValidate($event)">
                </app-private-key>
            </div>
        </form>
    </app-external-page-content-body>

    <app-external-page-content-footer>
        <ng-container *ngIf="activeStep === 1">
            <app-loading-button
                buttonId="activate-account"
                buttonClass="outline"
                buttonText="next"
                [isDisabled]="createAccountForm.get('step1').invalid || createAccountForm.get('step1').pristine"
                [callBack]="generateKeysForNextStep.bind(this)">
            </app-loading-button>
        </ng-container>
        <ng-container *ngIf="activeStep === 2">
            <app-loading-button
                buttonId="sign-in"
                buttonClass="outline"
                buttonText="activate_account_complete"
                [isDisabled]="createAccountForm.invalid || createAccountForm.pristine"
                (click)="activateAccount()"> <!-- this isn't an observable -->
            </app-loading-button>
        </ng-container>
    </app-external-page-content-footer>
</app-external-page>
