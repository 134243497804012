<div [class.component-main]="!componentEmbedded">
    <div class="content-space-between embedded">
        <h2>{{ 'add_cert' | myLbTranslate }}</h2>
    </div>
    <form class="ssl-certificates-form" [formGroup]="sslCertificatesForm">
        <div class="form-group">
            <!--suppress XmlInvalidId -->
            <label for="ssl-certs">{{ 'service' | myLbTranslate }}</label>
            <div class="form-content">
                <app-dropdown
                    dropDownId="ssl-certs"
                    [formGroup]="sslCertificatesForm"
                    formControlName="sslCertificates"
                    [content]="dropdown.certificates"
                    [dropdownWidth]="240">
                </app-dropdown>
                <app-validation-messages [control]="sslCertificatesForm.controls['sslCertificates']">
                </app-validation-messages>
            </div>
        </div>
        <div class="form-group" [formGroup]="sslCertificatesForm">
            <!--suppress XmlInvalidId -->
            <label for="cipher-list">{{ 'cipher' | myLbTranslate }}</label>
            <div class="form-content">
                <app-dropdown
                    dropDownId="cipher-list"
                    [formGroup]="sslCertificatesForm"
                    formControlName="ciphers"
                    [content]="updateCipherListDropdown()"
                    [dropdownWidth]="240">
                </app-dropdown>
                <app-validation-messages [control]="sslCertificatesForm.controls['ciphers']"></app-validation-messages>
            </div>
        </div>
    </form>
</div>
<div class="component-footer" [class.embedded]="componentEmbedded">
    <button class="outline" id="embedded-cancel-add" (click)="goBack()">
        {{ 'cancel' | myLbTranslate }}
    </button>
    <button
        class="primary"
        id="submit-cert"
        [disabled]="sslCertificatesForm.pristine || sslCertificatesForm.invalid"
        (click)="submitCert()">
        {{ 'add' | myLbTranslate }}
    </button>
</div>
