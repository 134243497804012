<div class="component-main">
    <h1>{{ 'l7_stick_table' | myLbTranslate }}</h1>
    <div style="text-align: right;margin-bottom: 10px">
        <button class="primary" (click)="refreshTable()">{{ 'l7_stick_table_refresh' | myLbTranslate }}</button>
    </div>

    <div class="dropdown-wrapper">
        <!--suppress XmlInvalidId -->
        <label for="sticktable-backends">{{ 'l7_stick_table_select_backend' | myLbTranslate }}</label>
        <app-dropdown
            dropDownId="sticktable-backends"
            [content]="backendsDropdown"
            [selectedKey]="selectedBackend"
            (keyOut)="updateTableForSelectedBackend($event)">
        </app-dropdown>
    </div>

    <app-search-rows [rows]="tableRows" [searchKeys]="searchKeys" (searchResults)="updateTableRows($event)">
    </app-search-rows>

    <app-table [rows]="tableRows" [columnMode]="'flex'">
        <app-table-column [flexGrow]="1" prop="statsid" name="ID"></app-table-column>
        <app-table-column [flexGrow]="1" prop="key" name="Key"></app-table-column>
        <app-table-column [flexGrow]="1" prop="use" name="Use"></app-table-column>
        <app-table-column [flexGrow]="1" prop="expires" name="Expires"></app-table-column>
        <app-table-column [flexGrow]="1" prop="riplabel" name="Server"></app-table-column>
        <app-table-column [flexGrow]="0.3" prop="actions" cellAlignment="right">
            <ng-template let-row="row" appLbTableColumnCell>
                <app-action-dots>
                    <button (click)="deleteEntry(row.key)">
                        {{ 'delete' | myLbTranslate }}
                    </button>
                </app-action-dots>
            </ng-template>
        </app-table-column>
    </app-table>
</div>
