<app-external-page>
    <app-external-page-content-body>
        <form id="user-form" [formGroup]="createAccountForm" [class.no-help]="activeStep !== 1">
            <h1 class="welcome-title">{{ 'activate_account' | myLbTranslate }}</h1>
            <div *ngIf="activeStep === 1" formGroupName="step1">
                <div class="form-group">
                    <label id="activation-passcode-label">
                        {{ 'activate_activation_passcode' | myLbTranslate }}*
                    </label>
                    <div class="form-content">
                        <input formControlName="activationPasscode" id="activationPasscode" type="text"/>
                        <app-validation-messages [control]="createAccountForm.get('step1.activationPasscode')">
                        </app-validation-messages>
                        <app-help class="help-button" tag="activate-account_passcode"></app-help>
                    </div>
                </div>
            </div>
            <div *ngIf="activeStep === 2" formGroupName="step2">
                <div class="form-group">
                    <label id="firstName-label" for="firstName">{{ 'name_first' | myLbTranslate }}*</label>
                    <div class="form-content">
                        <input
                            formControlName="firstName"
                            id="firstName"
                            type="text"
                            autocomplete="new-password">
                        <app-validation-messages [control]="createAccountForm.get('step2.firstName')">
                        </app-validation-messages>
                    </div>
                </div>
                <div class="form-group">
                    <label id="last-name-label" for="lastName">{{ 'name_last' | myLbTranslate }}*</label>
                    <div class="form-content">
                        <input
                            formControlName="lastName"
                            id="lastName"
                            type="text"
                            autocomplete="new-password">
                        <app-validation-messages [control]="createAccountForm.get('step2.lastName')">
                        </app-validation-messages>
                    </div>
                </div>
                <div class="form-group">
                    <label id="password-label" for="password">{{ 'password' | myLbTranslate }}*</label>
                    <div class="form-content">
                        <input
                            name="password"
                            formControlName="password"
                            id="password"
                            type="password"
                            autocomplete="new-password"/>
                        <app-validation-messages [control]="createAccountForm.get('step2.password')">
                        </app-validation-messages>
                    </div>
                </div>
                <div class="form-group">
                    <label id="confirm-password-label" for="confirm-password">
                        {{ 'password_confirm' | myLbTranslate }}*
                    </label>
                    <div class="form-content">
                        <input
                            name="password"
                            formControlName="confirmPassword"
                            id="confirm-password"
                            type="password"
                            autocomplete="new-password">
                        <app-validation-messages [control]="createAccountForm.get('step2.confirmPassword')">
                        </app-validation-messages>
                    </div>
                </div>
            </div>
            <div *ngIf="activeStep === 3" formGroupName="step3">
                <app-private-key
                    [keyName]="privateKeyName"
                    [userPassword]="createAccountForm.get('step2.password').value"
                    (keyDecrypted)="downloadKeyAndValidate($event)">
                </app-private-key>
            </div>
        </form>
    </app-external-page-content-body>

    <app-external-page-content-footer>
        <ng-container *ngIf="activeStep === 1">
            <app-loading-button
                buttonId="activate-account-step1"
                buttonClass="primary"
                buttonText="next"
                [isDisabled]="createAccountForm.get('step1').invalid ||createAccountForm.get('step1').pristine"
                [callBack]="nextStep.bind(this)">
            </app-loading-button>
        </ng-container>
        <ng-container *ngIf="activeStep === 2">
            <app-loading-button
                buttonId="activate-account-step2"
                buttonClass="primary"
                buttonText="next"
                [isDisabled]="createAccountForm.get('step2').invalid || createAccountForm.get('step2').pristine"
                [callBack]="generateKeysForNextStep.bind(this)">
            </app-loading-button>
        </ng-container>
        <ng-container *ngIf="activeStep === 3">
            <app-loading-button
                buttonId="activate-account-step3"
                buttonClass="primary"
                buttonText="activate_account_complete"
                [isDisabled]="createAccountForm.invalid || createAccountForm.pristine"
                (click)="activateAccount()"> <!-- this isn't an observable -->
            </app-loading-button>
        </ng-container>
    </app-external-page-content-footer>
</app-external-page>
