<div class="component-main">
    <div class="content-space-between">
        <div class="content-left">
            <!--            <img src="assets/centralisedManagement/icons/pipelines.svg" alt="Pipelines icon">-->
            <h1 id="component-title">{{ 'pipelines' | myLbTranslate }}</h1>
        </div>
        <div class="content-right">
            <button
                id="adcs-list-add"
                [disabled]="!userCanEdit || true"
                routerLink="/cm/adcs/pipelines/new"
                class="primary">
                {{ 'pipeline_add' | myLbTranslate }}
            </button>
        </div>
    </div>
    <app-ng-table
        name="pipelines-list"
        [data]="tableRows"
        [showPaginator]="false"
        [allowMultiSelect]="false">
        <app-ng-column [flexGrow]="1" prop="label" label="label" [customCellTemplate]="label">
            <ng-template let-row #label>
                <span
                    class="routerLink"
                    id="pipelines-list-pipeline-details"
                    [routerLink]="'/cm/pipelines/' + row.id">
                    {{ row.label | titlecase }}
                </span>
            </ng-template>
        </app-ng-column>
        <app-ng-column [flexGrow]="1" prop="type" label="type" [customCellTemplate]="type">
            <ng-template let-row #type>
                {{ row.type | titlecase }}
            </ng-template>
        </app-ng-column>
        <app-ng-column [flexGrow]="1.5" prop="status" label="status" [customCellTemplate]="status">
            <ng-template let-row #status>
                <img
                    class="status-icon"
                    title="{{row.status}}"
                    src="assets/centralisedManagement/icons/{{row.status}}.svg"
                    alt="{{row.status}} Logo"/>
                {{ row.status | titlecase }}
            </ng-template>
        </app-ng-column>
        <app-ng-column [flexGrow]="1" prop="actions" label="" cellAlignment="right" [customCellTemplate]="actions">
            <ng-template let-row #actions>
                <ng-container>
                    <app-action-dots>
                        <button
                            class="">
                            {{ 'delete' | myLbTranslate }}
                        </button>
                    </app-action-dots>
                </ng-container>
            </ng-template>
        </app-ng-column>
    </app-ng-table>
</div>

