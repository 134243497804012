<div class="component-main">
    <div class="content">
        <h1 id="component-title">
            {{ (editId ? 'rbac_group_edit_role' : 'rbac_group_add_role') | myLbTranslate | titlecase }}
        </h1>
    </div>
    <form [formGroup]="formGroup" class="process-container" [class.full-width]="true">
        <div
            #step
            class="step"
            [class.active]="activeStep === 1"
            [class.complete]="activeStep > 1"
            formGroupName="step1">
            <h3>{{ 'rbac_group_details' | myLbTranslate }}</h3>
            <div class="step-content">
                <ng-container *ngIf="activeStep === 1">
                    <div class="form-group">
                        <label for="name">{{ 'rbac_group_name' | myLbTranslate }}*</label>
                        <div class="form-content">
                            <input
                                id="name"
                                formControlName="name"
                                name="name"
                                type="text">
                            <app-validation-messages [control]="formGroup.get('step1.name')"></app-validation-messages>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="activeStep > 1">
                    <div class="set-content">
                        <span>
                            <strong>{{ 'rbac_group_name' | myLbTranslate }}:</strong>
                            {{ formGroup.value['step1'].name }}
                        </span>
                        <span>
                            <button class="button-as-link quinary right-arrow" (click)="setStep(1)">
                                {{ 'edit' | myLbTranslate }}
                            </button>
                        </span>
                    </div>
                </ng-container>
            </div>
        </div>
        <div
            #step
            class="step"
            [class.active]="activeStep === 2"
            [class.complete]="activeStep > 2"
            formGroupName="step2">
            <h3>
                {{ 'rbac_group_assign_role_select' | myLbTranslate }}
                <span *ngIf="activeStep === 2" class="note">({{ 'optional' | myLbTranslate }})</span>
            </h3>
            <div class="step-content">
                <ng-container *ngIf="activeStep === 2">
                    <div
                        *ngFor="let role of getFormGroup('step2.roles').controls index as i"
                        formArrayName="roles"
                        class="form-group">
                        <div class="form-content">
                            <input
                                class="lb-checkbox"
                                type="checkbox"
                                name="role"
                                id="role{{[i]}}"
                                [formControl]="role['controls'].value"/>
                            <label for="role{{[i]}}">{{ role.value.name | titlecase }}</label>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="activeStep > 2">
                    <div class="set-content">
                        <span>
                            <strong>{{ 'rbac_group_assign_role_selected' | myLbTranslate }}:</strong>
                            {{ getSelectedRolesAsString(formGroup.value['step2'].roles) || ('none_set' | myLbTranslate) }}
                        </span>
                        <span>
                            <button class="button-as-link quinary right-arrow" (click)="setStep(2)">
                                {{ 'edit' | myLbTranslate }}
                            </button>
                        </span>
                    </div>
                </ng-container>
            </div>
        </div>
        <div
            #step
            class="step"
            [class.active]="activeStep === 3"
            [class.complete]="activeStep > 3"
            formGroupName="step3">
            <h3>
                {{ (activeStep > 3 ? 'rbac_group_management_selected' : 'rbac_group_management') | myLbTranslate }}
                <span *ngIf="activeStep === 3" class="note">({{ 'optional' | myLbTranslate }})</span>
                <span *ngIf="activeStep > 3" style="font-weight: normal">{{ getSelectedUserCount() }}</span>
            </h3>
            <div class="step-content">
                <div class="row expand-all">
                    <button class="button-as-link" *ngIf="enableToggleOfAccordions" (click)="toggleAccordions()">
                        {{ (expandAll ? 'rbac_roles_edit_collapse_all' : 'rbac_roles_edit_expand_all') | myLbTranslate }}
                    </button>
                </div>
                <ng-container *ngFor="let group of grouping; index as i">
                    <div class="group-wrapper">
                        <app-lb-accordion-group
                            [title]="group.friendlyName"
                            [titleWrapperHeight]="55"
                            [enableToggle]="enableToggleOfAccordions"
                            [open]="expandAll"
                            [forceClosed]="forceCollapseAll"
                            [fullWidth]="true">
                            <div class="checkbox-wrapper">
                                <div
                                    *ngFor="let user of filteredFormGroups[group.friendlyName] index as i"
                                    formArrayName="users"
                                    class="form-group">
                                    <div class="form-content">
                                        <input
                                            class="lb-checkbox"
                                            type="checkbox"
                                            name="user"
                                            id="user{{[i]}}"
                                            [formControl]="user['controls'].value"/>
                                        <label for="user{{[i]}}">
                                            {{ user.value.firstName + ' ' + user.value.lastName }}
                                        </label>
                                        <span class="note">{{ user.value.email }}</span>
                                    </div>
                                </div>
                            </div>
                        </app-lb-accordion-group>
                        <ng-container *ngIf="(activeStep > 3 || editId) && getCounterByGroup(group.friendlyName)">
                            <div class="counter">
                                <span>{{ getCounterByGroup(group.friendlyName) }} {{ 'selected' | myLbTranslate }}</span>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
        <div #step class="step" [class.active]="activeStep === 4" [class.complete]="activeStep > 4">
            <h3>{{ 'ready' | myLbTranslate }}</h3>
            <div class="step-content">
                <ng-container *ngIf="activeStep === 4">
                    <p>{{ (formGroup.pristine ? 'submit_disabled' : 'rbac_group_ready_message') | myLbTranslate }}</p>
                </ng-container>
            </div>
        </div>
    </form>
</div>
<div class="component-footer">
    <button id="group-cancel" class="outline" [routerLink]="editId ? '../../../list' : '../../list'">
        {{ 'cancel' | myLbTranslate }}
    </button>
    <button id="group-back" class="primary" [disabled]="isBackButtonDisabled()" (click)="backStep()">
        {{ 'back' | myLbTranslate }}
    </button>
    <button
        *ngIf="activeStep < 4"
        id="group-next"
        class="primary"
        [disabled]="!editId && isNextButtonDisabled()"
        (click)="nextStep()">
        {{ 'next' | myLbTranslate }}
    </button>
    <app-loading-button
        *ngIf="activeStep === 4"
        buttonId="group-submit"
        buttonClass="primary"
        buttonText="submit"
        [isDisabled]="formGroup.invalid || formGroup.pristine"
        [handleLoadingStateExternally]="true"
        [callBack]="submitHelper.bind(this)">
    </app-loading-button>
</div>

