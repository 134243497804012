import { NgModule } from '@angular/core';
import { LoadbalancerSharedModule } from '../../../shared/loadbalancer.shared.module';
import { GslbReportsComponent } from './gslb-reports.component';

@NgModule({
    imports: [
        LoadbalancerSharedModule
    ],
    declarations: [
        GslbReportsComponent,
    ],
    exports: [
    ]
})
export class GslbReportsModule {
}
