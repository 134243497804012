<div
    class="page-content-wrapper {{additionalClass}}"
    [class.force-show-logo]="!showMarketingBlock"
    [style.width.px]="contentWidth ? contentWidth : null"
    [style.height.px]="contentHeight ? contentHeight : null">
    <div class="logo-block">
        <img src="assets/shared/logos/logo-white-text.svg" routerLink="login" alt="Logo"/>
    </div>
    <div #marketingElement *ngIf="showMarketingBlock" id="external-marketing-block">
        <ng-container *ngIf="marketingBlock else defaultContent">
            <ng-container *ngTemplateOutlet="marketingBlock"></ng-container>
        </ng-container>
        <ng-template #defaultContent>
            <img src="assets/shared/logos/logo-white-text.svg" class="logo" routerLink="login" alt="Logo"/>
            <img src="assets/shared/images/external-page-image.svg" alt="loadbalancer connections image"/>
            <span class="marketing-footer">Smart. Flexible. Unbreakable.</span>
        </ng-template>
    </div>
    <div #contentElement id="external-content-wrapper">
        <div class="form-block" [class.has-footer]="footerHasContent">
            <ng-content select="app-external-page-content-body"></ng-content>
        </div>
        <div *ngIf="footerHasContent" class="form-block-footer" [style.height.px]="formBlockFooterHeight">
            <ng-content select="app-external-page-content-footer"></ng-content>
        </div>
    </div>
</div>

<div id="copyright-footer">
    <span>&copy; Loadbalancer.org, {{currentYear}} Inc. {{'external_all_rights_reserved' | myLbTranslate}}</span>
    <span class="divide">|</span>
    <a (click)="openPolicyModal('policies_terms_of_service')">{{'external_terms' | myLbTranslate}}</a>
    <span class="divide">|</span>
    <a (click)="openPolicyModal('policies_privacy')">{{'external_privacy' |myLbTranslate}}</a>
    <ng-container *ngIf="environment.module !== modulesEnum.LOADBALANCER">
        <span class="divide">|</span>
        <!--        <iframe id="better-up-time" src="https://status.loadbalancer.org/badge?theme=light"></iframe>-->
        <a href="https://status.loadbalancer.org/" class="status-link" target="_blank">
            {{'status' | myLbTranslate}}
        </a>
    </ng-container>
</div>

<app-modal-wrapper
    [isDraggable]="false"
    modalId="service"
    [modalHeight]="650"
    [modalWidth]="650"
    modalTitle="policies_terms_of_service"
    [showFooter]="false">
    <app-modal-body>
        <p>These Terms of Service govern your use of the website located at <a href="https://www.loadbalancer.org/">https://www.loadbalancer.org/</a>
            and any related services provided by Loadbalancer.org, Inc..
        </p>
        <p>By accessing <a href="https://www.loadbalancer.org/">https://www.loadbalancer.org/</a>, you agree to
            abide by these Terms of Service and to comply with all applicable laws and regulations. If you do not
            agree with these Terms of Service, you are prohibited from using or accessing this website or using any
            other services provided by Loadbalancer.org, Inc..
        </p>
        <p>We, Loadbalancer.org, Inc., reserve the right to review and amend any of these Terms of Service at our
            sole discretion. Upon doing so, we will update this page. Any changes to these Terms of Service will
            take effect immediately from the date of publication.</p>
        <p>These Terms of Service were last updated on 14 October 2022.</p>
        <h2>Limitations of Use</h2>
        <p>By using this website, you warrant on behalf of yourself, your users, and other parties you represent
            that you will not:</p>
        <ol>
            <li>modify, copy, prepare derivative works of, decompile, or reverse engineer any materials and software
                contained on this website;
            </li>
            <li>remove any copyright or other proprietary notations from any materials and software on this website;
            </li>
            <li>transfer the materials to another person or &ldquo;mirror&rdquo; the materials on any other server;
            </li>
            <li>knowingly or negligently use this website or any of its associated services in a way that abuses or
                disrupts our networks or any other service Loadbalancer.org, Inc. provides;
            </li>
            <li>use this website or its associated services to transmit or publish any harassing, indecent, obscene,
                fraudulent, or unlawful material;
            </li>
            <li>use this website or its associated services in violation of any applicable laws or regulations;</li>
            <li>use this website in conjunction with sending unauthorised advertising or spam;</li>
            <li>harvest, collect or gather user data without the user’s consent; or</li>
            <li>use this website or its associated services in such a way that may infringe the privacy,
                intellectual property rights, or other rights of third parties.
            </li>
        </ol>
        <h2>Intellectual Property</h2>
        <p>The intellectual property in the materials contained in this website are owned by or licensed to
            Loadbalancer.org, Inc. and are protected by applicable copyright and trademark law. We grant our users
            permission to download one copy of the materials for personal, non-commercial transitory use.
        </p>
        <p>This constitutes the grant of a license, not a transfer of title. This license shall automatically
            terminate if you violate any of these restrictions or the Terms of Service, and may be terminated by
            Loadbalancer.org, Inc. at any time.
        </p>
        <h2>User-Generated Content</h2>
        <p>You retain your intellectual property ownership rights over content you submit to us for publication on
            our website. We will never claim ownership of your content but we do require a license from you in order
            to use it.
        </p>
        <p>When you use our website or its associated services to post, upload, share or otherwise transmit content
            covered by intellectual property rights, you grant to us a non-exclusive, royalty-free, transferable,
            sub-licensable, worldwide license to use, distribute, modify, run, copy, publicly display, translate or
            otherwise create derivative works of your content in a manner that is consistent with your privacy
            preferences and our Privacy Policy.
        </p>
        <p>The license you grant us can be terminated at any time by deleting your content. However, to the extent
            that we (or our partners) have used your content in connection with commercial or sponsored content, the
            license will continue until the relevant commercial or post has been discontinued by us.</p>
        <h2>Liability</h2>
        <p>Our website and the materials on our website are provided on an 'as is' basis. To the extent permitted by
            law, Loadbalancer.org, Inc. makes no warranties, expressed or implied, and hereby disclaims and negates
            all other warranties including, without limitation, implied warranties or conditions of merchantability,
            fitness for a particular purpose, or non-infringement of intellectual property, or other violation of
            rights.
        </p>
        <p>In no event shall Loadbalancer.org, Inc. or its suppliers be liable for any consequential loss suffered
            or incurred by you or any third party arising from the use or inability to use this website or the
            materials on this website, even if Loadbalancer.org, Inc. or an authorised representative has been
            notified, orally or in writing, of the possibility of such damage.
        </p>
        <p>In the context of this agreement, &ldquo;consequential loss&rdquo; includes any consequential loss,
            indirect loss, real or anticipated loss of profit, loss of benefit, loss of revenue, loss of business,
            loss of goodwill, loss of opportunity, loss of savings, loss of reputation, loss of use and/or loss or
            corruption of data, whether under statute, contract, equity, tort (including negligence), indemnity or
            otherwise.
        </p>
        <p>Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability
            for consequential or incidental damages, these limitations may not apply to you.
        </p>
        <h2>Accuracy of Materials</h2>
        <p>The materials appearing on our website are not comprehensive and are for general information purposes
            only. Loadbalancer.org, Inc. does not warrant or make any representations concerning the accuracy,
            likely results, or reliability of the use of the materials on this website, or otherwise relating to
            such materials or on any resources linked to this website.
        </p>
        <h2>Links</h2>
        <p>Loadbalancer.org, Inc. has not reviewed all of the sites linked to its website and is not responsible
            for the contents of any such linked site. The inclusion of any link does not imply endorsement, approval
            or control by Loadbalancer.org, Inc. of the site. Use of any such linked site is at your own risk and we
            strongly advise you make your own investigations with respect to the suitability of those sites.
        </p>
        <h2>Right to Terminate</h2>
        <p>We may suspend or terminate your right to use our website and terminate these Terms of Service
            immediately upon written notice to you for any breach of these Terms of Service.
        </p>
        <h2>Severance</h2>
        <p>Any term of these Terms of Service which is wholly or partially void or unenforceable is severed to the
            extent that it is void or unenforceable. The validity of the remainder of these Terms of Service is not
            affected.
        </p>
        <h2>Governing Law</h2>
        <p>These Terms of Service are governed by and construed in accordance with the laws of United States. You
            irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
        </p>
    </app-modal-body>
</app-modal-wrapper>

<app-modal-wrapper
    [isDraggable]="false"
    modalId="privacy"
    [modalHeight]="500"
    [modalWidth]="650"
    modalTitle="policies_privacy"
    [showFooter]="false">
    <app-modal-body>
        <p>Your privacy is important to us. It is Loadbalancer.org, Inc.&#039;s policy to respect your privacy and
            comply with any applicable law and regulation regarding any personal information we may collect about
            you, including across our website, <a
                href="https://www.loadbalancer.org/">https://www.loadbalancer.org/</a>,and other sites we own and
            operate.
        </p>
        <p>Personal information is any information about you which can be used to identify you. This includes
            information about you as a person (such as name, address, and date of birth), your devices, payment
            details, and even information about how you use a website or online service.
        </p>
        <p>In the event our site contains links to third-party sites and services, please be aware that those sites
            and services have their own privacy policies. After following a link to any third-party content, you
            should read their posted privacy policy information about how they collect and use personal information.
            This Privacy Policy does not apply to any of your activities after you leave our site.
        </p>
        <p>This policy is effective as of 14 October 2022.</p>
        <p>Last updated: 14 October 2022</p>
        <h2>Information We Collect</h2>
        <p>Information we collect falls into one of two categories: 'voluntarily provided' information and
            'automatically collected' information.
        </p>
        <p>'Voluntarily provided' information refers to any information you knowingly and actively provide us when
            using or participating in any of our services and promotions.
        </p>
        <p>'Automatically collected' information refers to any information automatically sent by your devices in the
            course of accessing our products and services.
        </p>
        <h2>Log Data</h2>
        <p>When you visit our website, our servers may automatically log the standard data provided by your web
            browser. It may include your device’s Internet Protocol (IP) address, your browser type and version, the
            pages you visit, the time and date of your visit, the time spent on each page, and other details about
            your
            visit.
        </p>
        <p>Additionally, if you encounter certain errors while using the site, we may automatically collect data
            about the error and the circumstances surrounding its occurrence. This data may include technical
            details about your device, what you were trying to do when the error happened, and other technical
            information relating to the problem. You may or may not receive notice of such errors, even in the
            moment they occur, that they have occurred, or what the nature of the error is.
        </p>
        <p>Please be aware that while this information may not be personally identifying by itself, it may be
            possible
            to combine it with other data to personally identify individual persons.
        </p>
        <h2>Device Data</h2>
        <p>When you visit our website or interact with our services, we may automatically collect data about your
            device, such as:
        </p>
        <ul>
            <li>Device Type</li>
            <li>Operating System</li>
        </ul>
        <p>Data we collect can depend on the individual settings of your device and software. We recommend checking
            the policies of your device manufacturer or software provider to learn what information they make
            available to us.
        </p>
        <h2>User-Generated Content</h2>
        <p>
            We consider 'user-generated content' to be materials (text, image and/or video content) voluntarily
            supplied to us by our users for the purpose of publication, processing, or usage on our platform. All
            user-generated content is associated with the account or email address used to submit the materials.
        </p>
        <p>Please be aware that any content you submit for the purpose of publication will be public after posting
            (and subsequent review or vetting process). Once published it may be accessible to third parties not
            covered under this privacy policy.
        </p>
        <h2>Transaction Data</h2>
        <p>Transaction data refers to data that accumulates over the normal course of operation on our platform.
            This may include transaction records, stored files, user profiles, analytics data and other metrics, as
            well as other types of information, created or generated, as users interact with our services.
        </p>
        <h2>Collection and Use of Information</h2>
        <p>We may collect personal information from you when you do any of the following on our website:
        </p>
        <ul>
            <li>Purchase a subscription</li>
            <li>Use a mobile device or web browser to access our content</li>
            <li>Contact us via email, social media, or on any similar technologies</li>
            <li>When you mention us on social media</li>
        </ul>
        <p>We may combine voluntarily provided and automatically collected personal information with general
            information or research data we receive from other trusted sources. For example, If you provide us with
            your location, we may combine this with general information about currency and language to provide you
            with an enhanced experience of our site and service.
        </p>
        <h2>Security of Your Personal Information</h2>
        <p>When we collect and process personal information, and while we retain this information, we will protect
            it within commercially acceptable means to prevent loss and theft, as well as unauthorised access,
            disclosure, copying, use or modification.
        </p>
        <p>Although we will do our best to protect the personal information you provide to us, we advise that no
            method of electronic transmission or storage is 100% secure and no one can guarantee absolute data
            security.
        </p>
        <p>You are responsible for selecting any password and its overall security strength, ensuring the security
            of your own information within the bounds of our services. For example, ensuring you do not make your
            personal information publicly available via our platform.
        </p>
        <h2>How Long We Keep Your Personal Information</h2>
        <p>We keep your personal information only for as long as we need to. This time period may depend on what we
            are using your information for, in accordance with this privacy policy. For example, if you have
            provided us with personal information such as an email address when contacting us about a specific
            enquiry, we may retain this information for the duration of your enquiry remaining open as well as for
            our own records so we may effectively address similar enquiries in future. If your personal information
            is no longer required for this purpose, we will delete it or make it anonymous by removing all details
            that identify you.
        </p>
        <p>However, if necessary, we may retain your personal information for our compliance with a legal,
            accounting, or reporting obligation or for archiving purposes in the public interest, scientific, or
            historical research purposes or statistical purposes.
        </p>
        <h2>Children’s Privacy</h2>
        <p>We do not aim any of our products or services directly at children under the age of 13 and we do not
            knowingly collect personal information about children under 13.
        </p>
        <h2>Disclosure of Personal Information to Third Parties</h2>
        <p>We may disclose personal information to:</p>
        <ul>
            <li>a parent, subsidiary or affiliate of our company</li>
            <li>third-party service providers for the purpose of enabling them to provide their services including
                (without limitation) IT service providers, data storage, hosting and server providers, ad networks,
                analytics, error loggers, debt collectors, maintenance or problem-solving providers, marketing or
                advertising providers, professional advisors, and payment systems operators
            </li>
            <li>our employees, contractors, and/or related entities</li>
            <li>our existing or potential agents or business partners</li>
            <li>credit reporting agencies, courts, tribunals, and regulatory authorities, in the event you fail to
                pay for goods or services we have provided to you
            </li>
            <li>courts, tribunals, regulatory authorities, and law enforcement officers, as required by law, in
                connection with any actual or prospective legal proceedings, or in order to establish, exercise, or
                defend our legal rights
            </li>
            <li>third parties, including agents or sub-contractors who assist us in providing information, products,
                services, or direct marketing to you
            </li>
            <li>third parties to collect and process data</li>
            <li>an entity that buys, or to which we transfer all or substantially all of our assets and business
            </li>
        </ul>
        <p>Third parties we currently use include:</p>
        <ul>
            <li>Google Analytics</li>
            <li>Hubspot</li>
            <li>Intercom</li>
            <li>Hubspot</li>
            <li>Google Adsense</li>
            <li>Stripe</li>
        </ul>
        <h2>International Transfers of Personal Information</h2>
        <p>The personal information we collect is stored and/or processed in United Kingdom, or where we or our
            partners, affiliates, and third-party providers maintain facilities.
        </p>
        <p>The countries to which we store, process, or transfer your personal information may not have the same
            data protection laws as the country in which you initially provided the information. If we transfer your
            personal information to third parties in other countries: (i) we will perform those transfers in
            accordance with the requirements of applicable law; and (ii) we will protect the transferred personal
            information in accordance with this privacy policy.
        </p>
        <h2>Your Rights and Controlling Your Personal Information</h2>
        <p><strong>Your choice:</strong> By providing personal information to us, you understand we will collect,
            hold, use, and disclose your personal information in accordance with this privacy policy. You do not
            have to provide personal information to us, however, if you do not, it may affect your use of our
            website or the products and/or services offered on or through it.
        </p>
        <p><strong>Information from third parties:</strong> If we receive personal information about you from a
            third party, we will protect it as set out in this privacy policy. If you are a third party providing
            personal information about somebody else, you represent and warrant that you have such person’s consent
            to provide the personal information to us.
        </p>
        <p><strong>Marketing permission:</strong> If you have previously agreed to us using your personal
            information for direct marketing purposes, you may change your mind at any time by contacting us using
            the details below.
        </p>
        <p><strong>Access:</strong> You may request details of the personal information that we hold about you.</p>
        <p><strong>Correction:</strong> If you believe that any information we hold about you is inaccurate, out of
            date, incomplete, irrelevant, or misleading, please contact us using the details provided in this
            privacy policy. We will take reasonable steps to correct any information found to be inaccurate,
            incomplete, misleading, or out of date.
        </p>
        <p><strong>Non-discrimination:</strong> We will not discriminate against you for exercising any of your
            rights over your personal information. Unless your personal information is required to provide you with
            a particular service or offer (for example processing transaction data), we will not deny you goods or
            services and/or charge you different prices or rates for goods or services, including through granting
            discounts or other benefits, or imposing penalties, or provide you with a different level or quality of
            goods or services.
        </p>
        <p><strong>Notification of data breaches:</strong> We will comply with laws applicable to us in respect of
            any data breach.
        </p>
        <p><strong>Complaints:</strong> If you believe that we have breached a relevant data protection law and wish
            to make a complaint, please contact us using the details below and provide us with full details of the
            alleged breach. We will promptly investigate your complaint and respond to you, in writing, setting out
            the outcome of our investigation and the steps we will take to deal with your complaint. You also have
            the right to contact a regulatory body or data protection authority in relation to your complaint.
        </p>
        <p><strong>Unsubscribe:</strong> To unsubscribe from our email database or opt-out of communications
            (including marketing communications), please contact us using the details provided in this privacy
            policy, or opt-out using the opt-out facilities provided in the communication. We may need to request
            specific information from you to help us confirm your identity.
        </p>
        <h2>Use of Cookies</h2>
        <p>We use 'cookies' to collect information about you and your activity across our site. A cookie is a small
            piece of data that our website stores on your computer, and accesses each time you visit, so we can
            understand how you use our site. This helps us serve you content based on preferences you have
            specified.
        </p>
        <p>Please refer to our Cookie Policy for more information.</p>
        <h2>Business Transfers</h2>
        <p>If we or our assets are acquired, or in the unlikely event that we go out of business or enter
            bankruptcy, we would include data, including your personal information, among the assets transferred to
            any parties who acquire us. You acknowledge that such transfers may occur, and that any parties who
            acquire us may, to the extent permitted by applicable law, continue to use your personal information
            according to this policy, which they will be required to assume as it is the basis for any ownership or
            use rights we have over such information.</p>
        <h2>Limits of Our Policy</h2>
        <p>Our website may link to external sites that are not operated by us. Please be aware that we have no
            control over the content and policies of those sites, and cannot accept responsibility or liability for
            their respective privacy practices.</p>
        <h2>Changes to This Policy</h2>
        <p>At our discretion, we may change our privacy policy to reflect updates to our business processes, current
            acceptable practices, or legislative or regulatory changes. If we decide to change this privacy policy,
            we will post the changes here at the same link by which you are accessing this privacy policy.
        </p>
        <p>If required by law, we will get your permission or give you the opportunity to opt in to or opt out of,
            as applicable, any new uses of your personal information.
        </p>
        <h2>Contact Us</h2>
        <p>For any questions or concerns regarding your privacy, you may contact us using the following details:</p>
        <p>Joshua Turnbull<br/><a href="mailto:info@loadbalancer.org">info@loadbalancer.org</a>/+1 833 274 2566</p>
    </app-modal-body>
</app-modal-wrapper>
