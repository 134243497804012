<div class="component-main">
    <div class="content">
        <h1 id="component-title">{{ 'create' | myLbTranslate }} {{ 'ticket' | myLbTranslate }}</h1>
    </div>
    <form [formGroup]="ticketForm">
        <div class="form-group">
            <!--suppress XmlInvalidId -->
            <label for="ticket-type">{{ 'ticket_type' | myLbTranslate }}</label>
            <div class="form-content">
                <app-dropdown
                    dropDownId="ticket-type"
                    [formGroup]="ticketForm"
                    formControlName="type"
                    [content]="dropdown.type">
                </app-dropdown>
                <app-validation-messages [control]="ticketForm.get('type')"></app-validation-messages>
            </div>
        </div>
        <div class="form-group">
            <label>{{ 'product' | myLbTranslate }}</label>
            <div class="form-content">
                <app-dropdown
                    dropDownId="product-type"
                    [formGroup]="ticketForm"
                    formControlName="product"
                    [content]="dropdown.product">
                </app-dropdown>
                <app-validation-messages [control]="ticketForm.get('product')"></app-validation-messages>
            </div>
        </div>
        <div class="form-group">
            <label>{{ 'subject' | myLbTranslate }}</label>
            <div class="form-content">
                <input type="text" formControlName="subject"/>
                <app-validation-messages [control]="ticketForm.get('subject')"></app-validation-messages>
            </div>
        </div>
        <app-editor
            editorControlName="comment"
            [formGroup]="ticketForm"
            [editorStyles]="editorStyles">
        </app-editor>
    </form>
</div>
<div class="component-footer">
    <button id="ticket-create-cancel" class="outline" routerLink="/cm/support/tickets">
        {{ 'cancel' | myLbTranslate }}
    </button>
    <app-loading-button
        buttonId="ticket-create-submit"
        buttonClass="primary"
        buttonText="submit"
        [callBack]="submitTicket.bind(this)"
        [isDisabled]="!permissionsLookup['ticket-create-submit'] || ticketForm.pristine || ticketForm.invalid">
    </app-loading-button>
</div>
