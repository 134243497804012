<div id="paginator-container" [hidden]="!datasource?.data?.length">
    <div class="paginator-group-1">
        <div *ngIf="pageSizeDropdown?.length" class="items-per-page">
            <div class="items-dropdown">
                <span class="items-per-page-label">{{ 'items_per_page' | myLbTranslate }}: </span>
                <app-dropdown
                    dropDownId="items-per-page"
                    [displayAsInput]="true"
                    [content]="pageSizeDropdown"
                    [dropdownWidth]="100"
                    [selectedKey]="5"
                    (keyOut)="updatePage($event)">
                </app-dropdown>
            </div>
            <span class="items-count"> {{ pageStart }}
                - {{ pageEnd }} {{ 'of' | myLbTranslate }} {{ datasource.filteredData.length }}</span>
        </div>
        <div class="csv-download">
            <button class="button-as-link" (click)="downloadCSV()">
                {{ 'download_csv' | myLbTranslate }}
            </button>
        </div>
    </div>

    <div class="paginator-section-2 content-space-between">
        <div class="items-selected">
            <span>{{ 'selected' | myLbTranslate }}: {{ selectedRows.length }}
                /{{ datasource.filteredData.length }} {{ 'total' | myLbTranslate }}</span>
        </div>
        <div class="paginator-range">
            <button [disabled]="pageIndex === 0" class="page" (click)="previousPage()">
                <i [class.isFirstPage]="pageIndex === 0" class="fas fa-chevron-left"></i>
                <span class="previous" [class.isFirstPage]="pageIndex === 0">Previous</span>
            </button>
            <button class="page page-number" [class.selected]="pageIndex === 0" (click)="goToPage(1)">1</button>
            <button
                *ngIf="showPreviousEllipsis"
                class="ellipsis"
                (click)="shiftBack()">...
            </button>
            <ng-container *ngFor="let number of visiblePageNumbers">
                <button
                    [class.selected]="pageIndex === number - 1"
                    class="page page-number"
                    (click)="goToPage(number)">{{ number }}
                </button>
            </ng-container>
            <button
                *ngIf="showNextEllipsis"
                class="ellipsis"
                (click)="shiftForward()">...
            </button>
            <button
                *ngIf="totalPages > 1"
                class="page page-number"
                [class.selected]="pageIndex === totalPages - 1"
                (click)="goToPage(totalPages)">{{ totalPages }}
            </button>
            <button
                [disabled]="pageIndex === totalPages - 1"
                class="page"
                (click)="nextPage()">
                <span class="next" [class.isLastPage]="pageIndex === totalPages - 1">Next</span>
                <i [class.isLastPage]="pageIndex === totalPages - 1" class="fas fa-chevron-right"></i>
            </button>
        </div>
    </div>
</div>
