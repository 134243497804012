<ng-container *ngIf="loaded">
    <div class="grid__item">
        <div
            *ngIf="view.flagState"
            class="flag {{view.flagState}}"
            [class.open]="slideMessageOpen"
            [class.disabled]="!view.hasSlider"
            (click)="slideMessage()">
        </div>
        <div class="grid-content-column-space-between">
            <div class="grid-content-column">
                <div class="item-header">
                    <img
                        *ngIf="view.iconSrc"
                        ngSrc="{{view.iconSrc}}"
                        height="20"
                        width="20"
                        alt="Name space icon">
                    <h2>{{ view.title | myLbTranslate }}</h2>
                </div>
                <ng-container *ngIf="view.error else normalContent">
                    <div class="error-wrapper">
                        <div class="error-image-wrapper">
                            <img
                                ngSrc="assets/shared/icons/notification-solid-exclamation-triangle.svg"
                                height="30"
                                width="30"
                                alt="Exclamation icon">
                            <span>{{ view.error.message | myLbTranslate }}</span>
                        </div>
                        <div class="error-time-wrapper">
                            <span>{{ 'last_checked' | myLbTranslate }}:</span>
                            <span *ngIf="view.error.dateTimeMessage.isToday">
                                {{ 'today' | myLbTranslate | titlecase }}</span>
                            <span>{{ view.error.dateTimeMessage.timeString }}</span>
                        </div>
                    </div>

                </ng-container>
                <ng-template #normalContent>
                    <div *ngIf="view.counters" class="content-space-between counters">
                        <ng-container *ngFor="let count of view.counters">
                            <ng-container *ngIf="count.count > 0">
                                <div class="counter {{view.title}}">
                                   <span class="count {{count.countClass}}">
                                        {{ count.count }}
                                    </span>
                                    <span class="sub-title {{count.subTitleClass}}">
                                        {{ count.countSubTitle }}
                                    </span>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                    <ng-container *ngIf="view.message === ''"></ng-container>
                    <ng-container *ngIf="view.mainImageSrc; else noMainImage">
                        <div *ngIf="view.subtitle || view.message" class="item-content">
                            <h6 *ngIf="view.subtitle" class="subtitle {{view.flagState}}">{{ view.subtitle }}</h6>
                            <div class="item-column-wrapper">
                                <div class="item-column-left">
                                    <p *ngIf="view.message" [innerHTML]="view.message"></p>
                                </div>
                                <div class="item-column-right">
                                    <img
                                        ngSrc="{{view.mainImageSrc}}"
                                        class="main-image"
                                        height="432"
                                        width="698"
                                        alt="main image"/>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #noMainImage>
                        <div *ngIf="view.subtitle || view.message" class="item-content">
                            <h6 *ngIf="view.subtitle" class="subtitle {{view.flagState}}">{{ view.subtitle }}</h6>
                            <p *ngIf="view.message" [innerHTML]="view.message"></p>
                        </div>
                    </ng-template>
                    <ul *ngIf="view.list" class="list">
                        <li *ngFor="let item of view.list">{{ item }}</li>
                    </ul>
                </ng-template>
            </div>
            <div class="item-footer">
                <ng-container *ngIf="view.linkExternal else internal">
                    <a href="{{view.linkUrl}}" target="_blank">
                        {{ view.linkText.includes('_') ? (view.linkText | myLbTranslate) : view.linkText }}
                    </a>
                </ng-container>
                <ng-template #internal>
                    <a routerLink="{{view.linkUrl}}">{{ view.linkText }}</a>
                </ng-template>
                <img
                    ngSrc="../../../../../../../../assets/shared/icons/chevron-right.svg"
                    height="12"
                    width="12"
                    alt="chevron right icon">
            </div>
        </div>
        <div
            *ngIf="view.hasSlider"
            class="slide-message"
            [class.open]="slideMessageOpen">
            <div class="grid-content-column-space-between">
                <div class="item-header">
                    <img
                        ngSrc="../../../../../../../../assets/centralisedManagement/icons/dashboard-star.svg"
                        height="20"
                        width="20"
                        alt="star icon">
                    <h2>{{ 'upgrade' | myLbTranslate }}</h2>
                </div>
                <div class="item-content">
                    <ng-container *ngIf="view?.sliderContent?.message else defaultMessage">
                        <p [innerHTML]="view.sliderContent.message"></p>
                    </ng-container>
                    <ng-template #defaultMessage>
                        <p>Looks like you have maxed out on this feature, check out your upgrade options!</p>
                    </ng-template>
                </div>
                <div class="item-footer">
                    <a routerLink="/cm/organisation/subscriptions">{{ 'upgrade_options'|myLbTranslate }}</a>
                    <img
                        ngSrc="../../../../../../../../assets/shared/icons/chevron-right.svg"
                        height="12"
                        width="12"
                        alt="chevron right icon">
                </div>
            </div>
            <img
                ngSrc="../../../../../../../../assets/centralisedManagement/icons/dashboard-spaceship.svg"
                class="spaceship"
                height="132"
                width="272"
                alt="Spaceship carousel image">
        </div>
    </div>
</ng-container>
