//  _    ___   ___  ___  ___
// | |  | _ ) / _ \| _ \/ __|
// | |__| _ \| (_) |   / (_ |
// |____|___(_)___/|_|_\\___|
//           Loadbalancer.org
// ---

import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { Directive } from '@angular/core';

@Directive({
    selector: '[appMultiPortRangeValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MultiPortRangeValidationDirective, multi: true }]
})

export class MultiPortRangeValidationDirective implements Validator {
    public validate(control: AbstractControl): {[key: string]: any} | null {
        return multiPortRangeValidation(control);
    }
}

export function multiPortRangeValidation(control: any) {
    if (!control || !control.value || control.value === '') {
            return null;
    }
    // This will validate to make sure that the separators are correct so 50,100 and 500-200
    // but to check the ranges we must break the string up.
    const minPort: number = 1;
    const maxPort: number = 65535;
    const regex = new RegExp(/(([0-9]+-[0-9]+|[0-9]+)[,]*)+$/);
    // remove spaces
    const ports: string = control.value.trim();
    // ports.endsWith might be a little overzelous.
    if (regex.test(ports) === false || ports.endsWith(',') === true) {
        return { 'multiPortRangeValidation' : 'Invalid port/s should be 0-9. Separators: , or -' };
    }
    const singlePorts = [];
    const rangePorts = [];
    const numbersArray = ports.split(',');
    for (const element of numbersArray) {
        if (element.indexOf('-') > -1 ) {
            rangePorts.push(element);
        } else {
            singlePorts.push(element);
        }
    }
    // check the single ports
    for (const singlePort of singlePorts) {
        if (singlePort <= minPort || singlePort >= maxPort) {
            return { 'multiPortRangeValidation': `Port: ${singlePort} Invalid Port/s Not between 1 and 65535` };
        }
    }
    // check the range
    for (const range of rangePorts) {
        const element = range.split('-');
        if (element[0] > element[1]) {
            return { 'multiPortRangeValidation': `${element[0]} is greater than ${element[1]}` };
        }
        if (element[0] <= minPort || element[1] >= maxPort) {
            return { 'multiPortRangeValidation': `Port: ${element[0]} or ${element[1]} Invalid Port/s Not between 1 and 65535` };
        }
    }

    return null;
}
