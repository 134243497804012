<div class="component-main">
    <div class="content-space-between">
        <h1 id="component-title">{{ 'downloads' | myLbTranslate }}</h1>
    </div>
    <div class="divider first"></div>
    <ng-container *ngFor="let shuttleSupplier of ShuttleSupplierRows; let i = index;">
        <div
            class="content-space-between no-padding"
            [class.coming-soon]="!shuttleSupplier.active">
            <div class="content-left-vertical">
                <div class="content">
                    <img
                        ngSrc="/assets/centralisedManagement/icons/{{shuttleSupplier.icon}}-colour.svg"
                        height="20"
                        width="20"
                        alt="{{shuttleSupplier.title}} Icon">
                    <h2>{{ shuttleSupplier.title }}</h2>
                </div>
                <p class="description">{{ shuttleSupplier.description |myLbTranslate }}</p>
                <div *ngIf="shuttleSupplier.features?.length" class="content features">
                    <ng-container *ngFor="let feature of shuttleSupplier.features">
                        <img ngSrc="{{feature.iconPath}}.svg" height="20" width="20" alt="feature icon">
                        <span class="feature-name">{{ feature?.name | myLbTranslate }}</span>
                    </ng-container>
                </div>
            </div>
            <div class="content-right">
                <ng-container *ngIf="shuttleSupplier.active else comingSoon">
                    <button class="primary" [routerLink]="'/cm/resources/platform/' + shuttleSupplier.name">
                        {{ 'select' |myLbTranslate }}
                    </button>
                </ng-container>
                <ng-template #comingSoon>
                    <span class="coming-soon">{{ 'coming_soon' |myLbTranslate }}</span>
                </ng-template>

            </div>
        </div>
        <div class="divider"></div>
    </ng-container>
</div>
