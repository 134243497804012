import { ThemeModel } from '../../../../app/core/models';

export const cloudianTheme: ThemeModel = {
    name: 'cloudian',
    deactivate: false,
    properties: {
        '--header-background': '#333e48',
        '--header-text': 'var(--color-text-inverted)',
        '--page-background': 'var(--color-off-white)',
        '--spinner-color': 'var(--color-primary)',

        /** primary **/
        '--color-primary': '#bcd035',
        '--color-primary-lighten': '#c2d449',
        '--color-primary-extra-light': '#d0de71',
        '--color-primary-super-light': '#d9e48d',

        /** secondary **/
        '--color-secondary': '#003465',

        /** tertiary **/
        '--color-tertiary': 'var(--color-primary)',

        /** success **/
        '--color-success': '#74b55e',
        '--color-success-shade': '#517e41',
        '--color-success-tint': '#9dcb8e',

        /** warning **/
        '--color-warning': '#ffa600',

        /** danger **/
        '--color-danger': '#e1938c',
        '--color-danger-tint': '#ffc9c3',

        /** Info **/
        '--color-info': '#b3b300',
        '--color-info-shade': '#c3c232',
        '--color-info-tint': '#c3c232',

        /** dark **/
        '--color-dark': '#333333',

        /** medium **/
        '--color-medium': '#474747',

        /** light **/
        '--color-light': '#848484',

        /** Text **/
        '--color-text': '#333e48',

        /** Text Inactive **/
        '--color-text-inactive': '#878787',

        /** Text Inverted **/
        '--color-text-inverted': 'var(--color-white)',

        /** Element Inactive **/
        '--color-element-inactive': '#dcdcdc',

        /** Container Background **/
        '--color-container-background': 'var(--color-white)',

        /** Container Inverted Background **/
        '--color-container-inverted-background': '#161616',

        /** Disabled Element **/
        '--color-disabled-element': '#a9a8a9',

        /** Notification Banner **/
        '--notification-banner-background': 'var(--color-white)',
        '--notification-banner-text': 'var(--color-text)',

        /** Menu Items **/
        '--menu-background-color': '#333e48',
        '--menu-item-text-color': 'var(--color-text-inverted)',
        '--menu-item-active-text-color': 'var(--color-primary)',
        '--menu-item-active-background-color': '#212b34',
        '--menu-item-active-border-color': 'var(--color-primary)',

        /** Inputs **/
        '--input-text-color': 'var(--color-disabled-element)',
        '--input-background-color': 'var(--color-white)',

        /** Other Colors **/
        '--color-white': '#ffffff',
        '--color-off-white': '#f8f8f8',
        '--color-light-gray': '#dadedf',
        '--color-gray-80': '#cccccc',
        '--color-mid-gray': '#808080',
        '--color-dark-gray': '#40403f',
        '--color-green': '#3c763d',

        /** Shadow Color **/
        '--box-shadow-color': '133, 133, 133',
    },
    styleSheetLocation: '/images/themes/cloudian/css/styles.css',
    lightLogo: '/images/themes/cloudian/images/logo_light.png',
    darkLogo: '/images/themes/cloudian/images/logo_dark.png',
    imagePath: '/images/themes/cloudian/images/'
};
