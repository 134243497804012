<div [formGroup]="formGroup" class="reveal-password-wrapper">
    <input
        [title]="title"
        [type]="inputType"
        [name]="formControlName"
        [class]="className"
        [id]="id"
        [formControlName]="formControlName"
        autocomplete="new-password"/>
    <button [disabled]="revealPasswordButtonIsDisabled()" (click)="togglePasswordView()">
        <img
            [src]="'/assets/shared/icons/eye-' + iconStateName + '.svg'"
            alt="Eye {{iconStateName}}"
        />
    </button>
</div>
