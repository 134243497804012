<div class="component-main">
    <div class="content-space-between">
        <div class="content-left">
            <h1 id="component-title">{{ 'adc_shuttle_management' | myLbTranslate }}</h1>
        </div>
    </div>
    <ng-container *ngIf="isEmptyState">
        <h1>{{ 'adc_shuttle_new_title' | myLbTranslate }}</h1>
        <p>{{ 'adc_shuttle_new_message' | myLbTranslate }}<br>{{ 'adc_shuttle_new_message_2' | myLbTranslate }}</p>
    </ng-container>
    <div class="divider first"></div>
    <ng-container *ngFor="let shuttleSupplier of shuttleSupplierRows; let i = index;">
        <div
            class="content-space-between no-padding"
            [class.coming-soon]="!shuttleSupplier.active">
            <div class="content-left-vertical">
                <div class="content">
                    <img
                        ngSrc="/assets/centralisedManagement/icons/{{shuttleSupplier.icon}}{{shuttleSupplier.active ? '-colour': ''}}.svg"
                        height="20"
                        width="20"
                        alt="{{shuttleSupplier.title}} Icon">
                    <h2>{{ shuttleSupplier.title }}</h2>
                </div>
                <p class="description" [class.disabled]="!shuttleSupplier.active">
                    {{ shuttleSupplier.description |myLbTranslate }}
                </p>
                <div *ngIf="shuttleSupplier.features?.length" class="content features">
                    <ng-container *ngFor="let feature of shuttleSupplier.features">
                        <img ngSrc="{{feature.iconPath}}.svg" height="20" width="20" alt="feature icon">
                        <span class="feature-name">{{ feature?.name | myLbTranslate }}</span>
                    </ng-container>
                </div>
            </div>
            <div class="content-right">
                <ng-container *ngIf="shuttleSupplier.active else comingSoon">
                    <button class="primary" [routerLink]="'/cm/adcs/shuttle/add/' + shuttleSupplier.vendorId">
                        {{ 'add' |myLbTranslate }}
                    </button>
                </ng-container>
                <ng-template #comingSoon>
                    <span class="coming-soon">{{ 'coming_soon' |myLbTranslate }}</span>
                </ng-template>
            </div>
        </div>
        <div class="divider"></div>
    </ng-container>
</div>
<ng-container *ngIf="!isEmptyState">
    <div class="component-footer">
        <button class="outline" routerLink="/cm/adcs/shuttle">
            {{ 'cancel' |myLbTranslate }}
        </button>
    </div>
</ng-container>
