<ng-container *ngIf="isOpen">
    <ng-container *ngIf="overlay; else noOverlay">
        <div class="overlay-container" (click)="overlayClicked($event)">
            <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
        </div>
    </ng-container>
    <ng-template #noOverlay>
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </ng-template>
</ng-container>

<ng-template #contentTemplate>
    <div
        #contentContainer
        [id]="contentId"
        [@openClose]="fade && isOpen"
        class="overlay-content-container"
        (mouseover)="holdHover(true)"
        (mouseleave)="holdHover(false)"
        [class.up]="openUpwards"
        [style.top]="top"
        [style.bottom]="bottom"
        [style.left]="left"
        [style.right]="right"
        [style.height.px]="height"
        [style.width.px]="contentWidth">
        <div
            *ngIf="showCaret"
            class="caret {{caretPosition}} {{contentId}}"
            [style.border-color]="this.tooltipCaretBackgroundColor">
        </div>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </div>
</ng-template>
