<div class="grid__item">
    <div class="grid-content-column-space-between">
        <div class="grid-content-column">
            <div class="item-header">
                <img
                    *ngIf="view.iconSrc"
                    ngSrc="{{view.iconSrc}}"
                    height="20"
                    width="20"
                    alt="Name space icon">
                <h2>{{view.title | myLbTranslate}}</h2>
            </div>
            <div class="video-wrapper">
                <iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/iA8vbSaxIyc?si=noscPd92klUsQsXZ"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </div>
        </div>
        <div class="item-footer">
            <a routerLink="{{view.linkUrl}}">{{view.linkText}}</a>
            <img ngSrc="../../../../../../../../assets/shared/icons/chevron-right.svg" height="12" width="12">
        </div>
    </div>
</div>
