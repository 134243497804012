<app-external-page>
    <app-external-page-content-body>
        <ng-container *ngIf="hasSSOToken else normalLogin">
            <div class="sso-wrapper-login">
                <h1 class="welcome-title">{{ 'accounts_single-sign-on'  | myLbTranslate }}</h1>
                <p *ngIf="ssoUser">login in with {{ ssoUser }}</p>
                <div class="sso-login">
                    <div class="loader">
                        <div class="dot-pulse"></div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #normalLogin>
            <form id="user-form" [formGroup]="loginForm" class="no-help">
                <h1 class="welcome-title">{{ welcomeMessage | myLbTranslate }}</h1>
                <ng-container *ngIf="!twoFactorUserDetails else twoFactorAuth">
                    <div id="login-username-wrapper" class="form-group">
                        <ng-container>
                            <label id="login-email-label" for="login-email">{{ 'email' | myLbTranslate }}</label>
                            <div class="form-content">
                                <input
                                    name="email"
                                    formControlName="login-email"
                                    id="login-email"
                                    type="text"
                                    autocorrect="off"
                                    autocapitalize="none">
                                <app-validation-messages [control]="loginForm.get('login-email')">
                                </app-validation-messages>
                            </div>
                        </ng-container>
                    </div>
                    <div class="form-group" id="login-password-wrapper">
                        <div class="content-space-between">
                            <label id="login-password-label" for="login-password">
                                {{ 'password' | myLbTranslate }}
                            </label>
                            <button
                                *ngIf="!connectionDetailsService.isLoadbalancer()"
                                id="login-forgot-password"
                                class="button-as-link"
                                [disabled]="hasSSOToken"
                                routerLink="/password-recovery"
                                tabindex="-1">
                                {{ 'forgot_password' | myLbTranslate }}
                            </button>
                        </div>
                        <div class="form-content">
                            <input
                                name="password"
                                formControlName="login-password"
                                id="login-password"
                                type="password">
                        </div>
                    </div>
                    <app-loading-button
                        #sign_in
                        buttonId="login-sign-in"
                        buttonText="login_in"
                        buttonClass="primary no-active-styling"
                        [handleLoadingStateExternally]="true"
                        [isDisabled]="loginForm.invalid || loginForm.disabled"
                        [callBack]="login.bind(this)">
                    </app-loading-button>
                    <ng-container *ngIf="!connectionDetailsService.isLoadbalancer()">
                        <div class="sso-wrapper">
                            <div class="sso-button" (click)="linkToSSOProvider('google')">
                                <img
                                    src="assets/centralisedManagement/icons/sso-google.svg"
                                    alt="Google single sign on SSO icon">
                                <span class="sso-name">Google</span>
                            </div>
                            <div class="sso-button" (click)="linkToSSOProvider('azureadv2')">
                                <img
                                    src="../../../assets/centralisedManagement/icons/sso-azureadv2.svg"
                                    alt="Azure single sign on SSO icon">
                                <span class="sso-name">Azure AD</span>
                            </div>
                            <div class="sso-button" (click)="linkToSSOProvider('saml')">
                                <img
                                    src="assets/centralisedManagement/icons/sso-saml.svg"
                                    alt="SAML single sign on SSO icon">
                                <span class="sso-name">SAML</span>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-template #twoFactorAuth>
                    <div id="login-two-factor-wrapper">
                        <h2>{{ 'accounts_two_factor_auth_required' | myLbTranslate }}</h2>
                        <p>{{ 'accounts_two_factor_auth_use_sms_code' | myLbTranslate }} {{ twoFactorAuthPhoneNumber }}</p>
                        <app-two-factor-auth
                            [userDetails]="twoFactorUserDetails"
                            [callBack]="login.bind(this)"
                            (authenticated)="checkForTokenInEvent($event)">
                        </app-two-factor-auth>
                    </div>
                </ng-template>
                <div class="connection-settings-wrapper">
                    <ng-container *ngIf="!connectionDetailsService.isLoadbalancer()">
                        <ng-container *ngIf="!twoFactorUserDetails">
                            <button
                                id="login-create-account"
                                class="button-as-link"
                                [disabled]="hasSSOToken"
                                (click)="createAccount()">
                                {{ 'create_account' | myLbTranslate }}
                            </button>
                        </ng-container>
                    </ng-container>

                    <app-lb-accordion
                        id="login_connection_settings"
                        *ngIf="connectionDetailsService.isLoadbalancer() || !environment.production">
                        <app-lb-accordion-group
                            [title]="getAccordionTitle()"
                            [titleAlignment]="connectionDetailsService.isLoadbalancer() ? 'left' : 'right'"
                            [arrowNextToTitle]="true"
                            [open]="forceOpen">
                            <h2 *ngIf="!connectionDetailsService.isLoadbalancer()">{{ 'login_connection_settings' | myLbTranslate }}</h2>
                            <div class="form-group" id="login-host-ip">
                                <label id="login-host-ip-label"
                                       for="login-ipaddress">{{ 'login_host_ip' | myLbTranslate }}</label>
                                <div class="form-content">
                                    <input
                                        name="ipaddress"
                                        id="login-ipaddress"
                                        formControlName="applianceAddress"
                                        autocorrect="off"
                                        type="text">
                                    <app-validation-messages [control]="loginForm.get('applianceAddress')">
                                    </app-validation-messages>
                                </div>
                            </div>
                            <div class="form-group">
                                <label id="login-port-label" for="login-port">{{ 'port' | myLbTranslate }}</label>
                                <div class="form-content">
                                    <input
                                        name="port"
                                        id="login-port"
                                        title="{{ 'port' | myLbTranslate }}"
                                        formControlName="appliancePort"
                                        type="number">
                                    <app-validation-messages [control]="loginForm.get('appliancePort')">
                                    </app-validation-messages>
                                </div>
                            </div>
                        </app-lb-accordion-group>
                    </app-lb-accordion>
                </div>
            </form>
        </ng-template>
    </app-external-page-content-body>
</app-external-page>

<app-modal-wrapper
    [isOverlayBlurred]="true"
    [isOverlayClickable]="false"
    [modalTitle]="errorTitle"
    modalId="loginError">
    <app-modal-body>
        <p>{{ errorMessage }}</p>
    </app-modal-body>
    <app-modal-footer>
        <ng-container *ngIf="errorTitle === 'login_untrusted_host_certificate'; else ok">
            <button id="login-verify-cert" class="primary" (click)="sendToPingPage()">
                {{ 'verify' | myLbTranslate }}
            </button>
        </ng-container>
        <ng-template #ok>
            <button
                #modalOK
                id="login-modal-ok"
                class="primary"
                (click)="modalService.close('loginError')"
                autofocus>
                Ok
            </button>
        </ng-template>
    </app-modal-footer>
</app-modal-wrapper>

<app-modal-wrapper
    [isOverlayBlurred]="true"
    [isOverlayClickable]="false"
    modalTitle="saml_modal_title"
    modalId="saml">
    <app-modal-body>
        <p>{{ 'saml_modal_message' | myLbTranslate }}</p>
        <form id="modal-form" [formGroup]="samlLoginForm" class="no-help">
            <label id="saml-login-email-label" for="login-email-label">{{ 'email' | myLbTranslate }}</label>
            <div class="form-content">
                <input
                    name="email"
                    formControlName="email"
                    id="saml-login-email"
                    type="text"
                    autocorrect="off"
                    autocapitalize="none">
                <app-validation-messages [control]="samlLoginForm.get('email')">
                </app-validation-messages>
            </div>
        </form>
    </app-modal-body>
    <app-modal-footer>
        <button id="login-with-saml-cancel" class="outline" (click)="modalService.close('saml')">
            {{ 'cancel' | myLbTranslate }}
        </button>
        <app-loading-button
            buttonId="login-with-saml"
            buttonText="continue"
            buttonClass="primary"
            [isDisabled]="samlLoginForm.invalid || samlLoginForm.disabled"
            [callBack]="loginWithSaml.bind(this)"
            autofocus>
        </app-loading-button>
    </app-modal-footer>
</app-modal-wrapper>
