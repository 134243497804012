<div class="component-main">
    <div class="content">
        <h1>{{ 'system_update_manual_update' | myLbTranslate }}</h1>
    </div>
    <div class="form-group">
        <label>{{ 'system_update_update_archive' | myLbTranslate }}</label>
        <div class="form-content">
            <app-upload id="upload-file" acceptedTypes=".gzip" (fileSelected)="onFileChange($event)"></app-upload>
        </div>
    </div>
</div>
<div class="component-footer">
    <button id="floating-ip-cancel" class="outline" routerLink="..">{{'cancel' | myLbTranslate }}</button>
    <app-loading-button
        buttonId="manual-update-submit"
        buttonClass="primary"
        buttonText="submit"
        [isDisabled]="uploadForm.invalid || uploadForm.pristine"
        [callBack]="submitUpload.bind(this)">
    </app-loading-button>
</div>
