<div class="component-main">
    <div class="content">
        <h1>{{ 'pulse_ha_global_config' | myLbTranslate }}</h1>
    </div>
    <form [formGroup]="pulseHAGlobalForm">
        <div class="form-group">
            <label for="hcInterval">{{ 'pulse_ha_health_check_interval' | myLbTranslate }}</label>
            <div class="form-content">
                <input
                    formControlName="hcInterval"
                    title="{{ 'hostname' | myLbTranslate }}"
                    type="number"
                    name="hcInterval"
                    id="hcInterval"/>
                <app-validation-messages
                    [control]="pulseHAGlobalForm.controls['hcInterval']"></app-validation-messages>
                <app-help class="help-button" tag="pulseha-conf_health-check-interval"></app-help>
            </div>
        </div>
        <div class="form-group">
            <label for="foInterval">{{ 'pulse_ha_fail_over_interval' | myLbTranslate }}</label>
            <div class="form-content">
                <input
                    formControlName="foInterval"
                    title="{{ 'hostname' | myLbTranslate }}"
                    type="number"
                    name="foInterval"
                    id="foInterval"/>
                <app-validation-messages
                    [control]="pulseHAGlobalForm.controls['foInterval']"></app-validation-messages>
                <app-help class="help-button" tag="pulseha-conf_failover-interval"></app-help>
            </div>
        </div>
        <div class="form-group">
            <label for="foLimit">{{ 'pulse_ha_fail_over_limit' | myLbTranslate }}</label>
            <div class="form-content">
                <input
                    formControlName="foLimit"
                    title="{{ 'hostname' | myLbTranslate }}"
                    type="number"
                    name="foLimit"
                    id="foLimit"/>
                <app-validation-messages
                    [control]="pulseHAGlobalForm.controls['foLimit']"></app-validation-messages>
                <app-help class="help-button" tag="pulseha-conf_failover-limit"></app-help>
            </div>
        </div>
        <div class="form-group">
            <!--suppress XmlInvalidId -->
            <label for="pulse-log-level">{{ 'log_level' | myLbTranslate }}*</label>
            <div class="form-content">
                <app-dropdown
                    dropDownId="pulse-log-level"
                    formControlName="logLevel"
                    [formGroup]="pulseHAGlobalForm"
                    [content]="dropdown.logLevels">
                </app-dropdown>
                <app-help class="help-button" tag="pulseha-conf_log-level"></app-help>
            </div>
        </div>
        <div class="form-group">
            <div class="form-content">
                <input
                    title="{{ 'pulse_ha_auto_fail_back_join' | myLbTranslate }}"
                    class="lb-checkbox"
                    id="autoFailBack"
                    type="checkbox"
                    name="autoFailBack"
                    formControlName="autoFailBack"/>
                <label for="autoFailBack" class="lb-checkbox">{{ 'pulse_ha_auto_fail_back' | myLbTranslate }}</label>
                <app-help tag="pulseha-conf_auto-fail-back"></app-help>
            </div>
        </div>
    </form>
    <h1>{{ 'pulse_ha_config' | myLbTranslate }}</h1>

    <form [formGroup]="pulseHAForm">
        <ng-container *ngIf="paired">
            <h2>{{ 'pulse_ha_node_cluster' | myLbTranslate }}</h2>
            <p>{{ 'pulse_ha_change_ip' | myLbTranslate }}</p>
        </ng-container>

        <div class="form-group">
            <!--suppress XmlInvalidId -->
            <label for="pulse-ip-address">{{ 'ip_address' | myLbTranslate }}*</label>
            <div class="form-content">
                <app-dropdown
                    dropDownId="pulse-ip-address"
                    formControlName="sipId"
                    [formGroup]="pulseHAForm"
                    [content]="dropdown.sips">
                </app-dropdown>
                <app-help class="help-button" tag="pulseha-conf_ip"></app-help>
            </div>
        </div>
        <div class="form-group">
            <label for="port">{{ 'pulse_ha_port' | myLbTranslate }}*</label>
            <div class="form-content">
                <input
                    title="{{ 'value' | myLbTranslate }}"
                    type="number"
                    name="port"
                    id="port"
                    appInputValidator
                    formControlName="port"/>
                <app-validation-messages [control]="pulseHAForm.controls['port']"></app-validation-messages>
                <app-help class="help-button" tag="pulseha-conf_port"></app-help>
            </div>
        </div>
        <div>
            <p *ngIf="!pulseHAOk">{{ 'pulse_ha_error' | myLbTranslate }}: {{ pulseHAWarning }}</p>
        </div>
    </form>
</div>

<div class="component-footer">
    <app-loading-button
        buttonClass="primary"
        buttonId="pulse-ha-config"
        buttonText="submit"
        [isDisabled]="isButtonDisabled('pulse-ha-config')"
        [callBack]="submit.bind(this)">
    </app-loading-button>
</div>
