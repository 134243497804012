<app-external-page>
    <app-external-page-content-body>
        <form id="user-form" [formGroup]="passwordRecovery" class="no-help">
            <h1 class="welcome-title">{{ 'accounts_recover_password' | myLbTranslate }}</h1>
            <div [hidden]="activeStep !== 1" formGroupName="step1">
                <p>{{ 'accounts_recover_password_intro' | myLbTranslate }}</p>
                <div class="form-group">
                    <label id="email-address-label" for="emailAddress">
                        {{ 'accounts_recover_password_email_label' | myLbTranslate }}
                    </label>
                    <div class="form-content">
                        <input name="email" formControlName="email" id="emailAddress" type="email">
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-content upload-wrapper">
                        <div class="upload-button-wrapper">
                            <button
                                class="primary button-key"
                                [class.padding]="!file"
                                (click)="openFileBrowser($event)">
                                <img ngSrc="/assets/shared/icons/key.svg" alt="key icon" height="20" width="20"/>
                            </button>
                            <app-upload
                                buttonText="accounts_recover_password_upload_user_message"
                                buttonChangeText=""
                                acceptedTypes=".key"
                                buttonClass="button-as-link"
                                [resetFileInput]="inputReset.user"
                                (fileSelected)="onFileChange($event, 'user')">
                            </app-upload>
                        </div>
                        <img
                            *ngIf="file['user']"
                            class="close-cross"
                            ngSrc="assets/shared/icons/close-cross.svg"
                            alt="clear file selection"
                            (click)="clearUpload('user')" height="20" width="20"/>
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-content upload-wrapper">
                        <div class="upload-button-wrapper">
                            <button
                                class="primary button-key"
                                [class.padding]="!file"
                                (click)="openFileBrowser($event)">
                                <img ngSrc="/assets/shared/icons/key.svg" alt="key icon" height="20" width="20"/>
                            </button>
                            <app-upload
                                buttonText="accounts_recover_password_upload_org_message"
                                buttonChangeText=""
                                acceptedTypes=".key"
                                buttonClass="button-as-link"
                                [resetFileInput]="inputReset.org"
                                (fileSelected)="onFileChange($event, 'org')">
                            </app-upload>
                        </div>
                        <img
                            *ngIf="file.org"
                            class="close-cross"
                            ngSrc="assets/shared/icons/close-cross.svg"
                            alt="clear file selection"
                            height="20"
                            width="20"
                            (click)="clearUpload('org')"/>
                    </div>
                </div>
                <button
                    id="password-recovery-next"
                    class="primary"
                    [disabled]="passwordRecovery.get('step1').invalid || passwordRecovery.get('step1').pristine"
                    (click)="nextStep()">
                    {{ 'next' |myLbTranslate }}
                </button>
            </div>
            <div [hidden]="activeStep !== 2" id="private-key-message-block" formGroupName="step2">
                <p>{{ 'accounts_recover_password_intro_2' | myLbTranslate }}</p>
                <div class="form-group">
                    <label id="password-label" for="password">
                        {{ 'password' | myLbTranslate }}*
                    </label>
                    <div class="form-content">
                        <input
                            name="password"
                            formControlName="password"
                            id="password"
                            type="password"
                            autocomplete="new-password"/>
                        <app-validation-messages [control]="passwordRecovery.get('step2.password')">
                        </app-validation-messages>
                    </div>
                </div>
                <div class="form-group">
                    <label
                        [class.disabled]="passwordRecovery.get('step2.confirmPassword').disabled"
                        id="confirm-password-label"
                        for="confirm-password">
                        {{ 'password_confirm' | myLbTranslate }}*
                    </label>
                    <div class="form-content">
                        <input
                            name="password"
                            formControlName="confirmPassword"
                            id="confirm-password"
                            type="password"
                            autocomplete="new-password">
                        <app-validation-messages [control]="passwordRecovery.get('step2.confirmPassword')">
                        </app-validation-messages>
                    </div>
                </div>
                <app-loading-button
                    buttonId="password-recovery-update-next"
                    buttonClass="primary"
                    buttonText="next"
                    [isDisabled]="passwordRecovery.get('step2').invalid || passwordRecovery.get('step2').pristine"
                    [callBack]="updatePassword.bind(this)">
                </app-loading-button>
            </div>
            <div [hidden]="activeStep !== 3" id="private-key-download" formGroupName="step3">
                <app-private-key
                    [keyName]="privateKeyName"
                    [userPassword]="passwordRecovery.get('step2.password').value"
                    (keyDecrypted)="downloadKeyAndValidate($event)">
                </app-private-key>
                <br>
                <button
                    id="password-recovery-sign-in"
                    [disabled]="passwordRecovery.get('step4').invalid"
                    class="primary"
                    name="accounts_recover_password_update"
                    type="submit"
                    routerLink="/login">
                    {{ 'accounts_recover_password_complete_redirect_message' | myLbTranslate }}
                </button>
            </div>
        </form>
    </app-external-page-content-body>
    <app-external-page-content-footer>
        <ng-container *ngIf="activeStep !== 3">
            <img class="back-arrow" src="assets/shared/icons/chevron-left.svg" alt="back arrow"/>
            <button
                id="login-forgot-password"
                class="button-as-link"
                routerLink="/login">
                {{ 'accounts_recover_password_redirect_login' | myLbTranslate }}
            </button>
        </ng-container>
    </app-external-page-content-footer>
</app-external-page>

<app-confirm-box></app-confirm-box>
