<div class="component-main">
    <div class="content-space-between">
        <h2 class="embedded">{{ 'static_ip' | myLbTranslate }}</h2>
        <button
            id="static-ip-add-new"
            class="primary"
            [disabled]="isButtonDisabled('static-ip-add-new')"
            (click)="addEditSIP()">
            {{ 'static_ip_add' | myLbTranslate }}
        </button>
    </div>
    <app-search-rows [rows]="tempRows" [searchKeys]="searchKeys" (searchResults)="updateTableRows($event)">
    </app-search-rows>
    <app-table
        [amountShown]="10"
        [rows]="tableRows"
        [columnMode]="'flex'"
        [selectMode]="'multiRow'"
        [selectAll]="true"
        [selectAllActionDots]="actionDotsArray"
        [selectedRows]="selectedRowObject"
        (selectedRowsOut)="onSelect($event)">
        <app-table-column [flexGrow]="1.5" columnWidth="auto" prop="ip" name="ip"></app-table-column>
        <app-table-column [flexGrow]="0.75" prop="ipv4" name="network">
            <ng-template let-row="row" appLbTableColumnCell>
                <div *ngIf="row.ipv4">
                    {{ row.ipv4 }}
                </div>
                <div *ngIf="row.ipv6">
                    {{ row.ipv6 }}
                </div>
            </ng-template>
        </app-table-column>
        <app-table-column [flexGrow]="1" prop="groupName" name="group"></app-table-column>
        <app-table-column [flexGrow]="0.75" prop="usage" name="usage">
            <ng-template let-row="row" appLbTableColumnCell>
                <app-usage [usage]="row.usage" modalTitle="floating_ip_usage"></app-usage>
            </ng-template>
        </app-table-column>
        <app-table-column [flexGrow]="1.1" prop="actions" name="" [cellAlignment]="'right'">
            <ng-template let-row="row" appLbTableColumnCell>
                <div>
                    <app-action-dots>
                        <button
                            id="static-ip-edit"
                            [disabled]="isButtonDisabled('static-ip-edit') || isBoundToUserInterface(row)"
                            (click)="addEditSIP(row)">
                            {{ 'edit' | myLbTranslate }}
                        </button>
                        <button
                            id="static-ip-delete"
                            [disabled]="isButtonDisabled('static-ip-delete') || row.usage.length"
                            (click)="confirmDeleteIndividualIP(row)">
                            {{ 'delete' | myLbTranslate }}
                        </button>
                    </app-action-dots>
                </div>
            </ng-template>
        </app-table-column>
    </app-table>
</div>
