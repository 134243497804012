<ng-template #services>
    <div class="menu-item-wrapper" [routerLink]="showAdcListPage ? 'adcs/list' : 'adcs'">
        <div class="menu-image-wrapper">
            <img ngSrc="/assets/centralisedManagement/icons/adc.svg" alt="ADC icon" height="25" width="25">
        </div>
        <span>{{ 'adcs' | myLbTranslate }}</span>
    </div>
    <!-- burger sub-menu for adcs -->
    <div *ngIf="isBurgerParentActive('adcs')" class="secondary">
        <div class="menu-item-wrapper" [routerLink]="'adcs/overview'">
            <span>{{ 'overview' | myLbTranslate }}</span>
        </div>
        <div class="menu-item-wrapper" [routerLink]="showAdcListPage ? 'adcs/list' : 'adcs'">
            <span>{{ 'list' | myLbTranslate }}</span>
        </div>
        <div class="menu-item-wrapper" [routerLink]="showAdcListPage ? 'adcs/shuttle' : 'adcs'">
            <span>{{ 'adc_shuttle_management' | myLbTranslate }}</span>
        </div>
    </div>
    <!--  TODO to be re-enabled when end points are in place -->
    <!--    <div class="menu-item-wrapper" routerLink="/cm/pipelines">-->
    <!--        <div class="menu-image-wrapper">-->
    <!--            <img src="/assets/centralisedManagement/icons/pipelines.svg" alt="Pipelines icon">-->
    <!--        </div>-->
    <!--        <span>{{'pipelines' | myLbTranslate }}</span>-->
    <!--    </div>-->
    <div
        class="menu-item-wrapper"
        [routerLink]="tierIsFree || showSchedulesListPage ? 'scheduler/schedules' : 'scheduler'">
        <div class="menu-image-wrapper">
            <img ngSrc="/assets/centralisedManagement/icons/schedule.svg" alt="Schedule icon" height="20" width="20">
        </div>
        <span>{{ 'scheduler' | myLbTranslate }}</span>
        <img
            *ngIf="tierIsFree"
            class="menu-item-subscription"
            ngSrc="./assets/centralisedManagement/icons/subscription_required.svg"
            alt="subscription icon"
            title="Subscription required"
            height="21"
            width="21">
    </div>
    <!-- burger sub-menu for scheduler -->
    <div *ngIf="isBurgerParentActive('scheduler')" class="secondary">
        <div class="menu-item-wrapper" [routerLink]="'scheduler/overview'">
            <span>{{ 'overview' | myLbTranslate }}</span>
        </div>
        <div class="menu-item-wrapper" [routerLink]="'scheduler/schedules'">
            <span>{{ 'schedules' | myLbTranslate }}</span>
        </div>
    </div>

    <div class="menu-item-wrapper" [routerLink]="showBackupListPage ? 'storage/backups' : 'storage'">
        <div class="menu-image-wrapper">
            <img
                ngSrc="/assets/centralisedManagement/icons/storage-white.svg"
                alt="Storage icon"
                height="26"
                width="25">
        </div>
        <span>{{ 'storage' | myLbTranslate }}</span>
    </div>
    <!-- burger sub-menu for storage -->
    <div *ngIf="isBurgerParentActive('storage')" class="secondary">
        <div class="menu-item-wrapper" [routerLink]="'storage/overview'">
            <span>{{ 'overview' | myLbTranslate }}</span>
        </div>
        <div class="menu-item-wrapper" [routerLink]="showBackupListPage ? 'storage/backups' : 'storage'">
            <span>{{ 'backups' | myLbTranslate }}</span>
        </div>
    </div>

    <div class="menu-item-wrapper" [routerLink]="showInsightListPage ? 'security/insights' : 'security'">
        <div class="menu-image-wrapper">
            <img ngSrc="/assets/centralisedManagement/icons/security.svg" alt="Security icon" height="21" width="20">
        </div>
        <span>{{ 'security' | myLbTranslate }}</span>
    </div>
    <!-- burger sub-menu for security -->
    <div *ngIf="isBurgerParentActive('security/')" class="secondary">
        <div class="menu-item-wrapper" [routerLink]="'security/overview'">
            <span>{{ 'overview' | myLbTranslate }}</span>
        </div>
        <div class="menu-item-wrapper" [routerLink]="'security/insights'">
            <span>{{ 'security_insights_label' | myLbTranslate }}</span>
        </div>
    </div>
</ng-template>

<ng-template #myPortal>
    <div class="menu-item-wrapper" routerLink="/cm/organisation">
        <div class="menu-image-wrapper">
            <img
                ngSrc="/assets/centralisedManagement/icons/org-white.svg"
                alt="{{'organisation_title' | myLbTranslate}} icon"
                height="25"
                width="25">
        </div>
        <span>{{ 'organization_title' | myLbTranslate | titlecase }}</span>
    </div>
    <!-- burger sub-menu for organisation -->
    <div *ngIf="isBurgerParentActive('organisation')" class="secondary">
        <div class="menu-item-wrapper" [routerLink]="'organisation/billing/overview'">
            <span>{{ 'billing' | myLbTranslate }}</span>
        </div>
        <div class="menu-item-wrapper" [routerLink]="'organisation/users'">
            <span>{{ 'users' | myLbTranslate }}</span>
        </div>
        <div class="menu-item-wrapper" [routerLink]="'organisation/security-group'">
            <span>{{ 'security' | myLbTranslate }}</span>
        </div>
        <div class="menu-item-wrapper" [routerLink]="'organisation/namespaces'">
            <span>{{ 'namespaces' | myLbTranslate }}</span>
        </div>
        <div class="menu-item-wrapper" routerLink="/cm/organisation/subscriptions">
            <span>{{ 'subscriptions' | myLbTranslate | titlecase }}</span>
        </div>
        <div class="menu-item-wrapper" [routerLink]="'organisation/settings'">
            <span>{{ 'settings' | myLbTranslate }}</span>
        </div>
    </div>

    <div class="menu-item-wrapper" routerLink="/cm/accounts">
        <div class="menu-image-wrapper">
            <img
                ngSrc="/assets/centralisedManagement/icons/account-settings.svg"
                alt="{{'account' | myLbTranslate }} icon"
                height="21"
                width="20">
        </div>
        <span>{{ 'account' | myLbTranslate | titlecase }}</span>
    </div>
    <!-- burger sub-menu for accounts -->
    <div *ngIf="isBurgerParentActive('accounts')" class="secondary">
        <div class="menu-item-wrapper" [routerLink]="'/cm/accounts/sso'">
            <span>{{ 'accounts_single-sign-on' | myLbTranslate }}</span>
        </div>
        <div class="menu-item-wrapper" [routerLink]="'/cm/accounts/two-factor-auth'">
            <span>{{ 'accounts_two_factor_auth' | myLbTranslate }}</span>
        </div>
        <div class="menu-item-wrapper" [routerLink]="'/cm/accounts/recover-keys'">
            <span>{{ 'recovery' | myLbTranslate }}</span>
        </div>
        <div class="menu-item-wrapper" [routerLink]="'/cm/accounts/settings'">
            <span>{{ 'settings' | myLbTranslate }}</span>
        </div>
    </div>

    <div class="menu-item-wrapper" routerLink="/cm/resources">
        <div class="menu-image-wrapper">
            <img
                ngSrc="/assets/centralisedManagement/icons/resources.svg"
                alt="{{'resources' | myLbTranslate }} icon"
                height="20"
                width="21">
        </div>
        <span>{{ 'resources' | myLbTranslate | titlecase }}</span>
    </div>
    <div (click)="openLogoutModal()" class="menu-item-wrapper">
        <div id="menu-logout-item" class="menu-image-wrapper">
            <img ngSrc="/assets/centralisedManagement/icons/logout.svg" alt="log out" height="19" width="19">
        </div>
        <span>{{ 'logout' | myLbTranslate }}</span>
    </div>
</ng-template>

<ng-template #support>
    <div class="menu-item-wrapper" routerLink="/cm/support">
        <div class="menu-image-wrapper">
            <img
                ngSrc="/assets/shared/icons/user-headset.svg"
                alt="{{'support_get' | myLbTranslate}} icon"
                height="20"
                width="18">
        </div>
        <span>{{ 'support_get' | myLbTranslate | titlecase }}</span>
    </div>
    <div *ngIf="!this.burgerMenuOpen" class="menu-item-wrapper" (click)="externalStatusLink()">
        <div class="menu-image-wrapper">
            <img
                ngSrc="/assets/centralisedManagement/icons/service-status.svg"
                alt="{{'service_status' | myLbTranslate }} icon"
                height="20"
                width="20">
        </div>
        <span>{{ 'service_status' | myLbTranslate | titlecase }}</span>
    </div>

    <div *ngIf="!this.burgerMenuOpen" class="menu-item-wrapper" (click)="giveFeedback()">
        <div class="menu-image-wrapper">
            <img
                ngSrc="/assets/centralisedManagement/icons/feedback.svg"
                alt="{{'support_send_feedback' | myLbTranslate }} icon"
                height="20"
                width="20">
        </div>
        <span>{{ 'support_send_feedback' | myLbTranslate | titlecase }}</span>
    </div>

    <!-- burger sub-menu for support -->
    <div *ngIf="isBurgerParentActive('support/')" class="secondary">
        <div class="menu-item-wrapper" [routerLink]="'support'">
            <span>{{ 'tickets_all' | myLbTranslate }}</span>
        </div>
        <div class="menu-item-wrapper" (click)="externalStatusLink()">
            <span>{{ 'service_status' | myLbTranslate | titlecase }}</span>
        </div>
        <div class="menu-item-wrapper" (click)="giveFeedback()">
            <span>{{ 'support_send_feedback' | myLbTranslate | titlecase }}</span>
        </div>
    </div>
</ng-template>

<div id="header-wrapper">
    <div class="header">
        <div class="header-block logo-component" routerLink="/cm/dashboard">
            <app-logo></app-logo>
        </div>
        <div class="header-block page-title">
            <h1>Page title</h1>
        </div>
        <div class="namespace-products">
            <div class="header-block namespace">
                <app-namespace tabindex="0"></app-namespace>
            </div>
            <div class="header-block products">
                <app-dropdown
                    dropDownId="cm-products"
                    title="{{'services' | myLbTranslate}}"
                    image="/assets/centralisedManagement/icons/products.svg"
                    [contentWidth]="180"
                    tabindex="0"
                    titleAlign="right">
                    <div class="drop-container-html">
                        <ng-template [ngTemplateOutlet]="services"></ng-template>
                    </div>
                </app-dropdown>
            </div>
        </div>
        <div class="header-block search">
            <app-algolia-search></app-algolia-search>
        </div>
        <div class="header-block icons">
            <app-cm-header-notifications></app-cm-header-notifications>
            <a
                routerLink="/cm/organisation/subscriptions"
                appHint
                tabindex="0"
                hintText="{{'subscriptions' | myLbTranslate}}"
                hintPosition="bottom">
                <div class="subscription">
                    <img
                        ngSrc="assets/centralisedManagement/icons/subscription.svg"
                        alt="Subscription"
                        height="20"
                        width="20"/>
                    <div *ngIf="tierIsFree" class="attention-marker"></div>
                </div>
            </a>
            <a (click)="getNews()"
               appHint
               tabindex="0"
               hintText="{{'news' | myLbTranslate}}"
               hintPosition="bottom">
                <div class="news">
                    <img
                        ngSrc="assets/shared/icons/news.svg"
                        alt="News"
                        height="20"
                        width="20"/>
                    <div *ngIf="false" class="attention-marker"></div>
                </div>
            </a>
            <app-dropdown
                dropDownId="cm-support-dropdown"
                tabindex="0"
                [image]="'/assets/shared/icons/user-headset.svg'"
                [contentWidth]="200"
                hintText="{{'support' | myLbTranslate}}"
                titleAlign="right">
                <div class="drop-container-html">
                    <ng-template [ngTemplateOutlet]="support"></ng-template>
                </div>
            </app-dropdown>
            <app-dropdown
                dropDownId="cm-myPortal-dropdown"
                tabindex="0"
                [image]="'/assets/centralisedManagement/icons/portal.svg'"
                titleAlign="right"
                [contentWidth]="180"
                hintText="{{'portal' | myLbTranslate}}">
                <div class="drop-container-html">
                    <ng-template [ngTemplateOutlet]="myPortal"></ng-template>
                </div>
            </app-dropdown>
        </div>
        <div class="header-block burger-menu" (click)="setRightMenuState(true)">
            <img ngSrc="assets/shared/icons/burger-menu.svg" alt="Burger menu icon" height="22" width="26"/>
        </div>
    </div>
</div>

<div id="main-wrapper">
    <div id="main-content">
        <div> <!-- Required as unable to apply 100% to the router-outlet -->
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

<app-burger-menu-content [open]="burgerMenuOpen" (menuEmitter)="setRightMenuState($event)">
    <ng-template [ngTemplateOutlet]="services"></ng-template>
    <ng-template [ngTemplateOutlet]="support"></ng-template>
    <ng-template [ngTemplateOutlet]="myPortal"></ng-template>
</app-burger-menu-content>

<!-- Drawer holder -->
<app-drawer></app-drawer>

<app-relogin></app-relogin>

<app-modal-wrapper
    [isOverlayBlurred]="true"
    [isOverlayClickable]="false"
    modalTitle="logout_confirmation"
    modalId="logoutConfirm"
    style="user-select: none;"
    footerButtonAlignment="content-center">
    <app-modal-body>
        <p>{{ 'confirm_logout' | myLbTranslate }}</p>
    </app-modal-body>
    <app-modal-footer>
        <button id="logout" class="primary" (click)="logout()">
            {{ 'logout' | myLbTranslate }}
        </button>
    </app-modal-footer>
</app-modal-wrapper>
