<div class="component-main">
    <div class="content-space-between">
        <div class="content-left">
            <h1>{{ 'ha_conf' | myLbTranslate }}</h1>
        </div>
        <div class="content-right buttons-wrapper">
            <app-loading-button
                id="ha-configuration-reload-restart"
                buttonClass="primary"
                *ngIf="restartRequired"
                [buttonText]="'ha_conf_reload_restart_services'"
                [isDisabled]="isButtonDisabled('ha-configuration-add-new')"
                [callBack]="clusterRestartReload.bind(this)">
            </app-loading-button>
            <button
                id="ha-configuration-add-new"
                [disabled]="isButtonDisabled('ha-configuration-add-new')"
                class="primary"
                (click)="openAddNode()">
                {{ 'ha_conf_add_node' | myLbTranslate }}
            </button>
        </div>
    </div>
    <app-search-rows [rows]="tableRows" [searchKeys]="searchKeys" (searchResults)="updateTableRows($event)">
    </app-search-rows>
    <app-table [rows]="tableRows" [columnMode]="'flex'" class="service-table">
        <app-table-column [flexGrow]="1.5" prop="hostname" name="hostname"></app-table-column>
        <app-table-column [flexGrow]="1" prop="ip" name="ha_conf_bind_address"></app-table-column>
        <app-table-column [flexGrow]="1" prop="latency" name="ha_conf_latency"></app-table-column>
        <app-table-column [flexGrow]="1" prop="services" name="services">
            <ng-template let-row="row" appLbTableColumnCell>
                <div class="status-container">
                    <span class='node-status {{overallServicesState(row)}}'></span>
                    <app-tooltip
                        trigger="Services"
                        class="services-tooltip"
                        [width]="200">
                        <div *ngFor="let item of row?.services | keyvalue" class="content-space-between services-list">
                            <ng-container *ngIf="currentIP === row.ip; else externalLink">
                                <span
                                    class="titleLink"
                                    [routerLink]="navigationService.getEnduranceUrl('/maintenance/services')"
                                    [queryParams]="{ sFilter: item.key }">
                                        {{ item.key | titlecase }}
                                </span>
                            </ng-container>
                            <ng-template #externalLink>
                                <span (click)="confirmRestartOnOtherAppliance(row, item)" class="titleLink">
                                    {{ item.key | titlecase }}
                                </span>
                            </ng-template>
                            <span class="status-block {{item.value}}"></span>
                        </div>
                    </app-tooltip>
                </div>
            </ng-template>
        </app-table-column>
        <app-table-column *ngIf="clusterStatus?.nodes?.length > 1" [flexGrow]="1.5" prop="status" name="status">
            <ng-template let-row="row" appLbTableColumnCell>
                <div class="content-space-between">
                    <div class="status-container">
                        <span class='node-status {{row.status}}'></span>
                        <span class="node-status-label">{{ row.status | titlecase }}</span>
                    </div>
                    <ng-container *ngIf="showHeartRate(row.status); else warning">
                        <div
                            *ngIf="showHeartRate(row.status) !== 'passive'"
                            [class]="'heart-rate ' + showHeartRate(row.status)">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                viewBox="0 0 150 70">
                                <polyline
                                    class="animation"
                                    points="0,35 38.514,35 44.595,28 50.676,35 57.771,35 62.838,45 70,0 80.067,70 84.122,35 97.297,35 103.379,30 110.473,35 150,35"/>
                            </svg>
                        </div>
                    </ng-container>
                    <ng-template #warning *ngIf="showHeartRate(row.status) !== 'passive'">
                        <div class="warning"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></div>
                    </ng-template>
                </div>
            </ng-template>
        </app-table-column>
        <app-table-column [flexGrow]="1" prop="state" name="state">
            <ng-template let-row="row" appLbTableColumnCell>
                <div class="status-container">
                    <span class='node-status {{row.state | lowercase}}'></span>
                    <span>{{ setNodeState(row.status) }}</span>
                </div>
            </ng-template>
        </app-table-column>
        <app-table-column [flexGrow]="1.5" prop="lastReceived" name="ha_conf_last_health_check"></app-table-column>
        <app-table-column [flexGrow]="0.5" prop="actions" name="" [cellAlignment]="'right'">
            <ng-template let-row="row" appLbTableColumnCell>
                <app-action-dots>
                    <button
                        *ngIf="row.status === 'active'"
                        [disabled]="isButtonDisabled('ha-configuration-resync')"
                        (click)="reSyncCluster()">
                        {{ 'ha_conf_re_sync_config' | myLbTranslate }}
                    </button>
                    <button
                        *ngIf="row.status === 'passive'"
                        [disabled]="isButtonDisabled('ha-configuration-promote')"
                        (click)="promoteNode(row)">
                        {{ 'ha_conf_promote' | myLbTranslate }}
                    </button>
                    <button
                        *ngIf="row.status !== 'active' && userService.getUser('applianceId') !== row.id"
                        [disabled]="isButtonDisabled('ha-configuration-remove-node')"
                        (click)="confirmRemoveNode(row)">
                        {{ 'remove' | myLbTranslate }}
                    </button>
                    <button
                        *ngIf="row.status === 'unavailable' || row.status === 'unknown'"
                        [disabled]="isButtonDisabled('ha-configuration-remove-node')"
                        (click)="openAddNode(row)">
                        {{ 'restore' | myLbTranslate }}
                    </button>
                </app-action-dots>
            </ng-template>
        </app-table-column>
    </app-table>
</div>

