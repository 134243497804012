import { ThemeModel } from 'src/app/core/models/theme.model';

export const defaultTheme: ThemeModel = {
    name: 'default',
    deactivate: false,
    properties: {
        // '--color-primary': '#54009c',
        // '--color-secondary': '#c200ff',
        // '--color-tertiary': '#ff00a3',
        // '--color-quaternary': '#00ffcf',
        // '--color-quinary': '#5c61d4',
        // '--color-hexadic': '#faee00',
        //
        // '--color-main-grey': '#333',
        // '--color-mid-grey': '#4c4c4c',
        // '--color-light-grey': '#666',
        // '--color-lighter-grey': '#808080',
        // '--color-lightest-grey': '#999',
        // '--color-very-light-grey': '#b2b2b2',
        // '--color-off-white': '#ccc',
        // '--color-white': '#fff',
        //
        // '--color-alert-error': 'var(--color-tertiary)',
        // '--color-alert-warn': '#ffa340',
        // '--color-alert-info': '#5b61d4',
        // '--color-alert-success': '#00b391',
        //
        //
        //
        // '--default-hover-color': '#55007d',
        // '--secondary-hover-color': '#b400ac',
        //
        // /** Inputs **/
        // '--input-text-color': 'var(--color-dark)',
        // '--input-background-color': 'var(--color-container-background)',
        // '--input-border-color': 'var(--color-text-inactive)',
        // '--input-border-focus-color': 'var(--color-dark)',
        //
        // /** Text **/
        // '--color-text-inactive': '#878787',
        // '--font-family': 'openSans',
        //
        // /** Components **/
        // '--header-background': 'var(--color-white)',
        // '--default-text-color': 'var(--color-dark)',
        //
        // '--menu-closed-background-color': '#222222',
        // '--menu-open-background-color': '#2E2E2E',
        //
        // /** Graph line colours **/
        // '--red-border-color': 'var(--default-color)',
        // '--red-point-background-color': 'var(--default-color)',
        // '--red-point-border-color': 'var(--color-white)',
        // '--red-point-hover-background-color': 'var(--color-white)',
        // '--red-point-hover-border-color': 'var(--default-color)',
        // '--grey-border-color': 'var(--active-color)',
        // '--grey-point-background-color': 'var(--color-text-inverted)',
        // '--grey-point-border-color': 'var(--color-white)',
        // '--grey-point-hover-background-color': 'var(--color-white)',
        // '--grey-point-hover-border-color': 'var(--color-text-inverted)'
    },
    styleSheetLocation: '/assets/shared/themes/default/css/styles.css',
    lightLogo: '/assets/shared/themes/default/images/logo_light.png',
    darkLogo: '/assets/shared/themes/default/images/logo_dark.png',
    imagePath: '/assets/shared/themes/default/images/'
};
