<div class="component-main">
    <div class="content-space-between">
        <h1>{{ 'net_int_config_create_bond' | myLbTranslate }}</h1>
    </div>
    <p>{{ 'net_int_config_create_bond_message' | myLbTranslate }}</p>
    <form [formGroup]="formGroup">
        <div class="form-group">
            <label for="name">{{ 'name' | myLbTranslate }}</label>
            <div class="form-content">
                <input type="text" name="ip" id="name" formControlName="name"/>
                <app-validation-messages [control]="formGroup.controls['name']"></app-validation-messages>
                <app-help class="help-button" tag="add-bond_name"></app-help>
            </div>
        </div>
        <div class="form-group">
            <label>{{ 'interface' | myLbTranslate }}</label>
            <div class="form-vertical">
                <div class="form-group" *ngFor="let control of getInterfacesFields.controls; index as i">
                    <div class="form-content">
                        <input
                            id="interfaces{{[i]}}"
                            class="lb-checkbox green"
                            type="checkbox"
                            [formControl]="getFormControl(control)"/>
                        <label for="interfaces{{[i]}}" class="lb-checkbox">{{ bondableInterfaces[i].name }}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group">
            <!--suppress XmlInvalidId -->
            <label for="bonds-modes">{{ 'mode' | myLbTranslate }}</label>
            <div class="form-content">
                <app-dropdown
                    dropDownId="bonds-modes"
                    formControlName="mode"
                    [formGroup]="formGroup"
                    [content]="dropdown">
                </app-dropdown>
                <app-help class="help-button" tag="add-bond_mode"></app-help>
            </div>
        </div>
        <div class="form-group">
            <label for="mtu">{{ 'net_int_config_mtu' | myLbTranslate }}</label>
            <div class="form-content">
                <input
                    id="mtu"
                    class="mtu"
                    type="number"
                    formControlName="mtu"
                    name="mtu"
                    min="68"
                    max="65535">
                <span>{{ 'bytes' | myLbTranslate }}</span>
                <app-validation-messages [control]="formGroup.controls['mtu']"></app-validation-messages>
                <app-help class="help-button" tag="add-bond_mtu"></app-help>
            </div>
        </div>
        <div class="form-group">
            <div class="form-content">
                <input
                    name="onBoot"
                    formControlName="onBoot"
                    class="lb-checkbox"
                    type="checkbox"
                    id="onBoot">
                <label for="offload" class="lb-checkbox">
                    {{ 'net_int_config_onBoot' | myLbTranslate }}
                </label>
            </div>
        </div>
        <div class="form-group">
            <div class="form-content">
                <input
                    name="offload"
                    formControlName="offload"
                    class="lb-checkbox"
                    type="checkbox"
                    id="offload">
                <label for="offload" class="lb-checkbox">
                    {{ 'net_int_config_offload' | myLbTranslate }}
                </label>
            </div>
        </div>
    </form>
</div>
<div class="component-footer">
    <button
        id="add-bond-cancel"
        class="outline"
        [routerLink]="'../'">
        {{ 'cancel' | myLbTranslate }}
    </button>
    <app-loading-button
        buttonId="add-bond-submit"
        buttonClass="primary"
        buttonText="submit"
        [isDisabled]="formGroup.invalid || formGroup.pristine"
        [callBack]="addBond.bind(this)">
    </app-loading-button>
</div>
