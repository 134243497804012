<form [formGroup]="serviceForm" id="aclForm">
    <div [formGroupName]="subStepEnum.GENERAL" [hidden]="getWizardSubStepState(subStepEnum.GENERAL)">
        <section [class.progress-line]="getFormValueByControlName('serviceId')">
            <div class="form-group">
                <div class="bullet-point-gray"></div>
                <!--suppress XmlInvalidId -->
                <label for="acl-frontends">{{ 'from' | myLbTranslate }}</label>
                <div class="form-content">
                    <app-dropdown
                        dropDownId="acl-frontends"
                        formControlName="serviceId"
                        [formGroup]="getFormGroup(subStepEnum.GENERAL)"
                        [content]="dropdown.frontends"
                        [search]="true">
                    </app-dropdown>
                    <app-help tag="acl-add-edit_frontend"></app-help>
                </div>
            </div>
        </section>

        <section
            *ngIf="showConditionType()"
            data-control-name="conditionType"
            [class.progress-line]="getFormValueByControlName('conditionType')">
            <div class="form-group">
                <div class="bullet-point-primary"></div>
                <!--suppress XmlInvalidId -->
                <label for="acl-conditionType">{{ 'if' | myLbTranslate }}</label>
                <div class="form-content">
                    <app-dropdown
                        dropDownId="acl-conditionType"
                        formControlName="conditionType"
                        [formGroup]="getFormGroup(subStepEnum.GENERAL)"
                        [content]="dropdown.conditionType"
                        [hiddenContentKeys]="dropdownHiddenContent">
                    </app-dropdown>
                    <app-help tag="acl-add-edit_path-type"></app-help>
                </div>
            </div>
        </section>

        <section *ngIf="showConditionParam()">
            <div class="form-group">
                <div class="bullet-point-gray"></div>
                <label for="conditionParam">{{ 'acls_header_param' | myLbTranslate }}</label>
                <div class="form-content">
                    <input
                        formControlName="conditionParam"
                        name="conditionParam"
                        type="text"
                        placeholder="Content-Type"
                        id="conditionParam">
                    <app-validation-messages [control]="serviceForm.get(subStepEnum.GENERAL + '.conditionParam')">
                    </app-validation-messages>
                    <app-help tag="acl-add-edit_paths"></app-help>
                </div>
            </div>
        </section>

        <section [class.progress-line]="setProgressLine('conditionType')" [hidden]="!showCondition()">
            <div class="form-group content-top">
                <div class="bullet-point-gray"></div>
                <!--suppress XmlInvalidId -->
                <label for="acl-condition">{{ 'is' | myLbTranslate }}</label>
                <app-dropdown
                    dropDownId="acl-condition"
                    formControlName="condition"
                    [formGroup]="getFormGroup(subStepEnum.GENERAL)"
                    [content]="dropdown.condition">
                </app-dropdown>
                <div class="form-vertical">
                    <div class="form-content" *ngFor="let control of conditionValues.controls; index as i">
                        <span *ngIf="i === 0" class="to">{{ 'to' | myLbTranslate }}</span>
                        <span class="and" *ngIf="i !== 0">
                            {{ serviceForm.get(subStepEnum.GENERAL + '.conditionType').value === 'sslSNI' ? 'or' : 'and' }}
                        </span>
                        <div class="form-content">
                            <input
                                #conditionTypeAttributes
                                (change)="checkForDuplicateValues()"
                                [formControl]="conditionValues.controls[i]"
                                title="{{ 'value' | myLbTranslate }}">
                            <button
                                [disabled]="conditionValues.controls.length === 1"
                                class="remove-condition button-as-link"
                                (click)="removeConditionValue(i)">{{ 'delete' | myLbTranslate }}
                            </button>
                            <app-validation-messages [control]="conditionValues.controls[i]"></app-validation-messages>
                            <app-help tag="acl-add-edit_paths"></app-help>
                        </div>
                    </div>
                </div>
            </div>
            <button
                *ngIf="showAddValueButton()"
                class="add-path-button"
                (click)="addValueFilter()"
                [class.inline_rules]="!canAddValue() && getFormValueByControlName('condition') === null"
                [class.add-value]="canAddValue()"
                [class.value-invalid]="!canAddValue()">
                {{ (serviceForm.get(subStepEnum.GENERAL + '.conditionType').value === 'sslSNI' ? 'or' : 'and') | myLbTranslate }}
            </button>
        </section>

        <section
            data-control-name="redirectType"
            [class.progress-line]="getFormValueByControlName('redirectType') && !isRedirectTypeDrop()"
            [hidden]="!isConditionTypeDefault() && showRedirectType()">
            <div class="form-group">
                <div [class]="getFormValueByControlName('redirectType')
                        ? 'bullet-point-gray'
                        : 'bullet-point-primary'"></div>
                <!--suppress XmlInvalidId -->
                <label for="acl-redirectType">{{ 'then' | myLbTranslate }}</label>
                <div class="form-content">
                    <app-dropdown
                        dropDownId="acl-redirectType"
                        formControlName="redirectType"
                        [formGroup]="getFormGroup(subStepEnum.GENERAL)"
                        [content]="dropdown.redirectType">
                    </app-dropdown>
                    <app-help tag="acl-add-edit_redirect-type"></app-help>
                </div>
            </div>
        </section>

        <section
            *ngIf="showBackendsOption"
            data-control-name="redirectLocation1"
            [hidden]="isRedirectTypeDrop() || hideRedirectSection()">
            <div class="form-group">
                <div class="bullet-point-gray"></div>
                <!--suppress XmlInvalidId -->
                <label for="acl-redirectLocation1">{{ 'to_upper' | myLbTranslate }}</label>
                <div class="form-content">
                    <app-dropdown
                        dropDownId="acl-redirectLocation1"
                        formControlName="redirectLocation1"
                        [formGroup]="getFormGroup(subStepEnum.GENERAL)"
                        [content]="dropdown.redirectLocation"
                        [search]="true">
                    </app-dropdown>
                    <app-help tag="acl-add-edit_redirect-location"></app-help>
                </div>
            </div>
            <div class="note" *ngIf="!dropdown.redirectLocation.length && showBackendsOption">
                <span>{{ 'acls_no_compatible_backends' | myLbTranslate }}<br>
                    {{ 'acls_frontend_mode_type' | myLbTranslate }} {{ this.selectedFrontend.mode }}.
                </span>
            </div>

            <div class="note" *ngIf="!dropdown.frontends.length && this.backends.length === 1">
                <span>{{ 'acls_no_compatible_frontends' | myLbTranslate }}</span><br>
                {{ 'acls_backend_mode_type' | myLbTranslate }} {{ this.backends[0].mode }}.
            </div>
        </section>

        <section>
            <div [hidden]="!showRedirectField" class="form-group">
                <div class="bullet-point-gray"></div>
                <label for="redirect-location">{{ 'to' | myLbTranslate }}</label>
                <div class="form-content">
                    <input
                        id="redirect-location"
                        formControlName="redirectLocation2"
                        name="redirectLocation"
                        type="text"
                        placeholder="e.g URL, IP, Backend/Real server">
                    <app-validation-messages
                        [control]="serviceForm.get(subStepEnum.GENERAL + '.redirectLocation2')">
                    </app-validation-messages>
                    <app-help tag="acl-add-edit_redirect-location"></app-help>
                </div>
            </div>
        </section>

        <section data-control-name="redirectCode">
            <div *ngIf="isRedirectTypeDeny()" class="form-group">
                <div class="bullet-point-gray"></div>
                <!--suppress XmlInvalidId -->
                <label for="redirect-code">{{ 'acls_redirect_with_code' | myLbTranslate }}</label>
                <div class="form-content">
                    <app-dropdown
                        dropDownId="redirect-code"
                        formControlName="redirectCode"
                        [formGroup]="getFormGroup(subStepEnum.GENERAL)"
                        [content]="dropdown.redirectCode"
                        [search]="true">
                    </app-dropdown>
                    <app-validation-messages [control]="serviceForm.get(subStepEnum.GENERAL + '.redirectCode')">
                    </app-validation-messages>
                    <app-help tag="acl-add-edit_redirect-code"></app-help>
                </div>
            </div>
        </section>
    </div>
</form>
