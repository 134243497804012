<div #contentWrapper [hidden]="isLoading"> <!-- Don't use *ngIf here as we calculate the button size -->
    <div class="split-button-wrapper {{buttonClass}}" [class.disabled]="buttonDisabled">
        <div class="split-wrapper-button left {{buttonClass}}">
            <ng-container *ngIf="callBack else dummyButton">
                <app-loading-button
                    [buttonText]="buttonText"
                    [buttonIconLocation]="buttonIconLocation"
                    [buttonClass]="buttonClass"
                    [buttonEmbedded]="true"
                    [isDisabled]="buttonDisabled"
                    [callBack]="callBack.bind(this)"
                    (click)="setIsLoading()">
                </app-loading-button>
            </ng-container>
            <ng-template #dummyButton>
                <button class="dummy-button" [class]="buttonClass" [disabled]="buttonDisabled">
                    {{ buttonText | myLbTranslate }}
                </button>
            </ng-template>
        </div>
        <div class="split-line {{buttonClass}}"></div>
        <div class="split-wrapper-button right {{buttonClass}}">
            <app-action-dots
                [actionDotsClass]="buttonClass"
                [splitAction]="true"
                [showCaret]="false"
                [contentWidth]="contentWidth"
                triggerButtonImagePath="./assets/shared/icons/split-button-down-arrow.svg"
                (actionDotChildClicked)="setIsLoading()">
                <ng-content></ng-content>
            </app-action-dots>
        </div>
    </div>
</div>
<div
    *ngIf="isLoading"
    class="split-button-wrapper loading {{buttonClass}}"
    [class.disabled]="buttonDisabled"
    [style.width.px]="splitButtonWidth">
    <div class="dot-pulse"></div>
</div>
