<div class="component-main">
    <div class="content-left">
        <h1 id="component-title">{{ 'no_access' | myLbTranslate }}</h1>&nbsp;
        <h1 *ngIf="path">to {{ path }}</h1>
    </div>
    <div class="content-left">
        <h2>{{ 'no_access_message' | myLbTranslate }}</h2>
    </div>
    <img ngSrc="assets/shared/icons/no-permission.svg" height="302" width="366" alt="No permissions folder image"/>
</div>
