<button class="help" title="Log out if there is an issue with on boarding." (click)="help()">?</button>
<app-external-page>
    <app-external-page-content-body>
        <div id="content">
            <div *ngIf="activeStep === 0">
                <h1 class="welcome-title">{{ 'onboard_user_already_assigned' | myLbTranslate }}</h1>
                <div class="step-header-wrapper">
                    <p>{{ 'onboard_current_user' | myLbTranslate }}</p>
                </div>
            </div>
            <div *ngIf="activeStep === 1">
                <h1 class="welcome-title">{{ 'onboard_add_organization'  | myLbTranslate }}</h1>
                <div class="step-header-wrapper">
                    <p>{{ 'onboard_add_organization_message' | myLbTranslate }}</p>
                    <app-add-organisation (eventEmitter)="patchOrganisationDataToForm($event)">
                    </app-add-organisation>
                </div>
            </div>
            <div *ngIf="activeStep === 2">
                <div class="step-header-wrapper">
                    <h2>{{ 'onboard_organization_private_key' | myLbTranslate }}</h2>
                    <app-private-key
                        [keyName]="orgKeyName"
                        [closeModalInternally]="false"
                        contentAlignment="left"
                        (keyDecrypted)="generateOrgKeyUsingUserKey($event)">
                    </app-private-key>
                </div>
            </div>
            <div *ngIf="activeStep === 3">
                <div class="step-header-wrapper">
                    <h2>{{ 'onboard_add_users' | myLbTranslate }}</h2>
                    <p>{{ 'onboard_add_users_message' | myLbTranslate }}</p>
                    <app-add-users (eventEmitter)="nextStep()"></app-add-users>
                </div>
            </div>
            <div *ngIf="activeStep === 4">
                <div class="step-header-wrapper">
                    <h2>{{ 'onboard_add_adc' | myLbTranslate }}</h2>
                    <p>{{ 'onboard_add_adc_message' |myLbTranslate }}</p>
                </div>
                <!--            <app-add-shuttle [inModal]="true" [centerTitle]="true"></app-add-shuttle>-->
            </div>
        </div>
    </app-external-page-content-body>
    <app-external-page-content-footer>
        <ng-container *ngIf="activeStep === 0">
            <button id="onboard-edit-org" class="primary" routerLink="/cm/organisation/settings">
                {{ 'onboard_edit_organization' | myLbTranslate }}
            </button>
        </ng-container>
        <ng-container *ngIf="activeStep === 1">
            <div class="button-wrapper">
                <button
                    class="primary next"
                    [disabled]="organisationForm.get('step1').invalid || organisationForm.get('step1').pristine"
                    (click)="nextStep()">
                    {{ 'next' | myLbTranslate }}
                </button>
            </div>
        </ng-container>
        <ng-container *ngIf="activeStep === 2">
            <!-- TODO update later to go to next step! -->
            <button
                id="onboard-continue"
                class="primary next"
                [disabled]="organisationForm.invalid"
                (click)="submitOrganisation()">
                {{ 'onboard_continue_to_portal' | myLbTranslate }}
            </button>
        </ng-container>
        <ng-container *ngIf="activeStep === 3">
            <button
                id="onboard-later"
                class="button-as-link"
                (click)="nextStep()">
                {{ 'onboard_do_it_later' | myLbTranslate }}
            </button>
        </ng-container>
        <ng-container *ngIf="activeStep === 4">
            <button id="onboard-do-it-later" class="button-as-link" routerLink="/cm/dashboard">
                {{ 'onboard_do_it_later' | myLbTranslate }}
            </button>
        </ng-container>
    </app-external-page-content-footer>
</app-external-page>
