<div class="component-main">
    <div class="content-space-between">
        <h1>{{ 'view_config_snmp_conf' | myLbTranslate }}</h1>
    </div>
    <form [formGroup]="userForm">
        <ng-container *ngIf="!addEditUser">
            <ng-container *ngIf="activeStep === 1">
                <div class="snmp_conf_select_version">
                    <span>{{ 'snmp_conf_select_version' | myLbTranslate }}</span>
                </div>
                <app-lb-checkbox
                    id="snmp_enable_v2"
                    [formGroup]="userForm"
                    formControlName="enabledV2"
                    label="{{ 'snmp_conf_snmp_enable_v2' | myLbTranslate }}">
                </app-lb-checkbox>
                <app-lb-checkbox
                    id="snmp_enable_v3"
                    [formGroup]="userForm"
                    formControlName="enabledV3"
                    label="{{ 'snmp_conf_snmp_enable_v3' | myLbTranslate }}">
                </app-lb-checkbox>
                <div class="form-group" [hidden]="userForm.get('enabledV3').value && !userForm.get('enabledV2').value">
                    <label
                        [class.disabled]="userForm.get('community').disabled">{{ 'snmp_conf_snmp_community' | myLbTranslate }}</label>
                    <div class="form-content">
                        <input
                            [disabled]="snmpDisabled"
                            title="{{'snmp_conf_snmp_community' | myLbTranslate }}"
                            type="text"
                            size="30"
                            name="community"
                            formControlName="community"/>
                        <app-validation-messages [control]="userForm.controls.community"></app-validation-messages>
                        <app-help tag="snmp_community"></app-help>
                    </div>
                </div>
                <div class="form-group">
                    <label [class.disabled]="userForm.get('location').disabled">
                        {{ 'snmp_conf_snmp_location' | myLbTranslate }}
                    </label>
                    <div class="form-content">
                        <input
                            [disabled]="snmpDisabled"
                            title="{{'snmp_conf_snmp_location' | myLbTranslate }}"
                            type="text"
                            size="30"
                            name="location"
                            formControlName="location"/>
                        <app-validation-messages [control]="userForm.controls.location"></app-validation-messages>
                        <app-help tag="snmp_location"></app-help>
                    </div>
                </div>
                <div class="form-group">
                    <label
                        [class.disabled]="userForm.get('contact').disabled">{{ 'snmp_conf_snmp_contact' | myLbTranslate }}</label>
                    <div class="form-content">
                        <input
                            [disabled]="snmpDisabled"
                            title="{{'snmp_conf_snmp_contact' | myLbTranslate }}"
                            type="text"
                            size="30"
                            name="contact"
                            formControlName="contact"/>
                        <app-validation-messages [control]="userForm.controls.contact"></app-validation-messages>
                        <app-help tag="snmp_contact"></app-help>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="showUsersSection()">
            <h2 id="users">{{ 'users' | myLbTranslate }}</h2>
            <div class="form-group">
                <label>{{ 'username' | myLbTranslate }}</label>
                <div class="form-content">
                    <input
                        title="{{'username' | myLbTranslate }}"
                        type="text"
                        size="30"
                        formControlName="username">
                    <app-validation-messages [control]="userForm.controls.username"></app-validation-messages>
                    <app-help tag="snmp_username"></app-help>
                </div>
            </div>
            <div class="form-group">
                <div class="form-content">
                    <div class="content radio-button-wrapper">
                        <input
                            type="radio"
                            id="snmp-auth-sha"
                            formControlName="authAlgorithm"
                            value="sha">
                        <label for="snmp-auth-sha">{{ 'sha' | myLbTranslate }}</label>
                        <input
                            type="radio"
                            id="snmp-auth-md5"
                            formControlName="authAlgorithm"
                            value="MD5">
                        <label for="snmp-auth-md5">{{ 'md5' | myLbTranslate }}</label>
                    </div>
                    <app-help tag="snmp_algorithm"></app-help>
                </div>
            </div>
            <div class="form-group">
                <label for="auth-passphrase">{{ 'snmp_conf_snmp_auth_passphrase' | myLbTranslate }}</label>
                <div class="form-content">
                    <app-lb-reveal-password
                        id="auth-passphrase"
                        title="{{'snmp_conf_snmp_auth_passphrase' | myLbTranslate }}"
                        formControlName="authPassphrase"
                        [formGroup]="userForm">
                    </app-lb-reveal-password>
                    <app-validation-messages [control]="userForm.controls.authPassphrase"></app-validation-messages>
                    <app-help tag="snmp_passphrase"></app-help>
                </div>
            </div>
            <div class="form-group">
                <label>{{ 'snmp_conf_snmp_privacy_alg' | myLbTranslate }}</label>
                <div class="form-content">
                    <div class="content radio-button-wrapper">
                        <input
                            type="radio"
                            id="snmp-privacy-aes"
                            formControlName="privacyAlgorithm"
                            value="AES">
                        <label for="snmp-privacy-aes">{{ 'aes' | myLbTranslate }}</label>
                        <input
                            type="radio"
                            id="snmp-privacy-des"
                            formControlName="privacyAlgorithm"
                            value="DES">
                        <label for="snmp-privacy-des">{{ 'des' | myLbTranslate }}</label>
                    </div>
                    <app-help tag="snmp_privacy-algorithm"></app-help>
                </div>
            </div>
            <div class="form-group">
                <label for="privacy-passphrase">{{ 'snmp_conf_snmp_privacy_passphrase' | myLbTranslate }}</label>
                <div class="form-content">
                    <app-lb-reveal-password
                        id="privacy-passphrase"
                        title="{{'snmp_conf_snmp_auth_passphrase' | myLbTranslate }}"
                        formControlName="privacyPassphrase"
                        [formGroup]="userForm">
                    </app-lb-reveal-password>
                    <app-validation-messages [control]="userForm.controls.privacyPassphrase"></app-validation-messages>
                    <app-help tag="snmp_privacy-passphrase"></app-help>
                </div>
            </div>
            <div class="form-group">
                <label for="permission">{{ 'permission' | myLbTranslate }}</label>
                <div class="form-content">
                    <div class="content radio-button-wrapper">
                        <input
                            type="radio"
                            id="read"
                            [checked]="!writeAccess"
                            (click)="setPermission(false)">
                        <label for="read">{{ 'read' | myLbTranslate }}</label>
                        <input
                            type="radio"
                            id="read-write"
                            [checked]="writeAccess"
                            (click)="setPermission(true)">
                        <label for="read-write">{{ 'read_write' | myLbTranslate }}</label>
                    </div>
                    <app-help tag="snmp_privacy-passphrase"></app-help>
                </div>
            </div>
        </ng-container>
    </form>
</div>
<div class="component-footer">
    <ng-container *ngIf="!snmpDisabled">
        <button class="outline" (click)="cancel()">{{ 'cancel' | myLbTranslate }}</button>
    </ng-container>
    <ng-container
        *ngIf="showSubmitButton">
        <app-loading-button
            buttonId="snmp-config-update"
            buttonText="submit"
            buttonClass="primary"
            [isDisabled]="userForm.invalid || userForm.pristine"
            [callBack]="submit.bind(this)">
        </app-loading-button>
    </ng-container>

    <!--  If no users and V3 enabled  -->
    <ng-container *ngIf="!users?.length && userForm.get('enabledV3').value">
        <ng-container *ngIf="activeStep === 2">
            <button class="outline" (click)="back()">{{ 'back' | myLbTranslate }}</button>
        </ng-container>
        <ng-container *ngIf="activeStep === 1">
            <button class="primary" (click)="next()">{{ 'next' | myLbTranslate }}</button>
        </ng-container>
        <ng-container *ngIf="activeStep === 2 && !userAdded">
            <app-loading-button
                buttonId="snmp-config-add"
                buttonText="add"
                buttonClass="primary"
                [isDisabled]="userForm.invalid || userForm.pristine"
                [callBack]="submitUserAndSettings.bind(this)">
            </app-loading-button>
        </ng-container>
        <ng-container *ngIf="activeStep === 2 && userAdded">
            <button class="primary" (click)="finish()">{{ 'skip_to_finish' | myLbTranslate }}</button>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="addEditUser || userAdded">
        <app-loading-button
            buttonId="snmp-config-add"
            buttonText="add"
            buttonClass="primary"
            [isDisabled]="userForm.invalid || userForm.pristine"
            [callBack]="submitSNMPUser.bind(this)">
        </app-loading-button>
    </ng-container>
</div>
