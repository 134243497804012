<div class="form-vertical">
    <div class="form-group">
        <app-dropdown
            dropDownId="timezone-continent"
            [disabled]="disabled"
            [displayAsInput]="true"
            [selectedKey]="initialTimeZone.continent"
            [content]="continents"
            [search]="true"
            (keyOut)="setContinent($event)">
        </app-dropdown>
    </div>
    <ng-container *ngIf="!singleSelector">
        <div class="form-group">
            <app-dropdown
                dropDownId="timezone-city"
                [disabled]="disabled || !regionDropdownContent.length"
                [displayAsInput]="true"
                [selectedKey]="initialTimeZone.region"
                [content]="regionDropdownContent"
                [search]="true"
                (keyOut)="setTimeZoneArea($event)">
            </app-dropdown>
        </div>
    </ng-container>
</div>
