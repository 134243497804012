<app-lb-wizard
    [hidden]="!wizardDataAvailable"
    [showProgressBar]="showProgressBar"
    [showSaveButton]="wizardData?.options?.showSaveButton"
    [showBackButton]="wizardData?.options?.showBackButton"
    [vertical]="true"
    [availableTemplates]="wizardData?.availableTemplates"
    [wizardModeState]="wizardData?.options?.showWizardMode"
    [wizardTitle]="wizardData?.wizardTitle | myLbTranslate"
    [cancelButtonText]="wizardData?.cancelText | myLbTranslate"
    (saveTemplate)="openSaveAsTemplateModal()"
    (cancelWizard)="cancelSubmission(wizardData?.parentUrl)"
    (wizardComplete)="submitService()">
    <ng-container *ngIf="description && isFirstVisibleStep()">
        <div class="description">
            <h2>{{ 'notes' | myLbTranslate }}:</h2>
            <p [innerHTML]="description"></p>
        </div>
    </ng-container>
    <ng-container #componentContainer></ng-container>
    <ng-container #saveTemplateContainer></ng-container>
</app-lb-wizard>

<ng-container #modalContainer></ng-container>
