<app-modal-wrapper
    [isDraggable]="confirmationObject.options.isDraggable"
    [isOverlayBlurred]="confirmationObject.options.isOverlayBlurred"
    [isOverlayClickable]="confirmationObject.options.isOverlayClickable"
    [showCloseButton]="confirmationObject.options.showCloseButton"
    modalId="confirmation-box"
    [modalTitle]="confirmationObject.title">
    <app-modal-body
        [innerHTML]="confirmationObject.options.translateText
            ? (confirmationObject.message | myLbTranslate) : confirmationObject.message">
    </app-modal-body>
    <app-modal-footer>
        <button
            *ngIf="!confirmationObject.options.hideCancelButton"
            id="modal-cancel"
            class="outline"
            (click)="cancelAction()">
            {{ 'cancel' | myLbTranslate }}
        </button>
        <app-loading-button
            buttonId="modal-confirm"
            buttonClass="primary"
            [buttonText]="confirmationObject.options.confirmTextKey"
            [callBack]="confirmAction.bind(this)">
        </app-loading-button>
    </app-modal-footer>
</app-modal-wrapper>
