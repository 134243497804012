import { Component } from '@angular/core';

@Component({
    selector: 'app-marketing-banner',
    templateUrl: './marketing-banner.component.html',
    styleUrls: ['./marketing-banner.component.scss']
})
export class MarketingBannerComponent {
    /**
     * State of banner
     */
    public hideBanner: boolean = false;

    constructor() {
    }

    public closeBanner() {
        this.hideBanner = true;
    }
}
