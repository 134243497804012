<app-external-page [formBlockFooterHeight]="80">
    <app-external-page-content-body>
        <div class="trial-wrapper">
            <div [hidden]="activeStep !== 1" class="step one">
                <h1 class="welcome-title">{{ 'free_trial_step_1_title' | myLbTranslate }}</h1>
                <p class="message-with-contact">{{ 'free_trial_step_1_intro' | myLbTranslate }}</p>
                <h2>{{ 'free_trial_step_1_simple_steps' | myLbTranslate }}</h2>
                <div class="simple-steps-wrapper">
                    <div class="simple-step">
                        <img
                            ngSrc="assets/external/icons/hyper-visors.svg"
                            alt="hyper visors icon"
                            height="31"
                            width="31"/>
                        <div class="step-text">
                            <p><strong>{{ 'hypervisor' | myLbTranslate | titlecase }}</strong></p>
                            <p>{{ 'free_trial_step_1_step_message_1' | myLbTranslate }}</p>
                        </div>
                    </div>
                    <div class="simple-step">
                        <img ngSrc="assets/external/icons/headphones.svg" alt="support icon" height="28" width="31"/>
                        <div class="step-text">
                            <p><strong>{{ 'support' | myLbTranslate | titlecase }}</strong></p>
                            <p>{{ 'free_trial_step_1_step_message_2' | myLbTranslate }}</p>
                        </div>
                    </div>
                    <div class="simple-step">
                        <img ngSrc="assets/external/icons/portal-dash.svg" alt="portal icon" height="31" width="31"/>
                        <div class="step-text">
                            <p><strong>{{ 'portal' | myLbTranslate | titlecase }}</strong></p>
                            <p>{{ 'free_trial_step_1_step_message_3' | myLbTranslate }}</p>
                        </div>
                    </div>
                </div>
                <div class="button-wrapper">
                    <button class="primary" (click)="nextStep()">
                        {{ 'free_trial_step_1_button' | myLbTranslate }}
                    </button>
                </div>
            </div>
            <div [hidden]="activeStep !== 2" class="step two">
                <div>
                    <h1 class="welcome-title">{{ 'free_trial_step_2_title' | myLbTranslate }}</h1>
                    <p class="message-with-contact">{{ 'free_trial_step_2_intro' | myLbTranslate }}</p>
                    <div *ngIf="progressState === adoptionEnum.WAITING" class="waiting">
                        <div class="row">
                            <app-dropdown
                                dropDownId="free-trial-choose-hypervisor"
                                startText="- Choose Hypervisor -"
                                [displayAsInput]="true"
                                [dropdownWidth]="230"
                                [content]="dropdown.hypervisors"
                                (keyOut)="chosenHypervisor($event)">
                            </app-dropdown>
                            <button
                                class="primary download"
                                [disabled]="!selectedHypervisor"
                                (click)="startDownload('main')">
                                {{ 'download' | myLbTranslate }}
                            </button>
                        </div>
                    </div>
                    <div *ngIf="progressState === adoptionEnum.DOWNLOADED" class="step-complete">
                        <span>{{ selectedHypervisor.title }} {{ 'adc_download_success' | myLbTranslate }}</span>
                    </div>
                    <div [hidden]="progressState !== adoptionEnum.DOWNLOADING">
                        <ng-container #progressContainer></ng-container>
                    </div>
                    <div *ngIf="selectedHypervisor" class="service-button-wrapper">
                        <div class="button-line">
                            <button
                                [disabled]="completedDownloads.includes('MD5')"
                                class="button-as-link"
                                (click)="startDownload('MD5')">
                                {{ 'download' | myLbTranslate }} MD5
                            </button>
                            <app-help class="help-button" tag="free-trial_md5-download"></app-help>
                        </div>
                        <div class="button-line">
                            <button
                                [disabled]="completedDownloads.includes('SHA')"
                                class="button-as-link"
                                (click)="startDownload('SHA')">
                                {{ 'download' | myLbTranslate }} SHA
                            </button>
                            <app-help class="help-button" tag="free-trial_sha-download"></app-help>
                        </div>
                    </div>
                    <div class="accordion-wrapper">
                        <app-lb-accordion>
                            <app-lb-accordion-group title="Minimum spec">
                                <p class="min-spec">{{ 'free_trial_step_2_min_spec' | myLbTranslate }}</p>
                            </app-lb-accordion-group>
                        </app-lb-accordion>
                        <div class="button-wrapper">
                            <button class="primary" [disabled]="!completedDownloads.length" (click)="nextStep()">
                                {{ 'next' | myLbTranslate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div [hidden]="activeStep !== 3" class="step three">
                <h1 class="welcome-title">{{ 'free_trial_step_3_title' | myLbTranslate }}</h1>
                <p class="message-with-contact">{{ 'free_trial_step_3_intro' | myLbTranslate }}</p>
                <p class="message-with-contact">{{ 'free_trial_step_3_intro_2' | myLbTranslate }}</p>
                <a class="primary external" href="http://pdfs.loadbalancer.org/quickstartguideLBv8.pdf" target="_blank">
                    {{ 'free_trial_step_3_quick_start' | myLbTranslate }}
                </a>
                <hr>
                <h1 class="welcome-title">{{ 'free_trial_step_3_title_2' | myLbTranslate }}</h1>
                <p>{{ 'free_trial_step_3_intro_3' | myLbTranslate }}</p>
                <div class="button-wrapper">
                    <button class="primary" (click)="nextStep()">{{ 'free_trial_step_3_button' | myLbTranslate }}
                    </button>
                </div>
            </div>
            <div [hidden]="activeStep !== 4" class="step four">
                <h1 class="welcome-title">{{ 'free_trial_step_4_title' | myLbTranslate }}</h1>
                <p>{{ 'free_trial_step_4_intro' | myLbTranslate }}</p>
                <div class="simple-steps-wrapper">
                    <div class="simple-step">
                        <img src="assets/external/icons/dashboard.svg" alt="dashboard icon"/>
                        <div class="step-text">
                            <p><strong>{{ 'dashboard' | myLbTranslate | titlecase }}</strong></p>
                            <p>{{ 'free_trial_step_4_step_message_1' | myLbTranslate }}</p>
                        </div>
                    </div>
                    <div class="simple-step">
                        <img src="assets/external/icons/automate.svg" alt="automate icon"/>
                        <div class="step-text">
                            <p><strong>{{ 'support' | myLbTranslate | titlecase }}</strong></p>
                            <p>{{ 'free_trial_step_4_step_message_2' | myLbTranslate }}</p>
                        </div>
                    </div>
                    <div class="simple-step">
                        <img src="../../../assets/external/icons/shield.svg" alt="security icon"/>
                        <div class="step-text">
                            <p><strong>{{ 'portal' | myLbTranslate | titlecase }}</strong></p>
                            <p>{{ 'free_trial_step_4_step_message_3' | myLbTranslate }}</p>
                        </div>
                    </div>
                </div>

                <div class="button-wrapper">
                    <button class="primary" (click)="activateTrial()">
                        {{ 'free_trial_step_4_button_activate_trial' | myLbTranslate }}
                    </button>
                    <button class="outline" (click)="nextStep()">
                        {{ 'free_trial_step_4_button_action_later' | myLbTranslate }}
                    </button>
                </div>
            </div>
            <div [hidden]="activeStep !== 5" class="step five">
                <h1 class="welcome-title">{{ 'free_trial_step_5_title' | myLbTranslate }}</h1>
                <p>{{ 'free_trial_step_5_intro' | myLbTranslate }}</p>
                <p>
                    {{ 'free_trial_step_5_intro_2' | myLbTranslate }}
                    <a class="primary" href="mailto:support@loadbalancer.org">(support@loadbalancer.org)</a>,
                    {{ 'free_trial_step_5_intro_3' | myLbTranslate }}
                    <a class="primary" href="www.loadbalancer.org/support"
                       target="_blank">www.loadbalancer.org/support</a>
                </p>
                <div class="button-wrapper">
                    <button class="primary" (click)="loadCalendly()">
                        {{ 'free_trial_step_5_button_schedule_demo' | myLbTranslate }}
                    </button>
                    <button class="outline" routerLink="/login">
                        {{ 'free_trial_step_5_button_skip' | myLbTranslate }}
                    </button>
                </div>
            </div>
        </div>
    </app-external-page-content-body>
    <app-external-page-content-footer>
        <div class="progress-wrapper">
            <div class="progress-step" (click)="setActiveStep(1)">
                <span>{{ 'welcome' | myLbTranslate }}</span>
                <div class="dot" [class.active]="activeStep >= 1"></div>
                <div class="line"></div>
            </div>
            <div class="progress-step" (click)="setActiveStep(2)">
                <span>Choose Hypervisor</span>
                <div class="dot" [class.active]="activeStep >= 2"></div>
                <div class="line"></div>
            </div>
            <div class="progress-step" (click)="setActiveStep(3)">
                <span>Set up Hypervisor</span>
                <div class="dot" [class.active]="activeStep >= 3"></div>
                <div class="line"></div>
            </div>
            <div class="progress-step" (click)="setActiveStep(4)">
                <span>
                    Register for Portal
                    <br><span class="note">Optional</span>
                </span>
                <div class="dot" [class.active]="activeStep >= 4"></div>
                <div class="line"></div>
            </div>
            <div class="progress-step" (click)="setActiveStep(5)">
                <span>
                    Request Demo
                    <br><span class="note">Optional</span>
                </span>
                <div class="dot" [class.active]="activeStep >= 5"></div>
                <div class="line"></div>
            </div>
        </div>
    </app-external-page-content-footer>
</app-external-page>

<app-calendly-widget
    *ngIf="userObject?.name || userObject.name === undefined"
    [userDetails]="userObject"
    [hidden]="hideCalendly"
    (hideCalendly)="hideCalendlyWidget()">
</app-calendly-widget>
