<div id="error-page-wrapper" class="external-page">
    <div id="main-content">
        <span class="title">{{ pageTitle }}</span>
        <span class="sub-title">{{ pageSubTitle | myLbTranslate }}</span>
        <ng-container *ngIf="userService.hasLocalStorage(); else login">
            <button class="external primary" (click)="goBack()">
                {{ 'go_back' | myLbTranslate }}
            </button>
        </ng-container>
        <ng-template #login>
            <button class="external primary" (click)="goToLogin()">{{ 'login_in' | myLbTranslate }}</button>
        </ng-template>
    </div>
</div>
<div id="copyright-footer">
    <span>&copy; Loadbalancer.org, {{ currentYear }} Inc. {{ 'external_all_rights_reserved' | myLbTranslate }}</span>
</div>
