<div class="component-main">
    <div class="content">
        <h1 id="component-title">
            {{ (isDuplicate ? 'rbac_role_duplicate' : editId ? 'rbac_roles_edit_role' : 'rbac_roles_add_role') | myLbTranslate | titlecase }}
        </h1>
    </div>
    <form [formGroup]="formGroup" class="process-container">
        <div
            #step
            class="step"
            [class.active]="activeStep === 1"
            [class.complete]="activeStep > 1"
            formGroupName="step1">
            <h3>{{ 'rbac_role_details' | myLbTranslate }}</h3>
            <div class="step-content">
                <ng-container *ngIf="activeStep === 1">
                    <div class="form-group">
                        <label for="name">{{ 'rbac_role_name' | myLbTranslate }}*</label>
                        <div class="form-content">
                            <input
                                formControlName="name"
                                id="name"
                                name="name"
                                type="text">
                            <app-validation-messages [control]="formGroup.get('step1.name')"></app-validation-messages>
                        </div>
                    </div>
                    <div *ngIf="isDuplicate" class="form-group">
                        <label for="duplicateName">{{ 'rbac_role_duplicated_from' | myLbTranslate }}*</label>
                        <div class="form-content">
                            <input
                                formControlName="duplicateName"
                                id="duplicateName"
                                name="roleName"
                                type="text">
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="activeStep > 1">
                    <div class="set-content">
                        <span>
                            <strong>{{ 'rbac_role_name' | myLbTranslate }}:</strong>
                            {{ formGroup.get('step1.name').value }}
                        </span>
                        <span *ngIf="isDuplicate">
                            <strong>{{ 'rbac_role_duplicated_from' | myLbTranslate }}:</strong>
                            {{ formGroup.get('step1.duplicateName').value }}
                        </span>
                        <span>
                            <button class="button-as-link quinary right-arrow" (click)="setStep(1)">
                                {{ 'edit' | myLbTranslate }}
                            </button>
                        </span>
                    </div>
                </ng-container>
            </div>
        </div>
        <div
            #step
            class="step"
            [class.active]="activeStep === 2"
            [class.complete]="activeStep > 2"
            formGroupName="step1">
            <h3>{{ 'rbac_role_management' | myLbTranslate }}</h3>
            <div class="step-content">
                <ng-container *ngIf="activeStep >= 2">
                    <div class="form-group">
                        <app-rbac-endpoints
                            [endpoints]="endpoints"
                            [grouping]="grouping"
                            [forceCollapse]="activeStep === 3"
                            [isDisabled]="activeStep === 3"
                            (endpointsEventEmitter)="setEndpointsInForm($event)">
                        </app-rbac-endpoints>
                    </div>
                </ng-container>
            </div>
        </div>
        <div
            #step
            class="step"
            [class.active]="activeStep === 3"
            [class.complete]="activeStep > 3"
            formGroupName="step1">
            <h3>{{ 'ready' | myLbTranslate }}</h3>
            <div class="step-content">
                <ng-container *ngIf="activeStep === 3">
                    <p>{{ 'rbac_role_ready_message' | myLbTranslate }}</p>
                </ng-container>
            </div>
        </div>
    </form>
</div>
<div class="component-footer">
    <button id="roles-cancel" class="outline" [routerLink]="getCancelButtonRoute()">
        {{ 'cancel' | myLbTranslate }}
    </button>
    <button id="adc-new-back" class="primary" [disabled]="isBackButtonDisabled()" (click)="backStep()">
        {{ 'back' | myLbTranslate }}
    </button>
    <button
        *ngIf="activeStep < 3"
        id="adc-new-next"
        class="primary"
        [disabled]="isNextButtonDisabled()"
        (click)="nextStep()">
        {{ 'next' | myLbTranslate }}
    </button>
    <app-loading-button
        *ngIf="activeStep === 3"
        buttonId="update-config"
        buttonClass="primary"
        buttonText="submit"
        [isDisabled]="formGroup.invalid || formGroup.pristine"
        [callBack]="submitHelper.bind(this)">
    </app-loading-button>
</div>
