<div class="wrapper {{size}}">
    <div class="product-box">
        <div class="face -front">
            <ng-content></ng-content>
            <div class="space {{size}}">
                <div class="vents {{size}}"></div>
                <div class="active-state {{size}}" [class.complete]="complete"></div>
            </div>
        </div>
        <div class="face -left"></div>
        <div class="face -top"></div>
        <div class="face -bottom"></div>
        <div class="face -bottom shadow {{size}}"></div>
        <div class="face -right">
            <div class="space {{size}}">
                <div class="vents {{size}}"></div>
            </div>
        </div>
        <div class="face -back"></div>
    </div>

    <div class="rack-mount {{size}}">
        <div class="hole"></div>
        <div class="hole"></div>
        <div class="hole"></div>
        <div class="hole"></div>
        <div class="gap"></div>
    </div>
</div>
